import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import {InputSwitch} from 'primereact/inputswitch';
import { Message } from 'primereact/message';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toolbar} from 'primereact/toolbar';
import { useHistory, Link } from "react-router-dom";
import loader from '../loader';
import typeOptions from './types.json';
import { ProgressSpinner } from 'primereact/progressspinner';
import Session from '../auth/Session';
import timeSetup from '../timeSetup';

const getItems = (res, setItems) => {
    let titems = res.items;
    titems = titems.map(i => ({
        ...i,
        start: timeSetup.from(i.start).format('YYYY-MM-DD HH:mm'),
        end: timeSetup.from(i.end).format('YYYY-MM-DD HH:mm')    
    }))
    setItems(titems);
}

function OutOfServiceList(props) {
  const { user_id } = props;

  let [ items, setItems ] = useState([]);
  
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  let history = useHistory();

  useEffect(() => {
    if (user_id) {
        loader.get('/outofservice/user/' + user_id, res => {
            if (!res.success) return;
            getItems(res, setItems);
        }, setLoading);
    } else {
        loader.get('/outofservice/list', res => {
            if (!res.success) return;
            getItems(res, setItems);
        }, setLoading);    
    }
  }, []);

  let updateItem = (data) => {
    setSuccess(false);
    loader.post('/outofservice/approve', data, res => {
        setSuccess(true);
    }, setLoading);
  };

  let deleteItem = (id) => {
    const url = '/outofservice/delete/' + id;
    setSuccess(false);
    loader.get(url, res => {
        if (!res.success) return;
        setSuccess(true);
        items = items.filter(i => i.id !== id);
        setItems(items);
    }, setLoading);
  };

  let toggleAuthorized = (outofservices, id) => {
    let items = Object.assign([], outofservices);
    items = items.map(item => {
        if (item.id === id) {
            item.authorized = !item.authorized;
            updateItem({ id: item.id, authorized: item.authorized });
        }
        return item;
    });
    setItems(items);
  }

  let recordOptions = (item, column) => {
    const logged = Session.getCookie();
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <InputSwitch 
                checked={item.authorized} 
                onChange={(e) => toggleAuthorized(items, item.id)} 
            />
            { String(logged.id) === String(item.user_id) &&
                <Button
                    onClick={e => history.push({ pathname: '/backoffice/outofservices/form/' + item.id })} 
                    type="button" 
                    icon="pi pi-pencil" 
                    className="p-button-info"
                />
            }
            { [logged.role1].includes('ADMINISTRATOR') &&
                <Button
                    onClick={e => deleteItem(item.id)} 
                    type="button" 
                    icon="pi pi-times" 
                    className="p-button-danger"
                />
            }
        </div>
    );
  }

  let recordAuthorized = (item, column) => {
    if (item.authorized) return 'Sim';
    else return 'Não';
  }

  const getOptionLabel = (options, value) => {
    const item = options.find(opt => opt.value === value);
    return item ? item.label : value;
  }

  if (loading) return <ProgressSpinner />;
  let createUrl = "/backoffice/outofservices/form/0" + (user_id ? "/" + user_id : "");
  return (
    <React.Fragment>

        <Toolbar>
            <div className="p-toolbar-group-left">
                <Link to={createUrl}>
                    <Button label="Inserir Nova" />
                </Link>
            </div>
            <div className="p-toolbar-group-right">
                
            </div>
        </Toolbar>

        { success && (
            <div className="p-grid">
                <div className="p-col-12">

                    <Message 
                        severity="success" 
                        text="A ausência foi alterada com sucesso!">
                    </Message>
                    
                </div>
            </div>
        ) }

        
        <div className="p-grid">
            <div className="p-col-12">

            <DataTable value={items} responsive={true}>
                { !user_id && (
                    <Column 
                        header="Utilizador"
                        field="username"  
                        sortable={true}
                        filter={true}
                        filterMatchMode="contains"
                    />
                ) }
                
                <Column 
                    header="Tipo" 
                    field="type"
                    sortable={true}
                    filter={true}
                    filterMatchMode="contains"
                    body={i => getOptionLabel(typeOptions, i.type)} 
                />
                
                <Column 
                    header="Inicio" 
                    filter={true}
                    filterMatchMode="contains"
                    body={item => item.start ? item.start : ''}
                    field="start"
                />
                <Column 
                    header="Fim" 
                    filter={true}
                    filterMatchMode="contains"
                    body={item => item.end ? item.end : ''}
                    field="end"
                />

                <Column body={recordAuthorized} header="Autorizado" field="authorized" />
                <Column body={recordOptions} header="Opções" />
            </DataTable>
                
            </div>
        </div>
            
    </React.Fragment>
  );

}

export default OutOfServiceList;
