import React, { useState, useEffect } from 'react';
import { Route, useParams } from "react-router-dom";
import Session from '../auth/Session';
import loader from '../loader';
import { ProgressSpinner } from 'primereact/progressspinner';
import ClientsForm from '../clients/ClientsForm';
import ClientsResume from '../clients/ClientsResume';
import ClientsPLans from '../clients/ClientsPlans';
import ClientsTherapy from '../clients/ClientsTherapy';
import ClientsHistory from '../clients/ClientsHistory';
import ClientsEntities from '../clients/ClientsEntities';
import ClientsLocation from '../clients/ClientsLocation';
import ClientsDocs from '../clients/ClientsDocs';
import ClientOutList from './ClientOutList';
import ClientsMenu from './ClientsMenu';
import ClientsReport from './ClientsReport';
import ClientsGraphs from '../clients/ClientsGraphs';

function ClientsRoute() {

  // Load diary
  let [ client, setClient ] = useState(null);
  let [ loading, setLoading ] = useState(false);

  let logged = Session.getCookie();
  let id = logged.client_id;
  let urlParams = useParams();
  let action = urlParams.action;

  // Load client
  useEffect(() => {
      setLoading(true);
    if (!parseInt(id, 10)) return;
    loader.get('/client/item/' + id, res => {
        setClient(res.item);
        setLoading(false);
    });
  }, [id]);

  if (loading || !client) return <ProgressSpinner />;
  
  let title = client ? client.firstname + ' ' + client.lastname : "Novo Cliente";
  return (
    <div>
        <h1 className="subtitle">{ title }</h1>

        <ClientsMenu client={client} activeMenu={action} />

        <Route path="/client/info/view">
            <ClientsResume client={client} logged={logged} />
        </Route>

        <Route path="/client/info/form">
            <ClientsForm id={id} />
        </Route>
        
        <Route path="/client/info/plans">
            <ClientsPLans client={client} />
        </Route>
        <Route path="/client/info/therapy">
            <ClientsTherapy client={client} />
        </Route>
        <Route path="/client/info/history">
            <ClientsHistory client={client} />
        </Route>
        <Route path="/client/info/entities">
            <ClientsEntities client={client} />
        </Route>
        <Route path="/client/info/location">
            <ClientsLocation client={client} />
        </Route>
        <Route path="/client/info/docs">
            <ClientsDocs client={client} />
        </Route>
        <Route path="/client/info/out">
            <ClientOutList client={client} />
        </Route>
        <Route path="/client/info/graphs">
            <ClientsGraphs client={client} />
        </Route>
        <Route path="/client/info/report">
            <ClientsReport client={client} />
        </Route>
        
    </div>
  );

}

export default ClientsRoute;
