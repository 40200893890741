import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from "react-router-dom";
import DiaryForm from './DiaryForm';
import Session from '../auth/Session';
import loader from '../loader';
import { ProgressSpinner } from 'primereact/progressspinner';
import { getParams } from '../helpers';
import UnAuthorized from '../auth/UnAuthorized';

function DiaryFormRoute() {

  // Load diary
  let urlParams = useParams();
  let params = getParams(useLocation().search);
  let [ diary, setDiary ] = useState(null);
  let [ loading, setLoading ] = useState(false);
  let logged = Session.getCookie();

  // Load diary
  let id = urlParams.id;
  useEffect(() => {
    if (!parseInt(id, 10)) return;
    loader.get('/diary/item/' + id, res => {
        setDiary(res.item);
    }, setLoading);
  }, [id]);

  if (loading) return <ProgressSpinner />;
  
  let title = diary ? diary.title : "Criar Tarefa Pontual";
  const therapy = !!parseInt(params.therapy, 10);
  const incident = !!parseInt(params.incident, 10);
  const parent_id = Number(params.parent_id) || null;
  const client_id = Number(params.client_id) || null;
  if (incident) title = diary ? diary.title : "Nova Ocorrência";
  const canAssign = ['ADMINISTRATOR', 'ORG_ADMIN'].includes(logged.role1);
  const user = canAssign ? null : logged;

  // Check permissions
  const allowed = Session.canAccessDiary(logged, diary); 
  if (!allowed.result) {
    return <div className="main-panel-small"><UnAuthorized reasons={allowed.reasons || []} /></div>;
  }

  return (
    <React.Fragment>
        <div className="card card-w-title main-panel-small create-diary">
            <h1>{title}</h1>

            <DiaryForm 
              logged={logged}
              id={urlParams.id} 
              user={user} 
              therapy={therapy} 
              incident={incident}
              parent_id={parent_id}
              client_id={client_id}
            />

        </div>
    </React.Fragment>
  );

}

export default DiaryFormRoute;
