import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import {PickList} from 'primereact/picklist';
import { useParams } from "react-router-dom";
import roles from '../users/roles';
import loader from '../loader';
import { ProgressSpinner } from 'primereact/progressspinner';

const initialState = {
    topics: []
}

function RolesForm() {

  let [ role, setRole ] = useState(initialState);
  let [ topics, setTopics ] = useState([]);
  
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  // Load topics
  useEffect(() => {
    loader.get('/topic/all/activities', res => {
        setTopics(res.items);
    }, setLoading);
  }, []);

  // Load role
  let urlParams = useParams();
  useEffect(() => {
    if (!urlParams.id) return;
    let role = roles.find(item => item.id === urlParams.id);
    loader.get('/role/topics/' + urlParams.id, res => {
        role.topics = res.items;
        setRole(role);
    }, setLoading);
  }, [urlParams.id]);

  // Register role
  let register = () => {
    let data = {
        ...role,
        topics: role.topics.map(f => f.id)
    };
    let url = '/role/update';
    setSuccess(false);
    loader.post(url, data, res => {
        setSuccess(true);
    }, setLoading);
  };

  const availableTopics = topics.filter(item1 => 
    !role.topics.find(item2 => item1.id === item2.id)
  );

  if (loading) return <ProgressSpinner />;
  return (
    <React.Fragment>
        <div className="card card-w-title">
            <h1>{ role.label }</h1>

            { success && (
                <div className="p-grid">
                    <div className="p-col-12">

                        <Message 
                            severity="success" 
                            text="A função foi guardada com sucesso!">
                        </Message>
                        
                    </div>
                </div>
            ) }

            <Button 
                onClick={register} 
                label="Guardar"
                style={{ float: 'right' }}
            />

            <div className="card card-w-title">
                <h2>Tarefas</h2>
                <PickList 
                    source={availableTopics} 
                    target={role.topics} 
                    sourceHeader="Tarefas disponíveis"
                    targetHeader="Tarefas associadas"
                    itemTemplate={item => item.title}
                    responsive={false}
                    onChange={(e) => 
                        setRole({ ...role, topics: e.target })
                    }
                />
            </div>
        </div>

    </React.Fragment>
  );

}

export default RolesForm;
