import React, { useState, useEffect } from 'react';
import {Calendar} from 'primereact/calendar';
import loader from '../loader';
import moment from 'moment';
import date_pt from '../date_pt.json';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';
import Session from '../auth/Session';

const serverUrl = process.env.REACT_APP_SERVER_URL;
let dt = null;

function DiaryIncidents(props) {

  let logged = Session.getCookie();
  let client_id = logged.client_id;

  let [ items, setItems ] = useState([]);
  let [ start, setStart ] = useState(moment().subtract(1, 'month').toDate());
  let [ end, setEnd ] = useState(moment().toDate());
  
  let [ loading, setLoading ] = useState(false);

  useEffect(() => {
    let tstart = moment(start).format('YYYY-MM-DD');
    let tend = moment(end).format('YYYY-MM-DD');
    loader.get('/diary/incidents/list/' + tstart + '/' + tend + '/' + client_id, res => {
        setItems(res.items);
    }, setLoading);
  }, [start, end]);

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/photo_placeholder.png';
  }

  let recordPhoto = (item, column) => {
    return (
      <Link to={"/backoffice/clients/view/" + item.client_id}>
        <img 
            src={getFileUrl(item.clientphoto, 'clients')} 
            alt="Fotografia"
            title="Fotografia"
            className="img-avatar-mini"
        />
      </Link>
    )
  }

  if (loading) return <ProgressSpinner />;
  return (

    <div className="card summary" style={{ minHeight: '180px'}}>
        
        <span className="title">Ocorrências</span>
        <span className="count incidents">{ items.length }</span>

        <div className="p-grid" style={{ marginTop: '20px' }}>
            <div className="p-col-12 p-lg-4">
                <Calendar 
                    placeholder="Data de Inicio"
                    value={start} 
                    locale={date_pt}
                    dateFormat="yy-mm-dd"
                    yearNavigator={true}
                    yearRange="2020:2030"
                    onChange={(e) => setStart(e.value) }>
                </Calendar>
            </div>
            <div className="p-col-12 p-lg-4">
                <Calendar 
                    placeholder="Data de Fim"
                    value={end} 
                    locale={date_pt}
                    dateFormat="yy-mm-dd"
                    yearNavigator={true}
                    yearRange="2020:2030"
                    onChange={(e) => setEnd(e.value) }>
                </Calendar>
            </div>
            <div className="p-col-12 p-lg-4">
                <Button
                    type="button" 
                    icon="pi pi-external-link" 
                    iconPos="left" 
                    label="CSV" 
                    onClick={e => dt.exportCSV()}
                />        
            </div>
        </div>

        { items.length === 0 ? <p>Sem dados a apresentar</p> : (
            
            <DataTable 
                value={items} 
                ref={(el) => { dt = el; }}
                style={{ textAlign: 'center' }}>

                <Column 
                    header="Cliente"
                    field="clientname"
                    body={recordPhoto}
                    filter={true}
                    filterMatchMode="contains"
                    style={{ textAlign: 'center', width: '63px' }}
                />

                <Column 
                    header="Etiqueta"
                    field="title"
                    filter={true}
                    filterMatchMode="contains"
                    body={item => (
                        <Link to={"/backoffice/diary/view/" + item.id}>
                            <div className="taskname">{ item.title }</div>
                            <small className="subtitle"><em>{ moment(item.datahora).format('ddd D HH:mm') }</em></small>
                        </Link>
                    ) }
                />

            </DataTable>
            
        ) }
        
    </div>
    
  );

}

export default DiaryIncidents;
