import React from 'react';
import WorkPlansForm from './WorkPlansForm';
import { useParams } from "react-router-dom";

function WorkPlansFormRoute() {

  let urlParams = useParams();

  let title = parseInt(urlParams.id, 10) ? "Alterar Horário" : "Novo Horário";
  return (
    <React.Fragment>
        <div className="card card-w-title">
          <h1>{ title }</h1>
            <WorkPlansForm 
                id={urlParams.id}
                owner_id={urlParams.owner_id} 
            />
        </div>
    </React.Fragment>
  );

}

export default WorkPlansFormRoute;
