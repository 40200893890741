import React, { useState, useEffect, useReducer } from 'react';
import { Button } from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import { Message } from 'primereact/message';
import {FileUpload} from 'primereact/fileupload';
import { useParams, Link } from "react-router-dom";
import loader from '../loader';
import { ProgressSpinner } from 'primereact/progressspinner';
import date_pt from '../plans/date_pt.json';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import Session from '../auth/Session';
import { Dialog } from 'primereact/dialog';
import ProductForm from './ProductForm';
import { InputText } from 'primereact/inputtext';
import timeSetup from '../timeSetup';

const serverUrl = process.env.REACT_APP_SERVER_URL;

function StockForm() {

    const logged = Session.getCookie();

    const initialState = {
        datahora: moment().toDate(),
        from_id: null,
        to_id: null,
        owner_id: logged.id,
        product_id: '',
        received_by: null,
        received_datahora: null,
        amount: 0
    }

  let [ stock, dispatch ] = useReducer(reducer, initialState);
  let [ entities, setEntities ] = useState([]);
  let [ users, setUsers ] = useState([]);
  let [ products, setProducts ] = useState([]);
  
  let [ errors, setErrors ] = useState({});
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  let [ productModal, setProductModal ] = useState(false);

  function reducer(state, action) {
    switch (action.type) {
      case 'update':
        return {
            ...state,
            [action.field]: action.value
        };
      case 'update_all':
        return {
            ...action.value
        }
      default:
        throw new Error();
    }
  }

  // Load entities
  useEffect(() => {
    loader.get('/entity/list?media=Endereço', res => {
        setEntities(res.items);
    }, setLoading);
  }, []);

  // Load users
  useEffect(() => {
    loader.get('/user/list', res => {
        setUsers(res.items);
    }, setLoading);
  }, []);

  // Load products
  const reloadProducts = (from_id) => {
    if (!from_id) return;
    loader.get('/product/list/1/' + from_id, res => {
        setProducts(res.items);
    }, setLoading);
    setProductModal(false);
  }
  useEffect(() => {
      reloadProducts(stock.from_id);
  }, [stock.from_id]);

  // Load stock
  let urlParams = useParams();
  useEffect(() => {
    if (!parseInt(urlParams.id, 10)) {
        return dispatch({ type: 'update_all', value: initialState });
    }
    loader.get('/stock/item/' + urlParams.id, res => {
        let item = res.item;
        item.datahora = timeSetup.from(item.datahora).toDate();
        item.received_datahora = item.received_datahora ? timeSetup.from(item.received_datahora).toDate() : null;
        dispatch({ type: 'update_all', value: item });
    }, setLoading);
  }, [urlParams.id]);

  // Register stock
  let register = () => {
    let data = {
        ...stock,
        datahora: timeSetup.to(stock.datahora).toISOString(),
        received_datahora: stock.received_datahora ? timeSetup.to(stock.received_datahora).toISOString() : null
    };
    let url = '/stock/create';
    if (stock.id) url = '/stock/update';
    setSuccess(false);
    loader.post(url, data, res => {
        if (res.errors) return setErrors(res.errors);
        dispatch({ type: 'update', field: 'id', value: res.id });
        setSuccess(true);
    }, setLoading);
  };

  let isDataInvalid = () => {
      return loading
        || !stock.from_id
        || !stock.datahora
        || !stock.to_id
        || !stock.product_id
        || stock.amount === 0;
  }

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/photo_placeholder.png';
  }

  // Upload
  const onUpload = (e) => {
    if (e.files.length === 0) return;
    
    const formData  = new FormData();
    formData.append('folder', 'stocks');
    formData.append('upload', e.files[0]);
    const url = '/upload';
    loader.upload(url, formData, res => {
      if (!res.filename) return console.log(res);
      dispatch({ type: 'update', field: 'transport_doc', value: res.filename });  
    }, setLoading);
  }

  const onRemoveFile = (e, file) => {
    dispatch({ type: 'update', field: 'image', value: '' });
  }

  let displayErrors = (errors, key) => !!errors[key] && errors[key].map(msg =>
    <Message severity="error" text={msg}></Message>
  )

  const entityOptions = entities.map(opt => ({
    label: opt.name,
    value: opt.id,
    key: opt.id
  }));

  const userOptions = users.map(opt => ({
    label: opt.firstname + ' ' + opt.lastname,
    value: opt.id,
    key: opt.id
  }));

  const productOptions = products.map(opt => ({
    label: opt.name,
    value: opt.id,
    key: opt.id
  }));
  productOptions.unshift({
    label: '-- Adicionar --',
    value: '',
    key: 'new'
  });

  const isMobile = window.innerWidth <= 768;
  if (loading) return <ProgressSpinner />;
  const title = stock.id ? "Corrigir Movimento" : "Registar Movimento";
  const productSelected = products.find(i => String(i.id) === String(stock.product_id));
  return (
    <React.Fragment>
        <div className="card card-w-title">
            <h1>{ title }</h1>

            { success && (
                <div className="p-grid">
                    <div className="p-col-12">

                        <Message 
                            severity="success" 
                            text="O movimento foi registado com sucesso!">
                        </Message>
                        
                    </div>
                </div>
            ) }

              <Button 
                onClick={register} 
                label="Guardar"
                disabled={isDataInvalid()}
                style={{ float: 'right' }}
              />

              <p className="form-info">
                  <strong style={{ color: 'red' }}>*</strong> 
                  {' '}Campos obrigatórios
              </p>

              <div className="p-grid">
                <div className="p-col-12 p-lg-6">
                    <div className="p-grid">

                    <div className="p-col-12 p-md-6">
                            <label className="field-required">Data / Hora do Movimento</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <Calendar 
                                placeholder="Data / Hora"
                                value={stock.datahora || ''}
                                locale={date_pt}
                                monthNavigator={true} 
                                yearNavigator={true} 
                                yearRange="2020:2030"
                                dateFormat="yy-mm-dd"
                                showTime={true}
                                hourFormat="24"
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'datahora', value: e.value })
                                }>
                            </Calendar>
                        </div>

                        <div className="p-col-12 p-md-6">
                            <label className="field-required">Origem</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <Dropdown 
                                placeholder="Escolha uma localização"
                                value={parseInt(stock.from_id, 10)}
                                options={entityOptions} 
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'from_id', value: e.value })
                                }
                            />
                        </div>

                        <div className="p-col-12 p-md-6">
                            <label className="field-required">Produto</label>
                        </div>
                        <div className="p-col-12 p-md-6" style={{ position: 'relative' }}>
                            <Dropdown 
                                placeholder="Escolha um produto"
                                value={parseInt(stock.product_id, 10)}
                                options={productOptions} 
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'product_id', value: e.value })
                                }
                            />
                            { !!stock.product_id ? (
                                <Button icon="pi pi-pencil" 
                                    style={{ position: 'absolute', right: '8px', top: '7px' }}
                                    onClick={e => setProductModal(true)} 
                                />
                            ) : (
                                <Button icon="pi pi-plus" 
                                    style={{ position: 'absolute', right: '8px', top: '7px' }}
                                    onClick={e => setProductModal(true)} 
                                />
                            )}
                            
                        </div>

                        { !!productSelected && (
                            <React.Fragment>
                                <div className="p-col-12 p-md-6">
                                    <label className="field-required">Unidades</label>
                                </div>
                                <div className="p-col-6 p-md-3">
                                    <div>Movimento</div>
                                    <InputText
                                        type="number" 
                                        placeholder="Nome"
                                        value={stock.amount}
                                        onChange={(e) => 
                                            dispatch({ type: 'update', field: 'amount', value: e.target.value })
                                        }
                                    />
                                </div>
                                <div className="p-col-6 p-md-3">
                                    <div><em>Disponível</em></div>
                                    { !!productSelected && (
                                        <InputText
                                            style={{ fontStyle: 'italic' }}
                                            value={productSelected.stock - stock.amount}
                                        />
                                    )}
                                </div>
                            </React.Fragment>
                        ) }

                        <div className="p-col-12 p-md-6">
                            <label className="field-required">Destino</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <Dropdown 
                                placeholder="Escolha uma localização"
                                value={parseInt(stock.to_id, 10)}
                                options={entityOptions} 
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'to_id', value: e.value })
                                }
                            />
                        </div>

                        <div className="p-col-12 p-md-6">
                            <label>Recebido Por</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <Dropdown 
                                placeholder="Escolha um utilizador"
                                value={parseInt(stock.received_by, 10)}
                                options={userOptions} 
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'received_by', value: e.value })
                                }
                            />
                        </div>

                        <div className="p-col-12 p-md-6">
                            <label>Recebido em</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <Calendar 
                                placeholder="Data / Hora"
                                value={stock.received_datahora || ''}
                                locale={date_pt}
                                monthNavigator={true} 
                                yearNavigator={true} 
                                yearRange="2020:2030"
                                dateFormat="yy-mm-dd"
                                showTime={true}
                                hourFormat="24"
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'received_datahora', value: e.value })
                                }>
                            </Calendar>
                        </div>

                        <div className="p-col-12 p-md-6">
                            <label>Guia de Transporte</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            { !!stock.transport_doc && (
                                <Link to={getFileUrl(stock.image, 'stocks')}>
                                    <Button label="Descarregar" icon="pi pi-download" />
                                </Link>
                            ) }{' '}
                            <FileUpload 
                                name="image"
                                mode="basic"
                                url={'/upload'}
                                customUpload={true}
                                uploadHandler={onUpload}
                                auto={true}
                                chooseLabel={stock.transport_doc ? "Alterar" : "Escolher" }
                                onRemove={onRemoveFile}
                                style={{ overflow: 'visible' }}
                            />
                        </div>

                   </div>
              </div>
            </div>
        </div>

        <Dialog
            header="Adicionar Produto" 
            visible={productModal} 
            style={{width: isMobile ? '100%' : '50vw' }} 
            modal={true} 
            onHide={e => setProductModal(false)}>
                <ProductForm
                    id={stock.product_id} 
                    onSave={product => {
                    dispatch({ type: 'update', field: 'product_id', value: product.id });
                    reloadProducts(stock.from_id); 
                }} />
        </Dialog>

    </React.Fragment>
  );

}

export default StockForm;
