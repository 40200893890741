import React, { useState, useEffect, useReducer } from 'react';
import { Button } from 'primereact/button';
import {InputSwitch} from 'primereact/inputswitch';
import {Dropdown} from 'primereact/dropdown';
import { Message } from 'primereact/message';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import loader from '../loader';
import Session from '../auth/Session';
import typeOptions from './types.json';
import date_pt from './date_pt.json';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputTextarea } from 'primereact/inputtextarea';
import timeSetup from '../timeSetup';
moment.locale('pt');

function OutOfServiceForm(props) {
  const { id, user_id } = props;

  let logged = Session.getCookie();
  const initialState = {
    type: 'Falta',
    motivation: '',
    authorized: false,
    user_id: user_id ? parseInt(user_id, 10) : logged.id,
    start: moment().startOf('day').add(1, 'day').toDate(),
    end: moment().startOf('day').add(2, 'day').toDate()
  };

  let [ users, setUsers ] = useState([]);

  function reducer(state, action) {
    switch (action.type) {
      case 'update':
        return {
            ...state,
            [action.field]: action.value,
            authorized: false
        };
      case 'update_all':
        return {
            ...action.value
        }
      default:
        throw new Error();
    }
  }
  let [ outofservice, dispatch ] = useReducer(reducer, initialState);
  
  let [ errors, setErrors ] = useState({});
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  // Load users
  useEffect(() => {
    loader.get('/user/list', res => {
        setUsers(res.items);
    }, setLoading);
  }, []);

  // Load item
  useEffect(() => {
    if (!parseInt(id, 10)) {
        return dispatch({ type: 'update_all', value: { ...initialState } });
    }
    loader.get('/outofservice/item/' + id, res => {
        if (!res.success) return;
        const value = {
            ...res.item,
            start: timeSetup.from(res.item.start).format('YYYY-MM-DD HH:mm:ss'),
            end: timeSetup.from(res.item.end).format('YYYY-MM-DD HH:mm:ss')
        };
        dispatch({ type: 'update_all', value });
    }, setLoading);
  }, [id]);
  
  // Register
  let register = () => {
    let data = {
        ...outofservice,
        start: timeSetup.to(outofservice.start).format('YYYY-MM-DD HH:mm:ss'),
        end: timeSetup.to(outofservice.end).format('YYYY-MM-DD HH:mm:ss')
    };
    let url = '/outofservice/create';
    if (outofservice.id) url = '/outofservice/update';
    setSuccess(false);
    loader.post(url, data, res => {
        if (res.errors) return setErrors(res.errors);
        dispatch({ type: 'update', field: 'id', value: res.id });
        setSuccess(true);
    }, setLoading);
  };

  let isDataInvalid = () => {
      return loading
        || !outofservice.start
        || !outofservice.end
        || !outofservice.user_id;
  }
  
  let displayErrors = (errors, key) => !!errors[key] && errors[key].map(msg =>
    <Message severity="error" text={msg}></Message>
  )

  if (loading) return <ProgressSpinner />;
  let start_date = outofservice.start ? 
    moment(outofservice.start).toDate() : null;
  let end_date = outofservice.end ? 
    moment(outofservice.end).toDate() : null;
  return (
    <React.Fragment>

        { success && (
            <div className="p-grid">
                <div className="p-col-12">

                    <Message 
                        severity="success" 
                        text="A ausência/férias foi guardada com sucesso!">
                    </Message>
                    
                </div>
            </div>
        ) }

        <Button 
            onClick={register} 
            label="Guardar"
            disabled={isDataInvalid()}
            style={{ float: 'right' }}
        />
        
        <p className="form-info">
            <strong style={{ color: 'red' }}>*</strong> 
            {' '}Campos obrigatórios
            
        </p>

        <div className="p-grid">
            <div className="p-col-12 p-lg-6">
                <div className="p-grid">

                    <div className="p-col-6 p-md-6">
                        <label className="field-required">Tipo</label>
                    </div>
                    <div className="p-col-6 p-md-6">
                        <Dropdown 
                            placeholder="Escolha um tipo"
                            value={outofservice.type}
                            options={typeOptions} 
                            onChange={(e) => {
                                dispatch({ type: 'update', field: 'type', value: e.value })
                            }}
                        />
                    </div>

                    {/*
                    <div className="p-col-6 p-md-6">
                        <label className="field-required">Utilizador</label>
                    </div>
                    <div className="p-col-6 p-md-6">
                        <Dropdown 
                            placeholder="Escolha um utilizador"
                            value={parseInt(outofservice.user_id, 10)}
                            options={ownerOptions} 
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'user_id', value: e.value })
                            }
                        />
                    </div>
                    */}

                    <div className="p-col-10 p-md-6">
                        <label className="field-required">Autorizado</label>
                    </div>
                    <div className="p-col-2 p-md-6">
                        <InputSwitch 
                            checked={outofservice.authorized} 
                        />
                    </div>

                    <div className="p-col-12 p-md-6">
                        <label className="field-required">Inicio</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Calendar 
                            placeholder="Data / Hora"
                            value={start_date}
                            locale={date_pt}
                            monthNavigator={true} 
                            yearNavigator={true} 
                            yearRange="2020:2030"
                            dateFormat="yy-mm-dd"
                            showTime={true}
                            hourFormat="24"
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'start', value: e.value })
                            }>
                        </Calendar>
                    </div>

                    <div className="p-col-12 p-md-6">
                        <label className="field-required">Fim</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Calendar 
                            placeholder="Data / Hora"
                            value={end_date}
                            locale={date_pt}
                            monthNavigator={true} 
                            yearNavigator={true} 
                            yearRange="2020:2030"
                            dateFormat="yy-mm-dd"
                            showTime={true}
                            hourFormat="24"
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'end', value: e.value })
                            }>
                        </Calendar>
                    </div>

                </div>
            </div>
            <div className="p-col-12 p-lg-6">
                <h2>Motivação / Justificação</h2>
                <InputTextarea
                    rows="10" 
                    value={outofservice.motivation}
                    onChange={(e) => 
                        dispatch({ type: 'update', field: 'motivation', value: e.target.value })
                    }
                />
                { displayErrors(errors, 'motivation') }
            </div>
        </div>
    </React.Fragment>
  );

}

export default OutOfServiceForm;
