import React, { useState, useEffect } from 'react';
import moment from 'moment';
import loader from '../loader';
import PlansList from '../plans/PlansList';
import DiaryCalendar from '../diary/DiaryCalendar';
import { ProgressSpinner } from 'primereact/progressspinner';
import timeSetup from '../timeSetup';

function ClientsPLans({ client }) {

  let [ plans, setPlans ] = useState([]);
  let [ date, setDate ] = useState(moment().startOf('week').format('YYYY-MM-DD'));
  let [ calendar, setCalendar ] = useState([]);
  
  let [ loading, setLoading ] = useState(false);

  // Load calendar
  useEffect(() => {
    if (!parseInt(client.id, 10)) return;
    let startdate = moment().startOf('week').format('YYYY-MM-DD');
    let enddate = moment().startOf('week').add(2, 'weeks').format('YYYY-MM-DD');
    loader.get('/diary/calendar/' + client.id + '/' + startdate + '/' + enddate, res => {
        const events = res.items.filter(i => i.therapy).map(i => ({
            id: i.id,
            title: i.title,
            start: i.start,
            end: i.end,
            backgroundColor: i.color ? i.color : null
        }));
        setCalendar(events);
    }, setLoading);
  }, [client.id]);

  if (!client || loading) return <ProgressSpinner />;
  return (
    <React.Fragment>

        <div className="card card-w-title">
            <h1>Terapêutica</h1>
            <PlansList client_id={client.id} therapy={true} />
        </div>

        <div className="card">
            <DiaryCalendar 
                start={moment(date).add(1, 'day').format('YYYY-MM-DD')}
                items={calendar}
            />
        </div>
        
    </React.Fragment>
  );

}

export default ClientsPLans;
