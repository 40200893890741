import React, { useState, useEffect, useReducer } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import {InputSwitch} from 'primereact/inputswitch';
import { Message } from 'primereact/message';
import { useParams } from "react-router-dom";
import loader from '../loader';
import Session from '../auth/Session';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Editor } from 'primereact/editor';
import typeOptions from './types.json';
import viewOptions from './views.json';
import conditionsOptions from './conditions.json';
import moment from 'moment';
import date_pt from '../date_pt.json';
import { Dropdown } from 'primereact/dropdown';
import { ColorPicker } from 'primereact/colorpicker';
import {Calendar} from 'primereact/calendar';

const fnOptions = [
    { label: 'Contar', value: 'count', key: 'count' },
    { label: 'Somar', value: 'sum', key: 'sum' },
    { label: 'Média', value: 'avg', key: 'avg' },
    { label: 'Mínimo', value: 'min', key: 'min' },
    { label: 'Máximo', value: 'max', key: 'max' }
  ];

function GoalsForm(props) {

  const { client_id, parent_id } = props;
  const logged = Session.getCookie();

  let [ topics, setTopics ] = useState([]);
  let [ fields, setFields ] = useState([]);
  let [ clients, setClients ] = useState([]);

  const initialState = {
    title: '',
    active: true,
    terminated: false,
    description: '',
    owner_id: logged.id,
    topic_id: null,
    field_id: null,
    start_date: moment().startOf('day').add(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
    end_date: moment().startOf('day').add(3, 'month').format('YYYY-MM-DD HH:mm:ss'),
    client_id: Number(client_id) || null,
    color: 'ff0000',
    icon: '',
    fn: fnOptions[0].value,
    display_view: viewOptions[0].value,
    type: typeOptions[0].value,
    view_min: -10,
    view_max: 10,
    condition: conditionsOptions[0].value,
    value: 0,
    tolerance: 0,
    created_at: moment().toISOString()
  }

  function reducer(state, action) {
    switch (action.type) {
      case 'update':
        return {
            ...state,
            [action.field]: action.value
        };
      case 'update_all':
        return {
            ...action.value
        }
      default:
        throw new Error();
    }
  }

  let [ goal, dispatch ] = useReducer(reducer, initialState)
  
  let [ errors, setErrors ] = useState({});
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  let urlParams = useParams();

  const filterByOrganization = (items, logged) => {
    return items.filter(opt => opt.active && (opt.organization_id === logged.organization_id))
  }

  // Load topics
  useEffect(() => {
    let url = '/topic/as/activities/0/' + goal.owner_id;
    loader.get(url, res => {
        let items = res.items;
        if (parent_id) items = items.filter(opt => String(parent_id) === String(opt.parent_id));
        setTopics(items);
    }, setLoading);
  }, [goal.owner_id]);

  // Load clients
  useEffect(() => {
    loader.get('/client/list', res => {
        setClients(filterByOrganization(res.items, logged));
    }, setLoading);
  }, []);

  // Load fields
  useEffect(() => {
    if (!goal.topic_id) return;
    loader.get('/topic/fields/' + goal.topic_id, res => {
        setFields(res.items);
    }, setLoading);
  }, [goal.topic_id]);
  
  useEffect(() => {
    if (!parseInt(urlParams.id, 10)) {
        return dispatch({ type: 'update_all', value: initialState });
    }
    loader.get('/goal/item/' + urlParams.id, res => {
        const value = { 
            ...res.item
        };
        dispatch({ type: 'update_all', value });
    }, setLoading);
  }, [urlParams.id]);

  let register = () => {
    let data = {
        ...goal
    };
    let url = '/goal/create';
    if (goal.id) url = '/goal/update';
    setSuccess(false);
    loader.post(url, data, res => {
        if (res.errors) return setErrors(res.errors);
        dispatch({ type: 'update', field: 'id', value: res.id });
        setSuccess(true);
    }, setLoading);
  };

  let isDataInvalid = () => {
      return loading
        || goal.title.length < 3
        || !goal.topic_id
        || !goal.client_id;
  }

  let displayErrors = (errors, key) => !!errors[key] && errors[key].map(msg =>
    <Message severity="error" text={msg}></Message>
  )

  // Get topic options
  const topicOptions = [];
  const topicCategories = {};
  const fieldOptions = [];

  // Group by category
  topics.map(opt => {
    if (!topicCategories[opt.category]) {
        topicCategories[opt.category] = [];
        topicOptions.push({
            label: opt.category,
            value: opt.id + opt.category,
            key: opt.id + opt.category,
            disabled: true
        });
    }
    topicOptions.push({
        label: opt.title,
        value: opt.id,
        key: opt.id
    });
  });

  // Filter fields
  fields.map(opt => {
    fieldOptions.push({
        label: opt.label,
        value: opt.topic_field_id,
        key: opt.topic_field_id
    });
  });

  // Get client options
  const clientOptions = clients.map(opt => ({
    label: opt.firstname + ' ' + opt.lastname,
    value: opt.id,
    key: opt.id
  }));

  if (loading) return <ProgressSpinner />;
  let start_date = goal.start_date ? moment(goal.start_date).toDate() : moment().toDate();
  let end_date = goal.end_date ? moment(goal.end_date).toDate() : null;
  return (
    <React.Fragment>
        

        { success && (
            <div className="p-grid">
                <div className="p-col-12">

                    <Message 
                        severity="success" 
                        text="A meta foi guardada com sucesso!">
                    </Message>
                    
                </div>
            </div>
        ) }

        <Button 
            onClick={register} 
            label="Guardar"
            disabled={isDataInvalid()}
            style={{ float: 'right' }}
        />
        <Button 
            onClick={e => window.history.back()} 
            label="Voltar"
            className="p-button-secondary"
            style={{ float: 'right' }}
        />
        
        <p className="form-info">
            <strong style={{ color: 'red' }}>*</strong> 
            {' '}Campos obrigatórios
        </p>            

        <div className="p-grid">
            <div className="p-col-12 p-lg-6">

                <div className="p-grid">

                    <div className="p-col-12 p-md-6">
                        <label className="field-required">Título</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <InputText 
                            placeholder="Título"
                            value={goal.title}
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'title', value: e.target.value })
                            }
                        />
                        { displayErrors(errors, 'title') }
                    </div>

                    <div className="p-col-12 p-md-6">
                        <label className="field-required">Tipo</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Dropdown
                            placeholder="Escolha um tipo"
                            value={parseInt(goal.topic_id, 10)}
                            options={topicOptions} 
                            onChange={(e) => {
                                dispatch({ type: 'update', field: 'topic_id', value: e.value })
                            }}
                        />
                    </div>

                    <div className="p-col-12 p-md-6">
                        <label className="field-required">Campo a medir</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Dropdown
                            placeholder="Escolha o campo a medir"
                            value={parseInt(goal.field_id, 10)}
                            options={fieldOptions} 
                            onChange={(e) => {
                                dispatch({ type: 'update', field: 'field_id', value: e.value })
                            }}
                        />
                    </div>

                    <div className="p-col-12 p-md-6">
                        <label className="field-required">Cliente</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Dropdown 
                            placeholder="Escolha um cliente"
                            value={parseInt(goal.client_id, 10)}
                            options={clientOptions} 
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'client_id', value: e.value })
                            }
                        />
                    </div>

                    { !!goal.color && (
                        <React.Fragment>
                            <div className="p-col-12 p-md-6">
                                <label>Côr de Etiqueta</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <ColorPicker
                                    value={goal.color} 
                                    onChange={(e) => 
                                        dispatch({ type: 'update', field: 'color', value: e.value })
                                    }
                                />
                            </div>
                        </React.Fragment>
                    )}

                        
                    <div className="p-col-12 p-md-6">
                        <label>Icone</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <InputText 
                            placeholder="Icone"
                            value={goal.icon}
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'icon', value: e.target.value })
                            }
                        />
                    </div>

                    <div className="p-col-12 p-md-6">
                        <label className="field-required">Inicio</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Calendar
                            placeholder="Inicio"
                            value={start_date}
                            locale={date_pt}
                            monthNavigator={true} 
                            yearNavigator={true} 
                            yearRange="2020:2030"
                            dateFormat="yy-mm-dd"
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'start_date', value: e.value })
                            }>
                        </Calendar>
                    </div>

                    <div className="p-col-12 p-md-6">
                        <label>Fim</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Calendar 
                            placeholder="Fim"
                            value={end_date}
                            locale={date_pt}
                            monthNavigator={true} 
                            yearNavigator={true} 
                            yearRange="2020:2030"
                            dateFormat="yy-mm-dd"
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'end_date', value: e.value })
                            }>
                        </Calendar>
                    </div>

                    <div className="p-col-10 p-md-6">
                        <label>Activo</label>
                    </div>
                    <div className="p-col-2 p-md-6">
                        <InputSwitch 
                            checked={goal.active} 
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'active', value: !goal.active })
                            } 
                        />
                    </div>
                </div>

            </div>
            <div className="p-col-12 p-lg-6">
                <div className="p-grid">

                <div className="p-col-12 p-md-6">
                        <label className="field-required">Agregação</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Dropdown
                            placeholder="Escolha agregação"
                            value={goal.fn}
                            options={fnOptions}
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'fn', value: e.value })
                            } 
                        />
                    </div>

                    <div className="p-col-12 p-md-6">
                        <label className="field-required">Interface de Visualização</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Dropdown 
                            placeholder="Escolha uma opção"
                            value={goal.display_view}
                            options={viewOptions} 
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'display_view', value: e.value })
                            }
                        />
                    </div>

                    <div className="p-col-12 p-md-6">
                        <label>Tipo de Dados</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Dropdown 
                            placeholder="Escolha um tipo"
                            value={goal.type}
                            options={typeOptions} 
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'type', value: e.value })
                            }
                        />
                    </div>

                    <div className="p-col-12 p-md-6">
                        <label>Atinge a meta se</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Dropdown 
                            placeholder="Escolha uma condição"
                            value={goal.condition}
                            options={conditionsOptions} 
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'condition', value: e.value })
                            }
                        />
                    </div>

                    <div className="p-col-12 p-md-6">
                        <label>Valor a atingir</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <InputText 
                            placeholder="Valor a atingir"
                            value={goal.value}
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'value', value: e.target.value })
                            }
                        />
                    </div>

                    { !!goal.condition === 'tolerance' &&
                        <React.Fragment>
                            <div className="p-col-12 p-md-6">
                                <label>Tolerância</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <InputText 
                                    placeholder="Tolerância"
                                    value={goal.tolerance}
                                    onChange={(e) => 
                                        dispatch({ type: 'update', field: 'tolerance', value: e.target.value })
                                    }
                                />
                            </div>
                        </React.Fragment>
                    }

                    <div className="p-col-12 p-md-6">
                        <label>Valor Mínimo Visivel</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <InputText 
                            placeholder="Valor Mínimo Visivel"
                            value={goal.view_min}
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'view_min', value: e.target.value })
                            }
                        />
                    </div>

                    <div className="p-col-12 p-md-6">
                        <label>Valor Máximo Visivel</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <InputText 
                            placeholder="Valor Máximo Visivel"
                            value={goal.view_max}
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'view_max', value: e.target.value })
                            }
                        />
                    </div>

                    <div className="p-col-12">
                        <label>Descrição</label>
                        <Editor 
                            style={{height: goal.description ? '197px' : '320px'}} 
                            value={goal.description}
                            onTextChange={(e) => 
                                dispatch({ type: 'update', field: 'description', value: e.htmlValue })
                            }
                        />
                    </div>

                </div>
            </div>
        </div>

        
    </React.Fragment>
  );

}

export default GoalsForm;
