import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import {DataTable} from 'primereact/datatable';
import {Calendar} from 'primereact/calendar';
import {Column} from 'primereact/column';
import {Toolbar} from 'primereact/toolbar';
import { useHistory, Link } from "react-router-dom";
import loader from '../loader';
import Session from '../auth/Session';
import { ProgressSpinner } from 'primereact/progressspinner';
import moment from 'moment';
import timeSetup from '../timeSetup';
import date_pt from '../date_pt.json';

const serverUrl = process.env.REACT_APP_SERVER_URL;
let dt = null;

function StockList() {

  let [ items, setItems ] = useState([]);
  let [ start, setStart ] = useState(moment().subtract(30, 'days').toDate());
  let [ end, setEnd ] = useState(moment().toDate());
  
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  let history = useHistory();
  useEffect(() => {
    let tstart = timeSetup.to(start).format('YYYY-MM-DD');
    let tend = timeSetup.to(end).format('YYYY-MM-DD');
    loader.get('/stock/list/' + tstart + '/' + tend, res => {
        let items = res.items;
        items = items.map(i => ({
            ...i,
            datahora: timeSetup.from(i.datahora).toISOString(),
            received_datahora: i.received_datahora ? timeSetup.from(i.received_datahora).toISOString() : null
        }));
        setItems(items);
    }, setLoading);
  }, [start, end]);

  let deleteItem = (id) => {
    const url = '/stock/delete/' + id;
    setSuccess(false);
    loader.get(url, res => {
        if (!res.success) return;
        setSuccess(true);
        items = items.filter(i => i.id !== id);
        setItems(items);
    }, setLoading);
  };

  let recordOptions = (item, column) => {
    const logged = Session.getCookie();
    return <div>
        <Button
            onClick={e => history.push({ pathname: '/backoffice/stock/form/' + item.id })} 
            type="button" 
            icon="pi pi-pencil" 
            className="p-button-info"
        />
        <Button
            onClick={e => deleteItem(item.id)} 
            type="button" 
            icon="pi pi-times" 
            className="p-button-danger"
            disabled={![logged.role1].includes('ADMINISTRATOR')}
        />
    </div>;
  }

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/photo_placeholder.png';
  }

  let recordPhoto = (item, column) => {
    if (!item.userphoto) return <em className="subtitle">Desconhecido</em>;
    return (
        <img 
            src={getFileUrl(item.userphoto, 'users')} 
            alt="Fotografia"
            title="Fotografia"
            className="img-avatar-mini"
        />
    )
  }

  const isMobile = window.innerWidth <= 768;
  if (loading) return <ProgressSpinner />;
  return (
    <React.Fragment>
        <div className="card card-w-title">
            <h1>Movimentos de Stock</h1>

            <Toolbar>
                <div className="p-toolbar-group-left">
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-6">
                            <Calendar 
                                placeholder="Data de Inicio"
                                value={start} 
                                locale={date_pt}
                                dateFormat="yy-mm-dd"
                                yearNavigator={true}
                                yearRange="2020:2030"
                                onChange={(e) => setStart(e.value) }>
                            </Calendar>
                        </div>
                        <div className="p-col-12 p-lg-6">
                            <Calendar 
                                placeholder="Data de Fim"
                                value={end} 
                                locale={date_pt}
                                dateFormat="yy-mm-dd"
                                yearNavigator={true}
                                yearRange="2020:2030"
                                onChange={(e) => setEnd(e.value) }>
                            </Calendar>
                        </div>
                    </div>
                </div>
                <div className="p-toolbar-group-right">
                    <Link to="/backoffice/stock/bulkform">
                        <Button label="Registar Movimento" />
                    </Link>
                    {' '}
                    <Button
                        type="button" 
                        icon="pi pi-external-link" 
                        iconPos="left" 
                        label="CSV" 
                        onClick={e => dt.exportCSV()}
                    />
                </div>
            </Toolbar>

            { success && (
                <div className="p-grid">
                    <div className="p-col-12">

                        <Message 
                            severity="success" 
                            text="O movimento foi corrigido com sucesso!">
                        </Message>
                        
                    </div>
                </div>
            ) }

            
            <div className="p-grid">
                <div className="p-col-12">

                <DataTable value={items} ref={(el) => { dt = el; }}>
                    { !isMobile && (
                        <Column 
                            header="Data / Hora"
                            field="datahora"
                            body={item => moment(item.datahora).format('YYYY-MM-DD HH:mm')}
                            filter={true}
                            filterMatchMode="contains"
                            style={{ textAlign: 'center' }}
                        />
                    ) }
                    { !isMobile && (
                        <Column 
                            header="Origem"
                            field="from"
                            filter={true}
                            filterMatchMode="contains"
                            style={{ textAlign: 'center' }}
                        />
                    ) }
                    <Column 
                        header="Produto"
                        field="name"
                        filter={true}
                        filterMatchMode="contains"
                        style={{ textAlign: 'center' }}
                    />
                    { !isMobile && (
                        <Column 
                            header="Movimento"
                            field="amount"
                            filter={true}
                            filterMatchMode="contains"
                            style={{ textAlign: 'center' }}
                        />
                    ) }
                    { !isMobile && (
                        <Column 
                            header="Stock Ori."
                            field="stock_origem"
                            style={{ textAlign: 'center' }}
                        />
                    ) }
                    <Column 
                        header="Destino"
                        field="to"
                        filter={true}
                        filterMatchMode="contains"
                        style={{ textAlign: 'center' }}
                    />
                    <Column 
                        header="Stock Dest."
                        field="stock"
                        style={{ textAlign: 'center' }}
                    />
                    { !isMobile && (
                        <Column 
                            header="Recebido em"
                            field="received_datahora"
                            body={item => {
                                return item.received_datahora ? 
                                    moment(item.received_datahora).format('YYYY-MM-DD HH:mm') : 
                                    <em className="subtitle">Desconhecido</em>
                            }}
                            filter={true}
                            filterMatchMode="contains"
                            style={{ textAlign: 'center' }}
                        />
                    ) }
                    { !isMobile && (
                        <Column 
                            header="Recebido Por"
                            field="userfullname"
                            filter={true}
                            filterMatchMode="contains"
                            body={recordPhoto}
                            style={{ textAlign: 'center' }}
                        />
                    ) }
                    <Column 
                        header="Opções"
                        body={recordOptions}
                        style={{ textAlign: 'right' }}
                    />
                </DataTable>
                    
                </div>
            </div>
                        
        </div>
    </React.Fragment>
  );

}

export default StockList;
