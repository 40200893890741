import React from 'react';
import OutOfServiceForm from './OutOfServiceForm';
import { Button } from 'primereact/button';
import { useHistory, useParams } from "react-router-dom";
import Session from '../auth/Session';

function OutOfServiceFormRoute() {

  let urlParams = useParams();
  let history = useHistory();
  let logged = Session.getCookie();

  const title = parseInt(urlParams.id) ? "Alterar Ausência" : "Registar Ausência";
  return (
    <React.Fragment>
        <div className="card card-w-title">
          <h1>
            { title }
            <Button
                style={{ float: 'right' }} 
                onClick={e => history.goBack()} 
                label="Voltar"
                className="p-button-danger"
            />
          </h1>
          <OutOfServiceForm user_id={logged.user_id} id={urlParams.id} />
        </div>
    </React.Fragment>
  );

}

export default OutOfServiceFormRoute;
