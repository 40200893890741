import React, { useState, useEffect } from 'react';
import moment from 'moment';
import loader from '../loader';
import WorkPlansList from '../workplans/WorkPlansList';
import DiaryCalendar from '../diary/DiaryCalendar';
import timeSetup from '../timeSetup';
import { ProgressSpinner } from 'primereact/progressspinner';

function UsersWorkplans({ id }) {

  let [ user, setUser ] = useState(null);
  let [ date, setDate ] = useState(moment().startOf('week').format('YYYY-MM-DD'));
  let [ calendar, setCalendar ] = useState([]);

  let [ loading, setLoading ] = useState(false);

  // Load user
  useEffect(() => {
    if (!parseInt(id, 10)) return;
    loader.get('/user/item/' + id, res => {
        setUser(res.item);
    }, setLoading);
  }, [id]);

  // Load calendar
  useEffect(() => {
    if (!parseInt(id, 10)) return;
    let startdate = moment().startOf('week').format('YYYY-MM-DD');
    let enddate = moment().startOf('week').add(2, 'weeks').format('YYYY-MM-DD');
    loader.get('/workplan/calendar/' + id + '/' + startdate + '/' + enddate, res => {
        let calitems = res.items.map(i => ({
            id: i.id,
            title: i.title,
            start: timeSetup.from(i.start).format('YYYY-MM-DD HH:mm:ss'),
            end: timeSetup.from(i.end).format('YYYY-MM-DD HH:mm:ss'),
            backgroundColor: i.color
        }));
        setCalendar(calitems);
    }, setLoading);
  }, [id]);

  if (loading || !user) return <ProgressSpinner />;
  console.log(calendar);
  return (
    <React.Fragment>

        <div className="card card-w-title">
            <h1>Horários</h1>
            <p className="subtitle"><small><em>Horários utilizados na atribuição automática de tarefas e na picagem de ponto</em></small></p>
            <WorkPlansList owner_id={user.id} />
        </div>
        
        <div className="card card-w-title">
            <h1>Previsto</h1>
            <DiaryCalendar 
                start={moment(date).add(1, 'day').format('YYYY-MM-DD')}
                items={calendar} 
            />
        </div>

    </React.Fragment>
  );

}

export default UsersWorkplans;
