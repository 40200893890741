import React, {Component} from 'react';
import classNames from 'classnames';
import AppTopbar from './AppTopbar';
import {AppFooter} from './AppFooter';
import {AppMenu} from './AppMenu';
import AppProfile from './AppProfile';
import Session from '../auth/Session';
import { withRouter } from "react-router-dom";
import VideoCallApp from '../videocall/App';

import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import '../layout/layout.scss';
import './layout.css';
import './theme_light.css';
import { hasMod } from '../users/Permissions';

class Layout extends Component {

    constructor() {
        super();
        this.state = {
            layoutMode: 'static',
            layoutColorMode: 'dark',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false
        };

        this.onWrapperClick = this.onWrapperClick.bind(this);
        this.onToggleMenu = this.onToggleMenu.bind(this);
        this.onSidebarClick = this.onSidebarClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
    
        this.user = Session.getCookie();
        const technicalRoles = ['SECURITY', 'ASSISTENT'];
        if (technicalRoles.includes(this.user.role1)) {
            this.props.history.replace({ pathname: '/technical' });
        }
        
        this.createMenu();
    }

    onWrapperClick(event) {
        if (!this.menuClick) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            });
        }

        this.menuClick = false;
    }

    onToggleMenu(event) {
        this.menuClick = true;

        if (this.isDesktop()) {
            if (this.state.layoutMode === 'overlay') {
                this.setState({
                    overlayMenuActive: !this.state.overlayMenuActive
                });
            }
            else if (this.state.layoutMode === 'static') {
                this.setState({
                    staticMenuInactive: !this.state.staticMenuInactive
                });
            }
        }
        else {
            const mobileMenuActive = this.state.mobileMenuActive;
            this.setState({
                mobileMenuActive: !mobileMenuActive
            });
        }
       
        event.preventDefault();
    }

    onSidebarClick(event) {
        this.menuClick = true;
    }

    onMenuItemClick(event) {
        if(!event.item.items) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            })
        }
    }

    createMenu() {
        this.menu = [
            {
               label:'Menu Inicial',
               icon:'pi pi-fw pi-home',
               url: '#/backoffice'
            }
        ];

        if (['ADMINISTRATOR', 'ORG_ADMIN', 'ADMIN'].includes(this.user.role1)) {
            this.menu.push({
                label: 'Diário',
                icon:'pi pi-fw pi-clock',
                url: '#/backoffice/diary/list'
            });
        }

        this.menu.push({
            label:'Clientes',
            icon:'pi pi-fw pi-heart',
            url: '#/backoffice/clients/list'
        });

        if (hasMod(['T4CPI1'])) {
            this.menu.push({
                label:'Atividades',
                icon:'pi pi-fw pi-briefcase',
                url: '#/backoffice/plans/list'
            });
        }
        
        this.menu.push({
            label: 'Categorias',
            icon:'pi pi-fw pi-info',
            url: '#/backoffice/topics/list'
        });

        // Contacts
        if (['ADMINISTRATOR', 'ORG_ADMIN', 'ADMIN'].includes(this.user.role1)) {
            if (hasMod(['T4CCT1'])) {
                this.menu.push({
                    label:'Contactos',
                    icon:'pi pi-fw pi-id-card',
                    url: '#/backoffice/entities/list'
                });
            }
        }

        // Stock
        if (['ADMINISTRATOR', 'ORG_ADMIN', 'ADMIN'].includes(this.user.role1)) {
            if (hasMod(['T4CST1'])) {
                this.menu.push({
                    label:'Stock',
                    icon:'pi pi-fw pi-list',
                    url: '#/backoffice/stock/list'
                });
            }
        }

        if (hasMod(['T4CGD1'])) {
            this.menu.push({
                label: 'Estatísticas',
                icon:'pi pi-fw pi-chart-line',
                url: '#/backoffice/stats'
            });
        }
    
        // Check admin permissions
        if (['ADMINISTRATOR', 'ORG_ADMIN'].includes(this.user.role1)) {
            let adminMenu = {
                label:'Administração',
                icon:'pi pi-fw pi-cog',
                items:[

                    {
                        label:'Utilizadores',
                        icon:'pi pi-fw pi-list',
                        url: '#/backoffice/users/list'
                    },

                    {
                        label:'Grupos',
                        icon:'pi pi-fw pi-list',
                        url: '#/backoffice/groups/list'
                    },
                    
                    {
                        label:'Tipos de Tarefas',
                        icon:'pi pi-fw pi-list',
                        url: '#/backoffice/topics/list/activity'
                    },

                    {
                        label:'Campos',
                        icon:'pi pi-fw pi-list',
                        url: '#/backoffice/fields/list'
                    },

                    {
                        label:'Funções',
                        icon:'pi pi-fw pi-list',
                        url: '#/backoffice/roles/list'
                    },

                    {
                        label:'Tentativas de Login',
                        icon:'pi pi-fw pi-list',
                        url: '#/backoffice/failed/list'
                    }
                ]
            };

            if (hasMod(['T4CPN1'])) {
                adminMenu.items.push({
                    label:'Planos',
                    icon:'pi pi-fw pi-list',
                    url: '#/backoffice/business/list'
                });
            }

            if (hasMod(['T4CFF1'])) {
                adminMenu.items.push({
                    label:'Ausências / Férias',
                    icon:'pi pi-fw pi-list',
                    url: '#/backoffice/outofservices/list'
                });
            }

            this.menu.push(adminMenu);
        }

        // Check superuser permissions
        if (['ADMINISTRATOR'].includes(this.user.role1)) {
            this.menu.push({
                label:'Especiais',
                icon:'pi pi-fw pi-star-o',
                items: [
                    {
                        label:'Organizações',
                        icon:'pi pi-fw pi-list',
                        url: '#/backoffice/organizations/list'
                    }
                ]
            });
        }
    }

    addClass(element, className) {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    removeClass(element, className) {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    componentDidUpdate() {
        if (this.state.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    }

    render() {

        const wrapperClass = classNames('layout-wrapper', {
            'layout-overlay': this.state.layoutMode === 'overlay',
            'layout-static': this.state.layoutMode === 'static',
            'layout-static-sidebar-inactive': this.state.staticMenuInactive && this.state.layoutMode === 'static',
            'layout-overlay-sidebar-active': this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
            'layout-mobile-sidebar-active': this.state.mobileMenuActive
        });

        const sidebarClassName = classNames("layout-sidebar", {
            'layout-sidebar-dark': this.state.layoutColorMode === 'dark',
            'layout-sidebar-light': this.state.layoutColorMode === 'light'
        });

        return (
            <div className={wrapperClass} onClick={this.onWrapperClick}>

                <AppTopbar onToggleMenu={this.onToggleMenu}/>

                <div ref={(el) => this.sidebar = el} 
                    className={sidebarClassName} 
                    onClick={this.onSidebarClick}>
                    <AppProfile onMenuItemClick={this.onMenuItemClick} />
                    <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick} />
                </div>

                <div className="layout-main">
                    { this.props.children }
                </div>

                <AppFooter />

                <div className="layout-mask"></div>
                <VideoCallApp username={this.user.username} />
            </div>
        );
    }
}

export default withRouter(Layout);