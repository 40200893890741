import React, { useState, useEffect } from 'react';
import {InputSwitch} from 'primereact/inputswitch';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import { Link } from "react-router-dom";
import moment from 'moment';
import loader from '../loader';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Calendar } from 'primereact/calendar';
import timeSetup from '../timeSetup';
import 'moment/locale/pt';
import date_pt from '../date_pt.json';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DiaryItemList } from './DiaryItemList';
moment.locale('pt');

const serverUrl = process.env.REACT_APP_SERVER_URL;

function DiaryDayColumn(props) {
  let { title, date, dt, colId } = props;
  let [ day, setDay ] = useState(date);
  let [ dayTerm, setDayTerm ] = useState(false);
  let [ items, setItems ] = useState([]);
  let [ showFilter, setShowFilter ] = useState(false);
  let [ filter, setFilter ] = useState({});
  let [ filterOptions, setFilterOptions ] = useState({clientname: [], title: [], username: []});

  // Render closed on mobile
  const isMobile = window.innerWidth <= 768;
  let [ closed, setClosed ] = useState(isMobile);
  
  let [ loading, setLoading ] = useState(false);
  
  // Load day items
  useEffect(() => {
    let tday = moment(day).format('YYYY-MM-DD');
    loader.get('/diary/day/' + tday + '/' + (dayTerm ? '1' : '0'), res => {
        if (!res.success) return console.log(res);
        let titems = res.items;
        titems = titems.map(i => ({
            ...i,
            datahora: timeSetup.from(i.datahora)
        }));
        setItems(titems);
        setFilter({ clientname: {}, title: {}, username: {} });
        let filterOptions = titems.reduce((acc, cur) => {
            if (acc.clientname.indexOf(cur.clientname) < 0) acc.clientname.push(cur.clientname);
            if (acc.title.indexOf(cur.title) < 0) acc.title.push(cur.title);
            if (acc.username.indexOf(cur.username) < 0) acc.username.push(cur.username);
            return acc;
        }, { clientname: [], title: [], username: [] });
        setFilterOptions(filterOptions);
    }, setLoading);
  }, [day, dayTerm]);

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/photo_placeholder.png';
  }

  let recordPhoto = (item, column) => {
    return (
      <Link to={"/backoffice/clients/view/" + item.client_id}>
        <img 
            src={getFileUrl(item.clientphoto, 'clients')} 
            alt="Fotografia"
            title="Fotografia"
            className="img-avatar-mini"
        />
      </Link>
    )
  }

  const onFilterClientname = (event) => {
    dt.filter(event.value, 'clientname', 'equals');
    setFilter({ ...filter, clientname: event.value });
  }

  const onFilterTitle = (event) => {
    dt.filter(event.value, 'title', 'equals');
    setFilter({ ...filter, title: event.value });
  }

  const onFilterUsername = (event) => {
    dt.filter(event.value, 'username', 'equals');
    setFilter({ ...filter, username: event.value });
  }

  const clearFilters = () => {
    dt.reset();
    setFilter({ clientname: '', title: '', username: '' });
  }

  const toggleFilters = () => {
      setShowFilter(!showFilter);
  }

  if (loading) return <ProgressSpinner />;
  return (
    <div className={"card card-w-title " + colId}>
        <h1>
            { moment(day).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD') ? title : moment(day).format('ddd D MMMM') }

            <i title="expandir/encolher" className={(closed ? "pi pi-chevron-down" : "pi pi-chevron-up") + " toggle-day"} onClick={e => setClosed(!closed)}></i>
            <i title="mostrar/esconder filtro" className="pi pi-filter toggle-day-filter" onClick={e => toggleFilters()} style={{ borderStyle: showFilter ? 'inset' : 'none'}}></i>
        </h1>

        <div className={ closed ? "p-toggleable-content-collapsed" : ""}>
            <div className="p-grid" style={{ display: showFilter ? 'flex' : 'none' }}>
                <div className="p-col-12 p-md-6">
                    <Calendar
                        placeholder="Data do dia1"
                        value={day} 
                        locale={date_pt}
                        dateFormat="yy-mm-dd"
                        title="Escolha a data"
                        onChange={(e) => setDay(e.value) }>
                    </Calendar>
                </div>
                <div className="p-col-6 p-md-3" style={{ textAlign: 'left' }}>
                    <Button 
                        onClick={e => clearFilters()} 
                        icon="pi pi-times" 
                        className="p-button-warning"
                        title="Limpar filtro"
                    />
                </div>
                <div className="p-col-6 p-md-3" style={{ textAlign: 'right' }}>
                    <InputSwitch 
                        checked={dayTerm}
                        onChange={e => setDayTerm(!dayTerm)}
                        title="Terminadas/Por fazer"
                    />
                </div>
            </div>

            <DataTable value={items} ref={(el) => dt = el}>

                <Column 
                    header="Cliente"
                    field="clientname"
                    body={recordPhoto}
                    filterHeaderStyle={{ display: showFilter ? 'table-cell' : 'none' }}
                    style={{ textAlign: 'center', width: '83px' }}
                    filter={true}
                    filterMatchMode="contains"
                    filterElement={
                        <Dropdown
                            style={{ width: '100%' }} 
                            className="ui-column-filter"
                            value={filter.clientname} 
                            options={filterOptions.clientname.map(o => ({value: o, label: o}))} 
                            onChange={onFilterClientname}
                            title="Filtrar por cliente"
                        />
                    }
                />
                
                <Column 
                    header="Tarefa"
                    field="title"
                    filter={true}
                    filterMatchMode="contains"
                    filterHeaderStyle={{ display: showFilter ? 'table-cell' : 'none' }}
                    filterElement={
                        <Dropdown
                            style={{ width: '100%' }} 
                            className="ui-column-filter"
                            value={filter.title} 
                            options={filterOptions.title.map(o => ({value: o, label: o}))} 
                            onChange={onFilterTitle}
                            title="Filtrar por tipo de tarefa"
                        />
                    }
                    body={item => (
                        <DiaryItemList 
                            item={item} 
                            link={"/backoffice/diary/view/" + item.id}
                        />
                    )}
                />

                { !isMobile &&
                    <Column 
                        header="Atribuído"
                        field="username"
                        filter={true}
                        filterMatchMode="contains"
                        filterHeaderStyle={{ display: showFilter ? 'table-cell' : 'none' }}
                        style={{ textAlign: 'center', width: '95px' }}
                        filterElement={
                            <Dropdown
                                style={{ width: '100%' }} 
                                className="ui-column-filter"
                                value={filter.username} 
                                options={filterOptions.username.map(o => ({value: o, label: o}))} 
                                onChange={onFilterUsername}
                                title="Filtrar por atribuído"
                            />
                        }
                        body={item => (
                            <Link to={"/backoffice/users/form/" + item.user_id}>{item.username}</Link>
                        ) }
                    />
                }
                
            </DataTable>
        </div>

    </div>
  );

}

export default DiaryDayColumn;
