import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import {InputSwitch} from 'primereact/inputswitch';
import { Message } from 'primereact/message';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toolbar} from 'primereact/toolbar';
import {Card} from 'primereact/card';
import { useHistory, Link } from "react-router-dom";
import loader from '../loader';
import { ProgressSpinner } from 'primereact/progressspinner';
import Session from '../auth/Session';

const serverUrl = process.env.REACT_APP_SERVER_URL;

function BusinessList(props) {

  let [ items, setItems ] = useState([]);
  let [ display, setDisplay ] = useState('grid');
  
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  let history = useHistory();

  useEffect(() => {
    let url = '/business/list';
    loader.get(url, res => {
        setItems(res.items);
    }, setLoading);
  }, []);

  let updateItem = (data) => {
    const url = '/business/update';
    setSuccess(false);
    loader.post(url, data, res => {
        setSuccess(true);
    }, setLoading);
  };

  let deleteItem = (id) => {
    const url = '/business/delete/' + id;
    setSuccess(false);
    loader.get(url, res => {
        if (!res.success) return;
        setSuccess(true);
        items = items.filter(i => i.id !== id);
        setItems(items);
    }, setLoading);
  };

  let toggleActive = (businesss, id) => {
    let items = Object.assign([], businesss);
    items = items.map(item => {
        if (item.id === id) {
            item.active = !item.active;
            updateItem({ id: item.id, active: item.active });
        }
        return item;
    });
    setItems(items);
  }

  let recordOptions = (item, column) => {
    const logged = Session.getCookie();
    return <div>
        <Button
            onClick={e => history.push({ pathname: '/backoffice/business/form/' + item.id })} 
            type="button" 
            icon="pi pi-pencil" 
            className="p-button-info"
        />
        <Button
            onClick={e => deleteItem(item.id)} 
            type="button" 
            icon="pi pi-times" 
            className="p-button-danger"
            disabled={![logged.role1].includes('ADMINISTRATOR')}
        />
    </div>;
  }

  let recordActive = (item, column) => {
    return <div>
        <InputSwitch 
            checked={item.active} 
            onChange={(e) => toggleActive(items, item.id)} 
        />
    </div>;
  }

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/topic_placeholder.jpg';
  }

  let recordPhoto = (item, column) => {
    return (
        <Link to={'/backoffice/business/form/' + item.id }>
            <img 
                src={getFileUrl(item.image, 'business')} 
                alt="Imagem"
                title="Imagem"
                className="img-avatar-mini"
            />
        </Link>
    )
  }

  const isMobile = window.innerWidth <= 768;
  if (loading) return <ProgressSpinner />;
  return (
    <React.Fragment>

        <Toolbar>
            <div className="p-toolbar-group-left">
                <Link to={"/backoffice/business/form/0"}>
                    <Button label={"Criar Plano"} />
                </Link>{' '}
                <Link to={'/backoffice/features/list'}>
                    <Button label={'Items de Planos'} className="p-button-secondary" />
                </Link>
            </div>
            <div className="p-toolbar-group-right">
                <InputSwitch 
                    label="Grelha"
                    checked={display === 'grid'} 
                    onChange={(e) => setDisplay(display === 'grid' ? 'list' : 'grid')} 
                />{' '}Grelha
            </div>
        </Toolbar>

        { success && (
            <div className="p-grid">
                <div className="p-col-12">

                    <Message 
                        severity="success" 
                        text="O plano foi alterado com sucesso!">
                    </Message>
                    
                </div>
            </div>
        ) }

        { display === 'grid' ? (
            <div className="p-grid">

                { items.map(item => (
                    <div className="p-col-6 p-lg-2" key={item.id}>
                        <Card 
                            header={(
                                <Link to={'/backoffice/business/form/' + item.id }>
                                    <img 
                                        src={getFileUrl(item.image, 'business')} 
                                        alt="Imagem"
                                        title="Imagem"
                                        className="img-avatar"
                                    />
                                </Link>
                            )}>
                            { item.title }
                        </Card>
                    </div>
                )) }
                
            </div>
        ) : (
            <div className="p-grid">
                <div className="p-col-12">

                <DataTable value={items} responsive={false}>
                    <Column body={recordPhoto} header="Imagem" />
                    <Column field="title" header="Título" filter={true} />
                    { !isMobile && (
                        <Column 
                            body={recordActive} 
                            header="Activo" 
                            style={{ textAlign: 'center' }} 
                        />
                    ) }
                    <Column body={recordOptions} header="Opções" style={{ textAlign: 'right' }} />
                </DataTable>
                    
                </div>
            </div>
        ) }
            
    </React.Fragment>
  );

}

export default BusinessList;
