import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Session from '../auth/Session';
import { Redirect, withRouter, Link } from "react-router-dom";
import OutOfOffice from './OutOfOffice';
import PicarPonto from '../public/PicarPonto';
import loader from '../loader';
import { hasMod } from '../users/Permissions';

const baseUrl = process.env.PUBLIC_URL;

let notification = null;

function createNotification() {
    if (notification) notification.close();
    let options = {
        tag: 'renotify',
        renotify: true,
        body: 'Recebeu uma mensagem de Team4Care.',
        vibrate: [200, 100, 200]
    };
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
        notification = registrations[0].showNotification("Novas mensagens!", options);
    });
}

class AppTopbar extends Component {

    state = {
        msg_count: null
    }

    static defaultProps = {
        onToggleMenu: null
    }

    static propTypes = {
        onToggleMenu: PropTypes.func.isRequired
    }
    
    logout() {
        const { history } = this.props;
        Session.signout(() => {
            history.push({ pathname: "/" });
        });
    };

    componentDidMount() {
        loader.get('/message/count', res => {
            this.setState({ msg_count: res.count });        
        });


        // Update message
        this.msgInterval = setInterval(() => {
            loader.get('/message/count', res => {
                this.setState({ msg_count: res.count });
                if (res.count > 0) createNotification();
            });
        }, 2 * 60 * 1000);
    }

    componentWillUnmount() {
        clearInterval(this.msgInterval);
    }

    render() {
        const user = Session.getCookie();
        if (!user) return <Redirect to="/login" />;
        return (
            <div className="layout-topbar clearfix">
                
                <button className="p-link layout-menu-button" onClick={this.props.onToggleMenu}>
                    <span className="pi pi-bars"/>
                </button>

                <Link to="/technical">
                    <img
                        className="topbar-logo" 
                        src={ baseUrl + '/images/icons_simple/apple-icon-72x72.png'} 
                        alt="Logo"
                    />
                    <span className="topbar-title">Team4Care</span>
                </Link>
                
                <div className="layout-topbar-icons">

                    { hasMod(['T4CFF1']) && 
                        <OutOfOffice />
                    }

                    { hasMod(['T4CAS1']) && 
                        <PicarPonto />
                    }

                    { hasMod(['T4CIN1']) &&
                        <Link to="/technical/diary/form/0/0/1">
                            <button className="p-link p-button-danger" title="Registar Ocorrência">
                                <span className="layout-topbar-item-text">Ocorrência</span>
                                <span className="layout-topbar-icon pi pi-exclamation-triangle"/>
                            </button>
                        </Link>
                    }

                    { hasMod(['T4CCV1']) &&
                        <Link to="/technical/videocall">
                            <button className="p-link" title="Mensagens">
                                <span className="layout-topbar-item-text">Videochamada</span>
                                <span className="layout-topbar-icon pi pi-video"/>
                            </button>
                        </Link>
                    }

                    { hasMod(['T4CCC1']) &&
                        <Link to="/technical/messages">
                            <button className="p-link" title="Mensagens">
                                <span className="layout-topbar-item-text">Mensagens</span>
                                <span className="layout-topbar-icon pi pi-comment"/>
                                { !!this.state.msg_count && 
                                    <span className="layout-topbar-badge">{ this.state.msg_count }</span> 
                                }
                            </button>
                        </Link>
                    }
                    
                </div>
            </div>
        );
    }
}

export default withRouter(AppTopbar);