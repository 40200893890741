import React, { useState, useEffect, useReducer } from 'react';
import { Button } from 'primereact/button';
import {Calendar} from 'primereact/calendar';
import {Dropdown} from 'primereact/dropdown';
import { Message } from 'primereact/message';
import {InputSwitch} from 'primereact/inputswitch';
import {FileUpload} from 'primereact/fileupload';
import loader from '../loader';
import DiaryFieldInput from './DiaryFieldInput';
import Session from '../auth/Session';
import { InputTextarea } from 'primereact/inputtextarea';
import { ProgressSpinner } from 'primereact/progressspinner';
import moment, { updateLocale } from 'moment';
import { useHistory } from "react-router-dom";
import timeSetup from '../timeSetup';
import { InputText } from 'primereact/inputtext';
import date_pt from '../date_pt.json';

let original_topic = '';
let original_fields = [];

const serverUrl = process.env.REACT_APP_SERVER_URL || '';

function DiaryForm(props) {

  const { id, user, topic, client, client_id, parent_id, terminated, therapy, incident } = props;
  const owner = Session.getCookie();

  const initialState = {
    datahora: null,
    topic_id: topic ? topic.id : null,
    user_id: incident ? owner.id : (user ? user.id : owner.id),
    client_id: client ? client.id : Number(client_id) || null,
    duration: topic ? topic.duration : null,
    terminated: !!terminated,
    therapy: therapy ? true : false,
    incident: incident ? true : false,
    request: '',
    owner_id: owner.id,
    fields: [],
    title: null
  }

  let [ topics, setTopics ] = useState([]);
  let [ users, setUsers ] = useState([]);
  let [ clients, setClients ] = useState([]);

  function reducer(state, action) {

    switch (action.type) {
        case 'update_fields':
            let value = state.fields;
            
            // Add missing
            let existing = state.fields.map(f => f.id);
            action.value.forEach(f => {
                if (!existing.includes(f.id)) value.push(f);
            });

            // Remove extra
            let extra = action.value.map(f => f.id);
            value = value.filter(f => extra.includes(f.id));
        
            if (String(state.topic_id) === original_topic) value = original_fields;
            return { ...state, fields: value };
        case 'update':
            let tvalue = action.value;
            if (action.field === 'fields' && String(state.topic_id) === original_topic) tvalue = original_fields;
            if (action.field === 'topic_id') {
                const topic = topics.find(t => String(t.id) === String(action.value));
                let title = topic.title;
                let datahora = state.datahora;
                let defaultDatahora = moment().startOf('day').add(1, 'day').add(9, 'hours').format('YYYY-MM-DD HH:mm:ss');
                if (state.incident) {
                    defaultDatahora = moment().startOf('hour').format('YYYY-MM-DD HH:mm:ss');
                }
                if (!state.topic_id && !datahora) datahora = defaultDatahora;
                return { ...state, [action.field]: tvalue, duration: topic.duration, datahora, title };
            }
            return { ...state, [action.field]: tvalue };
      case 'update_all':
        original_topic = action.value.topic_id;
        original_fields = action.value.fields;
        return { ...action.value }
      default:
        throw new Error();
    }
  }

  let [ diary, dispatch ] = useReducer(reducer, initialState);
  
  let [ errors, setErrors ] = useState({});
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  const filterByOrganization = (items, logged) => {
      return items.filter(opt => opt.active && (opt.organization_id === logged.organization_id))
  }

  // Update all fields of the same database
  const updateAll = (field, record) => {
    if (!record) return;
    diary.fields.forEach((tfield, i) => {
        if (
            tfield.id !== field.id
            && tfield.display === 'db_table'
            && tfield.db_table === field.db_table 
            && typeof record[tfield.db_table_value] !== 'undefined'
        ) {
            tfield['value'] = record[tfield.db_table_value];
        }
    });
    dispatch({ type: 'update', field: 'fields', value: diary.fields });
  }

  // Load topics
  useEffect(() => {
    let url = '/topic/as/activities' + (diary.therapy ? '/1' : '/0');
    if (diary.user_id) url += '/' + diary.user_id;
    loader.get(url, res => {
        let items = res.items;
        if (parent_id) items = items.filter(opt => String(parent_id) === String(opt.parent_id));
        setTopics(items);
        if (incident) {
            items = items.filter(i => i.incident);
            if (items.length === 1) dispatch({ type: 'update', field: 'topic_id', value: items[0].id })
        }
    }, setLoading);
  }, [diary.therapy, diary.user_id]);

  // Load users
  useEffect(() => {
    loader.get('/user/list', res => {
        setUsers(filterByOrganization(res.items, owner));
    }, setLoading);
  }, []);

  // Load clients
  useEffect(() => {
    loader.get('/client/list', res => {
        setClients(filterByOrganization(res.items, owner));
    }, setLoading);
  }, []);

  // Load fields
  useEffect(() => {
    if (!diary.topic_id) return;
    loader.get('/topic/fields/' + diary.topic_id, res => {
        dispatch({ type: 'update', field: 'fields', value: res.items });
    }, setLoading);
  }, [diary.topic_id]);

  // Load diary
  useEffect(() => {
    if (!parseInt(id, 10)) {
        return dispatch({ type: 'update_all', value: initialState });
    }
    loader.get('/diary/item/' + id, res => {
        res.item.created_at = res.item.created_at ? timeSetup.from(res.item.created_at).format('YYYY-MM-DD HH:mm:ss')
            : moment().format('YYYY-MM-DD HH:mm:ss');
        res.item.datahora = timeSetup.from(res.item.datahora).format('YYYY-MM-DD HH:mm:ss');
        dispatch({ type: 'update_all', value: res.item });
    }, setLoading);
  }, [id, incident]);

  // Register diary
  let register = () => {
    let data = {
        ...diary,
        datahora: timeSetup.to(diary.datahora).format('YYYY-MM-DD HH:mm:ss'),
        created_at: timeSetup.to(diary.created_at).format('YYYY-MM-DD HH:mm:ss')
    };
    let url = '/diary/create';
    if (diary.id) url = '/diary/update';
    setSuccess(false);
    loader.post(url, data, res => {
        if (res.errors) return setErrors(res.errors);
        dispatch({ type: 'update', field: 'id', value: res.id });
        setSuccess(true);
    }, setLoading);
  };

  // DElete item
  let history = useHistory();
  let deleteItem = (id) => {
    const url = '/diary/delete/' + id;
    setSuccess(false);
    loader.get(url, res => {
        if (res.success) return history.goBack();
        setSuccess(true);
    }, setLoading);
  };

  let isDataInvalid = () => {
    return loading
      || !diary.datahora
      || !diary.topic_id
      || (!diary.plan_id && !diary.user_id)
      || (client ? !diary.client_id : false);
  }

  let displayErrors = (errors, key) => !!errors[key] && errors[key].map(msg =>
    <Message severity="error" text={msg}></Message>
  )

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/photo_placeholder.png';
  }

  // Upload
  const onUploadVideo = (e) => {
    if (e.files.length === 0) return;
    
    const formData  = new FormData();
    formData.append('folder', 'diary');
    formData.append('upload', e.files[0]);
    const url = '/upload';
    loader.upload(url, formData, res => {
        if (!res.filename) return;
        dispatch({ type: 'update', field: 'request_video', value: res.filename });  
      }, setLoading);
  }

  const onRemoveVideo = (e, file) => {
    dispatch({ type: 'update', field: 'request_video', value: '' });
  }

  // Build topic options
  let topicOptions = [];
  const allowedTopics = owner.topics.map(t => String(t.id));
  topics.map(opt => {
    if (!allowedTopics.includes(opt.parent_id)) return;
    topicOptions.push({
        label: opt.title,
        value: opt.id,
        key: opt.id,
        category: opt.category
    });
  });

  const userOptions = users.map(opt => ({
    label: opt.username,
    value: opt.id,
    key: opt.id
  }));

  const clientOptions = clients.map(opt => ({
    label: opt.firstname + ' ' + opt.lastname,
    value: opt.id,
    key: opt.id
  }));
  
  if (loading) return <ProgressSpinner />;
  //let canChooseUser = ['ADMINISTRATOR', 'ORG_ADMIN', 'ADMIN'].includes(owner.role1);
  let datahora = diary.datahora ? moment(diary.datahora).toDate() : moment().toDate();
  let created_at = diary.created_at ? moment(diary.created_at) : moment();
  return (
    <React.Fragment>
        { success && (
            <div className="p-grid">
                <div className="p-col-12">

                    <Message 
                        severity="success" 
                        text="O registo foi guardado com sucesso!">
                    </Message>
                    
                </div>
            </div>
        ) }

        <Button 
            onClick={register} 
            label="Guardar"
            disabled={isDataInvalid()}
            style={{ float: 'right' }}
        />
        { !!parseInt(id, 10) && (
            <Button 
                onClick={e => deleteItem(id)} 
                label="Eliminar"
                disabled={!['ADMINISTRATOR', 'ORG_ADMIN'].includes(owner.role1)}
                style={{ float: 'right' }}
                className="p-button-danger"
            />
        )}
        <Button 
            onClick={e => window.history.back()} 
            label="Voltar"
            style={{ float: 'right' }}
            className="p-button-secondary"
        />

        <p className="form-info">
            <strong style={{ color: 'red' }}>*</strong> 
            {' '}Campos obrigatórios
        </p>

        <div className="p-grid" id="taskform">

            <div className="p-col-6">
                <label style={{ fontSize: '0.8em'}}>Data do Registo</label>
            </div>
            <div className="p-col-6">
                <em style={{ fontSize: '0.8em'}}>{ created_at.format('ddd, D MMM [de] YYYY [ás] HH:mm') }</em>
            </div>

            <React.Fragment>
                <div className="p-col-12 p-md-6">
                    <label className={client ? "field-required" : ""}>
                        <i class="pi pi-fw pi-heart"></i>
                        Cliente Associado
                    </label>
                </div>
                <div className="p-col-12 p-md-6">
                    <Dropdown 
                        placeholder="Escolha um cliente"
                        value={parseInt(diary.client_id, 10)}
                        options={clientOptions} 
                        onChange={(e) => 
                            dispatch({ type: 'update', field: 'client_id', value: e.value })
                        }
                    />
                </div>
            </React.Fragment>

             <React.Fragment>
                <div className="p-col-12 p-md-6">
                    <label className="field-required">
                        <i class="pi pi-fw pi-user"></i>
                        { diary.id ? 'Atribuida a' : 'Atribuir a' }
                    </label>
                </div>
                <div className="p-col-12 p-md-6">
                    <Dropdown 
                        placeholder="Escolha um utilizador"
                        value={parseInt(diary.user_id, 10)}
                        options={userOptions} 
                        onChange={(e) => 
                            dispatch({ type: 'update', field: 'user_id', value: e.value })
                        }
                    />
                </div>
            </React.Fragment>

            <React.Fragment>
                <div className="p-col-12 p-md-6">
                    <label className="field-required">
                        <i class="pi pi-fw pi-briefcase"></i>
                        { !!diary.incident ? 'Tipo de Ocorrência' : 'Tipo de Tarefa' }
                    </label>
                </div>
                <div className="p-col-12 p-md-6">
                    <Dropdown 
                        placeholder={ !!diary.incident ? "Escolha o tipo de ocorrência" : "Escolha um tipo de tarefa" }
                        value={parseInt(diary.topic_id, 10)}
                        options={topicOptions}
                        itemTemplate={opt => (
                            <div key={opt.id}>
                                <div className="topic-opt-cat">{ opt.category }</div>
                                <div className="topic-opt-label">{ opt.label }</div>
                            </div>
                        )}
                        onChange={(e) => 
                            dispatch({ type: 'update', field: 'topic_id', value: e.value })
                        }
                    />
                </div>
            </React.Fragment>

            { !!diary.incident && (
                <React.Fragment>
                    <div className="p-col-12 p-md-6">
                        <label className="field-required">
                            <i class="pi pi-fw pi-tag"></i>
                            Etiqueta
                        </label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <InputText
                            placeholder="Etiqueta"
                            value={diary.title}
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'title', value: e.target.value })
                            }
                        />
                        { displayErrors(errors, 'title') }
                    </div>
                </React.Fragment>
            ) }

            { !!diary.topic_id && (
                <React.Fragment>
                    <div className="p-col-12 p-md-6">
                        <label className="field-required">
                            <i class="pi pi-fw pi-calendar"></i>
                            Data / Hora de Realização
                        </label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Calendar 
                            inline={true}
                            placeholder="Inicio"
                            value={datahora} 
                            locale={date_pt}
                            dateFormat="yy-mm-dd"
                            showTime={true}
                            hourFormat="24"
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'datahora', value: e.value })
                            }>
                        </Calendar>
                    </div>
                </React.Fragment>
            )}

            { !!diary.topic_id && !incident && (
                <React.Fragment>
                    <div className="p-col-12 p-md-6">
                        <label>
                            <i class="pi pi-fw pi-info-circle"></i>
                            Detalhes do Pedido
                        </label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <InputTextarea
                            rows={5} 
                            style={{ width: '100%' }}
                            value={diary.request || ''}
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'request', value: e.target.value })
                            }
                        />
                    </div>
                    <div className="p-col-12 p-md-6">
                        <label>
                            <i class="pi pi-fw pi-video"></i>
                            Video Instrucional
                        </label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        { !!diary.request_video && (
                            <video controls
                                alt="Video"
                                title="Video"
                                className="img-avatar"
                                style={{minHeight: '140px'}}>
                                <source
                                    src={getFileUrl(diary.request_video, 'diary')}
                                    type="video/mp4"
                                />
                                O seu browser não suporta videos HTML5
                            </video>
                        ) }
                        <FileUpload 
                            name="request_video"
                            mode="basic"
                            url={'/upload'}
                            customUpload={true}
                            uploadHandler={onUploadVideo}
                            auto={true}
                            chooseLabel={diary.request_video ? "Alterar" : "Escolher" }
                            onRemove={onRemoveVideo}
                        />
                    </div>
                </React.Fragment>
            )}

            { !!diary.user_id && !!diary.fields.length &&
                <div className="p-col-12 p-shadow-3" style={{ display: 'block '}}>
                    <div className="p-grid">
                        { diary.fields.map((field, i) => 
                            <DiaryFieldInput
                                key={field.id}
                                field={field}
                                disabled={String(diary.user_id) !== String(diary.owner_id)}
                                onChange={(value, record) => {
                                    field['value'] = value;
                                    dispatch({ type: 'update', field: 'fields', value: diary.fields })
                                    if (field.db_table_sync) updateAll(field, record);
                                }}
                            />
                        ) }
                    </div>
                </div>
            }

            { !!diary.topic_id && (
                <React.Fragment>
                    <div className="p-col-10 p-md-6">
                        <label className="field-required">
                            <i class="pi pi-fw pi-check"></i>
                            Concluída
                        </label>
                    </div>
                    <div className="p-col-2 p-md-6">
                        <InputSwitch 
                            checked={diary.terminated || false} 
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'terminated', value: !diary.terminated })
                            } 
                        />
                    </div>
                </React.Fragment>
            )}

        </div>

    </React.Fragment>
  );

}

export default DiaryForm;
