import React from 'react';
import Mapa from '../clients/Mapa';

function EntitiesLocation(props) {

  const { entity, dispatch } = props;

  if (!entity) return null;
  return (
    <React.Fragment>
        
        <div className="card card-w-title">
            <h1>Localização</h1>
            
            <Mapa 
                data={entity}
                onMove={latlon => {
                    let action = { 
                        type: 'update_map',
                        latitude: latlon.lat,
                        longitude: latlon.lon,
                        mapzoom: latlon.zoom 
                    };
                    dispatch(action);
                } } 
            />
        </div>

    </React.Fragment>
  );

}

export default EntitiesLocation;
