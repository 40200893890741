import React, { useState, useEffect } from 'react';
import {Panel} from 'primereact/panel';
import {ProgressSpinner} from 'primereact/progressspinner';
import loader from '../loader';

const serverUrl = process.env.REACT_APP_SERVER_URL;

function Home() {

  let [ stickies, setStickies ] = useState([]);

  let [ loading, setLoading ] = useState(false);

  useEffect(() => {
    loader.get('/topic/stickies', res => {
        setStickies(res.items);
    }, setLoading);
  }, []);

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/topic_placeholder.png';
  }

  if (loading) return <ProgressSpinner />;
  return (
    <React.Fragment>
      { stickies.map(topic => (
        <Panel header={topic.title} className="alert">
          { !!topic.image && (
            <img 
              alt="Imagem"
              className="image-pad-right" 
              src={getFileUrl(topic.image, 'topics')} 
            />
          ) }
          <div dangerouslySetInnerHTML={{ __html: topic.description }}></div>
          <div style={{ clear: 'right' }}></div>
        </Panel>
      )) }

      <Panel header="Quadro Resumo">
          Quadro Resumo
      </Panel>
    </React.Fragment> 
  );
}

export default Home;
