import React, { useState, useEffect, useReducer } from 'react';
import { Button } from 'primereact/button';
import {InputSwitch} from 'primereact/inputswitch';
import {Dropdown} from 'primereact/dropdown';
import {Checkbox} from 'primereact/checkbox';
import { Message } from 'primereact/message';
import { Calendar } from 'primereact/calendar';
import {Editor} from 'primereact/editor';
import moment from 'moment';
import 'moment/locale/pt';
import loader from '../loader';
import Session from '../auth/Session';
import dailyOptions from '../plans/daily.json';
import weeklyOptions from '../plans/weekly_days.json';
import monthlyOptions from '../plans/monthly.json';
import durationOptions from '../plans/duration.json';
import date_pt from '../plans/date_pt.json';
import { ColorPicker } from 'primereact/colorpicker';
import timeSetup from '../timeSetup';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputText } from 'primereact/inputtext';
import { useHistory } from 'react-router-dom';
import ClientActivityList from './ClientActivityList';
moment.locale('pt');

function UserActivityForm(props) {
  let { id, owner_id } = props;

  let logged = Session.getCookie();
  const initialState = {
    title: '',
    description: '',
    active: true,
    owner_id: parseInt(owner_id, 10) ? owner_id : logged.id,
    freq_month: '*',
    freq_week: '1,2,3,4,5,6,7',
    freq_day: '*',
    day_hourmin: moment().startOf('day').add(8, 'hour').format('YYYY-MM-DD HH:mm:ss'),
    start_date: moment().startOf('day').add(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
    end_date: moment().startOf('day').add(1, 'month').format('YYYY-MM-DD HH:mm:ss'),
    topic_id: null,
    duration: 60
  };

  let [ users, setUsers ] = useState([]);
  let [ topics, setTopics ] = useState([]);

  let [ user_activity, dispatch ] = useReducer(reducer, initialState);
  
  let [ errors, setErrors ] = useState({});
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  function reducer(state, action) {
    switch (action.type) {
      case 'update':
        if (action.field === 'topic_id') {
            let foundTopic = topics.find(opt => opt.id === action.value);
            return {
                ...state,
                [action.field]: action.value,
                title: foundTopic ? foundTopic.title : state.title,
                duration: foundTopic ? foundTopic.duration : state.duration,
                color: foundTopic ? foundTopic.color : state.color
            };
        }
        if (action.field === 'active') {
            return {
                ...state,
                [action.field]: action.value,
                end_date: !action.value ? moment().format('YYYY-MM-DD') : null
            };
        }
        return {
            ...state,
            [action.field]: action.value
        };
      case 'update_all':
        return {
            ...action.value
        }
      default:
        throw new Error();
    }
  }

  // Load users
  useEffect(() => {
    loader.get('/user/list', res => {
        setUsers(res.items);
    }, setLoading);
  }, []);

  // Load topic activities
  useEffect(() => {
    let url = '/topic/as/activities';
    loader.get(url, res => {
        setTopics(res.items);

        // Set unique
        if (res.items.length === 1) {
            setTimeout(() => {
                dispatch({ type: 'update', field: 'topic_id', value: res.items[0].id });
            }, 10)
        }
    }, setLoading);
  }, []);

  // Load item
  useEffect(() => {
    if (!parseInt(id, 10)) {
        return dispatch({ type: 'update_all', value: initialState });
    }
    loader.get('/user_activity/item/' + id, res => {
        const value = {
            ...res.item,
            start_date: timeSetup.from(res.item.start_date).format('YYYY-MM-DD HH:mm:ss'),
            end_date: res.item.end_date ? timeSetup.from(res.item.end_date).format('YYYY-MM-DD HH:mm:ss') : null,
            day_hourmin: timeSetup.from(res.item.day_hourmin).format('YYYY-MM-DD HH:mm:ss')
        };
        dispatch({ type: 'update_all', value });
    }, setLoading);
  }, [id]);
  
  // Register
  const history = useHistory();
  let register = () => {
    let data = {
        ...user_activity,
        start_date: timeSetup.to(user_activity.start_date).format('YYYY-MM-DD HH:mm:ss'),
        end_date: user_activity.end_date ? timeSetup.to(user_activity.end_date).format('YYYY-MM-DD HH:mm:ss') : null,
        day_hourmin: timeSetup.to(user_activity.day_hourmin).format('YYYY-MM-DD HH:mm:ss')
    };
    let url = '/user_activity/create';
    if (user_activity.id) url = '/user_activity/update';
    setSuccess(false);
    setLoading(true);
    loader.post(url, data, res => {
        if (res.errors) {
            setLoading(false);
            return setErrors(res.errors);
        }
        //dispatch({ type: 'update', field: 'id', value: res.id });
        //setSuccess(true);
        history.goBack();
    });
  };

  let isDataInvalid = () => {
      return loading
        || !user_activity.owner_id
        || !user_activity.start_date
        || !user_activity.end_date;
  }
  
  let displayErrors = (errors, key) => !!errors[key] && errors[key].map(msg =>
    <Message severity="error" text={msg}></Message>
  )

  // Get owner options
  const ownerOptions = users.map(opt => ({
    label: opt.username,
    value: opt.id,
    key: opt.id
  }));

  // Get topic options
  const topicOptions = [];
  const topicCategories = {};

  // Group by category
  topics.map(opt => {
    if (!topicCategories[opt.category]) {
        topicCategories[opt.category] = [];
        topicOptions.push({
            label: opt.category,
            value: opt.id,
            key: opt.id,
            disabled: true
        });
    }
    topicOptions.push({
        label: opt.title,
        value: opt.id,
        key: opt.id
    });
  });

  if (loading) return <ProgressSpinner />;
  let day_hourmin = user_activity.day_hourmin ?
    moment(user_activity.day_hourmin).toDate() 
    : moment().toDate()
  let start_date = user_activity.start_date ? 
    moment(user_activity.start_date).toDate() : moment().toDate();
  let end_date = user_activity.end_date ? 
    moment(user_activity.end_date).toDate() : null;
  return (
    <React.Fragment>

        { success && (
            <div className="p-grid">
                <div className="p-col-12">

                    <Message 
                        severity="success" 
                        text="O horário foi guardado com sucesso!">
                    </Message>
                    
                </div>
            </div>
        ) }

            <Button 
                onClick={register} 
                label="Guardar"
                disabled={isDataInvalid()}
                style={{ float: 'right' }}
            />
            <Button 
                label="Novo Serviço" 
                style={{ float: 'right' }} 
                className="p-button-secondary"
                onClick={e => dispatch({ type: 'update_all', value: { ...initialState, color: 'ff0000' } }) } 
            />

            <p className="form-info">
                <strong style={{ color: 'red' }}>*</strong> 
                {' '}Campos obrigatórios
            </p>

            <div className="p-grid">
            <div className="p-col-12 p-lg-6">
                <div className="p-grid">

                    { !user_activity.owner_id && (
                        <React.Fragment>
                            <div className="p-col-6 p-md-6">
                                <label className="field-required">Colaborador</label>
                            </div>
                            <div className="p-col-6 p-md-6">
                                <Dropdown 
                                    placeholder="Escolha um utilizador"
                                    value={parseInt(user_activity.owner_id, 10)}
                                    options={ownerOptions} 
                                    onChange={(e) => 
                                        dispatch({ type: 'update', field: 'owner_id', value: e.value })
                                    }
                                />
                            </div>
                        </React.Fragment>
                    )}

                    <div className="p-col-12 p-md-6">
                        <label className="field-required">Tipo de Tarefa</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Dropdown 
                            placeholder="Escolha o tipo de tarefa"
                            value={parseInt(user_activity.topic_id, 10)}
                            options={topicOptions} 
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'topic_id', value: e.value })
                            }
                        />
                        { displayErrors(errors, 'topic_id') }
                    </div>

                    <div className="p-col-12 p-md-6">
                        <label className="field-required">Título</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <InputText
                            placeholder="Título"
                            value={user_activity.title}
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'title', value: e.target.value })
                            }
                        />
                        { displayErrors(errors, 'title') }
                    </div>

                    <div className="p-col-10 p-md-6">
                        <label>Activo</label>
                    </div>
                    <div className="p-col-2 p-md-6">
                        <InputSwitch 
                            checked={user_activity.active} 
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'active', value: !user_activity.active })
                            } 
                        />
                    </div>

                    { !!user_activity.color && (
                        <React.Fragment>
                            <div className="p-col-12 p-md-6">
                                <label>Côr de Etiqueta</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <ColorPicker
                                    value={user_activity.color} 
                                    onChange={(e) => 
                                        dispatch({ type: 'update', field: 'color', value: e.value })
                                    }
                                />
                            </div>
                        </React.Fragment>
                    )}

                    <div className="p-col-12 p-md-6">
                        <label>Frequência</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Dropdown 
                            placeholder="Escolha uma opção"
                            value={user_activity.freq_month}
                            options={monthlyOptions} 
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'freq_month', value: e.value })
                            }
                        />
                    </div>

                    { user_activity.freq_month === 'unique_datetime' ? (
                        <React.Fragment>
                            <div className="p-col-12 p-md-6">
                                <label>Data / Hora</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <Calendar 
                                    inline={true}
                                    placeholder="Data / Hora"
                                    value={user_activity.unique_datetime || ''}
                                    locale={date_pt}
                                    monthNavigator={true} 
                                    yearNavigator={true} 
                                    yearRange="2020:2030"
                                    dateFormat="yy-mm-dd"
                                    showTime={true}
                                    hourFormat="24"
                                    onChange={(e) => 
                                        dispatch({ type: 'update', field: 'unique_datetime', value: e.value })
                                    }>
                                </Calendar>
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className="p-col-12 p-md-6">
                                <label>Frequência Semanal</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                                { weeklyOptions.map(opt => (
                                    <React.Fragment>
                                        <Checkbox 
                                            inputId={"w" + opt.value} 
                                            value={opt.value}
                                            onChange={e => {
                                                let selected = user_activity.freq_week.split(',');
                                                if (e.checked) selected.push(e.value);
                                                else selected.splice(selected.indexOf(e.value), 1);
                                                dispatch({ type: 'update', field: 'freq_week', value: selected.join(',') });        
                                            }} 
                                            checked={user_activity.freq_week.split(',').includes(opt.value)}
                                        />
                                        <label htmlFor={"w" + opt.value} className="p-checkbox-label weekopt">
                                            { opt.label }
                                        </label>
                                        <br />
                                    </React.Fragment>
                                ))}
                            </div>

                            <div className="p-col-12 p-md-6">
                                <label>Frequência Diária</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <Dropdown 
                                    placeholder="Escolha uma opção"
                                    value={user_activity.freq_day}
                                    options={dailyOptions} 
                                    onChange={(e) => 
                                        dispatch({ type: 'update', field: 'freq_day', value: e.value })
                                    }
                                />
                            </div>

                            <div className="p-col-12 p-md-6">
                                <label>Hora de Inicio</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <Calendar 
                                    inline={true}
                                    placeholder="Hora"
                                    locale={date_pt}
                                    value={day_hourmin} 
                                    timeOnly={true} 
                                    showTime={true} 
                                    hourFormat="24"
                                    onChange={(e) => {
                                        dispatch({ type: 'update', field: 'day_hourmin', value: e.value })
                                    }}>
                                </Calendar>
                            </div>
                        </React.Fragment>
                    ) }

                    <div className="p-col-12 p-md-6">
                        <label>Duração</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Dropdown 
                            placeholder="Escolha uma opção"
                            value={user_activity.duration}
                            options={durationOptions} 
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'duration', value: e.value })
                            }
                        />
                        { displayErrors(errors, 'duration') }
                    </div>

                    <div className="p-col-12 p-md-6">
                        <label className="field-required">Inicio</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Calendar 
                            placeholder="Inicio"
                            value={start_date}
                            locale={date_pt}
                            monthNavigator={true} 
                            yearNavigator={true} 
                            yearRange="2020:2030"
                            dateFormat="yy-mm-dd"
                            onChange={(e) => {
                                dispatch({ type: 'update', field: 'start_date', value: e.value })
                            }}>
                        </Calendar>
                    </div>

                    <div className="p-col-12 p-md-6">
                        <label className="field-required">Fim</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Calendar 
                            placeholder="Fim"
                            value={end_date}
                            locale={date_pt}
                            monthNavigator={true} 
                            yearNavigator={true} 
                            yearRange="2020:2030"
                            dateFormat="yy-mm-dd"
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'end_date', value: e.value })
                            }>
                        </Calendar>
                    </div>

                </div>
            </div>
            <div className="p-col-12 p-lg-6">
                <Editor 
                    style={{height:'100px'}} 
                    value={user_activity.description}
                    onTextChange={(e) => 
                        dispatch({ type: 'update', field: 'description', value: e.htmlValue })
                    }
                />

                { !!user_activity.id && (
                    <div>
                        <hr />
                        <ClientActivityList id={user_activity.id} />
                    </div>
                )}
            </div>

        </div>

    </React.Fragment>
  );

}

export default UserActivityForm;
