import React from 'react';
import TopicsList from './TopicsList';
import { useParams } from "react-router-dom";

function TopicsListRoute() {

  let urlParams = useParams();
  let activity = !!urlParams.activity;
  let title = activity ? 'Tipos de Tarefas' : 'Categorias';
  return (
    <React.Fragment>
      <div className="card card-w-title">
        <h1>{ title }</h1>
        
        <TopicsList activity={activity} />
      </div>
    </React.Fragment>
  );

}

export default TopicsListRoute;
