import React, { useState, useEffect } from 'react';
import {Panel} from 'primereact/panel';
import {Card} from 'primereact/card';
import { Link } from "react-router-dom";
import loader from '../loader';

const serverUrl = process.env.REACT_APP_SERVER_URL;

function TopicList(props) {

  let { parent } = props;

  let [ items, setItems ] = useState([]);
  
  let [ loading, setLoading ] = useState(false);

  useEffect(() => {
    loader.get('/topic/list' + (parent ? '/' + parent.id : ''), res => {
        setItems(res.items);
    }, setLoading);
  }, [parent]);


  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/topic_placeholder.jpg';
  }

  if (loading) return <div>A carregar...</div>;
  const title = parent ? "Sub-topicos de " + parent.title : "Informações"
  return (
    <Panel header={title}>

        <div className="p-grid">

            { items.map(item => (
                <div className="p-col-6 p-lg-2" key={item.id}>
                    <Card 
                        header={(
                            <Link to={'/topics/view/' + item.id }>
                                <img 
                                    src={getFileUrl(item.image, 'topics')} 
                                    alt="Imagem"
                                    title="Imagem"
                                    className="img-avatar"
                                />
                            </Link>
                        )}>
                        { item.title }
                    </Card>
                </div>
            )) }
            
        </div>
                    
    </Panel>
  );

}

export default TopicList;
