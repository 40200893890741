import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import {InputSwitch} from 'primereact/inputswitch';
import { Message } from 'primereact/message';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toolbar} from 'primereact/toolbar';
import DiaryCalendar from '../diary/DiaryCalendar';
import { useHistory, Link } from "react-router-dom";
import moment from 'moment';
import loader from '../loader';
import durationOptions from '../workplans/duration.json';
import { ProgressSpinner } from 'primereact/progressspinner';
import Session from '../auth/Session';
import timeSetup from '../timeSetup';
import { Card } from 'primereact/card';

const serverUrl = process.env.REACT_APP_SERVER_URL;

const getItems = (res, setItems) => {
    let titems = res.items;
    titems = titems.map(i => ({
        ...i,
        start_date: timeSetup.from(i.start_date).format('YYYY-MM-DD HH:mm'),
        end_date: i.end_date ? timeSetup.from(i.end_date).format('YYYY-MM-DD HH:mm') : null,
        day_hourmin: timeSetup.from(i.day_hourmin).format('YYYY-MM-DD HH:mm')    
    }))
    setItems(titems);
}

function UserActivityList(props) {
  const { owner_id } = props;

  let [ items, setItems ] = useState([]);
  let [ date, setDate ] = useState(moment().startOf('week').format('YYYY-MM-DD'));
  let [ calendar, setCalendar ] = useState([]);
  let [ display, setDisplay ] = useState('grid');
  
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  let history = useHistory();

  const reload = () => {
    if (owner_id) {
        loader.get('/user_activity/user/' + owner_id, res => {
            if (!res.success) return;
            getItems(res, setItems);
        }, setLoading);
    } else {
        loader.get('/user_activity/list', res => {
            if (!res.success) return;
            getItems(res, setItems);
        }, setLoading);    
    }

    if (!parseInt(owner_id, 10)) return;
    let startdate = moment().startOf('week').format('YYYY-MM-DD');
    let enddate = moment().startOf('week').add(2, 'weeks').format('YYYY-MM-DD');
    loader.get('/user_activity/calendar/' + owner_id + '/' + startdate + '/' + enddate, res => {
        let calitems = res.items.map(i => ({
            id: i.id,
            title: i.title,
            start: timeSetup.from(i.start).format('YYYY-MM-DD HH:mm:ss'),
            end: timeSetup.from(i.end).format('YYYY-MM-DD HH:mm:ss'),
            backgroundColor: i.color
        }));
        setCalendar(calitems);
    });
  }

  useEffect(() => {
    reload();
  }, []);

  let updateItem = (data) => {
    const url = '/user_activity/update';
    setSuccess(false);
    loader.post(url, data, res => {
        setSuccess(true);
        reload();
    }, setLoading);
  };

  let deleteItem = (id) => {
    const url = '/user_activity/delete/' + id;
    setSuccess(false);
    loader.get(url, res => {
        if (!res.success) return;
        setSuccess(true);
        reload();
    }, setLoading);
  };

  let toggleActive = (user_activitys, id) => {
    let items = Object.assign([], user_activitys);
    items = items.map(item => {
        if (item.id === id) {
            item.active = !item.active;
            item.end_date = item.active ? null : new Date();
            updateItem({ 
                id: item.id, 
                active: item.active, 
                end_date: item.end_date,
                topic_id: item.topic_id, 
                title: item.title 
            });
        }
        return item;
    });
    setItems(items);
  }

  let recordOptions = (item, column) => {
    const logged = Session.getCookie();
    return <div style={{ textAlign: 'right' }}>
        <Button
            onClick={e => history.push({ pathname: '/backoffice/user_activities/form/' + item.id })} 
            type="button" 
            icon="pi pi-pencil" 
            className="p-button-info"
        />
        <Button
            onClick={e => deleteItem(item.id)} 
            type="button" 
            icon="pi pi-times" 
            className="p-button-danger"
            disabled={String(logged.id) !== String(item.owner_id)}
        />
    </div>;
  }

  let recordActive = (item, column) => {
    return <div style={{ textAlign: 'right' }}>
        <InputSwitch 
            checked={item.active} 
            onChange={(e) => toggleActive(items, item.id)} 
        />
    </div>;
  }

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/topic_placeholder.jpg';
  }

  const getOptionLabel = (options, value) => {
    const item = options.find(opt => opt.value === value);
    return item ? item.label : value;
  }

  if (loading) return <ProgressSpinner />;
  let createUrl = '/technical/services/form/0';
  return (
    <React.Fragment>
        
        <Toolbar>
            <div className="p-toolbar-group-left">
                <Link to={createUrl}>
                    <Button label="Adicionar Serviço" />
                </Link>
            </div>
            <div className="p-toolbar-group-right">
                <InputSwitch 
                    label="Grelha"
                    checked={display === 'grid'} 
                    onChange={(e) => setDisplay(display === 'grid' ? 'list' : 'grid')} 
                />{' '}Grelha
            </div>
        </Toolbar>

        { success && (
            <div className="p-grid">
                <div className="p-col-12">

                    <Message 
                        severity="success" 
                        text="O serviço foi alterado com sucesso!">
                    </Message>
                    
                </div>
            </div>
        ) }

        { items.length === 0 && (
            <p>Não têm serviços registados. Click em <Link to={createUrl}>Adicionar Serviço</Link>.</p>
        )}

        { display === 'grid' ? (
            <div className="p-grid">

                { items.map(item => (
                    <div className="p-col-12 p-lg-3" key={item.id} id="user-activities-grid">
                        <Card
                            title={item.title}
                            subTitle={item.duration + ' minutos'}
                            header={(
                                <Link to={'/technical/services/form/' + item.id }>
                                    <img 
                                        src={getFileUrl(item.image, 'topics')} 
                                        alt="Imagem"
                                        title="Imagem"
                                        className="img-avatar"
                                    />
                                </Link>
                            )}
                            footer={
                                <div className="p-grid diary-footer">
                                    <div className="p-col-6">
                                        <big>{ moment(item.day_hourmin).format('HH:mm') }</big>
                                    </div>
                                    <div className="p-col-6">
                                        <InputSwitch 
                                            checked={item.active || false} 
                                        />
                                    </div>
                                </div>
                            }>
                            <em className="subtitle">{ item.category }</em>
                            <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
                        </Card>
                    </div>
                )) }
                
            </div>
        ) : (

            <div className="p-grid">
                <div className="p-col-12">

                <DataTable value={items} responsive={true}>
                    <Column 
                        header="Título"
                        field="title"
                        body={item => (item.category ? item.category + ' - ' : '') + item.title}  
                        sortable={true}
                        filter={true}
                        filterMatchMode="contains"
                    />

                    <Column 
                        header="Hora" 
                        body={i => moment(i.day_hourmin).format('HH:mm')} 
                    />

                    <Column 
                        header="Duração" 
                        body={i => getOptionLabel(durationOptions, i.duration)}
                    />
                    <Column 
                        header="Inicio" 
                        body={item => moment(item.start_date).format('YYYY-MM-DD')}
                    />
                    <Column 
                        header="Fim" 
                        body={item => item.end_date ? moment(item.end_date).format('YYYY-MM-DD') : ''}
                    />
                    <Column body={recordActive} header="Activo" />
                    <Column body={recordOptions} header="Opções" />
                </DataTable>
                    
                </div>
            </div>
        ) }

        <div>
            <hr/>
            <h2>Calendário</h2>
            <small className="subtitle">Apenas calculado para as próximas 2 semanas</small>

            <DiaryCalendar 
                start={moment(date).add(1, 'day').format('YYYY-MM-DD')}
                items={calendar} 
            />
        </div>
            
    </React.Fragment>
  );

}

export default UserActivityList;
