import React from 'react';
import { Route, Redirect, useLocation } from "react-router-dom";
import Session from './Session';

const technicalRoles = ['SECURITY', 'ASSISTENT'];

function PrivateRoute({ children, ...rest }) {

    const location = useLocation();

    // Redirect not logged
    const logged = Session.getCookie();
    if (!logged) return <Redirect to={{ pathname: "/login", state: { from: location }}} />

    // Redirect not authorized to admin
    const isBackoffice = /backoffice/ig.test(location.pathname);
    const isAssistent = technicalRoles.includes(logged.role1);
    if (isBackoffice && isAssistent) return <Redirect to={{ pathname: "/technical" }} />
    return (
      <Route
        {...rest}
        render={({ location }) =>
          Session.isAuthenticated() ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
}

export default PrivateRoute;