import React, { useState, useEffect } from 'react';
import { useParams, Route, useLocation } from "react-router-dom";
import Session from '../auth/Session';
import loader from '../loader';
import { ProgressSpinner } from 'primereact/progressspinner';
import ClientsForm from './ClientsForm';
import ClientsResume from './ClientsResume';
import ClientsPLans from './ClientsPlans';
import ClientsTherapy from './ClientsTherapy';
import ClientsHistory from './ClientsHistory';
import ClientsEntities from './ClientsEntities';
import ClientsLocation from './ClientsLocation';
import ClientsDocs from './ClientsDocs';
import ClientOutList from './ClientOutList';
import ClientsMenu from './ClientsMenu';
import ClientsGraphs from './ClientsGraphs';
import ClientsReport from './ClientsReport';
import ClientsDiscuss from './ClientsDiscuss';
import DiscussForm from './DiscussForm';
import DiscussView from './DiscussView';
import { getParams } from '../helpers';
import ClientsGoals from './ClientsGoals';

function ClientsRoute() {

  let urlParams = useParams();
  let id = urlParams.id;
  let action = urlParams.action;
  let params = getParams(useLocation().search);

  // Load diary
  let [ client, setClient ] = useState(null);
  let [ loading, setLoading ] = useState(true);
  let logged = Session.getCookie();

  // Load client
  useEffect(() => {
    setLoading(true);
    if (!parseInt(id, 10)) return setLoading(false);
    loader.get('/client/item/' + id, res => {
        setClient(res.item);
        setLoading(false);
    });
  }, [id]);

  if (loading) return <ProgressSpinner />;
  
  let title = client ? client.firstname + ' ' + client.lastname : "Novo Cliente";
  return (
    <div>
        <h1 className="subtitle">{ title }</h1>

        { !!client && <ClientsMenu client={client} activeMenu={action} /> }

        <Route path="/backoffice/clients/view/:id">
            <ClientsResume client={client} logged={logged} />
        </Route>

        <Route path="/backoffice/clients/form/:id">
            <ClientsForm id={id} />
        </Route>
        
        <Route path="/backoffice/clients/plans/:id">
            <ClientsPLans client={client} setClient={setClient.bind(this)} />
        </Route>
        <Route path="/backoffice/clients/goals/:id">
            <ClientsGoals client={client} setClient={setClient.bind(this)} />
        </Route>
        <Route path="/backoffice/clients/therapy/:id">
            <ClientsTherapy client={client} />
        </Route>
        <Route path="/backoffice/clients/history/:id">
            <ClientsHistory client={client} />
        </Route>
        <Route path="/backoffice/clients/entities/:id">
            <ClientsEntities client={client} />
        </Route>
        <Route path="/backoffice/clients/location/:id">
            <ClientsLocation client={client} setClient={setClient.bind(this)} />
        </Route>
        <Route path="/backoffice/clients/docs/:id">
            <ClientsDocs client={client} />
        </Route>
        <Route path="/backoffice/clients/out/:id">
            <ClientOutList client={client} />
        </Route>
        <Route path="/backoffice/clients/graphs/:id">
            <ClientsGraphs client={client} />
        </Route>
        <Route path="/backoffice/clients/report/:id">
            <ClientsReport client={client} />
        </Route>
        <Route path="/backoffice/clients/discuss/:id/view/:discuss_id?">
            <DiscussView client={client} />
        </Route>
        <Route path="/backoffice/clients/discuss/:id/form/:discuss_id?">
            <DiscussForm client={client} params={params} />
        </Route>
        <Route exact path="/backoffice/clients/discuss/:id">
            <ClientsDiscuss client={client} />
        </Route>
    </div>
  );

}

export default ClientsRoute;
