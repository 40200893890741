import React, { useState, useEffect } from 'react';
import loader from '../loader';
import { ProgressSpinner } from 'primereact/progressspinner';
import FailedLoginsList from './FailedLoginsList';

function UsersFailedLogins({ id }) {

  let [ user, setUser ] = useState();
  let [ loading, setLoading ] = useState(false);

  // Load user
  useEffect(() => {
    if (!parseInt(id, 10)) return;
    loader.get('/user/item/' + id, res => {
        setUser(res.item);
    }, setLoading);
  }, [id]);

  if (loading) return <ProgressSpinner />;
  return (
    <React.Fragment>

      <FailedLoginsList user={user} />

    </React.Fragment>
  );

}

export default UsersFailedLogins;
