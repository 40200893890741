import React from 'react';
import UserActivityList from './UserActivityList';
import { useParams } from "react-router-dom";
import Session from '../auth/Session';

function UserActivityListRoute() {

  let urlParams = useParams();
  let logged = Session.getCookie();
  let owner_id = parseInt(urlParams.id, 10) ? urlParams.id : logged.id;
  return (
    <React.Fragment>
      <div className="card card-w-title">
        <h1>Os Meus Serviços</h1>
        
        <UserActivityList owner_id={owner_id} />
      </div>
    </React.Fragment>
  );

}

export default UserActivityListRoute;
