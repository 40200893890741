import React, { useState, useEffect } from 'react';
import {Toolbar} from 'primereact/toolbar';
import {Calendar} from 'primereact/calendar';
import loader from '../loader';
import moment from 'moment';
import {Chart} from 'primereact/chart';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import date_pt from '../date_pt.json';

let dt = null;

function DiaryChart(props) {

  let [ items, setItems ] = useState([]);
  let [ itemsDone, setItemsDone ] = useState([]);
  let [ start, setStart ] = useState(moment().startOf('month').toDate());
  let [ end, setEnd ] = useState(moment().endOf('month').toDate());

  let [ display, setDisplay ] = useState('chart');
  let [ loading, setLoading ] = useState(false);

  useEffect(() => {
    let isCanceled = false;
    let tstart = moment(start).format('YYYY-MM-DD');
    let tend = moment(end).format('YYYY-MM-DD');
    loader.get('/diary/report/all/' + tstart + '/' + tend, res => {
        if (isCanceled) return;
        setItems(res.items);
    });
    loader.get('/diary/report/all/' + tstart + '/' + tend + '/1', res => {
        if (isCanceled) return;
        setItemsDone(res.items);
    });
    return () => {
      isCanceled = true;
    }
  }, [start, end]);

  const data = {
    labels: items.map(i => i.monthday),
    datasets: [
        {
            label: 'Total de tarefas',
            data: items.map(i => i.total),
            fill: false,
            backgroundColor: '#42A5F5',
            borderColor: '#42A5F5'
        },
        {
            label: 'Tarefas concluídas',
            data: itemsDone.map(i => i.total),
            fill: false,
            backgroundColor: '#00FF00',
            borderColor: '#00FF00'
        }
    ]
  };

  if (loading) return <ProgressSpinner />;
  return (

    <div className="card card-w-title">
        <h1>Tarefas</h1>

        <Toolbar>
            <div className="p-toolbar-group-left">
                <div className="p-grid">
                    <div className="p-col-12 p-lg-6">
                        <Calendar 
                            placeholder="Data de Inicio"
                            value={start} 
                            locale={date_pt}
                            dateFormat="yy-mm-dd"
                            yearNavigator={true}
                            yearRange="2020:2030"
                            onChange={(e) => setStart(e.value) }>
                        </Calendar>
                    </div>
                    <div className="p-col-12 p-lg-6">
                        <Calendar 
                            placeholder="Data de Fim"
                            value={end} 
                            locale={date_pt}
                            dateFormat="yy-mm-dd"
                            yearNavigator={true}
                            yearRange="2020:2030"
                            onChange={(e) => setEnd(e.value) }>
                        </Calendar>
                    </div>
                </div>
            </div>

            { !!items.length && (
                <div className="p-toolbar-group-right">
                    <InputSwitch
                        id="displayToggle"
                        style={{ marginBottom: '-7px' }}
                        label="Grelha"
                        checked={display === 'table'} 
                        onChange={(e) => setDisplay(display === 'chart' ? 'table' : 'chart')} 
                    />{' '}<label htmlFor="displayToggle">Tabela</label>{' '}
                    { display === 'table' && (
                        <Button
                            type="button" 
                            icon="pi pi-external-link" 
                            iconPos="left" 
                            label="CSV" 
                            onClick={e => dt.exportCSV()}
                        />
                    )}
                </div>
            )}
            
        </Toolbar>

        { items.length === 0 ? <p>Sem dados a apresentar</p> : (
            display === 'chart' ? 
                <Chart type="line" data={data} options={{ maintainAspectRatio: false }} />
                : (
                <DataTable 
                    value={items} 
                    ref={(el) => { dt = el; }}
                    style={{ textAlign: 'center' }}>
                    <Column header="Data" field="monthday" />
                    <Column header="Total de Tarefas" field="total" />
                </DataTable>
                )
        ) }
        
    </div>
    
  );

}

export default DiaryChart;
