import React, { useState, useEffect } from 'react';
import {Panel} from 'primereact/panel';
import {ProgressSpinner} from 'primereact/progressspinner';
import loader from '../loader';
import DiaryChart from './DiaryChart';
import DiaryIncidents from './DiaryIncidents';
import Session from '../auth/Session';
import { Link } from 'react-router-dom';
import { hasMod } from '../users/Permissions';

const serverUrl = process.env.REACT_APP_SERVER_URL;

function Dashboard() {

  let [ stickies, setStickies ] = useState([]);
  let [ data, setData ] = useState({});

  let [ loading, setLoading ] = useState(false);
  let logged = Session.getCookie();

  useEffect(() => {
    loader.get('/topic/stickies', res => {
        setStickies(res.items);
    }, setLoading);
  }, []);

  useEffect(() => {
    loader.get('/stats/dashboard', res => {
        setData(res.data);
    }, setLoading);
  }, []);

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/topic_placeholder.png';
  }

  if (loading) return <ProgressSpinner />;
  const user = Session.getCookie();
  return (
      <React.Fragment>
          
          { loading ? <ProgressSpinner/> : (
            stickies.map(topic => (
              <Panel header={topic.title} className="alert" key={topic.id}>
                { !!topic.image && (
                  <img 
                    alt="Imagem"
                    className="image-pad-right" 
                    src={getFileUrl(topic.image, 'topics')} 
                  />
                ) }
                <div dangerouslySetInnerHTML={{ __html: topic.description }}></div>
                <div style={{ clear: 'right' }}></div>
              </Panel>
            ))
          ) }

          <div className="p-grid p-fluid dashboard">

            { ['ADMINISTRATOR', 'ORG_ADMIN', 'ADMIN'].includes(logged.role1) && (
              <div className="p-col-12 p-lg-4">  
                <Link to="/backoffice/diary/list">
                  <div className="card summary card-menu">
                      <span className="title">Diário</span>
                      <span className="detail">Tarefas de ontem, hoje e amanhã</span>
                      <span className="count visitors">
                        <i className="pi pi-check"></i>
                      </span>
                  </div>
                </Link>                
              </div>
            )}

            <div className="p-col-12 p-lg-4">
              <Link to="/backoffice/clients/list">
                <div className="card summary card-menu">
                    <span className="title">Clientes</span>
                    <span className="detail">Plano individual, terapêutica e outros</span>
                    <span className="count visitors">
                      <i className="pi pi-heart"></i>
                    </span>
                </div>
              </Link>
            </div>
            <div className="p-col-12 p-lg-4">
              <Link to="/backoffice/topics/list">
                <div className="card summary card-menu">
                    <span className="title">Categorias</span>
                    <span className="detail">Categorias de tarefas e ajuda</span>
                    <span className="count visitors">
                      <i className="pi pi-info"></i>
                    </span>
                </div>
              </Link>
            </div>

            { ['ADMINISTRATOR', 'ORG_ADMIN', 'ADMIN'].includes(user.role1) && hasMod(['T4CCT1']) && (
              <div className="p-col-12 p-lg-4">
                <Link to="/backoffice/entities/list">
                  <div className="card summary card-menu">
                      <span className="title">Contactos</span>
                      <span className="detail">Pessoas, fornecedores e locais</span>
                      <span className="count visitors">
                        <i className="pi pi-id-card"></i>
                      </span>
                  </div>
                </Link>
            </div>
            ) }

            { ['ADMINISTRATOR', 'ORG_ADMIN'].includes(user.role1) && (
              <div className="p-col-12 p-lg-4">
                <Link to="/backoffice/users/list">
                  <div className="card summary card-menu">
                      <span className="title">Utilizadores</span>
                      <span className="detail">Logins, horários, ausências e outros</span>
                      <span className="count visitors">
                        <i className="pi pi-user"></i>
                      </span>
                  </div>
                </Link>
            </div>
            ) }

            { ['ADMINISTRATOR', 'ORG_ADMIN'].includes(user.role1) && (
              <div className="p-col-12 p-lg-4">
                <Link to="/backoffice/topics/list/activity">
                  <div className="card summary card-menu">
                    <span className="title">Tarefas À la Carte</span>
                    <span className="detail">Tipos de tarefas e campos associados</span>
                    <span className="count visitors">
                      <i className="pi pi-briefcase"></i>
                    </span>
                  </div>
                </Link>
            </div>
            ) }

            { hasMod(['T4CST1']) &&
              <div className="p-col-12 p-lg-4">
                <Link to="/backoffice/stock/list">
                  <div className="card summary card-menu">
                      <span className="title">Stocks</span>
                      <span className="detail">Movimentos de materiais entre localizações</span>
                      <span className="count visitors">
                        <i className="pi pi-shopping-cart"></i>
                      </span>
                  </div>
                </Link>
              </div>
            }

            { hasMod(['T4CPI1']) &&
              <div className="p-col-12 p-lg-4">
                <Link to="/backoffice/plans/list">
                  <div className="card summary card-menu">
                      <span className="title">Outras Atividades</span>
                      <span className="detail">Atividades de grupo</span>
                      <span className="count visitors">
                        <i className="pi pi-calendar"></i>
                      </span>
                  </div>
                </Link>
              </div>
            }

            { ['ADMINISTRATOR', 'ORG_ADMIN'].includes(user.role1) && (
              <div className="p-col-12 p-lg-4">
                <Link to="/backoffice/groups/list">
                  <div className="card summary card-menu">
                      <span className="title">Grupos de Utilizadores</span>
                      <span className="detail">Grupos para troca de mensagens e alertas</span>
                      <span className="count visitors">
                        <i className="pi pi-users"></i>
                      </span>
                  </div>
                </Link>
            </div>
            ) }
            
          </div>

          <div className="p-grid p-fluid dashboard">
            <div className="p-col-12 p-lg-8">
              <DiaryChart />
            </div>

            { hasMod(['T4CIN1']) &&
              <div className="p-col-12 p-lg-4">
                <DiaryIncidents />
              </div>
            }

          </div>

      </React.Fragment>  
  );
}

export default Dashboard;
