import React from 'react';
import WorkPlansList from './WorkPlansList';
import { useParams } from "react-router-dom";

function WorkPlansListRoute() {

  let urlParams = useParams();

  return (
    <React.Fragment>
      <div className="card card-w-title">
        <h1>Horários dos Colaboradores</h1>
        
        <WorkPlansList owner_id={urlParams.id} />
      </div>
    </React.Fragment>
  );

}

export default WorkPlansListRoute;
