import React from 'react';
import {Rating} from 'primereact/rating';
import { InputText } from 'primereact/inputtext';
import {InputSwitch} from 'primereact/inputswitch';
import {Dropdown} from 'primereact/dropdown';
import {AutoComplete} from 'primereact/autocomplete';
import {ListBox} from 'primereact/listbox';
import {InputTextarea} from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import {SelectButton} from 'primereact/selectbutton';
import { Button } from 'primereact/button';
import date_pt from '../date_pt.json';
import { Chart } from 'primereact/chart';
import { useState } from 'react';
import loader from '../loader';
import { FileUpload } from 'primereact/fileupload';
import { Dialog } from 'primereact/dialog';

const serverUrl = process.env.REACT_APP_SERVER_URL;
const isMobile = window.innerWidth <= 768;

function mapMultiValues(type, value) {
    let fv = value ? value : [];
    if ('array_string' === type) fv = fv.map(v => String(v));
    else fv = fv.map(v => Number(v));
    return fv;
}

let scale_params = [];

export default function FormField(props) {
    const { field, onChange, disabled, readOnly, count } = props;
    const [chartType, setChartType] = useState('radar');
    const [dataOptions, setDataOptions] = useState([]);
    const [search, setSearch] = useState('');
    const [selected, setSelected] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [toggleSearch, setToggleSearch] = useState(false);
    const [loading, setLoading] = useState(false);

    const reqClass = readOnly || count > 1 ? "" : "field-required";

    // Download
    const getFileUrl = (filename, folder) => {
      return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/topic_placeholder.png';
    }

    // Upload
    const onUpload = (e) => {
      if (e.files.length === 0) return;

      const formData  = new FormData();
      formData.append('folder', 'diary');
      formData.append('upload', e.files[0]);
      const url = '/upload';
      loader.upload(url, formData, res => {
        if (!res.filename) return;
        onChange(res.filename);  
      }, setLoading);
    }

    // Upload
    const onUploadFile = (e) => {
      if (e.files.length === 0) return;
      const formData  = new FormData();
      formData.append('folder', 'diary');
      formData.append('upload', e.files[0]);
      const url = '/upload';
      loader.upload(url, formData, res => {
        if (!res.filename) return;
        onChange(res.filename);  
      }, setLoading);
    }

    let getUserTableValue = (event) => {
        const search = event.query.toLowerCase();
        if (!field.db_table) return;
        const searchData = {
            table_name: field.db_table,
            column_name: field.db_table_label,
            search
        };
        loader.post('/field/datatable/values', searchData, res => {        
            if (res.errors) return;
            setDataOptions(res.items);
        });
    }
    
    // Render field
    switch(field.display) {

        case 'decimal': return (
            <React.Fragment>
                <div className="p-col-6 p-md-6">
                    <label className={reqClass}>{ field.label }</label>
                </div>
                <div className="p-col-3 p-md-3">
                    <InputText 
                        title="Parte inteira"
                        type="number"
                        step="any"
                        min="00"
                        max="130"
                        disabled={disabled}
                        placeholder={field.label}
                        value={String(parseFloat(field.value || 0).toFixed(2)).split('.')[0]}
                        onChange={(e) => {
                            if (disabled) return;
                            let ints = parseInt(e.target.value, 10);
                            let decimals = String(parseFloat(field.value || 0).toFixed(2)).split('.')[1];
                            let value = ints + (decimals / 100);
                            onChange(value)
                        }}
                    />
                </div>
                <div className="p-col-3 p-md-3">
                    <InputText
                        title="Parte decimal"
                        mask=",99" 
                        type="number"
                        min="00"
                        max="99"
                        placeholder="00"
                        disabled={disabled} 
                        value={(String(parseFloat(field.value || 0).toFixed(2)).split('.')[1] || 0)}
                        onChange={(e) => {
                            if (disabled) return;
                            let ints = String(parseFloat(field.value || 0).toFixed(2)).split('.')[0];
                            let decimals = (parseInt(e.target.value, 10) / 100);
                            let value = parseInt(ints, 10) + decimals;
                            onChange(value) 
                        }}>
                    </InputText>
                </div>
            </React.Fragment>
        );

        case 'date':
            return (
                <React.Fragment>
                    <div className="p-col-12 p-md-6">
                        <label className={reqClass}>{ field.label }</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Calendar
                            inline={true}
                            locale={date_pt}
                            placeholder="Data"
                            disabled={disabled}
                            value={field.value ? new Date(field.value) : null} 
                            dateFormat="yy-mm-dd"
                            showTime={false}
                            onChange={(e) => {
                                if (disabled) return;
                                onChange(e.value) 
                            }}>
                        </Calendar>
                    </div>
                </React.Fragment>
            );

        case 'datetime': return (
            <React.Fragment>
                <div className="p-col-12 p-md-6">
                    <label className={reqClass}>{ field.label }</label>
                </div>
                <div className="p-col-12 p-md-6">
                    <Calendar
                        inline={true}
                        locale={date_pt}
                        placeholder="Data / Hora"
                        disabled={disabled}
                        value={field.value ? new Date(field.value) : null}  
                        dateFormat="yy-mm-dd"
                        showTime={true}
                        hourFormat="24"
                        onChange={(e) => {
                            if (disabled) return;
                            onChange(e.value) 
                        }}>
                    </Calendar>
                </div>
            </React.Fragment>
        );

        case 'image_upload': return (
            <React.Fragment>
                <div className="p-col-12 p-md-6">
                    <label className={reqClass}>{ field.label }</label>
                </div>
                <div className="p-col-12 p-md-6">
                    { !!field.value && (
                        <img 
                            src={getFileUrl(field.value, 'diary')} 
                            alt="Imagem"
                            title="Imagem"
                            className="img-avatar"
                            onClick={e => setShowModal(true)}
                            style={{ cursor: 'pointer' }}
                        />
                    ) }
                    { !readOnly &&
                        <FileUpload
                            name="photo"
                            mode="basic"
                            url={'/upload'}
                            accept="image/*"
                            customUpload={true}
                            uploadHandler={onUpload}
                            auto={true}
                            disabled={disabled || loading}
                            chooseLabel={field.value ? "Alterar" : "Escolher" }
                            onRemove={(e, file) => onChange('')}
                        />
                    }
                    <Dialog
                        header="Imagem" 
                        visible={showModal}
                        style={{width: isMobile ? '90%' : '1024px'}} 
                        modal={true} onHide={() => setShowModal(false)}>
                        <img 
                            src={getFileUrl(field.value, 'diary')} 
                            alt="Imagem"
                            title="Imagem"
                            className="img-avatar"
                        />
                    </Dialog>
                </div>
            </React.Fragment>
        );

        case 'file_upload': return (
            <React.Fragment>
                <div className="p-col-12 p-md-6">
                    <label className={reqClass}>{ field.label }</label>
                </div>
                <div className="p-col-12 p-md-6 diary-file-upload">
                    { !!field.value && <Button
                        target="_blank"
                        rel="noopener noreferrer"
                        alt="Documento"
                        title="Documento"
                        className="p-button-link p-button-info"
                        icon="pi pi-download"
                        label="Abrir"
                        onClick={e => window.open(getFileUrl(field.value, 'diary'))}
                    /> }
                    { !readOnly &&
                        <FileUpload
                            name="photo"
                            mode="basic"
                            url={'/upload'}
                            accept="application/acrobat,application/x-pdf,applications/vnd.pdf,text/pdf,text/x-pdf"
                            customUpload={true}
                            uploadHandler={onUploadFile}
                            auto={true}
                            disabled={disabled || loading}
                            chooseLabel={field.value ? "Alterar" : "Escolher" }
                            onRemove={(e, file) => onChange('')}
                        />
                    }
                </div>
            </React.Fragment>
        );

        case 'rating_10': return (
            <React.Fragment>
                <div className="p-col-12 p-md-6">
                    <label className={reqClass}>{ field.label }</label>
                </div>
                <div className="p-col-12 p-md-6">
                    <Rating 
                        disabled={disabled}
                        cancel={false}
                        value={field.value || null} 
                        stars={10}
                        onChange={(e) => {
                            if (disabled) return;
                            onChange(e.value) 
                        }}  
                    />
                </div>
            </React.Fragment>
        );

        case 'rating': return (
            <React.Fragment>
                <div className="p-col-6 p-md-6">
                    <label className={reqClass}>{ field.label }</label>
                </div>
                <div className="p-col-6 p-md-6">
                    <Rating 
                        disabled={disabled}
                        cancel={false}
                        value={field.value || null} 
                        onChange={(e) => {
                            if (disabled) return;
                            onChange(e.value) 
                        }}  
                    />
                </div>
            </React.Fragment>
        );

        case 'textarea': return (
            <React.Fragment>
                <div className="p-col-12 p-md-6">
                    <label className={reqClass}>{ field.label }</label>
                </div>
                <div className="p-col-12 p-md-6">
                    <InputTextarea 
                        rows={3} 
                        disabled={disabled}
                        style={{ width: '100%' }}
                        value={field.value || null}
                        onChange={(e) => {
                            if (disabled) return;
                            onChange(e.target.value) 
                        }}
                    />
                </div>
            </React.Fragment>
        );

        case 'db_table': return (
            <React.Fragment>
                <div className="p-col-12 p-md-6">
                    <label className={reqClass}>{ field.label }</label>
                </div>
                <div className="p-col-12 p-md-6">
                    
                    { !toggleSearch ?

                        <div className="p-inputgroup">
                            <InputText
                                disabled={disabled}
                                value={field.value || null}
                                onChange={(e) => {
                                    if (disabled) return;
                                    onChange(e.target.value) 
                                }}
                            />
                            { !readOnly &&
                                <Button 
                                    icon="pi pi-search" 
                                    onClick={e => setToggleSearch(true)} 
                                    disabled={disabled} 
                                />
                            }
                        </div>
                    
                        :
                        
                        <AutoComplete
                            disabled={disabled}
                            placeholder="Pesquisar..." 
                            value={search}
                            suggestions={dataOptions.map((i, j) => ({
                                label: i[field.db_table_label],
                                value: i[field.db_table_value],
                                key: 'v' + j + i[field.db_table_value]
                            }))}
                            field="label"
                            onChange={e => {
                                setSearch(e.value);
                            }}
                            completeMethod={getUserTableValue}
                            onSelect={(e) => {
                                if (disabled) return;
                                setSelected(e.value);
                                onChange(e.value.value, dataOptions.find(opt => opt[field.db_table_value] === e.value.value));
                                setToggleSearch(false);
                            }}
                        />

                    }
                </div>
            </React.Fragment>
        );

        case 'toggle': return (
            <React.Fragment>
                <div className="p-col-6 p-md-6">
                    <label className={reqClass}>{ field.label }</label>
                </div>
                <div className="p-col-6 p-md-6">
                    <InputSwitch 
                        disabled={disabled}
                        checked={field.value || false} 
                        onChange={(e) => {
                            if (disabled) return;
                            onChange(!field.value) 
                        }} 
                    />
                </div>
            </React.Fragment>
        );

        case 'select_multi':
            let fopts = mapMultiValues(field.type, field.options.split('|'));
            return (
                <React.Fragment>
                    <div className="p-col-12 p-md-6">
                        <label className={reqClass}>{ field.label }</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <ListBox
                            multiple={true} 
                            disabled={disabled}
                            style={{ width: '100%' }}
                            value={mapMultiValues(field.type, field.value)}
                            options={fopts} 
                            onChange={(e) => {
                                if (disabled) return;
                                onChange(mapMultiValues(field.type, e.value));
                            }}
                        />
                    </div>
                </React.Fragment>
            );

        case 'select_one': return (
            <React.Fragment>
                <div className="p-col-12 p-md-6">
                    <label className={reqClass}>{ field.label }</label>
                </div>
                <div className="p-col-12 p-md-6">
                    <ListBox 
                        disabled={disabled}
                        style={{ width: '100%' }}
                        value={field.value || null}
                        options={field.options ? field.options.split('|') : []} 
                        onChange={(e) => {
                            if (disabled) return;
                            onChange(e.value) 
                        }}
                    />
                </div>
            </React.Fragment>
        );

        case 'multi':
            let mv = mapMultiValues(field.type, field.value);
            return (
                <React.Fragment>
                    <div className="p-col-12 p-md-6">
                        <label className={reqClass}>{ field.label }</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        { mv.map((item, i) => (
                            <React.Fragment>
                                <div className="p-inputgroup">
                                    <InputText 
                                        value={item}
                                        onChange={e => {
                                            if (disabled) return;
                                            mv[i] = e.target.value;
                                            onChange(mv);
                                        }}
                                    />
                                    { !readOnly &&
                                        <Button
                                            icon="pi pi-times" 
                                            className="p-button-danger"
                                            onClick={e => {
                                                if (disabled) return;
                                                mv.splice(i, 1);
                                                onChange(mv);
                                            }}
                                        />
                                    }
                                </div>
                            </React.Fragment>
                        )) }
                        { !readOnly &&
                            <InputText
                                defaultValue=""
                                placeholder="Inserir..."
                                onKeyUp={e => {
                                    if (disabled) return;
                                    if (e.keyCode === 13) {
                                        mv.push(e.target.value);
                                        onChange(mv);
                                        e.target.value = '';
                                    }
                                }} 
                            />
                        }
                    </div>
                </React.Fragment>
            );

        case 'integer_10':
            return (
                <React.Fragment>
                    <div className="p-col-12 p-md-6">
                        <label className={reqClass}>{ field.label }</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <SelectButton 
                            optionLabel="i" 
                            value={{ i: field.value }} 
                            options={[0,1,2,3,4,5,6,7,8,9,10].map(i => ({ i }))}
                            onChange={(e) => {
                                if (disabled) return;
                                onChange(e.value.i) 
                            }}>
                        </SelectButton>
                    </div>
                </React.Fragment>
            );

        case 'select_one_bar': return (
            <React.Fragment>
                <div className="p-col-12 p-md-6">
                    <label className={reqClass}>{ field.label }</label>
                </div>
                <div className="p-col-12 p-md-6">
                    <SelectButton 
                        disabled={disabled}
                        optionLabel="i" 
                        value={{ i: String(field.value) }} 
                        options={field.options ? field.options.split('|').map(i => ({ i })) : []} 
                        onChange={(e) => {
                            if (disabled) return;
                            onChange(e.value.i); 
                        }}>
                    </SelectButton>
                </div>
            </React.Fragment>
        );

        case 'select_one_dropdown': return (
            <React.Fragment>
                <div className="p-col-12 p-md-6">
                    <label className={reqClass}>{ field.label }</label>
                </div>
                <div className="p-col-12 p-md-6">
                    <Dropdown 
                        disabled={disabled}
                        placeholder="Escolha uma opção"
                        value={field.value || null}
                        options={field.options ? field.options.split('|') : []}
                        onChange={(e) => onChange(e.value) }
                    />
                </div>
            </React.Fragment>
        );

        // IMC
        case 'imc':
            const imc_opts1 = [
                { key: 1, value: 0, label: '0 - Homem' },
                { key: 2, value: 1, label: '1 - Mulher' }
            ];
            const imc_opts2 = [
                { key: 1, value: 0, label: '0 - Sedentária' },
                { key: 2, value: 1, label: '1 - Moderada' },
                { key: 3, value: 2, label: '2 - Intensa' }
            ];
            const imcOps = [
                { min: 0, max: 18.499, label: 'Baixo peso', color: 'yellow' },
                { min: 18.5, max: 24.999, label: 'Normal', color: 'green' },
                { min: 25, max: 29.999, label: 'Excesso de peso', color: 'yellow' },
                { min: 30, max: 34.999, label: 'Obesidade I', color: 'red' },
                { min: 35, max: 39.999, label: 'Obesidade II', color: 'brown' },
                { min: 40, max: 100, label: 'Obesidade III', color: '#333333' }
            ];
            const imc_total = field.value ? Math.round(field.value[3] / Math.pow(field.value[2], 2) * 100) / 100 : 0;
            let imc = imcOps.find(i => i.min <= imc_total && i.max >= imc_total);
            if (imc) imc['value'] = imc_total;
            return (
            <React.Fragment>
                <div className="p-col-12 p-md-6">
                    <label className={reqClass}>{ field.label }</label>
                </div>
                <div className="p-col-12 p-md-6">
                    { imc ? (
                        <p style={{ backgroundColor: imc.color, textAlign: 'center' }}>
                            { imc.label } ({imc.value} pontos)
                        </p>
                    ) : '' }
                    <div>Género</div>
                    <Dropdown
                        disabled={disabled}
                        placeholder="Género"
                        value={field.value ? field.value[0] : null}
                        options={imc_opts1}
                        style={{ marginBottom: '0.5em' }}
                        onChange={(e) => {
                            let value = field.value || [];
                            value[0] = e.value;
                            onChange(value); 
                        }}
                    />
                    <div>Idade</div>
                    <InputText
                        type='number' 
                        disabled={disabled}
                        placeholder="Idade"
                        value={field.value ? field.value[1] : null}
                        style={{ marginBottom: '0.5em' }}
                        onChange={(e) => {
                            let value = field.value || [];
                            value[1] = e.target.value;
                            onChange(value);
                        }}
                    />
                    <div>Altura (metros, ex. 1.70)</div>
                    <InputText
                        type='number' 
                        disabled={disabled}
                        placeholder="Altura"
                        value={field.value ? field.value[2] : null}
                        style={{ marginBottom: '0.5em' }}
                        onChange={(e) => {
                            let value = field.value || [];
                            value[2] = e.target.value;
                            onChange(value);
                        }}
                    />
                    <div>Peso (kg)</div>
                    <InputText
                        type='number' 
                        disabled={disabled}
                        placeholder="Peso"
                        value={field.value ? field.value[3] : null}
                        style={{ marginBottom: '0.5em' }}
                        onChange={(e) => {
                            let value = field.value || [];
                            value[3] = e.target.value;
                            onChange(value);
                        }}
                    />
                    <div>Atividade Fisica</div>
                    <Dropdown
                        disabled={disabled}
                        placeholder="Atividade Física"
                        value={field.value ? field.value[4] : null}
                        options={imc_opts2}
                        style={{ marginBottom: '0.5em' }}
                        onChange={(e) => {
                            let value = field.value || [];
                            value[4] = e.value;
                            onChange(value); 
                        }}
                    />
                </div>
            </React.Fragment>
        );

        // COVID19 symptoms
        case 'covid19_symptoms':
            const covid19_sympopts1 = [
                { key: 1, value: 0, label: '0 - Não' },
                { key: 2, value: 1, label: '1 - Raramente' },
                { key: 3, value: 2, label: '2 - Ás vezes' },
                { key: 4, value: 3, label: '3 - Habitual' },
                { key: 5, value: 4, label: '4 - Moderado' },
            ];
            scale_params = [
                { name: "Febre", opts: covid19_sympopts1 },
                { name: "Fadiga", opts: covid19_sympopts1 },
                { name: "Tosse seca", opts: covid19_sympopts1 },
                { name: "Perda de paladar e olfato", opts: covid19_sympopts1 },
                { name: "Espiros", opts: covid19_sympopts1 },
                { name: "Dores no corpo", opts: covid19_sympopts1 },
                { name: "Nariz entupido", opts: covid19_sympopts1 },
                { name: "Dor de garganta", opts: covid19_sympopts1 },
                { name: "Erupções cutâneas", opts: covid19_sympopts1 },
                { name: "Diarreia", opts: covid19_sympopts1 },
                { name: "Dor de cabeça", opts: covid19_sympopts1 },
                { name: "Falta de ar", opts: covid19_sympopts1 }
            ];
            return (
            <React.Fragment>
                <div className="p-col-12 p-md-6">
                    <label className={reqClass}>{ field.label }</label>
                </div>
                <div className="p-col-12 p-md-6">
                    { scale_params.map((p, i) => (
                        <React.Fragment key={String(i)}>
                            <div>{p.name}</div>
                            <Dropdown key={i}
                                disabled={disabled}
                                placeholder={p.name}
                                value={field.value ? field.value[i] : null}
                                options={p.opts}
                                style={{ marginBottom: '0.5em' }}
                                onChange={(e) => {
                                    let value = field.value || [];
                                    value[i] = e.value;
                                    onChange(value); 
                                }}
                            />
                        </React.Fragment>
                        
                    ))}
                </div>
            </React.Fragment>
        );

        // Wheel of Life
        case 'wheel_life':
            const life_params = [
                { name: "Lar" },
                { name: "Carreira" },
                { name: "Vida Afetiva" },
                { name: "Comunicação Social" },
                { name: "Amigos" },
                { name: "Família" },
                { name: "Espiritualidade" },
                { name: "Saúde" },
                { name: "Equilibrio Emocional" },
                { name: "Intelecto" },
                { name: "Lazer" },
                { name: "Finanças" },
            ];
            const lifeOps = [
                { min: 0, max: 40, label: 'Muito baixo', color: 'red' },
                { min: 41, max: 80, label: 'Moderada', color: 'orange' },
                { min: 81, max: 120, label: 'Bom', color: 'green' }
            ];
            const life_total = (field.value || []).reduce((a, b) => a += b, 0);
            let life = lifeOps.find(i => i.min <= life_total && i.max >= life_total);
            if (life) life['value'] = life_total;

            // Chart
            const lifeChart = {
                labels: life_params.map(i => i.name),
                datasets: [
                    {
                        label: 'Roda da Vida',
                        
                        data: field.value,
                        backgroundColor: [
                            "#2f4f6f",
                            "#7f0000",
                            "#008000",
                            "#00008b",
                            "#ff8c00",
                            "#ffff00",
                            "#00ff00",
                            "#00ffff",
                            "#ff00ff",
                            "#1e90ff",
                            "#ffdead",
                            "#ff69b4"
                        ],
                    }
                ]
            };
            const lifeChartOptions = {
                maintainAspectRatio: false,
                legend: {
                    labels: {
                        fontColor: '#0093C8'
                    }
                },
                scale: {
                    ticks: {
                        min: 0,
                        max: 10,
                        fontColor: '#000000'
                    }
                },
                height: 380
            };
            const lifeViewOptions = [
                { label: 'Radar', value: 'radar', key: 'radar' },
                { label: 'Polar', value: 'polarArea', key: 'polarArea' },
            ];
            return (
            <React.Fragment>
                <div className="p-col-12">
                    <label className={reqClass}>{ field.label } - Gráfico</label>
                </div>
                <div className="p-col-12">
                    <Dropdown 
                      options={lifeViewOptions}
                      value={chartType}
                      onChange={e => setChartType(e.value)}
                    />
                    <Chart type={chartType} data={lifeChart} options={lifeChartOptions} style={{ height: '280px' }}/>
                    { life ? (
                        <p style={{ backgroundColor: life.color, textAlign: 'center' }}>
                            { life.label } ({life.value} pontos)
                        </p>
                    ) : '' }
                </div>
                <div className="p-col-12 p-md-6">
                    <label className={reqClass}>{ field.label }</label>
                </div>
                <div className="p-col-12 p-md-6">
                    { life_params.map((p, i) => (
                        <React.Fragment key={String(i)}>
                            <div>{p.name}</div>
                            <Rating 
                                disabled={disabled}
                                cancel={false}
                                value={field.value ? field.value[i] : null} 
                                stars={10}
                                onChange={(e) => {
                                    let value = field.value || [];
                                    value[i] = e.value;
                                    onChange(value);
                                }}  
                            />
                        </React.Fragment>
                        
                    ))}
                </div>
            </React.Fragment>
        );

        // Katz scale
        case 'scale_katz':
            const katz_opts1 = [
                { key: 1, value: 0, label: '0 - Necessita de ajuda total ou parcial' },
                { key: 2, value: 1, label: '1 - Independente' }
            ];
            const katz_opts2 = [
                { key: 1, value: 0, label: '0 - Parcial ou totalmente incontinente (intestino ou bexiga)' },
                { key: 2, value: 1, label: '1 - Continente' }
            ];
            const katzOps = [
                { min: 0, max: 2, label: 'Muito dependente', color: 'red' },
                { min: 3, max: 4, label: 'Dependencia moderada', color: 'orange' },
                { min: 5, max: 6, label: 'Independente', color: 'green' }
            ];
            const katz_total = (field.value || []).reduce((a, b) => a += b, 0);
            let katz = katzOps.find(i => i.min <= katz_total && i.max >= katz_total);
            if (katz) katz['value'] = katz_total;
            scale_params = [
                { name: "Banho", opts: katz_opts1 },
                { name: "Vestir", opts: katz_opts1 },
                { name: "Ir a Casa de Banho", opts: katz_opts1 },
                { name: "Transferência", opts: katz_opts1 },
                { name: "Continência", opts: katz_opts2 },
                { name: "Alimentação", opts: katz_opts1}
            ];
            return (
            <React.Fragment>
                <div className="p-col-12 p-md-6">
                    <label className={reqClass}>{ field.label }</label>
                </div>
                <div className="p-col-12 p-md-6">
                    { katz ? (
                        <p style={{ backgroundColor: katz.color, textAlign: 'center' }}>
                            { katz.label } ({katz.value} pontos)
                        </p>
                    ) : '' }
                    { scale_params.map((p, i) => (
                        <React.Fragment key={String(i)}>
                            <div>{p.name}</div>
                            <Dropdown key={i}
                                disabled={disabled}
                                placeholder={p.name}
                                value={field.value ? field.value[i] : null}
                                options={p.opts}
                                style={{ marginBottom: '0.5em' }}
                                onChange={(e) => {
                                    let value = field.value || [];
                                    value[i] = e.value;
                                    onChange(value); 
                                }}
                            />
                        </React.Fragment>
                        
                    ))}
                </div>
            </React.Fragment>
        );

        // Morse scale
        case 'scale_morse':
            const morse_opts1 = [
                { key: 1, value: 0, label: '0 - Não. Sem antecedes de queda' },
                { key: 2, value: 25, label: '25 - Sim, quedas não acidentais' }
            ];
            const morse_opts2 = [
                { key: 1, value: 0, label: '0 - Não' },
                { key: 2, value: 15, label: '15 - Sim. Existe diagnostico médico/enfermagem identificado' }
            ];
            const morse_opts3 = [
                { key: 1, value: 0, label: '0 - Sem apoio' },
                { key: 2, value: 15, label: '15 - Auxiliar de marcha' },
                { key: 3, value: 30, label: '30 - Apoiado na mobilia' }
            ];
            const morse_opts4 = [
                { key: 1, value: 0, label: '0 - Não' },
                { key: 2, value: 15, label: '15 - Sim' },
                { key: 3, value: 20, label: '20 - Apoiado na mobilia' }
            ];
            const morse_opts5 = [
                { key: 1, value: 0, label: '0 - Normal' },
                { key: 2, value: 10, label: '10 - Desiquilibrio fácil' },
                { key: 3, value: 20, label: '20 - Defice de marcha' }
            ];
            const morse_opts6 = [
                { key: 1, value: 0, label: '0 - Consciente das suas limitações' },
                { key: 2, value: 15, label: '15 - Não consciente das suas limitações' }
            ];
            const morseOps = [
                { min: 0, max: 0, label: 'Baixo risco de queda', color: 'green' },
                { min: 0, max: 44, label: 'Médio risco de queda', color: 'orange' },
                { min: 45, max: 110, label: 'Alto risco de queda', color: 'red' }
            ];
            const morse_total = (field.value || []).reduce((a, b) => a += b, 0);
            let morse = morseOps.find(i => i.min <= morse_total && i.max >= morse_total);
            if (morse) morse['value'] = morse_total;
            scale_params = [
                { name: "Antecedentes de Queda", opts: morse_opts1 },
                { name: "Diagnóstico Secundário", opts: morse_opts2 },
                { name: "Apoio na Deambulação", opts: morse_opts3 },
                { name: "Terapia Endovenosa em Perfusão", opts: morse_opts4 },
                { name: "Tipo de Marcha", opts: morse_opts5 },
                { name: "Perceção Mental", opts: morse_opts6 }
            ];
            return (
            <React.Fragment>
                <div className="p-col-12 p-md-6">
                    <label className={reqClass}>{ field.label }</label>
                </div>
                <div className="p-col-12 p-md-6">
                    { morse ? (
                        <p style={{ backgroundColor: morse.color, textAlign: 'center' }}>
                            { morse.label } ({morse.value} pontos)
                        </p>
                    ) : '' }
                    { scale_params.map((p, i) => (
                        <React.Fragment key={String(i)}>
                            <div>{p.name}</div>
                            <Dropdown key={i}
                                disabled={disabled}
                                placeholder={p.name}
                                value={field.value ? field.value[i] : null}
                                options={p.opts}
                                style={{ marginBottom: '0.5em' }}
                                onChange={(e) => {
                                    let value = field.value || [];
                                    value[i] = e.value;
                                    onChange(value); 
                                }}
                            />
                        </React.Fragment>
                    ))}
                </div>
            </React.Fragment>
        );

        // Must scale
        case 'scale_must':
            const must_opts1 = [
                { key: 1, value: 0, label: '0 - Maior que 20 kg/m2' },
                { key: 2, value: 1, label: '1 - De 18.5 a 20 kg/m2' },
                { key: 3, value: 2, label: '2 - Menor que 18.5 kg/m2' }
            ];
            const must_opts2 = [
                { key: 1, value: 0, label: '0 - Menos de 5 kg' },
                { key: 2, value: 1, label: '1 - De 5 a 10 kg' },
                { key: 3, value: 2, label: '2 - Mais de 10 kg' }
            ];
            const must_opts3 = [
                { key: 1, value: 0, label: '0 - Sem doença grave diagnosticada' },
                { key: 2, value: 2, label: '20 - Com doença grave dignosticada' }
            ];
            const mustOps = [
                { min: 0, max: 0, label: 'Baixo risco', color: 'green' },
                { min: 1, max: 1, label: 'Médio risco', color: 'orange' },
                { min: 2, max: 6, label: 'Alto risco', color: 'red' }
            ];
            const must_total = (field.value || []).reduce((a, b) => a += b, 0);
            let must = mustOps.find(i => i.min <= must_total && i.max >= must_total);
            if (must) must['value'] = must_total;
            scale_params = [
                { name: "Indice de massa corporal", opts: must_opts1 },
                { name: "Perda de pesa involuntária", opts: must_opts2 },
                { name: "Doença grave diagnosticada", opts: must_opts3 }
            ];
            return (
            <React.Fragment>
                <div className="p-col-12 p-md-6">
                    <label className={reqClass}>{ field.label }</label>
                </div>
                <div className="p-col-12 p-md-6">
                    { must ? (
                        <p style={{ backgroundColor: must.color, textAlign: 'center' }}>
                            { must.label } ({must.value} pontos)
                        </p>
                    ) : '' }
                    { scale_params.map((p, i) => (
                        <React.Fragment key={String(i)}>
                            <div>{ p.name }</div>
                            <Dropdown key={i}
                                disabled={disabled}
                                placeholder={p.name}
                                value={field.value ? field.value[i] : null}
                                options={p.opts}
                                style={{ marginBottom: '0.5em' }}
                                onChange={(e) => {
                                    let value = field.value || [];
                                    value[i] = e.value;
                                    onChange(value); 
                                }}
                            />
                        </React.Fragment>
                        
                    ))}
                </div>
            </React.Fragment>
        );

        // Braden scale
        case 'scale_braden':
            const braden_opts1 = [
                { key: 1, value: 1, label: '1 - Totalmente limitado' },
                { key: 2, value: 2, label: '2 - Muito limitado' },
                { key: 3, value: 3, label: '3 - Levemente limitado' },
                { key: 4, value: 4, label: '4 - Nenhuma limitação' }
            ];
            const braden_opts2 = [
                { key: 1, value: 1, label: '1 - Completamente molhado' },
                { key: 2, value: 2, label: '2 - Muito molhado' },
                { key: 3, value: 3, label: '3 - Ocasionalmente molhado' },
                { key: 4, value: 4, label: '4 - Raramente molhado' }
            ];
            const braden_opts3 = [
                { key: 1, value: 1, label: '1 - Acamado' },
                { key: 2, value: 2, label: '2 - Confinado a cadeira' },
                { key: 3, value: 3, label: '3 - Anda ocasionalmente' },
                { key: 4, value: 4, label: '4 - Anda frequentemente' }
            ];
            const braden_opts4 = [
                { key: 1, value: 1, label: '1 - Totalmente imovel' },
                { key: 2, value: 2, label: '2 - Bastante limitado' },
                { key: 3, value: 3, label: '3 - Levemente limitado' },
                { key: 4, value: 4, label: '4 - Não apresenta limitações' }
            ];
            const braden_opts5 = [
                { key: 1, value: 1, label: '1 - Muito pobre' },
                { key: 2, value: 2, label: '2 - Provavelmente inadequada' },
                { key: 3, value: 3, label: '3 - Adequada' },
                { key: 4, value: 4, label: '4 - Excelente' }
            ];
            const braden_opts6 = [
                { key: 1, value: 1, label: '1 - Problema' },
                { key: 2, value: 2, label: '2 - Problema potencial' },
                { key: 3, value: 3, label: '3 - Nenhum problema' },
            ];
            const bradenOps = [
                { min: 4, max: 9, label: 'Risco muito alto', color: 'red' },
                { min: 10, max: 12, label: 'Alto risco', color: 'orage' },
                { min: 13, max: 14, label: 'Risco moderado', color: 'yellow' },
                { min: 15, max: 23, label: 'Risco baixo', color: 'green' }
            ];
            const braden_total = (field.value || []).reduce((a, b) => a += b, 0);
            let braden = bradenOps.find(i => i.min <= braden_total && i.max >= braden_total);
            if (braden) braden['value'] = braden_total;
            scale_params = [
                { name: "Perceção Sensorial", opts: braden_opts1 },
                { name: "Humidade", opts: braden_opts2 },
                { name: "Atividade", opts: braden_opts3 },
                { name: "Mobilidade", opts: braden_opts4 },
                { name: "Nutrição", opts: braden_opts5 },
                { name: "Fricção e Cisalhamento", opts: braden_opts6 },
            ];
            return (
            <React.Fragment>
                <div className="p-col-12 p-md-6">
                    <label className={reqClass}>{ field.label }</label>
                </div>
                <div className="p-col-12 p-md-6">
                    { braden ? (
                        <p style={{ backgroundColor: braden.color, textAlign: 'center' }}>
                            { braden.label } ({braden.value} pontos)
                        </p>
                    ) : '' }
                    { scale_params.map((p, i) => (
                        <React.Fragment key={String(i)}>
                            <div>{ p.name }</div>
                            <Dropdown key={i}
                                disabled={disabled}
                                placeholder={p.name}
                                value={field.value ? field.value[i] : null}
                                options={p.opts}
                                style={{ marginBottom: '0.5em' }}
                                onChange={(e) => {
                                    let value = field.value || [];
                                    value[i] = e.value;
                                    onChange(value); 
                                }}
                            />
                        </React.Fragment>
                    ))}
                </div>
            </React.Fragment>
        );

        // Gijon scale
        case 'scale_gijon':
            const gijon_opts1 = [
                { key: 1, value: 1, label: '1 - Vive com a família sem dependência física/psíquica' },
                { key: 2, value: 2, label: '2 - Vive com o cônjuge de similar idade' },
                { key: 3, value: 3, label: '3 - Vive com a família e/ou cônjuge com algum grau de dependência' },
                { key: 4, value: 4, label: '4 - Vive sozinho mas tem filhos próximos' },
                { key: 5, value: 5, label: '5 - Vive sozinho' }
            ];
            const gijon_opts2 = [
                { key: 1, value: 1, label: '1 - Mais de 2 salários mínimos' },
                { key: 2, value: 2, label: '2 - De 2 salários mínimos até 1 salário mínimo' },
                { key: 3, value: 3, label: '3 - Desde ao salário mínimo até à pensão do sistema contributivo' },
                { key: 4, value: 4, label: '4 - Desde a pensão do sistema contributivo até à pensão social' },
                { key: 5, value: 5, label: '4 - Sem rendimentos ou inferiores aos anteriormente apresentados' }
            ];
            const gijon_opts3 = [
                { key: 1, value: 1, label: '1 - Adequada às necessidades' },
                { key: 2, value: 2, label: '2 - Barreiras arquitectónicas (escadas, portas, banheira)' },
                { key: 3, value: 3, label: '3 - Fracas condições de higiene (casa de banho incompleta, ausência de água quente)' },
                { key: 4, value: 4, label: '4 - Ausência de elevador, telefone' },
                { key: 5, value: 5, label: '5 - Habitação inadequada (barracas, habitação em ruínas, ausência de equipamentos mínimos)' }
            ];
            const gijon_opts4 = [
                { key: 1, value: 1, label: '1 - Relações sociais' },
                { key: 2, value: 2, label: '2 - Relações sociais só com a família e com os vizinhos' },
                { key: 3, value: 3, label: '3 - Relações sociais só com a família ou vizinhos' },
                { key: 4, value: 4, label: '4 - Não sai do domicílio, mas recebe visitas' },
                { key: 5, value: 5, label: '5 - Não sai do domicílio nem recebe visitas' }
            ];
            const gijon_opts5 = [
                { key: 1, value: 1, label: '1 - Com apoio familiar ou de vizinhos' },
                { key: 2, value: 2, label: '2 - Voluntariado social, ajuda domiciliária' },
                { key: 3, value: 3, label: '3 - Não tem apoio' },
                { key: 4, value: 4, label: '4 - Pendente do ingresso numa instituição geriátrica' },
                { key: 5, value: 5, label: '5 - Tem cuidados permanentes' }
            ];
            const gijonOps = [
                { min: 5, max: 10, label: 'Baixo risco', color: 'green' },
                { min: 11, max: 16, label: 'Médio risco', color: 'orange' },
                { min: 16, max: 25, label: 'Alto risco', color: 'red' }
            ];
            const gijon_total = (field.value || []).reduce((a, b) => a += b, 0);
            let gijon = gijonOps.find(i => i.min <= gijon_total && i.max >= gijon_total);
            if (gijon) gijon['value'] = gijon_total;
            scale_params = [
                { name: "Situação Familiar", opts: gijon_opts1 },
                { name: "Situação Econônica", opts: gijon_opts2 },
                { name: "Habitação", opts: gijon_opts3 },
                { name: "Relações Sociais", opts: gijon_opts4 },
                { name: "Apoio da Rede Social", opts: gijon_opts5 }
            ];
            return (
            <React.Fragment>
                <div className="p-col-12 p-md-6">
                    <label className={reqClass}>{ field.label }</label>
                </div>
                <div className="p-col-12 p-md-6">
                    { gijon ? (
                        <p style={{ backgroundColor: gijon.color, textAlign: 'center' }}>
                            { gijon.label } ({gijon.value} pontos)
                        </p>
                    ) : '' }
                    { scale_params.map((p, i) => (
                        <React.Fragment key={String(i)}>
                            <div>{p.name}</div>
                            <Dropdown key={i}
                                disabled={disabled}
                                placeholder={p.name}
                                value={field.value ? field.value[i] : null}
                                options={p.opts}
                                style={{ marginBottom: '0.5em' }}
                                onChange={(e) => {
                                    let value = field.value || [];
                                    value[i] = e.value;
                                    onChange(value); 
                                }}
                            />
                        </React.Fragment>
                    ))}
                </div>
            </React.Fragment>
        );

        // Barthel scale
        case 'scale_barthel':
            const barthel_opts1 = [
                { key: 1, value: 0, label: '0 - Incapacitado' },
                { key: 2, value: 5, label: '5 - Precisa de ajuda para cortar ou dieta modificada' },
                { key: 3, value: 10, label: '10 - Independente' }
            ];
            const barthel_opts2 = [
                { key: 1, value: 0, label: '0 - Dependente (ou no chuveiro)' },
                { key: 2, value: 5, label: '5 - Independente' }
            ];
            const barthel_opts3 = [
                { key: 1, value: 0, label: '0 - Precisa de ajuda com a higiene pessoal' },
                { key: 2, value: 5, label: '5 - Indepedente rosto/cabelo/dentes/barbear' }
            ];
            const barthel_opts4 = [
                { key: 1, value: 0, label: '0 - Dependente' },
                { key: 2, value: 5, label: '5 - Ajuda parcial' },
                { key: 3, value: 10, label: '10 - Independente' }
            ];
            const barthel_opts5 = [
                { key: 1, value: 0, label: '0 - Incontinente (necessidade de enemas)' },
                { key: 2, value: 5, label: '5 - Acidente ocasional' },
                { key: 3, value: 10, label: '10 - Continente' }
            ];
            const barthel_opts6 = [
                { key: 1, value: 0, label: '0 - Incontinente, ou cateterizado e incapaz de manejo' },
                { key: 2, value: 5, label: '5 - Acidente ocasional' },
                { key: 3, value: 10, label: '10 - Continente' }
            ];
            const barthel_opts7 = [
                { key: 1, value: 0, label: '0 - Dependente' },
                { key: 2, value: 5, label: '5 - Ajuda parcial' },
                { key: 3, value: 10, label: '10 - Independente (pentear-se, limpar-se)' }
            ];
            const barthel_opts8 = [
                { key: 1, value: 0, label: '0 - Incapacitado (sem equilibrio sentado)' },
                { key: 2, value: 5, label: '5 - Muita ajuda (1 ou 2 pessoas), senta-se' },
                { key: 3, value: 10, label: '10 - Pouca ajuda (verbal e fisica)' },
                { key: 4, value: 15, label: '15 - Independente' }
            ];
            const barthel_opts9 = [
                { key: 1, value: 0, label: '0 - Imóvel ou < 50 metros' },
                { key: 2, value: 5, label: '5 - Cadeira de rodas mas independente, > 50 metros' },
                { key: 3, value: 10, label: '10 - Caminha com ajuda (verbal ou fisica), > 50 metros' },
                { key: 4, value: 15, label: '15 - Independente, mas pode precisar de ajuda' }
            ];
            const barthel_opts10 = [
                { key: 1, value: 0, label: '0 - Incapacitado' },
                { key: 2, value: 5, label: '5 - Ajuda parcial (verbal ou fisica)' },
                { key: 3, value: 10, label: '10 - Independente' }
            ];
            const barthelOps = [
                { min: 0, max: 25, label: 'Totalmente dependente', color: 'red' },
                { min: 26, max: 50, label: 'Necessita de apoio considerável', color: 'orange' },
                { min: 51, max: 75, label: 'Necessita de pequenos apoios', color: 'yellow' },
                { min: 75, max: 100, label: 'Quase autónomo', color: 'green' }
            ];
            const barthel_total = (field.value || []).reduce((a, b) => a += b, 0);
            let barthel = barthelOps.find(i => i.min <= barthel_total && i.max >= barthel_total);
            if (barthel) barthel['value'] = barthel_total;
            scale_params = [
                { name: "Alimentação", opts: barthel_opts1 },
                { name: "Banho", opts: barthel_opts2 },
                { name: "Atividades de Rotina", opts: barthel_opts3 },
                { name: "Vestir-se", opts: barthel_opts4 },
                { name: "Intestino", opts: barthel_opts5 },
                { name: "Sistema Urinário", opts: barthel_opts6 },
                { name: "Uso da Casa de Banho", opts: barthel_opts7 },
                { name: "Transferência", opts: barthel_opts8 },
                { name: "Mobilidade (Superficies Planas)", opts: barthel_opts9 },
                { name: "Escadas", opts: barthel_opts10 }
            ];
            return (
            <React.Fragment>
                <div className="p-col-12 p-md-6">
                    <label className={reqClass}>{ field.label }</label>
                </div>
                <div className="p-col-12 p-md-6">
                    { barthel ? (
                        <p style={{ backgroundColor: barthel.color, textAlign: 'center' }}>
                            { barthel.label } ({barthel.value} pontos)
                        </p>
                    ) : '' }
                    { scale_params.map((p, i) => (
                        <React.Fragment key={String(i)}>
                            <div>{ p.name }</div>
                            <Dropdown key={i}
                                disabled={disabled}
                                placeholder={p.name}
                                value={field.value ? field.value[i] : null}
                                options={p.opts}
                                style={{ marginBottom: '0.5em' }}
                                onChange={(e) => {
                                    let value = field.value || [];
                                    value[i] = e.value;
                                    onChange(value); 
                                }}
                            />
                        </React.Fragment>
                    ))}
                </div>
            </React.Fragment>
        );

        // Lawton & Brody scale
        case 'scale_lawtonbrody':
            const lawton_opts1 = [
                { key: 1, value: 0, label: '0 - Normal' },
                { key: 2, value: 1, label: '1 - Independente' },
                { key: 3, value: 2, label: '2 - Necessita de ajuda / derruba com frequência' },
                { key: 4, value: 3, label: '3 - Deve ser alimentado na maioria das vezes' }
            ];
            const lawton_opts2 = [
                { key: 1, value: 0, label: '0 - Normal' },
                { key: 2, value: 1, label: '1 - Independente mas lento e desajeitado' },
                { key: 3, value: 2, label: '2 - Sequência errática / esquece itens' },
                { key: 4, value: 3, label: '3 - Necessita de ajuda para vestir-se' }
            ];
            const lawton_opts3 = [
                { key: 1, value: 0, label: '0 - Normal' },
                { key: 2, value: 1, label: '1 - Banha-se só mas necessita ser lembrado' },
                { key: 3, value: 2, label: '2 - Banha-se só mas com assistência' },
                { key: 4, value: 3, label: '3 - Deve ser banhado por outros' }
            ];
            const lawton_opts4 = [
                { key: 1, value: 0, label: '0 - Vai ao banheiro independentemente' },
                { key: 2, value: 1, label: '1 - Vai ao banheiro quando lembrado' },
                { key: 3, value: 2, label: '2 - Precisa de assistência para a atividade' },
                { key: 4, value: 3, label: '3 - Não tem controlo sobre fezes / urina' }
            ];
            const lawton_opts5 = [
                { key: 1, value: 0, label: '0 - Lembra-se sem ajuda' },
                { key: 2, value: 1, label: '1 - Lembra-se quando a medicação é deixada em local especial' },
                { key: 3, value: 2, label: '2 - Necessita de lembretes escritos ou falados' },
                { key: 4, value: 3, label: '3 - Deve receber medicação dos outros' }
            ];
            const lawton_opts6 = [
                { key: 1, value: 0, label: '0 - Descura / mesma aparência' },
                { key: 2, value: 1, label: '1 - Interessa-se quando sai mas não em casa' },
                { key: 3, value: 2, label: '2 - Permite ser arrumado ou faz quando solicitado' },
                { key: 4, value: 3, label: '3 - Resiste para ser limpo / trocado por outros' }
            ];
            const lawton_opts7 = [
                { key: 1, value: 0, label: '0 - Normal' },
                { key: 2, value: 1, label: '1 - independente mas lento / desajeitado' },
                { key: 3, value: 2, label: '2 - Esquece items ou coloca em local errado' },
                { key: 4, value: 3, label: '3 - Não realiza mais esta atividade' }
            ];
            const lawton_opts8 = [
                { key: 1, value: 0, label: '0 - Mantem a casa como de costume' },
                { key: 2, value: 1, label: '1 - Faz apenas parte do trabalho' },
                { key: 3, value: 2, label: '2 - Ocasionalmente varre a casa ou pequebos serviços' },
                { key: 4, value: 3, label: '3 - Não cuida da casa' }
            ];
            const lawton_opts9 = [
                { key: 1, value: 0, label: '0 - Realiza todo so reparos habituais' },
                { key: 2, value: 1, label: '1 - Realiza parte dos trabalhos' },
                { key: 3, value: 2, label: '2 - Ocasionalmente faz reparos menores' },
                { key: 4, value: 3, label: '3 - Não faz nenhum reparo da casa' }
            ];
            const lawton_opts10 = [
                { key: 1, value: 0, label: '0 - Lava como de costume (rotina)' },
                { key: 2, value: 1, label: '1 - Lava com menor frequência' },
                { key: 3, value: 2, label: '2 - Lava apenas se lembrado; esquece o sabão' },
                { key: 4, value: 3, label: '3 - Não lava mais as roupas' }
            ];
            const lawton_opts11 = [
                { key: 1, value: 0, label: '0 - Trabalha normalmente' },
                { key: 2, value: 1, label: '1 - Problemas leves com responsabilidade de rotina' },
                { key: 3, value: 2, label: '2 - Trabalha parcialmente; medo de perder o trabalho' },
                { key: 4, value: 3, label: '3 - Não trabalha mais' }
            ];
            const lawton_opts12 = [
                { key: 1, value: 0, label: '0 - A mesma habitual' },
                { key: 2, value: 1, label: '1 - Atividade recreacional menos frequente' },
                { key: 3, value: 2, label: '2 - Perdeu atividades recreativas; precisa incentivo' },
                { key: 4, value: 3, label: '3 - Não possui mais atividades recreacionais' }
            ];
            const lawton_opts13 = [
                { key: 1, value: 0, label: '0 - Comparea encontros; mantêm responsabilidade' },
                { key: 2, value: 1, label: '1 - Comparece menos frequentemente' },
                { key: 3, value: 2, label: '2 - Comparece ocasionalmente' },
                { key: 4, value: 3, label: '3 - Não comparece mais aos encontros' }
            ];
            const lawton_opts14 = [
                { key: 1, value: 0, label: '0 - O memso que o habitual' },
                { key: 2, value: 1, label: '1 - Viaja se alguẽm dirigir' },
                { key: 3, value: 2, label: '2 - Viaja em cadeira de rodas' },
                { key: 4, value: 3, label: '3 - Limitado a casa ou hospital' }
            ];
            const lawton_opts15 = [
                { key: 1, value: 0, label: '0 - Normal' },
                { key: 2, value: 1, label: '1 - Esquece itens ou compra itens desnecessários' },
                { key: 3, value: 2, label: '2 - Necessita ser acompanhado' },
                { key: 4, value: 3, label: '3 - Não faz mais as compras' }
            ];
            const lawton_opts16 = [
                { key: 1, value: 0, label: '0 - Normal' },
                { key: 2, value: 1, label: '1 - Tem dificuldade em pagar valores / contar dinheiro' },
                { key: 3, value: 2, label: '2 - Perde ou coloca dinheiro em local errado' },
                { key: 4, value: 3, label: '3 - Não mais manipula dinheiro' }
            ];
            const lawton_opts17 = [
                { key: 1, value: 0, label: '0 - Normal' },
                { key: 2, value: 1, label: '1 - Tem dificuldade em pagar valores / contar dinheiro' },
                { key: 3, value: 2, label: '2 - Perde ou coloca dinheiro em local errado' },
                { key: 4, value: 3, label: '3 - Não mais manipula dinheiro' }
            ];
            const lawton_opts18 = [
                { key: 1, value: 0, label: '0 - Pagamento de contas normal' },
                { key: 2, value: 1, label: '1 - Paga contas atrasadas' },
                { key: 3, value: 2, label: '2 - Esquece pagar contas; deixa passar prazos' },
                { key: 4, value: 3, label: '3 - Não mais administra finanças' }
            ];
            const lawton_opts19 = [
                { key: 1, value: 0, label: '0 - Utiliza transporte público normalmente' },
                { key: 2, value: 1, label: '1 - Utiliza transporte público menos frequencia' },
                { key: 3, value: 2, label: '2 - Perde-se utilizando transportes públicos' },
                { key: 4, value: 3, label: '3 - Não mais usa transporte público' }
            ];
            const lawton_opts20 = [
                { key: 1, value: 0, label: '0 - Dirige normalmente' },
                { key: 2, value: 1, label: '1 - Dirige mais cautelosamente' },
                { key: 3, value: 2, label: '2 - Pouco cuidado a dirigir; perde-se ocasionalmente' },
                { key: 4, value: 3, label: '3 - Não dirige mais' }
            ];
            const lawton_opts21 = [
                { key: 1, value: 0, label: '0 - Normal' },
                { key: 2, value: 1, label: '1 - Sai de casa menos frequentemente' },
                { key: 3, value: 2, label: '2 - Perde-se nas proximidades' },
                { key: 4, value: 3, label: '3 - Não sai mais desacompanhado' }
            ];
            const lawton_opts22 = [
                { key: 1, value: 0, label: '0 - Normal' },
                { key: 2, value: 1, label: '1 - Ocasionalmente desorientado em locais estranhos' },
                { key: 3, value: 2, label: '2 - Move-se mas fica muito desorientado' },
                { key: 4, value: 3, label: '3 - Não é capaz de sair' }
            ];
            const lawton_opts23 = [
                { key: 1, value: 0, label: '0 - Normal' },
                { key: 2, value: 1, label: '1 - Liga apenas para números familiares' },
                { key: 3, value: 2, label: '2 - Apenas atende o telefone' },
                { key: 4, value: 3, label: '3 - Não usa mais o telefone' }
            ];
            const lawton_opts24 = [
                { key: 1, value: 0, label: '0 - Normal' },
                { key: 2, value: 1, label: '1 - Menos falante; dificuldade em lembrar palavras / normas' },
                { key: 3, value: 2, label: '2 - Comete erros ocasionais de fala' },
                { key: 4, value: 3, label: '3 - Fala pouco ou ininteligivel' }
            ];
            const lawton_opts25 = [
                { key: 1, value: 0, label: '0 - Compreende tudo o que lhe é dito' },
                { key: 2, value: 1, label: '1 - Solicita que repitam' },
                { key: 3, value: 2, label: '2 - Dificuldades em compreender conversas / palavras' },
                { key: 4, value: 3, label: '3 - Não compreende as pessoas com frequência' }
            ];
            const lawton_opts26 = [
                { key: 1, value: 0, label: '0 - Normal' },
                { key: 2, value: 1, label: '1 - Lê com pouca frequencia' },
                { key: 3, value: 2, label: '2 - Dificuldades em compreender / lembrar o que leu' },
                { key: 4, value: 3, label: '3 - Não lê mais' }
            ];
            const lawton_opts27 = [
                { key: 1, value: 0, label: '0 - Normal' },
                { key: 2, value: 1, label: '1 - Escreve com pouca frequencia / erros ocasionais' },
                { key: 3, value: 2, label: '2 - Apenas assina o nome' },
                { key: 4, value: 3, label: '3 - Não escreve mais' }
            ];
            const lawton_opts28 = [
                { key: 1, value: 0, label: '0 - Normais' },
                { key: 2, value: 1, label: '1 - Pequenos problemas matrimoniais' },
                { key: 3, value: 2, label: '2 - Sérios problemas matrimoniais' },
                { key: 4, value: 3, label: '3 - Divorciado / separado, sem relacionamentos' }
            ];
            const lawton_opts29 = [
                { key: 1, value: 0, label: '0 - Normais' },
                { key: 2, value: 1, label: '1 - Facilmente irritável / punições intempestivas' },
                { key: 3, value: 2, label: '2 - Negligencia as necessidades fisicas e emocionais das crianças' },
                { key: 4, value: 3, label: '3 - Incapacitado para cuidar de crianças' }
            ];
            const lawton_opts30 = [
                { key: 1, value: 0, label: '0 - Encontro com amigos com a mesma frequencia' },
                { key: 2, value: 1, label: '1 - Encontro com amigos com pouca frequencia' },
                { key: 3, value: 2, label: '2 - Aceita visitas mas não procura companhia' },
                { key: 4, value: 3, label: '3 - Recusa a vida social, cria problemas com os visitantes' }
            ];
            const lawtonOps = [
                { min: 0, max: 0, label: 'Independente', color: 'green' },
                { min: 0, max: 30, label: 'Ligeiramente dependente', color: 'yellow' },
                { min: 31, max: 40, label: 'Dependencia moderada', color: '#FF7900' },
                { min: 41, max: 60, label: 'Dependencia grave', color: 'orange' },
                { min: 61, max: 90, label: 'Depêndencia total', color: 'red' }
            ];
            const lawton_total = (field.value || []).reduce((a, b) => a += b, 0);
            let lawton = lawtonOps.find(i => i.min <= lawton_total && i.max >= lawton_total);
            if (lawton) lawton['value'] = lawton_total;
            scale_params = [
                { name: 'Cuidados pessoais', items: [
                    { name: "Alimentação", opts: lawton_opts1 },
                    { name: "Vestir-se", opts: lawton_opts2 },
                    { name: "Banho", opts: lawton_opts3 },
                    { name: "Eliminações fisiolõgicas", opts: lawton_opts4 },
                    { name: "Medicação", opts: lawton_opts5 },
                    { name: "Interessa na aparência", opts: lawton_opts6 }
                ] },
                { name: 'Cuidados domésticos', items: [
                    { name: "Preparar e cozinhar", opts: lawton_opts7 },
                    { name: "Arrumação da mesa", opts: lawton_opts8 },
                    { name: "Trabalhos domésticos", opts: lawton_opts9 },
                    { name: "Reparos domésticos", opts: lawton_opts10 },
                    { name: "Lavar roupas", opts: lawton_opts11 }
                ] },
                { name: 'Trabalho e Recreação', items: [
                    { name: "Trabalho", opts: lawton_opts12 },
                    { name: "Recreação", opts: lawton_opts13 },
                    { name: "Organizações", opts: lawton_opts14 },
                    { name: "Viagens", opts: lawton_opts15 }
                ] },
                { name: 'Compras e dinheiro', items: [
                    { name: "Compra de alimentos", opts: lawton_opts16 },
                    { name: "Uso de dinheiro", opts: lawton_opts17 },
                    { name: "Administralão de finanças", opts: lawton_opts18 }
                ] },
                { name: 'Locomoção', items: [
                    { name: "Transportes públicos", opts: lawton_opts19 },
                    { name: "Condução de veículos", opts: lawton_opts20 },
                    { name: "Mobilidade pela vizinhança", opts: lawton_opts21 },
                    { name: "Mobilidade por locais não familiares", opts: lawton_opts22 }
                ] },
                { name: 'Comunicação', items: [
                    { name: "Uso do telefone", opts: lawton_opts23 },
                    { name: "Conversas", opts: lawton_opts24 },
                    { name: "Compreensão", opts: lawton_opts25 },
                    { name: "Leitura", opts: lawton_opts26 },
                    { name: "Escrita", opts: lawton_opts27 }
                ] },
                { name: 'Relações Sociais', items: [
                    { name: "Relações familiares (conjuge)", opts: lawton_opts28 },
                    { name: "Relações familiares (crianças)", opts: lawton_opts29 },
                    { name: "Amigos", opts: lawton_opts30 }
                ] }
            ];
            return (
            <React.Fragment>
                <div className="p-col-12 p-md-6">
                    <label className={reqClass}>{ field.label }</label>
                </div>
                <div className="p-col-12 p-md-6">
                    { lawton ? (
                        <p style={{ backgroundColor: lawton.color, textAlign: 'center' }}>
                            { lawton.label } ({lawton.value} pontos)
                        </p>
                    ) : '' }
                    { scale_params.map((c, i) => 
                        <React.Fragment key={i}>
                            <p>{ c.name }</p>
                            { c.items.map((p, j) => {
                                return <Dropdown key={10 * i + j}
                                    disabled={disabled}
                                    placeholder={p.name}
                                    value={field.value ? field.value[10 * i + j] : null}
                                    options={p.opts}
                                    onChange={(e) => {
                                        let value = field.value || [];
                                        value[10 * i + j] = e.value;
                                        onChange(value); 
                                    }}
                                /> }
                            ) }
                        </React.Fragment>
                    ) }
                </div>
            </React.Fragment>
        );

        // Default form field
        default: return (
            <React.Fragment>
                <div className="p-col-6 p-md-6">
                    <label className={reqClass}>{ field.label }</label>
                </div>
                <div className="p-col-6 p-md-6">
                    { ['decimal', 'integer'].includes(field.type) ? (
                        <InputText
                            type='number' 
                            disabled={disabled}
                            placeholder={ field.label }
                            value={Number(field.value)}
                            onChange={(e) => onChange(e.target.value) }
                        />
                    ) : (
                        <InputText 
                            disabled={disabled}
                            placeholder={ field.label }
                            value={field.value || ''}
                            onChange={(e) => onChange(e.target.value) }
                        />
                    )}
                </div>
            </React.Fragment>
        )
    }
}