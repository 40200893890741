import React, { useState, useEffect } from 'react';
import {PickList} from 'primereact/picklist';
import moment from 'moment';
import loader from '../loader';
import PlansList from '../plans/PlansList';
import DiaryCalendar from '../diary/DiaryCalendar';
import { ProgressSpinner } from 'primereact/progressspinner';
import timeSetup from '../timeSetup';

function ClientsPLans({ client, setClient }) {

  let [ plans, setPlans ] = useState([]);
  let [ date, setDate ] = useState(moment().startOf('week').format('YYYY-MM-DD'));
  let [ calendar, setCalendar ] = useState([]);
  
  let [ loading, setLoading ] = useState(false);

  // Load plans
  useEffect(() => {
    loader.get('/plan/list', res => {
        setPlans(res.items);
    }, setLoading);
  }, []);

  // Load calendar
  useEffect(() => {
    if (!parseInt(client.id, 10)) return;
    let startdate = moment().startOf('week').format('YYYY-MM-DD');
    let enddate = moment().startOf('week').add(2, 'weeks').format('YYYY-MM-DD');
    loader.get('/diary/calendar/' + client.id + '/' + startdate + '/' + enddate, res => {
        const events = res.items.filter(i => !i.therapy).map(i => ({
            id: i.id,
            title: i.title,
            start: i.start,
            end: i.end,
            backgroundColor: i.color ? i.color : null
        }));
        setCalendar(events);
    }, setLoading);
  }, [client.id]);

  // Save client
  let save = () => {
    let data = {
        id: client.id,
        plans: client.plans.map(f => f.id)
    };
    let url = '/client/update';
    loader.post(url, data, res => res.json())
    .then(res => {
        if (!res.success) console.log(res);
    }, setLoading);
  };

  if (!client || loading) return <ProgressSpinner />;

  const availablePlans = plans.filter(item1 => 
    !client.plans.find(item2 => item1.id === item2.id)
  );
  console.log(calendar);
  return (
    <React.Fragment>

        <div className="card card-w-title">
            <h1>Plano Individual</h1>
            <PlansList client_id={client.id} />
        </div>

        <div className="card">
            <DiaryCalendar 
                start={moment(date).add(1, 'day').format('YYYY-MM-DD')}
                items={calendar}
            />
        </div>
        
        <div className="card card-w-title">
            <h2>Outras Atividades</h2>
            <PickList 
                source={availablePlans} 
                target={client.plans} 
                sourceHeader="Atividades disponíveis"
                targetHeader="Atividades atribuidas"
                itemTemplate={item => item.title}
                onChange={(e) => {
                    setClient({ ...client, plans: e.target });
                    setInterval(() => { save() }, 10);
                }}
            />
        </div>

    </React.Fragment>
  );

}

export default ClientsPLans;
