import React, { useState, useEffect } from 'react';
import loader from '../loader';
import SessionsList from './CheckpointList';
import { ProgressSpinner } from 'primereact/progressspinner';

function UsersCheckpoints({ id }) {

  let [ user, setUser ] = useState();
  let [ loading, setLoading ] = useState(false);

  // Load user
  useEffect(() => {
    if (!parseInt(id, 10)) return;
    loader.get('/user/item/' + id, res => {
        setUser(res.item);
    }, setLoading);
  }, [id]);

  if (loading || !user) return <ProgressSpinner />;
  return (
    <React.Fragment>
 
        <div className="card card-w-title">
            <h1>Folha de Ponto</h1>
            <SessionsList user_id={user.id} />
        </div>

    </React.Fragment>
  );

}

export default UsersCheckpoints;
