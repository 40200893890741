import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from "react-router-dom";
import { getParams } from '../helpers';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './login.css';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password as InputPassword } from 'primereact/password';
import { Message } from 'primereact/message';
import { Checkbox } from 'primereact/checkbox';
import generator from 'generate-password';
import loader from '../loader';
import { Dialog } from 'primereact/dialog';

const baseUrl = process.env.PUBLIC_URL;
const serverUrl = process.env.REACT_APP_SERVER_URL;

function RegisterPage() {

  let [ firstname, setFirstname ] = useState('');
  let [ lastname, setLastname ] = useState('');
  let [ email, setEmail ] = useState('');
  let [ username, setUsername ] = useState('');
  let [ password, setPassword ] = useState('');
  let [ confirm, setConfirm ] = useState('');
  let [ code, setCode ] = useState('');
  let [ agreement, setAgreement ] = useState('');
  let [ organization, setOrganization ] = useState('');
  let [ errors, setErrors ] = useState({});
  let [ showAgreement, setShowAgreement ] = useState(false);
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  let history = useHistory();
  let params = getParams(useLocation().search);

  function setUserCode(value) {
    if (String(value).length > 4) return;
    setCode(value);
  }

  useEffect(() => {
    const pw = generator.generate({
      length: 8,
      //uppercase: false,
      numbers: true,
      excludeSimilarCharacters: true,
      strict: true
    });
    setPassword(pw);
    setConfirm(pw);

    // Load organization
    loader.get('/organization/fallback/' + (params.org || ''), res => {
      setOrganization(res.item);
    });
  }, []);

  let register = () => {
    let data = {
        firstname,
        lastname,
        email,
        username,
        password,
        code,
        agreement,
        origin: params.origin || '',
        organization_id: organization.id,
        regular: !!params.regular
    };
    const url = '/user/register';
    setLoading(true);
    loader.post(url, data, res => {
      if (res.errors) setErrors(res.errors);
      else setSuccess(true);
    }, setLoading, true);
  };

  let isDataInvalid = () => {
      return loading
        || firstname.length < 3
        || lastname.length < 3
        || email.length < 8
        || username.length < 3
        //|| password === '' 
        // || password !== confirm
        || code.length !== 4
        || !agreement;
  }

  let displayErrors = (errors, key) => !!errors[key] && errors[key].map(msg =>
    <Message severity="error" text={msg}></Message>
  ) 

  const isMobile = window.innerWidth <= 768;

  if (!organization) return null;

  const org_logo = organization.logo ? serverUrl + "/download/organizations/" + organization.logo
    : baseUrl + 'assets/images/logo_512x512.png';
  const org_title = organization.name || "Team 4 Care";
  const org_bg = organization.background_img ? `url("${serverUrl}/download/organizations/${organization.background_img}")`
    : `url("${baseUrl}/assets/images/bg3.jpg")`;

  return (
    <div className="App" style={{ backgroundImage: org_bg }}>
      <div className="App-header">
        <div className="login-container register">
          <div className="p-grid p-fluid">
            <div className="p-col-12">
              <div className="card card-w-title">

                <img src={ org_logo } 
                  className="logo" 
                  alt={ org_title }
                  title={ org_title }
                />
            
                <h1>Registar</h1>

                { success ? (
                    <div className="p-grid">
                        <div className="p-col-12">
                            <Message severity="success" text="O registo foi concluido."></Message>
                            <Message severity="success" text="Por favor, consulte o seu e-mail e ative a sua conta"></Message>
                            <Link to="/">Voltar ao inicio</Link>
                        </div>
                    </div>
                ) : (
                  <div className="p-grid form">
                    <div className="p-col-6">
                        <label>Primeiro Nome</label>
                        <InputText 
                          placeholder="Primeiro nome..."
                          value={firstname}
                          onChange={(e) => setFirstname(e.target.value)}
                        />
                        { displayErrors(errors, 'firstname') }
                    </div>
                    <div className="p-col-6">
                        <label>Último Nome</label>
                        <InputText 
                          placeholder="Último nome..."
                          value={lastname}
                          onChange={(e) => setLastname(e.target.value)}
                        />
                        { displayErrors(errors, 'lastname') }
                    </div>
                    
                    <div className="p-col-12">
                        <label>Endereço de E-mail</label>
                        <InputText 
                          type="email"
                          placeholder="Endereço de E-mail"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        { displayErrors(errors, 'email') }
                    </div>
                    <div className="p-col-12">
                        <label>Nome de Utilizador</label>
                        <InputText 
                          placeholder="Nome de Utilizador"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                        { displayErrors(errors, 'username') }
                    </div>

                    {/*
                    <div className="p-col-12">
                        <label>Palavra-passe</label>
                        <InputPassword 
                            placeholder="Password" 
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        { displayErrors(errors, 'password') }
                    </div>
                    <div className="p-col-12">
                        <label>Confirmar Palavra-passe</label>
                        <InputPassword 
                            placeholder="Confirmar Password" 
                            feedback={false}
                            value={confirm}
                            onChange={(e) => setConfirm(e.target.value)}
                        />
                    </div>
                    */}

                    <div className="p-col-12">
                        <label>Código PIN</label>
                        <InputPassword 
                            placeholder="" 
                            feedback={false}
                            value={code}
                            onChange={(e) => setUserCode(e.target.value)}
                        />
                        { displayErrors(errors, 'code') }
                        <div className="help-text">4 algarismos</div>
                    </div>

                    <div className="p-col-12">
                        <label>Termos e Condições</label><br />
                        <Checkbox 
                          inputId="agreement"
                          onChange={e => setAgreement(e.checked)} 
                          checked={agreement ? 'checked' : ''}
                        />
                        <label htmlFor="agreement" 
                          className="p-checkbox-label">
                          Li e aceito os&nbsp;
                          <span className="link" onClick={e => setShowAgreement(!showAgreement)}>termos e condições</span>
                        </label>
                        { displayErrors(errors, 'agreement') }
                    </div>

                    <div className="p-col-12">
                        <Button 
                          icon={ loading ? "pi pi-spin pi-spinner" : "" }
                          className="p-button-lg"
                          onClick={register} 
                          label="Registar"
                          disabled={isDataInvalid()}
                        />
                    </div>
                    </div>
                ) }

              </div>
            </div>

          </div>
        </div>
      </div>

      <Dialog
        header="Termos e Condições" 
        visible={showAgreement}
        style={{width: isMobile ? '90%' : '50vw'}} 
        modal={true} onHide={() => setShowAgreement(false)}>
        <div dangerouslySetInnerHTML={{
          __html: organization ? organization.agreement 
            : '<p>Por favor contacte o seu fornecedor para saber mais sobre os termos e condições de uso. Obrigado.</p>' 
          }} 
        />
      </Dialog>

    </div>
  );

}

export default RegisterPage;
