import React, { useState, useEffect, useReducer } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password as InputPassword } from 'primereact/password';
import {Calendar} from 'primereact/calendar';
import {Dropdown} from 'primereact/dropdown';
import { Message } from 'primereact/message';
import {FileUpload} from 'primereact/fileupload';
import moment from 'moment';
import date_pt from '../date_pt.json';
import durationOptions from '../plans/duration.json';
import loader from '../loader';
import { ProgressSpinner } from 'primereact/progressspinner';
import Session from '../auth/Session';
import { InputMask } from 'primereact/inputmask';
import { InputSwitch } from 'primereact/inputswitch';

//durationOptions.unshift({ value: 0, label: '0 minutos' });

const serverUrl = process.env.REACT_APP_SERVER_URL;

function reducer(state, action) {
    switch (action.type) {
      case 'update':
        return {
            ...state,
            [action.field]: action.value
        };
      case 'update_all':
        return {
            ...action.value
        }
      default:
        throw new Error();
    }
}

function MyProfile() {

  let [ user, dispatch ] = useReducer(reducer, null)

  let [ errors, setErrors ] = useState({});
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(true);

  let logged = Session.getCookie();

  // Load user
  useEffect(() => {
    loader.get('/user/item/' + logged.id, res => {
        dispatch({ type: 'update_all', value: res.item });
    }, setLoading);
  }, [logged.id]);

  // Register
  let register = () => {
    let data = {
        ...user,
        birthdate: user.birthdate ? moment(user.birthdate).format('YYYY-MM-DD') : null
    };
    let url = '/user/update';
    setSuccess(false);
    loader.post(url, data, res => {
        if (res.errors) return setErrors(res.errors);
        dispatch({ type: 'update', field: 'id', value: res.id });
        setSuccess(true);
    }, setLoading);
  };

  // Get Google Access Token
  let getGoogleAccessToken = () => {
    const gAuthUrl = '/user/google/authurl/' + logged.id + '?from=' + encodeURIComponent(window.location.href);
    loader.get(gAuthUrl, res => {
        window.location.href = res.url;
    }, setLoading);
  }

  let isDataInvalid = () => {
      return loading
        || user.username.length < 3
        || user.password === '' 
        || user.code.length !== 4 
        || user.password !== user.confirm
        || !user.organization_id
        || (user.notifications && (!user.mobile_n || user.mobile_n.length < 15));
  }

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/photo_placeholder.png';
  }

  // Upload
  const onUpload = (e) => {
    if (e.files.length === 0) return;

    const formData  = new FormData();
    formData.append('folder', 'users');
    formData.append('upload', e.files[0]);
    const url = '/upload';
    loader.upload(url, formData, res => {
      if (!res.filename) return;
      dispatch({ type: 'update', field: 'photo', value: res.filename });  
    }, setLoading);
  }

  const onRemoveAvatar = (e, file) => {
    dispatch({ type: 'update', field: 'photo', value: '' });
  }

  let displayErrors = (errors, key) => !!errors[key] && errors[key].map(msg =>
    <Message severity="error" text={msg}></Message>
  )

  if (loading || !user) return <ProgressSpinner />;
  return (
    <React.Fragment>
        
        <div className="card card-w-title">
            <h1>O Meu Perfil</h1>

            { success && (
                <div className="p-grid">
                    <div className="p-col-12">

                        <Message 
                            severity="success" 
                            text="O registo foi guardado com sucesso!">
                        </Message>
                        
                    </div>
                </div>
            ) }

            <Button 
                onClick={register} 
                label="Guardar"
                disabled={isDataInvalid()}
                style={{ float: 'right' }}
            />
            <p className="form-info">
                <strong style={{ color: 'red' }}>*</strong> 
                {' '}Campos obrigatórios
            </p>

            <div className="p-grid">
                <div className="p-col-12 p-lg-6">
                    <div className="p-grid">
                        <div className="p-col-6 p-md-6">
                            <label className="field-required">Endereço de E-mail</label>
                        </div>
                        <div className="p-col-6 p-md-6">
                            <InputText 
                                placeholder="Endereço de E-mail"
                                value={user.email}
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'email', value: e.target.value })
                                }
                            />
                            { displayErrors(errors, 'email') }
                        </div>
                        <div className="p-col-6 p-md-6">
                            <label className="field-required">Nome de Utilizador</label>
                        </div>
                        <div className="p-col-6 p-md-6">
                            <InputText 
                                placeholder="Nome de Utilizador"
                                value={user.username}
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'username', value: e.target.value })
                                }
                            />
                            { displayErrors(errors, 'username') }
                        </div>
                        <div className="p-col-6 p-md-6">
                            <label className="field-required">Palavra-passe</label>
                        </div>
                        <div className="p-col-6 p-md-6">
                            <InputPassword 
                                placeholder="Password" 
                                value={user.password || ''}
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'password', value: e.target.value })
                                }
                            />
                            { displayErrors(errors, 'password') }
                        </div>
                        <div className="p-col-6 p-md-6">
                            <label className="field-required">Confirmar</label>
                        </div>
                        <div className="p-col-6 p-md-6">
                            <InputPassword 
                                placeholder="Confirmar Password" 
                                feedback={false}
                                value={user.confirm || ''}
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'confirm', value: e.target.value })
                                }
                            />
                        </div>
                        <div className="p-col-6 p-md-6">
                            <label className="field-required">Código PIN</label>
                        </div>
                        <div className="p-col-6 p-md-6">
                            <InputPassword 
                                placeholder="Código PIN" 
                                feedback={false}
                                value={user.code || ''}
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'code', value: e.target.value })
                                }
                            />
                            <p className="field-help">(4 algarismos)</p>
                            { displayErrors(errors, 'code') }
                        </div>

                        <div className="p-col-12 p-md-6">
                            <label>Máx. Horas Extraordinárias</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <Dropdown 
                                value={user.extra_time}
                                options={durationOptions} 
                                placeholder="Escolha uma opção"
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'extra_time', value: e.value })
                                }
                            />
                        </div>

                    </div>
                </div>
                <div className="p-col-12 p-lg-6">
                    <div className="p-grid">

                        <div className="p-col-6 p-md-6">
                            <label>Primeiro Nome</label>
                        </div>
                        <div className="p-col-6 p-md-6">
                            <InputText 
                                placeholder="Primeiro Nome"
                                value={user.firstname}
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'firstname', value: e.target.value })
                                }
                            />
                            { displayErrors(errors, 'firstname') }
                        </div>

                        <div className="p-col-6 p-md-6">
                            <label>Último Nome</label>
                        </div>
                        <div className="p-col-6 p-md-6">
                            <InputText 
                                placeholder="Último Nome"
                                value={user.lastname}
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'lastname', value: e.target.value })
                                }
                            />
                            { displayErrors(errors, 'lastname') }
                        </div>

                        <div className="p-col-12 p-md-6">
                            <label>Data de Nascimento</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <Calendar 
                                placeholder="Data de Nascimento"
                                value={user.birthdate} 
                                locale={date_pt}
                                dateFormat="yy-mm-dd"
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'birthdate', value: e.value })
                                }>
                            </Calendar>
                        </div>

                        <div className="p-col-6 p-md-6">
                            <label>Notificações SMS</label>
                        </div>
                        <div className="p-col-6 p-md-6">
                            <InputSwitch
                                checked={user.notifications} 
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'notifications', value: !user.notifications })
                                } 
                            />
                        </div>

                        <div className="p-col-6 p-md-6">
                            <label>
                                Atualizar Calendário Google
                            </label>
                        </div>
                        <div className="p-col-6 p-md-2">
                            <InputSwitch 
                                checked={!!user.google_access_token} 
                                onChange={(e) => {
                                    if (user.google_access_token) {
                                        dispatch({ type: 'update', field: 'google_access_token', value: '' });
                                        dispatch({ type: 'update', field: 'google_refresh_token', value: '' });    
                                    } else getGoogleAccessToken();
                                }}
                            />
                        </div>
                        <div className="p-col-6 p-md-4">
                            <InputText
                                placeholder="Access token..."
                                value={user.google_access_token}
                                disabled={true}
                            />
                        </div>
                        {/*
                        <div className="p-col-6 p-md-2">
                            <InputText
                                placeholder="Refresh token..."
                                value={user.google_refresh_token}
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'google_refresh_token', value: e.target.value })
                                }
                            />
                        </div>
                        */}

                        <div className="p-col-6 p-md-6">
                            <label className={user.notifications ? "field-required" : ""}>
                                Telemóvel
                            </label>
                        </div>
                        <div className="p-col-6 p-md-6">
                            <InputMask
                                mask="+999 999 999 999?99"
                                placeholder="Telemóvel"
                                value={user.mobile_n}
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'mobile_n', value: e.target.value })
                                }
                            />
                        </div>

                        <div className="p-col-12 p-md-6">
                            <label>Fotografia</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                        { !!user.photo && (
                            <img 
                                src={getFileUrl(user.photo, 'users')} 
                                alt="Fotografia"
                                title="Fotografia"
                                className="img-avatar"
                            />
                        ) }
                        <FileUpload 
                            name="photo"
                            mode="basic"
                            url={'/upload'}
                            customUpload={true}
                            uploadHandler={onUpload}
                            auto={true}
                            chooseLabel={user.photo ? "Alterar" : "Escolher" }
                            onRemove={onRemoveAvatar}
                        />
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </React.Fragment>
  );

}

export default MyProfile;
