import React from 'react';
import ClientOutForm from './ClientOutForm';
import { useParams } from "react-router-dom";
import Session from '../auth/Session';

function ClientOutFormRoute() {

  let urlParams = useParams();
  let logged = Session.getCookie();
  let client_id = logged.client_id;

  const title = parseInt(urlParams.id) ? "Alterar Ausência" : "Registar Ausência";
  return (
    <div className="card card-w-title">
      <h1>{ title }</h1>

      <ClientOutForm client_id={client_id} id={urlParams.id} />
    </div>
  );

}

export default ClientOutFormRoute;
