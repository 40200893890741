import React, { useEffect } from 'react';
import './timeSetup';
import { CookiesProvider } from 'react-cookie';
import SplashScreen from './splashscreen/SplashScreen';
import LoginPage from './auth/LoginPage';
import RegisterPage from './auth/RegisterPage';
import Dashboard from './dashboard/Dashboard';
import Stats from './dashboard/Stats';
import UsersRoute from './users/UsersRoute';
import UsersList from './users/UsersList';
import ClientsList from './clients/ClientsList';
import OrganizationsForm from './organizations/OrganizationsForm';
import OrganizationsList from './organizations/OrganizationsList';
import TopicsForm from './topics/TopicsFormRoute';
import TopicsList from './topics/TopicsListRoute';
import TopicsViewAdmin from './topics/TopicsView';
import FieldsForm from './fields/FieldsForm';
import FieldsList from './fields/FieldsList';
import DiaryFormRoute from './diary/DiaryFormRoute';
import PlansForm from './plans/PlansFormRoute';
import PlansListRoute from './plans/PlansListRoute';
import WorkPlansForm from './workplans/WorkPlansFormRoute';
import WorkPlansListRoute from './workplans/WorkPlansListRoute';

import PrivateRoute from './auth/PrivateRoute';
import { HashRouter as Router, Switch, Route } from "react-router-dom";

import Layout from './public/Layout';
import TopicsListPublic from './public/TopicsList';
import TopicsViewPublic from './public/TopicsView';
import Home from './public/Home';

import TechnicalLayout from './technical/Layout';
import TechnicalHome from './technical/Home';
import TechnicalDiaryFormRoute from './technical/DiaryFormRoute';
import TechnicalTopicsListPublic from './technical/TopicsList';
import TechnicalTopicsViewPublic from './technical/TopicsView';
import TechnicalOutOfServiceFormRoute from './technical/OutOfServiceFormRoute';
import TechnicalOutOfServiceListRoute from './technical/OutOfServiceListRoute';
import TechnicalCheckpointListRoute from './technical/CheckpointListRoute';

import ClientLayout from './clientui/Layout';
import ClientDashboard from './clientui/Dashboard';
import ClientDiaryFormRoute from './clientui/DiaryFormRoute';
import ClientTasksHome from './clientui/TasksHome';
import ClientTopicsListPublic from './clientui/TopicsList';
import ClientTopicsViewPublic from './clientui/TopicsView';
import ClientRoute from './clientui/ClientsRoute';
import ClientClientOutListRoute from './clientui/ClientOutListRoute';
import ClientClientOutFormRoute from './clientui/ClientOutFormRoute';

import Session from './auth/Session';

import BackofficeLayout from './backoffice/Layout';
import EntitiesList from './entities/EntitiesListRoute';
import EntitiesForm from './entities/EntitiesFormRoute';
import GroupsForm from './groups/GroupsForm';
import GroupsList from './groups/GroupsList';
import MessagesList from './messages/MessagesListRoute';
import ClientsRoute from './clients/ClientsRoute';
import OutOfServiceFormRoute from './outofservice/OutOfServiceFormRoute';
import OutOfServiceListRoute from './outofservice/OutOfServiceListRoute';
import Diary3Days from './diary/Diary3Days';
import ClientOutFormRoute from './clientout/ClientOutFormRoute';
import ClientOutListRoute from './clientout/ClientOutListRoute';
import DiaryViewRoute from './diary/DiaryViewRoute';
import RolesForm from './roles/RolesForm';
import RolesList from './roles/RolesList';
import MyProfile from './public/MyProfile';
import StockForm from './stock/StockForm';
import StockBulkForm from './stock/StockBulkForm';
import StockList from './stock/StockList';
import UserActivityListRoute from './user_activity/UserActivityListRoute';
import UserActivityFormRoute from './user_activity/UserActivityFormRoute';
import ClientActivityListRoute from './clientui/ClientActivityListRoute';
import FailedLoginsList from './users/FailedLoginsList';
import BusinessFormRoute from './business/BusinessFormRoute';
import BusinessListRoute from './business/BusinessListRoute';
import FeaturesList from './features/FeaturesList';
import FeaturesForm from './features/FeaturesForm';
import VideoCallContext from './videocall/VideoCallContext';
import BuyNowPage from './auth/BuyNowPage';
import ListOnline from './videocall/ListOnline';
import ActivationPage from './auth/ActivationPage';
import GoalsFormRoute from './goals/GoalsFormRoute';
import GoalsList from './goals/GoalsList';
import loader from './loader';

function App() {

  let tasksHome = false;
  let me = Session.getCookie();
  if (me && ['SECURITY', 'ASSISTENT'].includes(me.role1)) tasksHome = true;

  useEffect(() => {

    // Check notifications
    if (window.Notification && (Notification.permission === 'denied' || Notification.permission === "default")) {
      Notification.requestPermission(function (permission) {
        if (permission === "granted") console.log('Notifications permission', permission);
      });
    }

    // Check Google Auth code response
    const queryParams = new URLSearchParams(window.location.search);
    const gstate = queryParams.get('state');
    const gcode = queryParams.get('code');
    const gscope = queryParams.get('scope');
    if (gstate && gcode && gscope) {
      const gAuthUrl = '/user/google/authcode' + window.location.search;
      loader.get(gAuthUrl, res => {
        const gbuff = Buffer.from(gstate, 'base64');
        const gparams = gbuff.toString('ascii').split('|');
        window.location.href = gparams[1]; 
      });
    }
  }, []);

  return (
    <CookiesProvider>
      <VideoCallContext>
        <Router>
          <Switch>

            <Route exact path="/">
              <SplashScreen />
            </Route>

            <Route path="/buynow">
              <BuyNowPage />
            </Route>

            <Route path="/login">
              <LoginPage />
            </Route>

            <Route path="/registar">
              <RegisterPage />
            </Route>

            <Route path="/activate/:activation_code">
              <ActivationPage />
            </Route>

            <Route path="/topics">
              <Layout>
                
                <Route exact path="/topics">
                  <Home />
                </Route>

                <Route path="/topics/list">
                  <TopicsListPublic />
                </Route>

                <Route path="/topics/view/:id">
                  <TopicsViewPublic />
                </Route>
                
              </Layout>
            </Route>

            <PrivateRoute exact path="/home">
              <Layout>
                { tasksHome ? <Home /> : <TechnicalHome /> }
              </Layout>
            </PrivateRoute>
            
            <PrivateRoute path="/client">
              <ClientLayout>

                <Route exact path="/client">
                  <ClientDashboard />
                </Route>

                <Route path="/client/myprofile">
                  <MyProfile />
                </Route>

                <Route path="/client/messages">
                  <MessagesList />
                </Route>

                <Route path="/client/videocall">
                    <ListOnline />
                </Route>

                <Route exact path="/client/diary">
                  <ClientTasksHome />
                </Route>

                <Route path="/client/diary/form/:id/:therapy?/:incident?">
                  <ClientDiaryFormRoute />
                </Route>

                <Route path="/client/topics/list">
                  <ClientTopicsListPublic />
                </Route>

                <Route path="/client/topics/view/:id">
                  <ClientTopicsViewPublic />
                </Route>

                <Route path="/client/info/:action/:id?">
                  <ClientRoute />
                </Route>

                <Route path="/client/out/list">
                  <ClientClientOutListRoute />
                </Route>

                <Route path="/client/out/form/:id">
                  <ClientClientOutFormRoute />
                </Route>

                <Route path="/client/activities/list">
                  <ClientActivityListRoute />
                </Route>

              </ClientLayout>
            </PrivateRoute>

            <PrivateRoute path="/technical">
              <TechnicalLayout>

                <Route exact path="/technical">
                  <TechnicalHome />
                </Route>

                <Route path="/technical/messages">
                  <MessagesList />
                </Route>

                <Route path="/technical/videocall">
                    <ListOnline />
                </Route>

                <Route path="/technical/myprofile">
                  <MyProfile />
                </Route>

                <Route path="/technical/diary/form/:id/:therapy?/:incident?">
                  <TechnicalDiaryFormRoute />
                </Route>
                
                <Route path="/technical/topics/list">
                  <TechnicalTopicsListPublic />
                </Route>

                <Route path="/technical/topics/view/:id">
                  <TechnicalTopicsViewPublic />
                </Route>

                <Route path="/technical/outofservices/form/:id">
                  <TechnicalOutOfServiceFormRoute />
                </Route>

                <Route path="/technical/outofservices/list">
                  <TechnicalOutOfServiceListRoute />
                </Route>
                
                <Route path="/technical/checkpoint/list">
                  <TechnicalCheckpointListRoute />
                </Route>

                <Route path="/technical/services/list">
                  <UserActivityListRoute />
                </Route>

                <Route path="/technical/services/form/:id">
                  <UserActivityFormRoute />
                </Route>

              </TechnicalLayout>
            </PrivateRoute>
            
            <PrivateRoute path="/backoffice">
              <BackofficeLayout>

                <Route exact path="/backoffice">
                  <Dashboard />
                </Route>

                <Route exact path="/backoffice/stats">
                  <Stats />
                </Route>

                <Route path="/backoffice/myprofile">
                  <MyProfile />
                </Route>

                <Route path="/backoffice/users/:action/:id">
                  <UsersRoute />
                </Route>
                
                <Route path="/backoffice/users/list">
                  <UsersList />
                </Route>

                <Route path="/backoffice/groups/form/:id">
                  <GroupsForm />
                </Route>
                <Route path="/backoffice/groups/list">
                  <GroupsList />
                </Route>

                <Route path="/backoffice/roles/form/:id">
                  <RolesForm />
                </Route>
                <Route path="/backoffice/roles/list">
                  <RolesList />
                </Route>
                
                <Route exact path="/backoffice/clients/list">
                  <ClientsList />
                </Route>

                <Route path="/backoffice/clients/:action/:id">
                  <ClientsRoute />
                </Route>

                <Route path="/backoffice/goals/form/:id">
                  <GoalsFormRoute />
                </Route>
                <Route path="/backoffice/goals/list">
                  <GoalsList />
                </Route>

                <Route path="/backoffice/organizations/form/:id">
                  <OrganizationsForm />
                </Route>
                <Route path="/backoffice/organizations/list">
                  <OrganizationsList />
                </Route>

                <Route path="/backoffice/messages">
                    <MessagesList />
                </Route>

                <Route path="/backoffice/videocall">
                    <ListOnline />
                </Route>

                <Route path="/backoffice/topics/view/:id/:activity?">
                  <TopicsViewAdmin />
                </Route>
                <Route path="/backoffice/topics/form/:id/:activity?">
                  <TopicsForm />
                </Route>
                <Route path="/backoffice/topics/list/:activity?">
                  <TopicsList />
                </Route>

                <Route path="/backoffice/entities/form/:id">
                  <EntitiesForm />
                </Route>
                <Route path="/backoffice/entities/list">
                  <EntitiesList />
                </Route>

                <Route path="/backoffice/stock/form/:id">
                  <StockForm />
                </Route>
                <Route path="/backoffice/stock/bulkform">
                  <StockBulkForm />
                </Route>
                <Route path="/backoffice/stock/list">
                  <StockList />
                </Route>

                <Route path="/backoffice/fields/form/:id">
                  <FieldsForm />
                </Route>
                <Route path="/backoffice/fields/list">
                  <FieldsList />
                </Route>

                <Route path="/backoffice/diary/form/:id/:therapy?/:incident?">
                  <DiaryFormRoute />
                </Route>
                <Route path="/backoffice/diary/view/:id">
                  <DiaryViewRoute />
                </Route>
                <Route path="/backoffice/diary/list">
                  <Diary3Days />
                </Route>

                <Route path="/backoffice/plans/form/:id/:client_id?/:therapy?">
                  <PlansForm />
                </Route>
                <Route path="/backoffice/plans/list">
                  <PlansListRoute />
                </Route>

                <Route path="/backoffice/workplans/form/:id/:owner_id?">
                  <WorkPlansForm />
                </Route>
                <Route path="/backoffice/workplans/list">
                  <WorkPlansListRoute />
                </Route>

                <Route path="/backoffice/outofservices/form/:id/:user_id?">
                  <OutOfServiceFormRoute />
                </Route>
                <Route path="/backoffice/outofservices/list/:user_id?">
                  <OutOfServiceListRoute />
                </Route>

                <Route path="/backoffice/clientouts/form/:id/:client_id?">
                  <ClientOutFormRoute />
                </Route>
                <Route path="/backoffice/clientouts/list/:client_id?">
                  <ClientOutListRoute />
                </Route>

                <Route path="/backoffice/business/form/:id">
                  <BusinessFormRoute />
                </Route>
                <Route path="/backoffice/business/list">
                  <BusinessListRoute />
                </Route>

                <Route path="/backoffice/features/form/:id">
                  <FeaturesForm />
                </Route>
                <Route path="/backoffice/features/list">
                  <FeaturesList />
                </Route>

                <Route path="/backoffice/failed/list">
                  <FailedLoginsList />
                </Route>
                  
              </BackofficeLayout>
            </PrivateRoute>

          </Switch>
        </Router>
      </VideoCallContext>
    </CookiesProvider>    
  );
}

export default App;
