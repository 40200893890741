import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import {Panel} from 'primereact/panel';
import {ProgressSpinner} from 'primereact/progressspinner';
import TopicsListPublic from './TopicsList';
import loader from '../loader';

const serverUrl = process.env.REACT_APP_SERVER_URL;

export default function TopicsView(props) {

  let [ topic, setTopic ] = useState(null);
  let [ loading, setLoading ] = useState(true);

  // Load item
  let urlParams = useParams();
  useEffect(() => {
    if (parseInt(urlParams.id, 10) < 1) return;
    loader.get('/topic/item/' + urlParams.id, res => {
      setTopic(res.item);
    }, setLoading);
  }, [urlParams.id]);

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/topic_placeholder.png';
  }

  if (loading) return <ProgressSpinner />;
  if (!topic) return <div>A informação não foi encontrada</div>;
  return (
    <React.Fragment>
      <Panel header={topic.title} className="main-panel">
          { !!topic.image && (
              <img 
                alt="Imagem"
                className="image-pad-right" 
                src={getFileUrl(topic.image, 'topics')} 
              />
          ) }
          <div dangerouslySetInnerHTML={{ __html: topic.description }}></div>
          <div style={{ clear: 'right' }}></div>
      </Panel>
      <TopicsListPublic parent={topic} />
    </React.Fragment>
  )
}