import React, { Component } from 'react';
import classNames from 'classnames';
import Session from '../auth/Session';
import { withRouter, Link } from "react-router-dom";

const serverUrl = process.env.REACT_APP_SERVER_URL || '';

class AppProfile extends Component {

    constructor() {
        super();
        this.state = {
            expanded: false
        };
        this.onClick = this.onClick.bind(this);

        this.user = Session.getCookie();
    }

    logout() {
        const { history } = this.props;
        Session.signout(() => {
            history.push({ pathname: "/" });
        });
    };

    onClick(event) {
        this.setState({expanded: !this.state.expanded});
        event.preventDefault();
    }

    // Download
    getFileUrl(filename, folder) {
        return filename ? serverUrl + '/download/' + folder + '/' + filename
            : '/assets/images/photo_placeholder.png';
    }

    render() {
        const { photo, firstname, lastname } = this.user;
        return  (
            <div className="layout-profile">
                <div>
                    <img src={this.getFileUrl(photo, 'users')} alt="" />
                </div>
                <button className="p-link layout-profile-link" onClick={this.onClick}>
                    <span className="username">{ firstname } { lastname }</span>
                    <i className="pi pi-fw pi-cog"/>
                </button>
                <ul className={classNames({'layout-profile-expanded': this.state.expanded})}>
                    <li>
                        <Link to="/technical">
                            <button className="p-link">
                                <i className="pi pi-fw pi-mobile"/><span>Aplicação de Colaborador</span>
                            </button>
                        </Link>
                    </li>
                    <li>
                        <Link to="/backoffice/myprofile">
                            <button className="p-link" onClick={(e) => this.props.onMenuItemClick({ originalEvent: e, item: {}})}>
                                <i className="pi pi-fw pi-user"/><span>O Meu Perfil</span>
                            </button>
                        </Link>
                    </li>
                    <li>
                        <button className="p-link" onClick={this.logout.bind(this)}>
                            <i className="pi pi-fw pi-power-off"/><span>Sair</span>
                        </button>
                    </li>
                </ul>
            </div>
        );
    }
}

export default withRouter(AppProfile);