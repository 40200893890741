import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import {InputSwitch} from 'primereact/inputswitch';
import { Message } from 'primereact/message';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toolbar} from 'primereact/toolbar';
import { useHistory, Link } from "react-router-dom";
import moment from 'moment';
import loader from '../loader';
import monthlyOptions from './monthly.json';
import durationOptions from './duration.json';
import { ProgressSpinner } from 'primereact/progressspinner';
import Session from '../auth/Session';
import timeSetup from '../timeSetup';

let dt = null;

const serverUrl = process.env.REACT_APP_SERVER_URL;

const getItems = (res, setItems) => {
    let titems = res.items;
    titems = titems.map(i => ({
        ...i,
        start_date: timeSetup.from(i.start_date).format('YYYY-MM-DD HH:mm'),
        end_date: i.end_date ? timeSetup.from(i.end_date).format('YYYY-MM-DD HH:mm') : null,
        day_hourmin: timeSetup.from(i.day_hourmin).format('YYYY-MM-DD HH:mm')    
    }))
    setItems(titems);
}

function PlansList(props) {
  const { owner_id } = props;

  let [ items, setItems ] = useState([]);
  
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  let history = useHistory();

  useEffect(() => {
    if (owner_id) {
        loader.get('/workplan/user/' + owner_id, res => {
            if (!res.success) return;
            getItems(res, setItems);
        }, setLoading);
    } else {
        loader.get('/workplan/list', res => {
            if (!res.success) return;
            getItems(res, setItems);
        }, setLoading);    
    }
  }, []);

  let updateItem = (data) => {
    const url = '/workplan/update';
    setSuccess(false);
    loader.post(url, data, res => {
        setSuccess(true);
    }, setLoading);
  };

  let deleteItem = (id) => {
    const url = '/workplan/delete/' + id;
    setSuccess(false);
    loader.get(url, res => {
        if (!res.success) return;
        setSuccess(true);
        items = items.filter(i => i.id !== id);
        setItems(items);
    }, setLoading);
  };

  let toggleActive = (workplans, id) => {
    let items = Object.assign([], workplans);
    items = items.map(item => {
        if (item.id === id) {
            item.active = !item.active;
            item.end_date = item.active ? null : new Date();
            updateItem({ id: item.id, owner_id: item.owner_id, active: item.active, end_date: item.end_date });
        }
        return item;
    });
    setItems(items);
  }

  let recordOptions = (item, column) => {
    const logged = Session.getCookie();
    return <div style={{ textAlign: 'right' }}>
        <Button
            onClick={e => history.push({ pathname: '/backoffice/workplans/form/' + item.id })} 
            type="button" 
            icon="pi pi-pencil" 
            className="p-button-info"
        />
        <Button
            onClick={e => deleteItem(item.id)} 
            type="button" 
            icon="pi pi-times" 
            className="p-button-danger"
            disabled={![logged.role1].includes('ADMINISTRATOR')}
        />
    </div>;
  }

  let recordActive = (item, column) => {
    return <div style={{ textAlign: 'center' }}>
        <InputSwitch 
            checked={item.active} 
            onChange={(e) => toggleActive(items, item.id)} 
        />
    </div>;
  }

  const getOptionLabel = (options, value) => {
    const item = options.find(opt => opt.value === value);
    return item ? item.label : value;
  }

  const isMobile = window.innerWidth <= 768;
  if (loading) return <ProgressSpinner />;
  let createUrl = '/backoffice/workplans/form/0' + (owner_id ? '/' + owner_id : '');
  return (
    <React.Fragment>
        
        <Toolbar>
            <div className="p-toolbar-group-left">
                <Link to={createUrl}>
                    <Button label="Adicionar Horário" />
                </Link>
            </div>
            <div className="p-toolbar-group-right">
                <Button
                    type="button" 
                    icon="pi pi-external-link" 
                    iconPos="left" 
                    label="CSV" 
                    onClick={e => dt.exportCSV()}
                    style={{ float: 'right' }}
                />
            </div>
        </Toolbar>

        { success && (
            <div className="p-grid">
                <div className="p-col-12">

                    <Message 
                        severity="success" 
                        text="O horário foi alterado com sucesso!">
                    </Message>
                    
                </div>
            </div>
        ) }

        <div className="p-grid">
            <div className="p-col-12">

            <DataTable value={items} ref={(el) => { dt = el; }}>
                { !owner_id && (
                    <Column 
                        header="Colaborador"
                        field="username"  
                        sortable={true}
                        filter={true}
                        filterMatchMode="contains"
                    />
                ) }

                {/*
                <Column 
                    header="Tipo" 
                    body={i => getOptionLabel(monthlyOptions, i.freq_month)} 
                />
                */}

                <Column 
                    header="Entrada" 
                    field="day_hourmin"
                    body={i => {
                        const time = moment(i.day_hourmin).hour();
                        const label = time < 7 ? 'Madrugada' : (
                            time < 13 ? 'Manhã' : (
                            time < 19 ? 'Tarde' : 'Noite' 
                        ));
                        return label + ' ' + moment(i.day_hourmin).format('HH:mm');
                    }} 
                />

                <Column 
                    header="Duração" 
                    field="duration"
                    body={i => getOptionLabel(durationOptions, i.duration)}
                />
                { !isMobile && (
                    <Column 
                        header="Inicio" 
                        field="start_date"
                        body={item => moment(item.start_date).format('YYYY-MM-DD')}
                    />
                ) }
                { !isMobile && (
                    <Column 
                        header="Fim" 
                        field="end_date"
                        body={item => item.end_date ? moment(item.end_date).format('YYYY-MM-DD') : ''}
                    />
                ) }
                { !isMobile && <Column body={recordActive} header="Activo" /> }
                <Column body={recordOptions} header="Opções" />
            </DataTable>
                
            </div>
        </div>
            
    </React.Fragment>
  );

}

export default PlansList;
