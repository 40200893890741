import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Link } from "react-router-dom";
import moment from 'moment';
import loader from '../loader';
import { ProgressSpinner } from 'primereact/progressspinner';
import DiaryDayColumn from './DiaryDayColumn';
import timeSetup from '../timeSetup';
import DiaryNoTasksAssigned from './DiaryNoTasksAssigned';
import { hasMod } from '../users/Permissions';

moment.updateLocale('pt', {
    calendar : {
        lastDay : '[Ontem]',
        sameDay : '[Hoje]',
        nextDay : '[Amanhã]'
    }
});

let dt1, dt2, dt3;

function Diary3Days() {

  let [ date, setDate ] = useState(moment().startOf('week').format('YYYY-MM-DD'));
  let [ items, setItems ] = useState([]);
  
  let [ loading, setLoading ] = useState(false);

  // Load day items
  useEffect(() => {
    let start = timeSetup.to(date).format('YYYY-MM-DD');
    let end = timeSetup.to(date).add(7, 'days').format('YYYY-MM-DD');
    loader.get('/diary/between/'+start+'/'+end, res => {
        setItems(res.items);
    }, setLoading);
  }, [date]);

  if (loading) return <ProgressSpinner />;
  return (
    <React.Fragment>
        <div className="card card-w-title">
            <h1>
                Diário
                <Link to="/backoffice/diary/form/0">
                    <Button style={{ float: 'right' }} label="Criar Tarefa" />
                </Link>
            </h1>
        </div>

        <div className="p-grid" id="diary3days">
            <div className="p-col-12 p-md-4">
                <DiaryDayColumn 
                    title="Ontem"
                    date={moment().subtract(1, 'day').startOf('day').toDate() } 
                    dt={dt1}
                    colId="yesterday"
                />
            </div>
            <div className="p-col-12 p-md-4">
                <DiaryDayColumn 
                    title="Hoje"
                    date={moment().startOf('day').toDate() } 
                    dt={dt2}
                    colId="today"
                />
            </div>
            <div className="p-col-12 p-md-4">
                <DiaryDayColumn 
                    title="Amanhã"
                    date={moment().add(1, 'day').startOf('day').toDate() } 
                    dt={dt3}
                    colId="tomorrow"
                />
            </div>
        </div>

        { hasMod(['T4CTA1']) &&
            <DiaryNoTasksAssigned />
        }

    </React.Fragment>
  );

}

export default Diary3Days;
