import React, { useState, useEffect } from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toolbar} from 'primereact/toolbar';
import {Calendar} from 'primereact/calendar';
import {Dropdown} from 'primereact/dropdown';
import loader from '../loader';
import moment from 'moment';
import { Button } from 'primereact/button';
import {Chart} from 'primereact/chart';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputSwitch } from 'primereact/inputswitch';
import date_pt from '../date_pt.json';

let dt = null;

const chartOptions = [
  { label: 'Linha', value: 'line', key: 'line' },
  { label: 'Barras', value: 'bar', key: 'bar' }
];

const fnOptions = [
  { label: 'Contar', value: 'count', key: 'count' },
  { label: 'Somar', value: 'sum', key: 'sum' },
  { label: 'Média', value: 'avg', key: 'avg' },
  { label: 'Mínimo', value: 'min', key: 'min' },
  { label: 'Máximo', value: 'max', key: 'max' },
  { label: 'Valores', value: 'raw', key: 'raw' }
];

function DynamicChart(props) {
  const { client, noTitle } = props;

  let [ items, setItems ] = useState([]);
  let [ fn, setFn ] = useState('count');
  let [ start, setStart ] = useState(moment().startOf('month').toDate());
  let [ end, setEnd ] = useState(moment().endOf('month').toDate());
  let [ activities, setActivities ] = useState([]);
  let [ activity_id, setActivity ] = useState();
  let [ topic, setTopic ] = useState();
  let [ fields, setFields ] = useState([]);
  let [ field_id, setField ] = useState();
  let [ clients, setClients ] = useState([]);
  let [ client_id, setClient ] = useState(client ? client.id : null);
  let [ linearType, setLinearType ] = useState('line');
  
  let [ display, setDisplay ] = useState('chart');
  let [ loading, setLoading ] = useState(false);

  useEffect(() => {
    loader.get('/topic/list/activities', res => {
        setActivities(res.items);
    }, setLoading);
  }, []);

  useEffect(() => {
    loader.get('/client/list', res => {
        setClients(res.items);
    }, setLoading);
  }, []);

  useEffect(() => {
    if (!parseInt(activity_id, 10)) return;
    loader.get('/topic/item/' + activity_id, res => {
        setTopic(res.item);
        setFields(res.item.fields);
    }, setLoading);
  }, [activity_id]);
  
  useEffect(() => {
    if (!parseInt(field_id, 10)) return;
    let tstart = moment(start).format('YYYY-MM-DD');
    let tend = moment(end).format('YYYY-MM-DD');
    let url = '/diary/report/dynamic/' + tstart + '/' + tend + '/' + activity_id + '/' + field_id + '/' + fn;
    if (client_id) url += '/' + client_id;
    loader.get(url, res => {
        setItems(res.items);
        setFn(res.fn);
    }, setLoading);
  }, [start, end, field_id, client_id, fn]);

  const activitiesOptions = activities.map(opt => ({
    label: opt.title,
    value: opt.id,
    key: opt.id
  }));

  const fieldOptions = fields.map(opt => ({
    label: opt.label,
    value: opt.id,
    key: opt.id
  }));

  const field = fields.find(f => String(f.id) === String(field_id));

  const clientOptions = clients.map(opt => ({
    label: opt.firstname + ' ' + opt.lastname,
    value: opt.id,
    key: opt.id
  }));
  clientOptions.unshift({ label: 'Qualquer', value: null, key: '0' });

  let valueLabel = 'Total';
  if (fn === 'sum') valueLabel = 'Valor';

  useEffect(() => {
    if (!parseInt(field_id, 10)) return;
    let tstart = moment(start).format('YYYY-MM-DD');
    let tend = moment(end).format('YYYY-MM-DD');
    let url = '/diary/report/dynamic/' + tstart + '/' + tend + '/' + activity_id + '/' + field_id + '/' + fn;
    if (client_id) url += '/' + client_id;
    setFn(fn);
    loader.get(url, res => {
        const items = res.items.map(i => ({ ...i, total: getValue(field, fn, i.total) }));
        setItems(items);
    }, setLoading);
  }, [start, end, field_id, client_id, fn]);

  function getValue(field, fn, value) {
    let v = value;
    if (typeof v === '[object Null]') return v;
    if (toString.call(value) === "[object Array]") {
      switch(fn) {
        case 'max': v = Math.max.apply(null, value); break;
        case 'min': v = Math.min.apply(null, value); break;
        case 'avg': v = value.reduce((a, b) => a+b, 0) / value.length; break;  
        case 'sum': v = value.reduce((a, b) => a+b, 0); break;
        case 'count': v = value.length; break;
        default:;
      }
    }
    if (fn !== 'raw') {
        switch(field.type) {
            case 'array_integer':
            case 'integer': return parseInt(v, 10); break;
            case 'array_decimal':
            case 'decimal': return parseFloat(v); break;
            default:;
        }
    }
    return v;
  }

  let chartType = linearType;
  if (field && ['string', 'text'].includes(field.type)) chartType = 'pie';

  let colors = '#42A5F5';
  let labels = [];
  if (field && ['string', 'text'].includes(field.type)) {
    colors = [];  
    labels = items.map(i => i.label);
    labels.forEach(l => colors.push('#'+Math.floor(Math.random()*16777215).toString(16)));
  }

  console.log('field', field);
  console.log('values', items);
  const data = {
    labels: items.map(i => i.label),
    datasets: [
        {
            label: (topic ? topic.title : '') + ' ' + (field ? field.label : ''),
            data: items.map(i => i.total),
            fill: false,
            backgroundColor: colors,
            borderColor: '#E0E0E0'
        }
    ]
  };

  if (loading) return <ProgressSpinner />;
  return (

    <div className="card card-w-title">
        { !noTitle && <h1>Gráfico Dinâmico</h1> }

        <Toolbar>
            <div className="p-toolbar-group-left">
                <div className="p-grid">
                    <div className="p-col-12 p-lg-2">
                        <Calendar 
                            placeholder="Data de Inicio"
                            value={start} 
                            locale={date_pt}
                            dateFormat="yy-mm-dd"
                            yearNavigator={true}
                            yearRange="2020:2030"
                            onChange={(e) => setStart(e.value) }>
                        </Calendar>
                    </div>
                    <div className="p-col-12 p-lg-2">
                        <Calendar 
                            placeholder="Data de Fim"
                            value={end} 
                            locale={date_pt}
                            dateFormat="yy-mm-dd"
                            yearNavigator={true}
                            yearRange="2020:2030"
                            onChange={(e) => setEnd(e.value) }>
                        </Calendar>
                    </div>
                    <div className="p-col-12 p-lg-2">
                        <Dropdown
                            placeholder="Escolha o tipo"
                            value={activity_id || null}
                            options={activitiesOptions}
                            onChange={(e) => setActivity(e.value) }
                        />
                    </div>
                    <div className="p-col-12 p-lg-2">
                        <Dropdown
                            placeholder="Escolha o campo"
                            value={field_id || null}
                            options={fieldOptions}
                            onChange={(e) => setField(e.value) }
                        />
                    </div>

                    { !client && (
                        <div className="p-col-12 p-lg-2">
                            <Dropdown
                                placeholder="Escolha o cliente"
                                value={client_id || null}
                                options={clientOptions}
                                onChange={(e) => setClient(e.value) }
                            />
                        </div>
                    )}

                    <div className="p-col-12 p-lg-2">
                        <Dropdown
                            placeholder="Escolha agregação"
                            value={fn}
                            options={fnOptions}
                            onChange={(e) => setFn(e.value) }
                        />
                    </div>
                    <div className="p-col-12 p-lg-2">
                        <Dropdown
                            placeholder="Escolha o gráfico"
                            value={linearType}
                            options={chartOptions}
                            onChange={(e) => setLinearType(e.value) }
                        />
                    </div>
                </div>
            </div>

            { !!items.length && (
                <div className="p-toolbar-group-right">
                    <InputSwitch
                        id="displayToggle"
                        style={{ marginBottom: '-7px' }}
                        label="Grelha"
                        checked={display === 'table'} 
                        onChange={(e) => setDisplay(display === 'chart' ? 'table' : 'chart')} 
                    />{' '}<label htmlFor="displayToggle">Tabela</label>{' '}
                    { display === 'table' && (
                        <Button
                            type="button" 
                            icon="pi pi-external-link" 
                            iconPos="left" 
                            label="CSV" 
                            onClick={e => dt.exportCSV()}
                        />
                    )}
                </div>
            )}
        </Toolbar>

        { items.length === 0 ? <p>Sem dados a apresentar</p> : (
            display === 'chart' ? 
                <Chart type={chartType} data={data} options={{ maintainAspectRatio: false }} />
                : (
                <DataTable 
                    value={items} 
                    ref={(el) => { dt = el; }}
                    style={{ textAlign: 'center' }}>
                    <Column header="Etiqueta" field="label" />
                    <Column header={valueLabel} field="total" />
                </DataTable>
                )
        ) }
        
    </div>
    
  );

}

export default DynamicChart;
