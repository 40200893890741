import React, { useState, useEffect, useReducer } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import {InputSwitch} from 'primereact/inputswitch';
import {Dropdown} from 'primereact/dropdown';
import {AutoComplete} from 'primereact/autocomplete';
import { Message } from 'primereact/message';
import {FileUpload} from 'primereact/fileupload';
import {Editor} from 'primereact/editor';
import {PickList} from 'primereact/picklist';
import loader from '../loader';
import Session from '../auth/Session';
import durationOptions from '../plans/duration.json';
import { ColorPicker } from 'primereact/colorpicker';
import { ProgressSpinner } from 'primereact/progressspinner';
import slugify from './slugify';

const serverUrl = process.env.REACT_APP_SERVER_URL;

function reducer(state, action) {
    switch (action.type) {
      case 'update':
          if (action.field === 'title') {
              return {
                  ...state,
                  title: action.value,
                  tag: slugify(action.value)
              }
          }
        return {
            ...state,
            [action.field]: action.value
        };
      case 'update_all':
        return {
            ...action.value
        }
      default:
        throw new Error();
    }
}

function Form(props) {
  const { topic_id, defaultActivity } = props;

  let logged = Session.getCookie();
  const initialState = {
    title: '',
    tag: '',
    description: '',
    active: true,
    sticky: false,
    parent_id: null,
    config: '{}',
    tags: '',
    activity: defaultActivity || false,
    owner_id: logged.id,
    fields: [],
    duration: 60,
    cost: 0,
    icon: 'pi-briefcase',
    therapy: false,
    incident: false,
    info_video: ''
  };

  let [ topic, dispatch ] = useReducer(reducer, initialState);
  let [ tags, setTags ] = useState([]);
  let [ tagSugestions, setTagSugestions ] = useState([]);
  let [ fields, setFields ] = useState([]);
  
  let [ errors, setErrors ] = useState({});
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  // Load tags
  useEffect(() => {
    loader.get('/topic/list/all', res => {
        setTags(res.items);
    }, setLoading);
  }, []);

  // Load fields
  useEffect(() => {
    loader.get('/field/list', res => {
        setFields(res.items);
    }, setLoading);
  }, []);

  // Load item
  useEffect(() => {
    if (!parseInt(topic_id, 10)) {
        return dispatch({ type: 'update_all', value: initialState });
    }
    loader.get('/topic/item/' + topic_id, res => {
        const value = {
            ...res.item,
            tags: res.item.tags ? res.item.tags.split('|') : []
        };
        dispatch({ type: 'update_all', value });
    }, setLoading);
  }, [topic_id]);
  
  // Register
  let register = () => {
    let data = {
        ...topic,
        tags: topic.tags ? topic.tags.join('|') : '',
        fields: topic.fields.map(f => f.id)
    };
    let url = '/topic/create';
    if (topic.id) url = '/topic/update';
    setSuccess(false);
    loader.post(url, data, res => {
        if (res.errors) return setErrors(res.errors);
        dispatch({ type: 'update', field: 'id', value: res.id });
        setSuccess(true);
    }, setLoading);
  };

  let isDataInvalid = () => {
      return loading
        || topic.title.length < 3
        || topic.tag.length < 3;
  }

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/topic_placeholder.jpg';
  }

  // Upload
  const onUpload = (e) => {
    if (e.files.length === 0) return;
    const formData  = new FormData();
    formData.append('folder', 'topics');
    formData.append('upload', e.files[0]);
    const url = '/upload';
    loader.upload(url, formData, res => {
      if (!res.filename) return;
      dispatch({ type: 'update', field: 'image', value: res.filename });  
    }, setLoading);
  }

  const onRemoveImage = (e, file) => {
    dispatch({ type: 'update', field: 'image', value: '' });
  }

  // Upload
  const onUploadVideo = (e) => {
    if (e.files.length === 0) return;
    
    const formData  = new FormData();
    formData.append('folder', 'topic');
    formData.append('upload', e.files[0]);
    const url = '/upload';
    loader.upload(url, formData, res => {
        if (!res.filename) return;
        dispatch({ type: 'update', field: 'info_video', value: res.filename });  
      }, setLoading);
  }

  const onRemoveVideo = (e, file) => {
    dispatch({ type: 'update', field: 'info_video', value: '' });
  }

  let displayErrors = (errors, key) => !!errors[key] && errors[key].map(msg =>
    <Message severity="error" text={msg}></Message>
  )

  // Get parent options
  const parentOptions = tags.map(opt => ({
    label: opt.title,
    value: opt.id,
    key: opt.id
  })).filter(opt => opt.value != topic.id);

  // Get tag options
  const tagsOptions = tags.map(opt => opt.tag).filter(opt => opt.tag !== topic.tag);

  // Sugested tags
  const suggestTags = (event) => {
    let results = tagsOptions.filter((tag) => {
        return tag.toLowerCase().startsWith(event.query.toLowerCase());
    });
    setTagSugestions(results);
  }

  const availableFields = fields.filter(item1 => 
    !topic.fields.find(item2 => item1.id === item2.id)
  );

  if (loading) return <ProgressSpinner />;
  const msg = topic.activity ? "A atividade foi guardada com sucesso!" 
    : "O conteúdo foi guardado com sucesso!";
  return (
    <React.Fragment>

        { success && (
            <div className="p-grid">
                <div className="p-col-12">

                    <Message severity="success" text={msg}></Message>
                    
                </div>
            </div>
        ) }

        <Button 
            onClick={register} 
            label="Guardar"
            disabled={isDataInvalid()}
            style={{ float: 'right' }}
        />

        <p className="form-info">
            <strong style={{ color: 'red' }}>*</strong> 
            {' '}Campos obrigatórios
            
        </p>

        <div className="p-grid">
            <div className="p-col-12 p-lg-6">
                <div className="p-grid">
                    
                    <div className="p-col-12 p-md-6">
                        <label className="field-required">Título</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <InputText 
                            placeholder="Título"
                            value={topic.title}
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'title', value: e.target.value })
                            }
                        />
                        { displayErrors(errors, 'title') }
                    </div>

                    <div className="p-col-12 p-md-6">
                        <label className="field-required">Tag</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <InputText 
                            placeholder="Tag"
                            value={topic.tag}
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'tag', value: e.target.value })
                            }
                        />
                        { displayErrors(errors, 'tag') }
                    </div>

                    <div className="p-col-12 p-md-6">
                        <label>{ !topic.activity ? 'Tópico Superior' : 'Categoria' }</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Dropdown 
                            showClear={true}
                            placeholder="Escolha uma opção"
                            value={parseInt(topic.parent_id, 10)}
                            options={parentOptions} 
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'parent_id', value: e.value })
                            }
                        />
                    </div>

                    { !defaultActivity && (
                        <React.Fragment>
                            <div className="p-col-12 p-md-6">
                                <label>Tags Relacionadas</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <AutoComplete 
                                    multiple={true} 
                                    value={topic.tags}
                                    onChange={(e) => 
                                        dispatch({ type: 'update', field: 'tags', value: e.value })
                                    }
                                    suggestions={tagSugestions}
                                    completeMethod={suggestTags}
                                />
                                { displayErrors(errors, 'tags') }
                            </div>
                        </React.Fragment>
                    ) }

                    { !!defaultActivity && (
                        <React.Fragment>
                            <div className="p-col-10 p-md-6">
                                <label>Apenas Terapêutica?</label>
                            </div>
                            <div className="p-col-2 p-md-6">
                                <InputSwitch 
                                    checked={topic.therapy} 
                                    onChange={(e) => 
                                        dispatch({ type: 'update', field: 'therapy', value: !topic.therapy })
                                    } 
                                />
                            </div>

                            <div className="p-col-10 p-md-6">
                                <label>Apenas Ocorrência?</label>
                            </div>
                            <div className="p-col-2 p-md-6">
                                <InputSwitch 
                                    checked={topic.incident} 
                                    onChange={(e) => 
                                        dispatch({ type: 'update', field: 'incident', value: !topic.incident })
                                    } 
                                />
                            </div>
                        </React.Fragment>
                    ) }

                    <div className="p-col-10 p-md-6">
                        <label>Activo</label>
                    </div>
                    <div className="p-col-2 p-md-6">
                        <InputSwitch 
                            checked={topic.active} 
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'active', value: !topic.active })
                            } 
                        />
                    </div>

                    { !!topic.activity && (
                        <React.Fragment>
                            <div className="p-col-12 p-md-6">
                                <label>Côr de Etiqueta</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <ColorPicker
                                    value={topic.color} 
                                    onChange={(e) => 
                                        dispatch({ type: 'update', field: 'color', value: e.value })
                                    }
                                />
                            </div>
                        </React.Fragment>
                    )}

                    { !defaultActivity && (
                        <React.Fragment>
                            <div className="p-col-10 p-md-6">
                                <label>Alerta na Homepage</label>
                            </div>
                            <div className="p-col-2 p-md-6">
                                <InputSwitch 
                                    checked={topic.sticky} 
                                    onChange={(e) => 
                                        dispatch({ type: 'update', field: 'sticky', value: !topic.sticky })
                                    } 
                                />
                            </div>
                        </React.Fragment>
                    ) }

                    { topic.activity && !topic.incident && (
                        <React.Fragment>
                            <div className="p-col-12 p-md-6">
                                <label>Duração da Atividade</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <Dropdown 
                                    placeholder="Escolha uma opção"
                                    value={topic.duration}
                                    options={durationOptions} 
                                    onChange={(e) => 
                                        dispatch({ type: 'update', field: 'duration', value: e.value })
                                    }
                                />
                                { displayErrors(errors, 'duration') }
                            </div>
                        </React.Fragment>
                    ) }
                    
                    { !!topic.activity && (
                        <React.Fragment>
                            <div className="p-col-12 p-md-6">
                                <label>Icone</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <InputText 
                                    placeholder="Icone"
                                    value={topic.icon}
                                    onChange={(e) => 
                                        dispatch({ type: 'update', field: 'icon', value: e.target.value })
                                    }
                                />
                            </div>
                        </React.Fragment>
                    ) }
                    
                    <React.Fragment>
                        <div className="p-col-12 p-md-6">
                            <label className="field-required">Imagem</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            { !!topic.image && (
                                <img 
                                    src={getFileUrl(topic.image, 'topics')} 
                                    alt="Imagem"
                                    title="Imagem"
                                    className="img-avatar"
                                />
                            ) }
                            <FileUpload 
                                name="logo"
                                mode="basic"
                                url={'/upload'}
                                customUpload={true}
                                uploadHandler={onUpload}
                                auto={true}
                                chooseLabel={topic.image ? "Alterar" : "Escolher" }
                                onRemove={onRemoveImage}
                            />
                        </div>
                    </React.Fragment>
                    
                </div>
            </div>
            <div className="p-col-12 p-lg-6">
                <Editor 
                    style={{height: topic.activity ? '197px' : '320px'}} 
                    value={topic.description}
                    onTextChange={(e) => 
                        dispatch({ type: 'update', field: 'description', value: e.htmlValue })
                    }
                />
                <br />
                <div className="p-grid">
                    <div className="p-col-12">
                        <label>
                            <i class="pi pi-fw pi-video"></i>
                            Video Informativo
                            <br /><small>* Apenas ficheiros MP4</small>
                        </label>
                    </div>
                    <div className="p-col-12">
                        { !!topic.info_video && (
                            <video controls
                                alt="Video"
                                title="Video"
                                className="img-avatar"
                                style={{minHeight: '140px'}}>
                                <source
                                    src={getFileUrl(topic.info_video, 'topic')}
                                    type="video/mp4"
                                />
                                O seu browser não suporta videos HTML5
                            </video>
                        ) }
                        <FileUpload 
                            name="info_video"
                            mode="basic"
                            url={'/upload'}
                            customUpload={true}
                            uploadHandler={onUploadVideo}
                            auto={true}
                            chooseLabel={topic.info_video ? "Alterar" : "Escolher" }
                            onRemove={onRemoveVideo}
                        />
                    </div>
                </div>
            </div>

            

            { topic.activity && (
                <React.Fragment>
                    <h5>Campos do Registo Diário</h5>

                    <PickList 
                        source={availableFields} 
                        target={topic.fields} 
                        sourceHeader="Campos disponíveis"
                        targetHeader="Campos selecionados"
                        itemTemplate={item => item.label}
                        responsive={false}
                        onChange={(e) => 
                            dispatch({ type: 'update', field: 'fields', value: e.target })
                        }
                    />
                    
                </React.Fragment>
            ) }

        </div>
    </React.Fragment>
  );

}

export default Form;
