import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import {InputSwitch} from 'primereact/inputswitch';
import { Message } from 'primereact/message';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toolbar} from 'primereact/toolbar';
import {Card} from 'primereact/card';
import { useHistory, Link } from "react-router-dom";
import moment from 'moment';
import loader from '../loader';
import Mapa from './Mapa';
import Session from '../auth/Session';
import { ProgressSpinner } from 'primereact/progressspinner';
import { hasMod } from './Permissions';

const serverUrl = process.env.REACT_APP_SERVER_URL;

function CreateForm() {

  let [ items, setItems ] = useState([]);
  let [ display, setDisplay ] = useState('grid');
  
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  let history = useHistory();

  useEffect(() => {
    loader.get('/user/list', res => {
        setItems(res.items);
    }, setLoading);
  }, []);

  let updateItem = (data) => {
    const url = '/user/update';
    setSuccess(false);
    loader.post(url, data, res => {
        setSuccess(true);
    }, setLoading);
  };

  let deleteItem = (id) => {
    const url = '/user/delete/' + id;
    setSuccess(false);
    loader.get(url, res => {
        if (!res.success) return;
        setSuccess(true);
        items = items.filter(i => i.id !== id);
        setItems(items);
    }, setLoading);
  };

  let toggleActive = (users, id) => {
    let items = Object.assign([], users);
    items = items.map(item => {
        if (item.id === id) {
            item.active = !item.active;
            updateItem({ id: item.id, active: item.active });
        }
        return item;
    });
    setItems(items);
  }

  let recordOptions = (item, column) => {
    const logged = Session.getCookie();
    return <div>
        <Button
            onClick={e => history.push({ pathname: '/backoffice/users/form/' + item.id })} 
            type="button" 
            icon="pi pi-pencil" 
            className="p-button-info"
        />
        <Button
            onClick={e => deleteItem(item.id)} 
            type="button" 
            icon="pi pi-times" 
            className="p-button-danger"
            disabled={![logged.role1].includes('ADMINISTRATOR')}
        />
    </div>;
  }

  let recordActive = (item, column) => {
    return <div>
        <InputSwitch 
            checked={item.active} 
            onChange={(e) => toggleActive(items, item.id)} 
        />
    </div>;
  }

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/photo_placeholder.png';
  }

  let recordPhoto = (item, column) => {
    return (
        <img 
            src={getFileUrl(item.photo, 'users')} 
            alt="Fotografia"
            title="Fotografia"
            className="img-avatar-mini"
        />
    )
  }

  const recordLastLogin = (item) => {
      return item.last_login ?
        moment(item.last_login).format('YYYY-MM-DD')
        : '';
  }

  const mapItems = items.filter(i => i.location).map(i => ({
      id: i.id,
      label: i.username + ' (' + i.location.action + ')',
      lat: i.location.lat,
      lon: i.location.lon,
  }));

  const isMobile = window.innerWidth <= 768;
  if (loading) return <ProgressSpinner />;

  // Get max accounts
  let max = 5;
  if (hasMod(['T4CUSR20'])) max = 20;
  if (hasMod(['T4CUSR50'])) max = 50;
  if (hasMod(['T4CUSR100'])) max = 100;
  if (hasMod(['T4CUSR300'])) max = 300;
  return (
    <React.Fragment>
        <div className="card card-w-title">
            <h1>Utilizadores</h1>

            <Toolbar>
                <div className="p-toolbar-group-left">

                    { items.length < max &&
                        <Link to="/backoffice/users/form/0">
                            <Button label="Inserir Novo" />
                        </Link>
                    }
                    
                </div>
                <div className="p-toolbar-group-right">
                    <InputSwitch 
                        label="Grelha"
                        checked={display === 'grid'} 
                        onChange={(e) => setDisplay(display === 'grid' ? 'list' : 'grid')} 
                    />{' '}Grelha
                </div>
            </Toolbar>

            { success && (
                <div className="p-grid">
                    <div className="p-col-12">

                        <Message 
                            severity="success" 
                            text="O utilizador foi alterado com sucesso!">
                        </Message>
                        
                    </div>
                </div>
            ) }

            { display === 'grid' ? (
                <div className="p-grid">

                    { items.map(item => (
                        <div className="p-col-6 p-lg-2" key={item.id}>
                            <Card 
                                header={(
                                    <Link to={'/backoffice/users/form/' + item.id }>
                                        <img 
                                            src={getFileUrl(item.photo, 'users')} 
                                            alt="Fotografia"
                                            title="Fotografia"
                                            className="img-avatar"
                                        />
                                    </Link>
                                )}>
                                { item.firstname } { item.lastname }
                            </Card>
                        </div>
                    )) }
                    
                </div>
            ) : (
                <div className="p-grid">
                    <div className="p-col-12">

                    <DataTable value={items}>
                        <Column 
                            header="Fotografia"
                            body={recordPhoto}
                        />
                        <Column 
                            header="Nome de Utilizador"
                            field="username"
                            sortable={true}
                            filter={true}
                            filterMatchMode="contains" 
                        />

                        { !isMobile && 
                        <Column 
                            header="E-mail"
                            field="email"
                            sortable={true}
                            filter={true}
                            filterMatchMode="contains"
                        /> }
                        
                        { !isMobile && (<Column 
                            header="Último Login"
                            body={recordLastLogin}
                            sortable={true}
                            filter={true}
                            filterMatchMode="contains"
                        /> ) }

                        { !isMobile && <Column body={recordActive} header="Activo" /> }
                        
                        <Column 
                            header="Opções"
                            body={recordOptions}
                            style={{ textAlign: 'right' }}
                        />
                    </DataTable>
                        
                    </div>
                </div>
            ) }
            
        </div>

        { hasMod(['T4CLE1']) && (
            <div className="card card-w-title">
                <h2>Última Localização dos Colaboradores</h2>
                <Mapa items={mapItems} />
            </div>
        ) }

    </React.Fragment>
  );

}

export default CreateForm;
