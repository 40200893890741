import React, { useState, useEffect, useReducer } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import {InputSwitch} from 'primereact/inputswitch';
import {Dropdown} from 'primereact/dropdown';
import { Message } from 'primereact/message';
import {Editor} from 'primereact/editor';
import { Dialog } from 'primereact/dialog';
import { useParams } from "react-router-dom";
import loader from '../loader';
import slugify from '../topics/slugify';
import typeOptions from './types.json';
import displayOptions from './displays.json';
import viewOptions from './views.json';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FileUpload } from 'primereact/fileupload';

const serverUrl = process.env.REACT_APP_SERVER_URL;

const initialState = {
    label: '',
    description: '',
    active: true,
    systemid: '',
    type: typeOptions[0].value,
    options: '',
    options_topic: null,
    display: displayOptions[0].value,
    display_view: viewOptions[0].value,
    health_check: false,
    db_table: '',
    db_table_label: '',
    db_table_value: '',
    db_table_sync: false
}

const columnsTypesOptions = [
    { label: 'Texto', value: 'string', key: 'string' },
    { label: 'Número', value: 'integer', key: 'integer' },
    { label: 'Decimal', value: 'decimal', key: 'decimal' },
];

function isConfigComplete(tableConfig) {
    return !!tableConfig.name
        && !!tableConfig.primary
        && !! tableConfig.filename;
}

function reducer(state, action) {
    switch (action.type) {
      case 'update':
        if (action.field === 'label') {
            return {
                ...state,
                label: action.value,
                systemid: slugify(action.value)
            }
        }
        if (action.field === 'display') {
            return {
                ...state,
                display: action.value,
                type: action.defaultType
            }
        }
        return {
            ...state,
            [action.field]: action.value
        };
      case 'update_all':
        return {
            ...action.value
        }
      default:
        throw new Error();
    }
}

function FieldsForm() {

  let [ field, dispatch ] = useReducer(reducer, initialState)
  let [ topics, setTopics ] = useState([]);
  let [ tables, setTables ] = useState([]);
  let [ tableLabels, setTableLabels ] = useState([]);
  let [ tableValues, setTableValues ] = useState([]);
  
  let [ errors, setErrors ] = useState({});
  let [ success, setSuccess ] = useState('');
  let [ modalShow, setModalShow ] = useState(false);
  let [ tableConfig, setTableConfig ] = useState({ name: '', filename: '', columns: [] });
  let [ loading, setLoading ] = useState(false);

  // Upload
  const onUploadFile = (e) => {
    if (e.files.length === 0) return;
    const formData  = new FormData();
    formData.append('folder', 'diary');
    formData.append('upload', e.files[0]);
    const url = '/upload';
    loader.upload(url, formData, res => {
      if (!res.filename) return;
      tableConfig.filename = res.filename;
      setTableConfig(Object.assign({}, tableConfig));
    }, setLoading);
  }

  let importTable = () => {
    loader.post('/field/datatable/csv', tableConfig, res => {
        if (res.errors) return setErrors(res.errors);
    }, setLoading);
  }

  let getUserTables = () => {
    loader.post('/field/datatable/items', {}, res => {        
        if (res.errors) return setErrors(res.errors);
        setTables(res.items.map(i => ({ label: i.table_name, value: i.table_name, key: i.table_name })))
    }, setLoading);
  }

  let getUserTableFields = () => {
    if (!field.db_table) return;
    loader.post('/field/datatable/fields', { table_name: field.db_table }, res => {        
        if (res.errors) return setErrors(res.errors);
        const items = res.items.map(i => ({ label: i.column_name, value: i.column_name, key: i.column_name }));
        setTableLabels(items);
        setTableValues(items);
    }, setLoading);
  }

  // Load user tables
  useEffect(() => {
    getUserTables();
  }, [field.display]);

  // Load table fields
  useEffect(() => {
    getUserTableFields();
  }, [field.display, field.db_table]);

  // Load topics
  useEffect(() => {
    loader.get('/topic/list', res => {
        setTopics(res.items);
    }, setLoading);
  }, []);

  let urlParams = useParams();
  useEffect(() => {
    if (parseInt(urlParams.id, 10) < 1) {
        return dispatch({ type: 'update_all', value: initialState });
    }
    loader.get('/field/item/' + urlParams.id, res => {
        const value = { 
            ...res.item 
        };
        dispatch({ type: 'update_all', value });
    }, setLoading);
  }, [urlParams.id]);

  let register = () => {
    let data = {
        ...field,
        options: field.options ? field.options : '' 
    };
    let url = '/field/create';
    if (field.id) url = '/field/update';
    setSuccess(false);
    loader.post(url, data, res => {        
        if (res.errors) return setErrors(res.errors);
        dispatch({ type: 'update', field: 'id', value: res.id });
        setSuccess(true);
    }, setLoading);
  };

  let isDataInvalid = () => {
      return loading
        || field.label.length < 3
        || field.systemid.length < 3;
  }

  let displayErrors = (errors, key) => !!errors[key] && errors[key].map(msg =>
    <Message severity="error" text={msg}></Message>
  )

  // Get topic options
  const topicOptions = topics.map(opt => ({
    label: opt.title,
    value: opt.id,
    key: opt.id
  }));
  topicOptions.unshift({ value: '', label: 'Não aplicável' });

  const multiOptions = [
    'select_one', 
    'select_one_bar', 
    'select_one_dropdown', 
    'select_multi', 
    'multi'
  ];

  if (loading) return <ProgressSpinner />;
  const title = parseInt(field.id, 10) ? "Alterar Campo" : "Criar Novo Campo";
  const field_options = (field.options ? String(field.options).split('|') : []).filter(i => !!i);
  return (
    <React.Fragment>
        <div className="card card-w-title main-panel-small">
            <h1>{ title }</h1>

            { success && (
                <div className="p-grid">
                    <div className="p-col-12">

                        <Message 
                            severity="success" 
                            text="O campo foi guardado com sucesso!">
                        </Message>
                        
                    </div>
                </div>
            ) }

            <Button 
                onClick={register} 
                label="Guardar"
                disabled={isDataInvalid()}
                style={{ float: 'right'}}
            />

            <p className="form-info">
                <strong style={{ color: 'red' }}>*</strong> 
                {' '}Campos obrigatórios
            </p>

            <div className="p-grid">

                <div className="p-col-12 p-md-6">
                    <label className="field-required">Etiqueta</label>
                </div>
                <div className="p-col-12 p-md-6">
                    <InputText 
                        placeholder="Etiqueta"
                        value={field.label}
                        onChange={(e) => 
                            dispatch({ type: 'update', field: 'label', value: e.target.value })
                        }
                    />
                    { displayErrors(errors, 'label') }
                </div>

                <div className="p-col-12 p-md-6">
                    <label className="field-required">Nome de Sistema</label>
                </div>
                <div className="p-col-12 p-md-6">
                    <InputText 
                        placeholder="Nome de sistema"
                        value={field.systemid}
                        onChange={(e) => 
                            dispatch({ type: 'update', field: 'systemid', value: e.target.value })
                        }
                    />
                    { displayErrors(errors, 'systemid') }
                </div>

                <div className="p-col-12 p-md-6">
                    <label>Texto de Ajuda</label>
                </div>
                <div className="p-col-12 p-md-6">
                    <Editor 
                        style={{height:'100px'}} 
                        value={field.description}
                        onTextChange={(e) => 
                            dispatch({ type: 'update', field: 'description', value: e.htmlValue })
                        }
                    />
                </div>

                <div className="p-col-10 p-md-6">
                    <label>Activo</label>
                </div>
                <div className="p-col-2 p-md-6">
                    <InputSwitch 
                        checked={field.active} 
                        onChange={(e) => 
                            dispatch({ type: 'update', field: 'active', value: !field.active })
                        } 
                    />
                </div>

                <div className="p-col-10 p-md-6">
                    <label>Parâmetro de Saúde</label>
                </div>
                <div className="p-col-2 p-md-6">
                    <InputSwitch 
                        checked={field.health_check} 
                        onChange={(e) => 
                            dispatch({ type: 'update', field: 'health_check', value: !field.health_check })
                        } 
                    />
                </div>

                <div className="p-col-12 p-md-6">
                    <label className="field-required">Interface de Input</label>
                </div>
                <div className="p-col-12 p-md-6">
                    <div className="p-inputgroup">
                        <Dropdown 
                            placeholder="Escolha uma opção"
                            value={field.display}
                            options={displayOptions} 
                            onChange={(e) => 
                                dispatch({
                                    type: 'update',
                                    field: 'display',
                                    value: e.value,
                                    defaultType: displayOptions.find(opt => opt.value === e.value).defaultType
                                })
                            }
                        />
                        { field.display === 'db_table' &&
                            <Button icon="pi pi-cog" onClick={e => setModalShow(true)} />
                        }
                    </div>
                </div>

                { field.display === 'db_table' &&
                    <React.Fragment>
                        <div className="p-col-12 p-md-6">
                            <label className="field-required">Tabela de Dados</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <Dropdown 
                                placeholder="Escolha uma opção"
                                value={field.db_table}
                                options={tables} 
                                onChange={(e) => 
                                    dispatch({
                                        type: 'update',
                                        field: 'db_table',
                                        value: e.value
                                    })
                                }
                            />
                        </div>
                        <div className="p-col-12 p-md-6">
                            <label className="field-required">Campo da Etiqueta</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <Dropdown 
                                placeholder="Escolha uma opção"
                                value={field.db_table_label}
                                options={tableLabels} 
                                onChange={(e) => 
                                    dispatch({
                                        type: 'update',
                                        field: 'db_table_label',
                                        value: e.value
                                    })
                                }
                            />
                        </div>
                        <div className="p-col-12 p-md-6">
                            <label className="field-required">Campo de Valor</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <Dropdown 
                                placeholder="Escolha uma opção"
                                value={field.db_table_value}
                                options={tableValues} 
                                onChange={(e) => 
                                    dispatch({
                                        type: 'update',
                                        field: 'db_table_value',
                                        value: e.value
                                    })
                                }
                            />
                        </div>
                        <div className="p-col-10 p-md-6">
                            <label>Atualiza Todo o Diário</label>
                        </div>
                        <div className="p-col-2 p-md-6">
                            <InputSwitch 
                                checked={field.db_table_sync} 
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'db_table_sync', value: !field.db_table_sync })
                                } 
                            />
                        </div>
                    </React.Fragment>
                }

                { multiOptions.includes(field.display) && (
                    <React.Fragment>
                        <div className="p-col-12 p-md-6">
                            <label>Lista de Opções</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            { field_options.map((item, i) => (
                                <React.Fragment>
                                    <div className="p-inputgroup">
                                        <InputText 
                                            value={item}
                                            onChange={e => {
                                                let items = Object.assign([], field_options);
                                                items[i] = e.target.value;
                                                dispatch({ type: 'update', field: 'options', value: items.join('|') })
                                            }}
                                        />
                                        <Button
                                            icon="pi pi-times" 
                                            className="p-button-danger"
                                            onClick={e => {
                                                let items = Object.assign([], field_options);
                                                items.splice(i, 1);
                                                dispatch({ type: 'update', field: 'options', value: items.join('|') })
                                            }}
                                        />
                                    </div>
                                </React.Fragment>
                            )) }
                            <InputText
                                defaultValue=""
                                placeholder="Inserir..."
                                onKeyUp={e => {
                                    if (e.keyCode === 13) {
                                        let items = Object.assign([], field_options);
                                        items.push(e.target.value);
                                        dispatch({ type: 'update', field: 'options', value: items.join('|') })
                                        e.target.value = '';
                                    }
                                }}
                            />
                        </div>
                    </React.Fragment>
                )}

                {/*
                <div className="p-col-12 p-md-6">
                    <label>Opções de Sub-tópicos</label>
                </div>
                <div className="p-col-12 p-md-6">
                    <Dropdown 
                        placeholder="Escolha um tópico"
                        value={field.options_topic}
                        options={topicOptions} 
                        onChange={(e) => 
                            dispatch({ type: 'update', field: 'options_topic', value: e.value })
                        }
                    />
                </div>
                */}

                <div className="p-col-12 p-md-6">
                    <label className="field-required">Interface de Visualização</label>
                </div>
                <div className="p-col-12 p-md-6">
                    <Dropdown 
                        placeholder="Escolha uma opção"
                        value={field.display_view}
                        options={viewOptions} 
                        onChange={(e) => 
                            dispatch({ type: 'update', field: 'display_view', value: e.value })
                        }
                    />
                </div>

                <div className="p-col-12 p-md-6">
                    <label>Tipo de Dados (opcional)</label>
                </div>
                <div className="p-col-12 p-md-6">
                    <Dropdown 
                        placeholder="Escolha um tipo"
                        value={field.type}
                        options={typeOptions} 
                        onChange={(e) => 
                            dispatch({ type: 'update', field: 'type', value: e.value })
                        }
                    />
                </div>

            </div>

        </div>

        <Dialog 
            header="Importar Tabela" visible={modalShow} style={{width: '50vw'}} modal onHide={e => setModalShow(false)}>
            <form onSubmit={importTable}>
                <div className="p-grid">
                    <div className="p-col-6">Nome da Tabela</div>
                    <div className="p-col-6">
                        <InputText
                            placeholder="Etiqueta" 
                            type="text" 
                            value={tableConfig.name}
                            onChange={e => {
                                tableConfig.name = e.target.value;
                                setTableConfig(Object.assign({}, tableConfig));
                            }} 
                        />
                    </div>
                    <div className="p-col-6">Auto-incrementos?</div>
                    <div className="p-col-6">
                        <InputSwitch 
                            checked={!!tableConfig.increments} 
                            onChange={(e) => {
                                tableConfig.increments = !tableConfig.increments;
                                setTableConfig(Object.assign({}, tableConfig));
                            }}
                        />
                    </div>
                    <div className="p-col-6">Campo ID</div>
                    <div className="p-col-6">
                        <InputText
                            type="text"
                            value={tableConfig.primary}
                            onChange={e => {
                                tableConfig.primary = e.target.value;
                                setTableConfig(Object.assign({}, tableConfig));
                            }}
                        />
                    </div>
                    <div className="p-col-6">Dados</div>
                    <div className="p-col-6">
                        <FileUpload
                            name="db_table"
                            mode="basic"
                            url={'/upload'}
                            accept="text/csv"
                            customUpload={true}
                            uploadHandler={onUploadFile}
                            auto={true}
                            disabled={loading}
                            chooseLabel={tableConfig.filename ? "Alterar" : "Escolher" }
                            onRemove={(e, file) => setTableConfig(Object.assign({}, { name: '', filename: '', columns: []}))}
                        />
                    </div>
                    
                    <div className="p-col-6">Campo</div>
                    <div className="p-col-5">Tipo</div>
                    <div className="p-col-1">X</div>

                    { tableConfig.columns.map((column, i) =>
                    <React.Fragment key={'column_'+i}>
                        <div className="p-col-6">
                            <InputText 
                                type="text" 
                                value={column.name} 
                                onChange={ e => {
                                    tableConfig.columns[i].name = e.target.value;
                                    setTableConfig(Object.assign({}, tableConfig));
                                }}
                            />
                        </div>
                        <div className="p-col-5">
                            <Dropdown
                                value={column.type}
                                options={columnsTypesOptions}
                                onChange={e => {
                                    tableConfig.columns[i].type = e.value;
                                    setTableConfig(Object.assign({}, tableConfig));
                                }}
                            />
                        </div>
                        <div className="p-col-1">
                            <Button 
                                icon="pi pi-trash"
                                onClick={e => {
                                    tableConfig.columns.splice(i, 1);
                                    setTableConfig(Object.assign({}, tableConfig));
                                }}
                            />
                        </div>    
                    </React.Fragment>
                    )}

                    <div className="p-col-6">
                        <Button 
                            label="Adicionar campo"
                            className="p-button-secondary"
                            type="button"
                            onClick={e => {
                                tableConfig.columns.push({ name: '', type: 'string' });
                                setTableConfig(Object.assign({}, tableConfig));
                            }}
                        />
                    </div>
                    <div className="p-col-6">
                        <Button label="Importar" type="submit" disabled={!isConfigComplete(tableConfig)} />
                    </div>
                </div>
            </form>
        </Dialog>


    </React.Fragment>
  );

}

export default FieldsForm;
