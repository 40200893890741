import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toolbar} from 'primereact/toolbar';
import { useHistory, Link } from "react-router-dom";
import moment from 'moment';
import loader from '../loader';
import typeOptions from '../clientout/types.json';
import { ProgressSpinner } from 'primereact/progressspinner';
import Session from '../auth/Session';
import timeSetup from '../timeSetup';

const getItems = (res, setItems) => {
    let titems = res.items;
    titems = titems.map(i => ({
        ...i,
        start: timeSetup.from(i.start).format('YYYY-MM-DD HH:mm:ss'),
        end: timeSetup.from(i.end).format('YYYY-MM-DD HH:mm:ss')    
    }))
    setItems(titems);
}

function ClientOutList({ client }) {

  let [ items, setItems ] = useState([]);
  
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  let history = useHistory();

  // Load client
  let client_id = client.id;
  useEffect(() => {
    loader.get('/clientout/client/' + client_id, res => {
        if (!res.success) return;
        getItems(res, setItems);
    }, setLoading)
  }, [client_id]);

  let deleteItem = (id) => {
    const url = '/clientout/delete/' + id;
    setSuccess(false);
    loader.get(url, res => {
        if (!res.success) return;
        setSuccess(true);
        items = items.filter(i => i.id !== id);
        setItems(items);
    }, setLoading);
  };

  let recordOptions = (item, column) => {
    const logged = Session.getCookie();
    const isMobile = window.innerWidth <= 768;
    return <div style={{ textAlign: !isMobile ? 'right' : 'left' }}>
        <Button
            onClick={e => history.push({ pathname: '/client/out/form/' + item.id })} 
            type="button" 
            icon="pi pi-pencil" 
            className="p-button-info"
        />
        <Button
            onClick={e => deleteItem(item.id)} 
            type="button" 
            icon="pi pi-times" 
            className="p-button-danger"
            disabled={![logged.role1].includes('ADMINISTRATOR')}
        />
    </div>;
  }

  const getOptionLabel = (options, value) => {
    const item = options.find(opt => opt.value === value);
    return item ? item.label : value;
  }

  if (!client || loading) return <ProgressSpinner />;
  let createUrl = "/client/out/form/0" + (client_id ? "/" + client_id : "");
  return (
    <React.Fragment>
        <Toolbar>
            <div className="p-toolbar-group-left">
                
            </div>
            <div className="p-toolbar-group-right">
                <Link to={createUrl}>
                    <Button label="Registar Ausência" />
                </Link>
            </div>
        </Toolbar>
        
        <div className="p-grid">
            <div className="p-col-12">

            <DataTable value={items} responsive={true}>
                { !client_id && (
                    <Column 
                        header="Cliente"
                        field="firstname"  
                        sortable={true}
                        filter={true}
                        filterMatchMode="contains"
                    />
                ) }
                
                <Column 
                    header="Tipo" 
                    field="type"
                    sortable={true}
                    filter={true}
                    filterMatchMode="contains"
                    body={i => getOptionLabel(typeOptions, i.type)} 
                />
                
                <Column 
                    header="Inicio" 
                    filter={true}
                    filterMatchMode="contains"
                    body={item => item.start ? moment(item.start).format('YYYY-MM-DD HH:mm') : ''}
                />
                <Column 
                    header="Fim" 
                    filter={true}
                    filterMatchMode="contains"
                    body={item => item.end ? moment(item.end).format('YYYY-MM-DD HH:mm') : ''}
                />

                <Column body={recordOptions} header="Opções" />
            </DataTable>
                
            </div>
        </div>
            
    </React.Fragment>
  );

}

export default ClientOutList;
