import React from 'react';
import OutOfServiceList from './OutOfServiceList';
import Session from '../auth/Session';
import { Button } from 'primereact/button';
import { useHistory } from "react-router-dom";

function OutOfServiceListRoute() {

  //let urlParams = useParams();
  let logged = Session.getCookie();
  let history = useHistory();

  return (
    <React.Fragment>
      <div className="card card-w-title">
        <h1>As Minhas Ausências</h1>
      
        <Button
          style={{ float: 'right' }} 
          onClick={e => history.goBack()} 
          label="Voltar"
          className="p-button-danger"
        />

        <OutOfServiceList user_id={logged.id} />
      </div>
    </React.Fragment>
  );

}

export default OutOfServiceListRoute;
