import React, { useState, useEffect } from 'react';
import loader from '../loader';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import {Chips} from 'primereact/chips';
import Session from '../auth/Session';

const serverUrl = process.env.REACT_APP_SERVER_URL || '';

function ClientsDocs({ client }) {

  let defaultDocument = { client_id: client.id, category: 'documento' };

  let [ items, setItems ] = useState([]);
  let [ document, setDocument ] = useState(defaultDocument);
  let [ errors, setErrors ] = useState({});
  let [ loading, setLoading ] = useState(false);

  const reload = (id) => {
    if (!parseInt(client.id, 10)) return;
    loader.get('/document/list?client_id=' + client.id, res => {
        setItems(res.items);
    }, setLoading);
  }

  // Load docs
  useEffect(() => {
    reload(client.id);
  }, [client.id]);

  // Save docs
  let save = () => {
    let url = '/document/create';
    if (document.id) url = '/document/update';
    loader.post(url, document, res => {
        if (!res.success) return;
        setDocument(defaultDocument);
        reload(client.id);
    }, setLoading);
  };

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/photo_placeholder.png';
  }

  // Upload
  const onUpload = (e) => {
    if (e.files.length === 0) return;
    const formData  = new FormData();
    formData.append('folder', 'docs');
    formData.append('upload', e.files[0]);
    const url = '/upload';
    loader.upload(url, formData, res => {
      if (!res.filename) return;
      setDocument({ ...document, filename: res.filename });  
    }, setLoading);
  }

  let deleteItem = (id) => {
    const url = '/document/delete/' + id;
    loader.get(url, res => {
        if (!res.success) return;
        items = items.filter(i => i.id !== id);
        setItems(items);
    }, setLoading);
  };

  const onRemoveFile = (e, file) => {
    setDocument({ ...document, filename: '' });
  }

  let displayErrors = (errors, key) => !!errors[key] && errors[key].map(msg =>
    <Message severity="error" text={msg}></Message>
  )

  const isMobile = window.innerWidth <= 768;
  if (!client || loading) return<ProgressSpinner />;
  const logged = Session.getCookie();
  const isSuperAdmin = ['ADMINISTRATOR', 'ORG_ADMIN'].includes(logged.role1);
  const isAnalyst = [logged.role1, logged.role2].includes('ANALYST');
  const isAdmin = ['ADMIN'].includes(logged.role1);
  return (
    <React.Fragment>

        <div className="card card-w-title">
            <h1>Documentos</h1>
            
            <div className="p-grid">

                <div className="p-col-12 p-lg-4">
                    <InputText
                        placeholder="Etiqueta"
                        value={document.title}
                        onChange={(e) => 
                            setDocument({ ...document, title: e.target.value })
                        }
                    />
                    { displayErrors(errors, 'title') }
                </div>

                <div className="p-col-12 p-lg-4">
                    <Chips
                        value={document.category.split('|').filter(i => !!i)} 
                        onChange={(e) => setDocument({ ...document, category: e.value.join('|')})}>
                    </Chips>
                </div>
                
                <div className="p-col-12 p-lg-2">
                    <FileUpload
                        name="filename"
                        mode="basic"
                        url={'/upload'}
                        customUpload={true}
                        uploadHandler={onUpload}
                        auto={true}
                        chooseLabel={document.filename ? "Alterar" : "Ficheiro" }
                        onRemove={onRemoveFile}
                    />
                </div>

                <div className="p-col-12 p-lg-2" style={{ textAlign: 'right'}}>
                    <Button label="Guardar" onClick={e => save() } />
                </div>
            </div>

            <DataTable value={items}>
                <Column
                    header="Etiqueta"
                    field="title"
                    body={item => (
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={getFileUrl(item.filename, 'docs')} 
                            alt="Documento"
                            title="Documento"
                            className="img-avatar"
                        ><i className="pi pi-download"></i> {item.title}
                        </a>
                    )}
                />
                { !isMobile && (
                    <Column
                        header="Etiqueta"
                        field="category"
                        body={item => (
                            <Chips value={item.category.split('|').filter(i => !!i)} />
                        )}
                    />
                ) }
                <Column
                    header="Opções"
                    field="filename"
                    style={{ textAlign: 'right'}}
                    body={item => (
                        <Button
                            disabled={!isSuperAdmin && !isAnalyst && !isAdmin}
                            onClick={e => deleteItem(item.id)} 
                            type="button" 
                            icon="pi pi-times" 
                            className="p-button-danger"
                        />
                    )}
                />

            </DataTable>

        </div>

    </React.Fragment>
  );

}

export default ClientsDocs;
