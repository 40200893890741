import React from 'react';
import { useParams, useLocation } from "react-router-dom";
import EntitiesForm from './EntitiesForm';
import { getParams } from '../helpers';

function EntitiesFormRoute() {

  // Load diary
  let urlParams = useParams();
  let filter = getParams(useLocation().search);

  const title = parseInt(urlParams.id, 10) ? "Alterar Contacto" : "Adicionar Contacto";
  return (
    <React.Fragment>
      <div className="card card-w-title main-panel-small">
          <h1>{title}</h1>

          <EntitiesForm id={urlParams.id} filter={filter} />
      </div>
    </React.Fragment>
  );

}

export default EntitiesFormRoute;
