import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toolbar} from 'primereact/toolbar';
import { useHistory, Link } from "react-router-dom";
import loader from '../loader';
import Session from '../auth/Session';
import { ProgressSpinner } from 'primereact/progressspinner';

function FeaturesList() {

  let [ items, setItems ] = useState([]);
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  let history = useHistory();

  useEffect(() => {
    loader.get('/feature/list', res => {
        setItems(res.items);
    }, setLoading);
  }, []);

  let deleteItem = (id) => {
    const url = '/feature/delete/' + id;
    setSuccess(false);
    loader.get(url, res => {
        setSuccess(true);
        items = items.filter(i => i.id !== id);
        setItems(items);
    }, setLoading);
  };

  let recordOptions = (item, column) => {
    const logged = Session.getCookie();
    return <div style={{ textAlign: 'right'}}>
        <Button
            onClick={e => history.push({ pathname: '/backoffice/features/form/' + item.id })} 
            type="button" 
            icon="pi pi-pencil" 
            className="p-button-info"
        />
        <Button
            onClick={e => deleteItem(item.id)} 
            type="button" 
            icon="pi pi-times" 
            className="p-button-danger"
            disabled={![logged.role1].includes('ADMINISTRATOR')}
        />
    </div>;
  }

  if (loading) return <ProgressSpinner />;
  return (
    <React.Fragment>
        <div className="card card-w-title">
            <h1>Items de Planos</h1>

            <Toolbar>
                <div className="p-toolbar-group-left">
                    <Link to="/backoffice/features/form/0">
                        <Button label="Adicionar Item" />
                    </Link>
                </div>
                <div className="p-toolbar-group-right">
                    
                </div>
            </Toolbar>

            { success && (
                <div className="p-grid">
                    <div className="p-col-12">

                        <Message 
                            severity="success" 
                            text="O item foi alterado com sucesso!">
                        </Message>
                        
                    </div>
                </div>
            ) }

            
            <div className="p-grid">
                <div className="p-col-12">

                <DataTable value={items} responsive={false}>
                    <Column field="title" header="Etiqueta" />
                    <Column body={recordOptions} header="Opções" />
                </DataTable>
                    
                </div>
            </div>
            
        </div>
    </React.Fragment>
  );

}

export default FeaturesList;
