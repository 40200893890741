import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import Session from '../auth/Session';
import UnAuthorized from '../auth/UnAuthorized';
import DiaryView from './DiaryView';
import loader from '../loader';
import { ProgressSpinner } from 'primereact/progressspinner';

function DiaryViewRoute() {

  // Get params
  let urlParams = useParams();
  let [ diary, setDiary ] = useState(null);
  let [ loading, setLoading ] = useState(true);
  let logged = Session.getCookie();

  // Load item metadata
  let id = urlParams.id;
  useEffect(() => {
    if (!parseInt(id, 10)) return;
    loader.get('/diary/metadata/' + id, res => {
        setDiary(res.item);
    }, setLoading);
  }, [id]);

  if (loading) return <ProgressSpinner />;

  // Check permissions
  const allowed = Session.canAccessDiary(logged, diary); 
  if (!allowed.result) {
    return <div className="main-panel-small"><UnAuthorized reasons={allowed.reasons || []} /></div>;
  }

  return (
    <div className="main-panel-small">
      <DiaryView id={urlParams.id} />
    </div>
  );

}

export default DiaryViewRoute;
