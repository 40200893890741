import moment from "moment";
import 'moment/locale/pt';
moment.locale('pt');
const offset = moment().utcOffset();
const serverOffset = parseInt(process.env.REACT_APP_SERVER_UTC_OFFSET, 10);
console.log('client server offsets', offset, serverOffset);

function to(time) {
    const ttime = moment(time);
    let result = ttime.clone().subtract(offset, 'minutes').add(serverOffset, 'minutes');
    //console.log('to', time, ttime.format('YYYY-MM-DD HH:mm:ss'), offset, serverOffset, result.format('YYYY-MM-DD HH:mm:ss'));
    return result;
}

function from(time) {
    //console.log(moment.parseZone(time).format('YYYY-MM-DD HH:mm:ss'));
    const ttime = moment.parseZone(time);
    let result = moment(ttime).subtract(serverOffset, 'minutes').add(offset, 'minutes');
    //console.log('from', time, ttime.format('YYYY-MM-DD HH:mm:ss'), offset, serverOffset, result.format('YYYY-MM-DD HH:mm:ss'));
    return result;
}

function enumerateDaysBetweenDates(startDate, endDate) {
    var dates = [];

    var currDate = moment(startDate).startOf('day');
    var lastDate = moment(endDate).startOf('day');
    if (currDate.format('YYYY-MM-DD') === currDate.format('YYYY-MM-DD')) return [currDate.format('YYYY-MM-DD')];
    if (currDate.isAfter(lastDate)) return [];

    while(currDate.add(1, 'days').diff(lastDate) < 0) {
        dates.push(currDate.clone().format('YYYY-MM-DD'));
    }

    return dates;
}

export default { from, to, enumerateDaysBetweenDates };