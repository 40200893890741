import React from 'react';
import {TabMenu} from 'primereact/tabmenu';
import { useHistory } from "react-router-dom";

function ClientsMenu({ activeMenu }) {
    
    let history = useHistory();
    
    const items = [
        {id: "view", label: 'Geral', icon: 'pi pi-fw pi-home', url: '#/client/info/view'}
    ];

    items.push({ id: "form", label: 'Ficha', icon: 'pi pi-fw pi-pencil', url: '#/client/info/form'});
    
    items.push({id: "plans", label: 'Plano Individual', icon: 'pi pi-fw pi-calendar', url: '#/client/info/plans'});
    items.push({id: "therapy", label: 'Terapêutica', icon: 'pi pi-fw pi-heart', url: '#/client/info/therapy'});
    items.push({id: "history", label: 'Histórico', icon: 'pi pi-fw pi-list', url: '#/client/info/history'});

    items.push({id: "entities", label: 'Contactos', icon: 'pi pi-fw pi-user', url: '#/client/info/entities'});
    items.push({id: "location", label: 'Localização', icon: 'pi pi-fw pi-map-marker', url: '#/client/info/location'});

    items.push({id: "out", label: 'Ausências', icon: 'pi pi-fw pi-sign-out', url: '#/client/info/out'});
    items.push({id: "docs", label: 'Documentos', icon: 'pi pi-fw pi-paperclip', url: '#/client/info/docs'});

    items.push({id: "graphs", label: 'Gráficos', icon: 'pi pi-fw pi-chart-bar', url: '#/client/info/graphs'});
    items.push({id: "report", label: 'Relatório', icon: 'pi pi-fw pi-file-pdf', url: '#/client/info/report'});

    let activeItem = items.find(i => i.id === activeMenu);
    return (
        <React.Fragment>
            <TabMenu id="clientmenu" 
                model={items} 
                activeItem={activeItem}
                onTabChange={(e) => history.push({ pathname: e.value.url })}
            />
        </React.Fragment>
    );
}

export default ClientsMenu;