import React from 'react';
import BusinessList from './BusinessList';

function BusinessListRoute() {

  return (
    <React.Fragment>
      <div className="card card-w-title">
        <h1>Produtos</h1>
        
        <BusinessList />
      </div>
    </React.Fragment>
  );

}

export default BusinessListRoute;
