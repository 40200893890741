import React from 'react';
import PlansList from './PlansList';
import { useParams } from "react-router-dom";

function PlansListRoute() {

  let urlParams = useParams();

  return (
    <React.Fragment>
        <div className="card card-w-title">
          <h1>Atividades</h1>

          <PlansList client_id={urlParams.id} />
        </div>
    </React.Fragment>
  );

}

export default PlansListRoute;
