import React from 'react';

class Mapa extends React.Component {

    constructor(props) {
        super(props);
        this.mymap = null;
        this.items = [];
    }

    componentDidMount() {
        const { items } = this.props;
        this.mymap = window.L.map('mapid')
            .setView([37.0202561, -7.9484004], 9);
        window.L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.mymap);
        items.forEach(item => {
            const popupOptions = { closeButton: false, autoClose: false, closeOnClick: false };
            this.items.push(window.L.popup(popupOptions)
                .setLatLng([item.lat, item.lon])
                .setContent(item.label)
                .addTo(this.mymap));
        });
    }

    componentDidUpdate() {
        const { items } = this.props;
        if (this.items.length) {
            this.items.forEach(item => {
                item.remove();
            });
        }
        items.forEach(item => {
            const popupOptions = { closeButton: false, autoClose: false, closeOnClick: false };
            this.items.push(window.L.popup(popupOptions)
                .setLatLng([item.lat, item.lon])
                .setContent(item.label)
                .addTo(this.mymap));
        });
    }

    render() {
        return (
            <div id="mapid"></div>
        )
    }
}

export default Mapa;