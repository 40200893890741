import React, { useState, useEffect } from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toolbar} from 'primereact/toolbar';
import {Calendar} from 'primereact/calendar';
import {Dropdown} from 'primereact/dropdown';
import loader from '../loader';
import moment from 'moment';
import { Button } from 'primereact/button';
import {Chart} from 'primereact/chart';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputSwitch } from 'primereact/inputswitch';
import Session from '../auth/Session';
import date_pt from '../date_pt.json';

let dt = null;

function DynamicChart(props) {

  let logged = Session.getCookie();
  let client_id = logged.client_id;

  let [ items, setItems ] = useState([]);
  let [ fn, setFn ] = useState('count');
  let [ start, setStart ] = useState(moment().startOf('month').toDate());
  let [ end, setEnd ] = useState(moment().endOf('month').toDate());
  let [ activities, setActivities ] = useState([]);
  let [ activity_id, setActivity ] = useState();
  let [ topic, setTopic ] = useState();
  let [ fields, setFields ] = useState([]);
  let [ field_id, setField ] = useState();
  
  let [ display, setDisplay ] = useState('chart');
  let [ loading, setLoading ] = useState(false);

  useEffect(() => {
    loader.get('/topic/list/activities', res => {
        setActivities(res.items);
    }, setLoading);
  }, []);

  useEffect(() => {
    if (!parseInt(activity_id, 10)) return;
    loader.get('/topic/item/' + activity_id, res => {
        setTopic(res.item);
        setFields(res.item.fields);
    }, setLoading);
  }, [activity_id]);
  
  useEffect(() => {
    if (!parseInt(field_id, 10)) return;
    let tstart = moment(start).format('YYYY-MM-DD');
    let tend = moment(end).format('YYYY-MM-DD');
    let url = '/diary/report/dynamic/' + tstart + '/' + tend + '/' + activity_id + '/' + field_id;
    if (client_id) url += '/' + client_id;
    loader.get(url, res => {
        setItems(res.items);
        setFn(res.fn);
    }, setLoading);
  }, [start, end, field_id, client_id]);

  const activitiesOptions = activities.map(opt => ({
    label: opt.title,
    value: opt.id,
    key: opt.id
  }));

  const fieldOptions = fields.map(opt => ({
    label: opt.label,
    value: opt.id,
    key: opt.id
  }));

  const field = fields.find(f => String(f.id) === String(field_id));
  
  let valueLabel = 'Total';
  if (fn === 'sum') valueLabel = 'Valor';

  let chartType = 'line';
  if (field && ['string', 'text'].includes(field.type)) chartType = 'pie';

  let colors = '#42A5F5';
  let labels = [];
  if (field && ['string', 'text'].includes(field.type)) {
    colors = [];  
    labels = items.map(i => i.label);
    labels.forEach(l => colors.push('#'+Math.floor(Math.random()*16777215).toString(16)));
  }

  const data = {
    labels: items.map(i => i.label),
    datasets: [
        {
            label: (topic ? topic.title : '') + ' ' + (field ? field.label : ''),
            data: items.map(i => i.total),
            fill: false,
            backgroundColor: colors,
            borderColor: '#E0E0E0'
        }
    ]
  };

  if (loading) return <ProgressSpinner />;
  return (

    <div className="card card-w-title">
        <h1>Grafico Dinâmico</h1>

        <Toolbar>
            <div className="p-toolbar-group-left">
                <div className="p-grid">
                    <div className="p-col-12 p-lg-2">
                        <Calendar 
                            placeholder="Data de Inicio"
                            value={start} 
                            locale={date_pt}
                            dateFormat="yy-mm-dd"
                            yearNavigator={true}
                            yearRange="2020:2030"
                            onChange={(e) => setStart(e.value) }>
                        </Calendar>
                    </div>
                    <div className="p-col-12 p-lg-2">
                        <Calendar 
                            placeholder="Data de Fim"
                            value={end} 
                            locale={date_pt}
                            dateFormat="yy-mm-dd"
                            yearNavigator={true}
                            yearRange="2020:2030"
                            onChange={(e) => setEnd(e.value) }>
                        </Calendar>
                    </div>
                    <div className="p-col-12 p-lg-2">
                        <Dropdown
                            placeholder="Escolha o tipo"
                            value={activity_id || null}
                            options={activitiesOptions}
                            onChange={(e) => setActivity(e.value) }
                        />
                    </div>
                    <div className="p-col-12 p-lg-2">
                        <Dropdown
                            placeholder="Escolha o campo"
                            value={field_id || null}
                            options={fieldOptions}
                            onChange={(e) => setField(e.value) }
                        />
                    </div>
                </div>
            </div>

            { !!items.length && (
                <div className="p-toolbar-group-right">
                    <InputSwitch
                        id="displayToggle"
                        style={{ marginBottom: '-7px' }}
                        label="Grelha"
                        checked={display === 'table'} 
                        onChange={(e) => setDisplay(display === 'chart' ? 'table' : 'chart')} 
                    />{' '}<label htmlFor="displayToggle">Tabela</label>{' '}
                    { display === 'table' && (
                        <Button
                            type="button" 
                            icon="pi pi-external-link" 
                            iconPos="left" 
                            label="CSV" 
                            onClick={e => dt.exportCSV()}
                        />
                    )}
                </div>
            )}
        </Toolbar>

        { items.length === 0 ? <p>Sem dados a apresentar</p> : (
            display === 'chart' ? 
                <Chart type={chartType} data={data} options={{ maintainAspectRatio: false }} />
                : (
                <DataTable 
                    value={items} 
                    ref={(el) => { dt = el; }}
                    style={{ textAlign: 'center' }}>
                    <Column header="Etiqueta" field="label" />
                    <Column header={valueLabel} field="total" />
                </DataTable>
                )
        ) }
        
    </div>
    
  );

}

export default DynamicChart;
