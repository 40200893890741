import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import {InputSwitch} from 'primereact/inputswitch';
import {SelectButton} from 'primereact/selectbutton';
import { Message } from 'primereact/message';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toolbar} from 'primereact/toolbar';
import {Card} from 'primereact/card';
import { useHistory, Link } from "react-router-dom";
import moment from 'moment';
import loader from '../loader';
import { ProgressSpinner } from 'primereact/progressspinner';
import Session from '../auth/Session';
import Mapa from './Mapa';

const serverUrl = process.env.REACT_APP_SERVER_URL;

function EntitiesList(props) {

  const { filter, addLabel } = props;

  let [ items, setItems ] = useState([]);
  let [ display, setDisplay ] = useState('grid');
  
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  let history = useHistory();

  useEffect(() => {
    let url = '/entity/list?';
    if (filter.client_id) url += '&client_id=' + filter.client_id;
    if (filter.user_id) url += '&user_id=' + filter.user_id;
    loader.get(url, res => {
        setItems(res.items);
    }, setLoading);
  }, []);

  const mapItems = items.filter(i => !!i.latitude).map(i => ({
    id: i.id,
    label: i.name,
    link: '/backoffice/entities/form/' + i.id,
    lat: i.latitude,
    lon: i.longitude,
    client_id: i.client_id,
    user_id: i.user_id
  }));

  const displayOptions = [
    { key: 'grid', name: 'Grelha', value: 'grid' },
    { key: 'list', name: 'Lista', value: 'list' },
    { key: 'map', name: 'Mapa', value: 'map' }
  ];

  let updateItem = (data) => {
    const url = '/entity/update';
    setLoading(true);
    setSuccess(false);
    loader.post(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then(res => res.json())
    .then(res => {
        setLoading(false);
        setSuccess(true);
    })
    .catch(err => {
        setLoading(false);
        console.log(err);
    });
  };

  let deleteItem = (id) => {
    const url = '/entity/delete/' + id;
    setSuccess(false);
    loader.get(url, res => {
        if (!res.success) return console.log(res);
        setSuccess(true);
        items = items.filter(i => i.id !== id);
        setItems(items);
    }, setLoading);
  };

  let toggleActive = (entitys, id) => {
    let items = Object.assign([], entitys);
    items = items.map(item => {
        if (item.id === id) {
            item.active = !item.active;
            updateItem({ id: item.id, active: item.active });
        }
        return item;
    });
    setItems(items);
  }

  let recordOptions = (item, column) => {
    const logged = Session.getCookie();
    return <div>
        <Button
            onClick={e => history.push({ pathname: '/backoffice/entities/form/' + item.id })} 
            type="button" 
            icon="pi pi-pencil" 
            className="p-button-info"
        />
        <Button
            onClick={e => deleteItem(item.id)} 
            type="button" 
            icon="pi pi-times" 
            className="p-button-danger"
            disabled={![logged.role1].includes('ADMINISTRATOR')}
        />
    </div>;
  }

  let recordActive = (item, column) => {
    return <div style={{ textAlign: 'center' }}>
        <InputSwitch 
            checked={item.active} 
            onChange={(e) => toggleActive(items, item.id)} 
        />
    </div>;
  }

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/photo_placeholder.png';
  }

  const recordLastLogin = (item) => {
      return item.updated_at ?
        moment(item.updated_at).format('YYYY-MM-DD HH:mm:ss')
        : '';
  }

  const getCreateUrl = () => {
      let url = "/backoffice/entities/form/0?";
      if (filter.client_id) url += '&client_id=' + filter.client_id;
      if (filter.user_id) url += '&user_id=' + filter.user_id;
      if (filter.name) url += '&name=' + filter.name;
      return url;
  }
  const isMobile = window.innerWidth <= 768;
  if (loading) return <ProgressSpinner />;
  return (
    <React.Fragment>

        <Toolbar>
                <div className="p-toolbar-group-left">
                    <Link to={getCreateUrl()}>
                        <Button label={ addLabel || "Adicionar" } />
                    </Link>
                </div>
                <div className="p-toolbar-group-right">
                    <SelectButton 
                        optionValue="value"
                        optionLabel="name"
                        value={display} 
                        options={displayOptions} 
                        onChange={(e) => setDisplay(e.value)} 
                    />
                </div>
            </Toolbar>

            { success && (
                <div className="p-grid">
                    <div className="p-col-12">

                        <Message 
                            severity="success" 
                            text="O contacto foi alterado com sucesso!">
                        </Message>
                        
                    </div>
                </div>
            ) }

            { display === 'grid' && (
                <div className="p-grid">

                    { items.map(item => (
                        <div className="p-col-6 p-lg-2" key={item.id}>
                            <Card
                                header={(
                                    <Link to={'/backoffice/entities/form/' + item.id }>
                                        <img 
                                            src={getFileUrl(item.clientphoto, 'clients')} 
                                            alt="Fotografia"
                                            title="Fotografia"
                                            className="img-avatar"
                                        />
                                    </Link>
                                )}>
                                <div><strong>
                                    { item.client_id ? item.clientname :
                                        ( item.user_id ? item.username
                                            : item.name)
                                    }
                                </strong></div>
                                <div><small className="subtitle"><em>{ item.relation }</em></small></div>
                                <div>{ item.value }</div>
                            </Card>
                        </div>
                    )) }
                    
                </div>
            ) }

            { display === 'list' && (
                <div className="p-grid">
                    <div className="p-col-12">

                    <DataTable value={items}>

                        <Column 
                            header="Nome"
                            field="name"
                            sortable={true}
                            filter={true}
                            filterMatchMode="contains"
                        />

                        { !isMobile && (<Column 
                            header="Tipo"
                            field="media"
                            sortable={true}
                            filter={true}
                            filterMatchMode="contains"
                        /> ) }

                        <Column 
                            header="Contacto"
                            field="value"
                            sortable={true}
                            filter={true}
                            filterMatchMode="contains"
                        />
                        
                        { !isMobile && (<Column 
                            header="Cliente Associado"
                            field="clientname"
                            sortable={true}
                            filter={true}
                            filterMatchMode="contains"
                        /> ) }

                        { !isMobile && (<Column 
                            header="Relação"
                            field="relation"
                            sortable={true}
                            filter={true}
                            filterMatchMode="contains"
                        /> ) }

                        { !isMobile && (<Column 
                            header="Utilizador Associado"
                            field="username"
                            sortable={true}
                            filter={true}
                            filterMatchMode="contains" 
                        /> ) }

                        { !isMobile && (<Column 
                            header="Última atualização"
                            body={recordLastLogin}
                            sortable={true}
                            filter={true}
                            filterMatchMode="contains"
                            style={{ textAlign: 'center' }}
                        />) }

                        { !isMobile && <Column body={recordActive} header="Activo" /> }
                        <Column 
                            header="Opções"
                            body={recordOptions}
                            style={{ textAlign: 'right' }}
                        />
                    </DataTable>
                        
                    </div>
                </div>
            ) }

            { display === 'map' && (
                <div style={{ paddingTop: '10px' }}>
                    <Mapa items={mapItems} />
                </div>
            ) }
            
    </React.Fragment>
  );

}

export default EntitiesList;
