import React, { useState, useEffect } from 'react';
import {Card} from 'primereact/card';
import { Button } from 'primereact/button';
import { Link, useHistory } from "react-router-dom";
import loader from '../loader';
import { ProgressSpinner } from 'primereact/progressspinner';

const serverUrl = process.env.REACT_APP_SERVER_URL;

function TopicList(props) {

  let { parent } = props;

  let [ items, setItems ] = useState([]);
  
  let [ loading, setLoading ] = useState(false);

  let history = useHistory();

  useEffect(() => {
    loader.get('/topic/list' + (parent ? '/' + parent.id : ''), res => {
        setItems(res.items);
    }, setLoading);
  }, [parent]);


  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/topic_placeholder.jpg';
  }

  if (loading) return <ProgressSpinner />;
  const title = parent ? "Sub Tópicos" : "Conteúdos";
  if (!items.length) return null;
  return (
    <div className="card card-w-title">
        <h1>
            { title }
            { !parent && (
                <Button
                    style={{ float: 'right' }} 
                    onClick={e => history.goBack()} 
                    label="Voltar"
                    className="p-button-danger"
                />
            ) }
        </h1>

        <div className="p-grid topics">

            { items.map(item => (
                <div className="p-col-12 p-lg-2" key={item.id}>
                    <Card 
                        header={(
                            <Link to={'/client/topics/view/' + item.id }>
                                <img 
                                    src={getFileUrl(item.image, 'topics')} 
                                    alt="Imagem"
                                    title="Imagem"
                                    className="img-avatar"
                                />
                            </Link>
                        )}>
                        { item.title }
                    </Card>
                </div>
            )) }
            
        </div>
                    
    </div>
  );

}

export default TopicList;
