import React from 'react';
import OutOfServiceList from './OutOfServiceList';
import { useParams } from "react-router-dom";

function OutOfServiceListRoute() {

  let urlParams = useParams();

  return (
    <React.Fragment>
        <div className="card card-w-title">
          <h1>Ausências / Faltas / Férias</h1>

          <OutOfServiceList user_id={urlParams.id} />
        </div>
    </React.Fragment>
  );

}

export default OutOfServiceListRoute;
