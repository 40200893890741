import React from 'react';
import ClientOutList from './ClientOutList';
import { useParams } from "react-router-dom";

function ClientOutListRoute() {

  let urlParams = useParams();

  return (
    <div className="card card-w-title">
      <h1>Ausências</h1>

      <ClientOutList client_id={urlParams.id} />
    </div>
  );

}

export default ClientOutListRoute;
