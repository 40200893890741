import React, { useState, useEffect } from 'react';
import loader from '../loader';
import { Chart } from 'primereact/chart';
import { ProgressSpinner } from 'primereact/progressspinner';

let goalRamp = ['FF0D0D', 'FF4E11', 'FF8E15', 'FAB733', 'ACB334', '69B34C', '50883A'].reverse();

function ClientsGoalsResume({ client, setClient }) {

  let [ goals, setGoals ] = useState([]);
  let [ goalsResults, setGoalsResults ] = useState({});
  
  let [ loading, setLoading ] = useState(false);

  // Load goals
  useEffect(() => {
    if (!parseInt(client.id, 10)) return;
    let url = '/goal/list/' + client.id;
    loader.get(url, res => {
        setGoals(res.items);

        // Load goals results
        let results = {};
        let done = 0;
        res.items.forEach(g => {
            loader.get('/goal/calculate/' + g.id, res2 => {
                results[String(res2.id)] = res2.result;
                done++;
                if (done >= res.items.length) setGoalsResults(results);
            });
        });

    }, setLoading);
  }, [client.id]);

  if (!client || loading) return <ProgressSpinner />;

  return (
    <React.Fragment>

      { goals.map(i => {
        if (!goalsResults[String(i.id)]) return null;
        let data = goalsResults[String(i.id)].map(r => Number(r.total));
        let rampIndex = data[0] > i.value ? 
            (data[0] - i.value) / (i.view_max - i.value) * goalRamp.length-1 
            : (i.value - data[0]) / (i.value - i.view_min) * goalRamp.length-1;
        return (
            <div>
                <h5>{i.title}</h5>
                <Chart
                    type="horizontalBar"
                    data={{
                        labels: [i.title],
                        datasets: [
                            {
                                label: 'Atual',
                                backgroundColor: '#FF0000',
                                data
                            },
                            {
                                label: 'Objectivo',
                                backgroundColor: i.color ? '#' + i.color : '#FF0000',
                                data: [i.value]
                            }
                        ]
                    }}
                    options={{
                        maintainAspectRatio: false,
                        title: i.title,
                        scales: {
                            xAxes: [{
                                ticks: {
                                    min: Number(i.view_min),
                                    max: Number(i.view_max),
                                    fontColor: '#495057'
                                }
                            }],
                            yAxes: [{
                                display: false
                            }]
                        }
                    }}
                    height={130}
                />
            </div>
          )
      })}

    </React.Fragment>
  );

}

export default ClientsGoalsResume;
