import React from 'react'
import { Link } from "react-router-dom"
import moment from 'moment'
moment.locale('pt')

export function DiaryItemList({ item, link }) {
  const isMobile = window.innerWidth <= 768;
  let date_parts = moment(item.datahora).format('ddd D MMMM HH:mm').split(' ');
  return (
    <Link to={link}>
      <div className="p-grid diary-entry">
        <div className="p-col-12 task">
          <div className="taskname">{ item.title }</div>

          { !isMobile && 
            <div className="subtitle"><i className="pi pi-folder-open"></i> <em>{ item.category }</em></div>
          }

        </div>

        {/* !isMobile && 
          <div className="p-col-12 user">
            <i className="pi pi-user"></i> <Link to={"/backoffice/users/form/" + item.user_id}>{ item.username }</Link>
          </div>
        */}

        <div className="p-col-4 time">
          <i className="pi pi-clock"></i> { date_parts[3] }
        </div>
        <div className="p-col-8 date" style={{ textAlign: 'right' }}>
        <i className="pi pi-calendar"></i>
          { date_parts[0] } { date_parts[1] } { date_parts[2] }
        </div>
      </div>
  </Link>
  )
}