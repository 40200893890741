import React, { useState, useEffect, useReducer } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import {InputSwitch} from 'primereact/inputswitch';
import { Message } from 'primereact/message';
import {FileUpload} from 'primereact/fileupload';
import { useParams } from "react-router-dom";
import loader from '../loader';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Editor } from 'primereact/editor';

const serverUrl = process.env.REACT_APP_SERVER_URL;

const initialState = {
    name: '',
    logo: '',
    active: true,
    agreement: '',
    background_img: ''
}

function reducer(state, action) {
    switch (action.type) {
      case 'update':
        return {
            ...state,
            [action.field]: action.value
        };
      case 'update_all':
        return {
            ...action.value
        }
      default:
        throw new Error();
    }
}

function OrganizationsForm() {

  let [ organization, dispatch ] = useReducer(reducer, initialState)
  
  let [ errors, setErrors ] = useState({});
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  let urlParams = useParams();
  
  useEffect(() => {
    if (!parseInt(urlParams.id, 10)) {
        return dispatch({ type: 'update_all', value: initialState });
    }
    loader.get('/organization/item/' + urlParams.id, res => {
        const value = { 
            ...res.item
        };
        dispatch({ type: 'update_all', value });
    }, setLoading);
  }, [urlParams.id]);

  let register = () => {
    let data = {
        ...organization
    };
    let url = '/organization/create';
    if (organization.id) url = '/organization/update';
    setSuccess(false);
    loader.post(url, data, res => {
        if (res.errors) return setErrors(res.errors);
        dispatch({ type: 'update', field: 'id', value: res.id });
        setSuccess(true);
    }, setLoading);
  };

  let isDataInvalid = () => {
      return loading
        || organization.name.length < 3
        || organization.logo.length < 3;
  }

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/logo_placeholder.png';
  }

  // Upload
  const onUpload = (e, cb) => {
    if (e.files.length === 0) return;
    setLoading(true);
    const formData  = new FormData();
    formData.append('folder', 'organizations');
    formData.append('upload', e.files[0]);
    const url = '/upload';
    loader.upload(url, formData, cb, setLoading);
  }

  const onRemoveAvatar = (e, file) => {
    dispatch({ type: 'update', field: 'logo', value: '' });
  }

  const onRemoveBgImage = (e, file) => {
    dispatch({ type: 'update', field: 'background_img', value: '' });
  }

  let displayErrors = (errors, key) => !!errors[key] && errors[key].map(msg =>
    <Message severity="error" text={msg}></Message>
  )

  if (loading) return <ProgressSpinner />;
  let title = organization.id ? 'Criar Nova Organização' : 'Atualizar Organização';
  return (
    <React.Fragment>
        <div className="card card-w-title main-panel-small">
            <h1>{ title }</h1>

            { success && (
                <div className="p-grid">
                    <div className="p-col-12">

                        <Message 
                            severity="success" 
                            text="O registo foi concluido com sucesso!">
                        </Message>
                        
                    </div>
                </div>
            ) }

            <Button 
                onClick={register} 
                label="Guardar"
                disabled={isDataInvalid()}
                style={{ float: 'right' }}
            />
            
            <p className="form-info">
                <strong style={{ color: 'red' }}>*</strong> 
                {' '}Campos obrigatórios
            </p>            

            <div className="p-grid">

                <div className="p-col-12 p-md-6">
                    <label className="field-required">Nome</label>
                </div>
                <div className="p-col-12 p-md-6">
                    <InputText 
                        placeholder="Nome"
                        value={organization.name}
                        onChange={(e) => 
                            dispatch({ type: 'update', field: 'name', value: e.target.value })
                        }
                    />
                    { displayErrors(errors, 'name') }
                </div>

                <div className="p-col-10 p-md-6">
                    <label>Activo</label>
                </div>
                <div className="p-col-2 p-md-6">
                    <InputSwitch 
                        checked={organization.active} 
                        onChange={(e) => 
                            dispatch({ type: 'update', field: 'active', value: !organization.active })
                        } 
                    />
                </div>

                <div className="p-col-12 p-md-6">
                    <label className="field-required">Logotipo</label>
                </div>
                <div className="p-col-12 p-md-6">
                  { !!organization.logo && (
                    <img 
                        src={getFileUrl(organization.logo, 'organizations')} 
                        alt="Logotipo"
                        title="Logotipo"
                        className="img-avatar"
                    />
                  ) }
                  <FileUpload 
                    name="logo"
                    mode="basic"
                    url={'/upload'}
                    customUpload={true}
                    uploadHandler={e => onUpload(e, res => {
                        if (!res.filename) return;
                        dispatch({ type: 'update', field: 'logo', value: res.filename });  
                    })}
                    auto={true}
                    chooseLabel={organization.logo ? "Alterar" : "Escolher" }
                    onRemove={onRemoveAvatar}
                  />
                </div>

                <div className="p-col-12 p-md-6">
                    <label className="field-required">Imagem de fundo</label>
                </div>
                <div className="p-col-12 p-md-6">
                  { !!organization.background_img && (
                    <img 
                        src={getFileUrl(organization.background_img, 'organizations')} 
                        alt="Imagem de fundo"
                        title="Imagem de fundo"
                        className="img-avatar"
                    />
                  ) }
                  <FileUpload 
                    name="logo"
                    mode="basic"
                    url={'/upload'}
                    customUpload={true}
                    uploadHandler={e => onUpload(e, res => {
                        if (!res.filename) return;
                        dispatch({ type: 'update', field: 'background_img', value: res.filename });  
                    })}
                    auto={true}
                    chooseLabel={organization.logo ? "Alterar" : "Escolher" }
                    onRemove={onRemoveBgImage}
                  />
                </div>

                <div className="p-col-12">
                    <Editor 
                        style={{height: organization.agreement ? '197px' : '320px'}} 
                        value={organization.agreement}
                        onTextChange={(e) => 
                            dispatch({ type: 'update', field: 'agreement', value: e.htmlValue })
                        }
                    />
                </div>

            </div>

        </div>
    </React.Fragment>
  );

}

export default OrganizationsForm;
