import React, { useState, useEffect, useReducer } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import {InputSwitch} from 'primereact/inputswitch';
import {Dropdown} from 'primereact/dropdown';
import {Checkbox} from 'primereact/checkbox';
import { Message } from 'primereact/message';
import { Calendar } from 'primereact/calendar';
import {Editor} from 'primereact/editor';
import {PickList} from 'primereact/picklist';
import moment from 'moment';
import loader from '../loader';
import Session from '../auth/Session';
import dailyOptions from './daily.json';
import weeklyOptions from './weekly_days.json';
import monthlyOptions from './monthly.json';
import durationOptions from './duration.json';
import date_pt from './date_pt.json';
import { ColorPicker } from 'primereact/colorpicker';
import { ProgressSpinner } from 'primereact/progressspinner';
import timeSetup from '../timeSetup';
import { hasMod } from '../users/Permissions';

function Form(props) {
  const { id, client_id, therapy } = props;

  let logged = Session.getCookie();
  const owner = Session.getCookie();
  const initialState = {
    title: '',
    description: '',
    active: true,
    therapy: therapy ? true : false,
    owner_id: logged.id,
    topic_id: null,
    freq_month: '*',
    freq_week: '1,2,3,4,5,6,7',
    freq_day: '*',
    day_hourmin: moment().startOf('day').add(8, 'hour').format('YYYY-MM-DD HH:mm:ss'),
    start_date: moment().startOf('day').add(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
    end_date: null,
    client_id: parseInt(client_id, 10) > 0 ? client_id : null,
    duration: 60,
    users: []
  };

  let [ topics, setTopics ] = useState([]);
  let [ users, setUsers ] = useState([]);
  let [ clients, setClients ] = useState([]);

  function reducer(state, action) {
    switch (action.type) {
      case 'update':
        if (action.field === 'topic_id') {
            let foundTopic = topics.find(opt => opt.id === action.value);
            return {
                ...state,
                [action.field]: action.value,
                title: foundTopic ? foundTopic.title : state.title,
                duration: foundTopic ? foundTopic.duration : state.duration,
                color: foundTopic ? foundTopic.color : state.color
            };
        }
        if (action.field === 'active') {
            return {
                ...state,
                [action.field]: action.value,
                end_date: !action.value ? moment().format('YYYY-MM-DD') : null
            };
        }
        return {
            ...state,
            [action.field]: action.value
        };
      case 'update_all':
        return {
            ...action.value
        }
      default:
        throw new Error();
    }
  }
  let [ plan, dispatch ] = useReducer(reducer, initialState);
  
  let [ errors, setErrors ] = useState({});
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  // Load topic activities
  useEffect(() => {
    let url = '/topic/as/activities' + (plan.therapy ? '/1' : '');
    loader.get(url, res => {
        setTopics(res.items);

        // Set unique
        if (res.items.length === 1) {
            setTimeout(() => {
                dispatch({ type: 'update', field: 'topic_id', value: res.items[0].id });
            }, 10)
        }
    }, setLoading);
  }, [plan.therapy]);

  // Load users
  useEffect(() => {
    loader.get('/user/list', res => {
        setUsers(res.items);
    }, setLoading);
  }, []);

  // Load clients
  useEffect(() => {
    loader.get('/client/list', res => {
        setClients(res.items);
    }, setLoading);
  }, []);

  // Load item
  useEffect(() => {
    if (!parseInt(id, 10)) {
        return dispatch({ type: 'update_all', value: { ...initialState, color: 'ff0000' } });
    }
    loader.get('/plan/item/' + id, res => {
        const value = {
            ...res.item,
            start_date: timeSetup.from(res.item.start_date).format('YYYY-MM-DD HH:mm:ss'),
            end_date: res.item.end_date ? timeSetup.from(res.item.end_date).format('YYYY-MM-DD HH:mm:ss') : null,
            day_hourmin: timeSetup.from(res.item.day_hourmin).format('YYYY-MM-DD HH:mm:ss')
        };
        dispatch({ type: 'update_all', value });
    }, setLoading);
  }, [id]);
  
  // Register
  let register = () => {
    let data = {
        ...plan,
        users: plan.users.map(f => f.id),
        start_date: timeSetup.to(plan.start_date).format('YYYY-MM-DD HH:mm:ss'),
        end_date: plan.end_date ? timeSetup.to(plan.end_date).format('YYYY-MM-DD HH:mm:ss') : null,
        day_hourmin: timeSetup.to(plan.day_hourmin).format('YYYY-MM-DD HH:mm:ss')
    };
    let url = '/plan/create';
    if (plan.id) url = '/plan/update';
    setSuccess(false);
    loader.post(url, data, res => {
        if (res.errors) return setErrors(res.errors);
        dispatch({ type: 'update', field: 'id', value: res.id });
        setSuccess(true);
    }, setLoading);
  };

  let isDataInvalid = () => {
      return loading
        || !plan.topic_id
        || !plan.owner_id;
  }
  
  let displayErrors = (errors, key) => !!errors[key] && errors[key].map(msg =>
    <Message severity="error" text={msg}></Message>
  )

  // Build topic options
  let topicOptions = [];
  const allowedTopics = owner.topics.map(t => String(t.id));
  topics.map(opt => {
    if (!allowedTopics.includes(opt.parent_id)) return;
    topicOptions.push({
        label: opt.title,
        value: opt.id,
        key: opt.id,
        category: opt.category
    });
  });

  // Get client options
  const clientOptions = clients.map(opt => ({
    label: opt.firstname + ' ' + opt.lastname,
    value: opt.id,
    key: opt.id
  }));

  const availableUsers = users.filter(item1 => 
    !(plan.users.find(item2 => String(item1.id) === String(item2.id)))
  );

  if (loading) return <ProgressSpinner />;
  let day_hourmin = plan.day_hourmin ?
    moment(plan.day_hourmin).toDate() 
    : moment().toDate()
  let start_date = plan.start_date ? 
    moment(plan.start_date).toDate() : moment().toDate();
  let end_date = plan.end_date ? 
    moment(plan.end_date).toDate() : null;
  return (
    <React.Fragment>

        { success && (
            <div className="p-grid">
                <div className="p-col-12">

                    <Message 
                        severity="success" 
                        text="A atividade foi guardada com sucesso!">
                    </Message>
                    
                </div>
            </div>
        ) }

        <Button 
            onClick={register} 
            label="Guardar"
            disabled={isDataInvalid()}
            style={{ float: 'right' }}
        />
        <Button 
            label="Nova" 
            style={{ float: 'right' }} 
            className="p-button-success"
            onClick={e => dispatch({ type: 'update_all', value: { ...initialState, color: 'ff0000' } }) } 
        />
        <Button
            label="Voltar"
            className="p-button-secondary"
            style={{ float: 'right' }}
            onClick={e => window.history.back()}
        />
        
        <p className="form-info">
            <strong style={{ color: 'red' }}>*</strong> 
            {' '}Campos obrigatórios
            
        </p>

        <div className="p-grid">
            <div className="p-col-12 p-lg-6">
                <div className="p-grid">

                    <div className="p-col-12 p-md-6">
                        <label className="field-required">Tipo</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Dropdown 
                            placeholder="Escolha um tipo"
                            value={parseInt(plan.topic_id, 10)}
                            options={topicOptions}
                            itemTemplate={opt => (
                                <div key={opt.id}>
                                    <div className="topic-opt-cat">{ opt.category }</div>
                                    <div className="topic-opt-label">{ opt.label }</div>
                                </div>
                            )}
                            onChange={(e) => {
                                dispatch({ type: 'update', field: 'topic_id', value: e.value })
                            }}
                        />
                    </div>

                    <div className="p-col-12 p-md-6">
                        <label className="field-required">Etiqueta</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <InputText 
                            placeholder="Etiqueta"
                            value={plan.title}
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'title', value: e.target.value })
                            }
                        />
                        { displayErrors(errors, 'firstname') }
                    </div>

                    {/*
                    <div className="p-col-6 p-md-6">
                        <label className="field-required">Autor</label>
                    </div>
                    <div className="p-col-6 p-md-6">
                        <Dropdown 
                            placeholder="Escolha um utilizador"
                            value={parseInt(plan.owner_id, 10)}
                            options={ownerOptions} 
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'owner_id', value: e.value })
                            }
                        />
                    </div>
                    */}

                    <div className="p-col-12 p-md-6">
                        <label>Exclusiva do Cliente</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Dropdown 
                            placeholder="Escolha um cliente"
                            value={parseInt(plan.client_id, 10)}
                            options={clientOptions} 
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'client_id', value: e.value })
                            }
                        />
                    </div>

                    <div className="p-col-10 p-md-6">
                        <label className="field-required">Activo</label>
                    </div>
                    <div className="p-col-2 p-md-6">
                        <InputSwitch 
                            checked={plan.active} 
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'active', value: !plan.active })
                            } 
                        />
                    </div>

                    { !!plan.color && (
                        <React.Fragment>
                            <div className="p-col-12 p-md-6">
                                <label>Côr de Etiqueta</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <ColorPicker
                                    value={plan.color} 
                                    onChange={(e) => 
                                        dispatch({ type: 'update', field: 'color', value: e.value })
                                    }
                                />
                            </div>
                        </React.Fragment>
                    )}

                    <div className="p-col-12 p-md-6">
                        <label className="field-required">Frequência</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Dropdown 
                            placeholder="Escolha uma opção"
                            value={plan.freq_month}
                            options={monthlyOptions} 
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'freq_month', value: e.value })
                            }
                        />
                    </div>

                    { plan.freq_month === 'unique_datetime' ? (
                        <React.Fragment>
                            <div className="p-col-12 p-md-6">
                                <label className="field-required">Data / Hora</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <Calendar 
                                    inline={true}
                                    placeholder="Data / Hora"
                                    value={plan.unique_datetime || ''}
                                    locale={date_pt}
                                    monthNavigator={true} 
                                    yearNavigator={true} 
                                    yearRange="2020:2030"
                                    dateFormat="yy-mm-dd"
                                    showTime={true}
                                    hourFormat="24"
                                    onChange={(e) => 
                                        dispatch({ type: 'update', field: 'unique_datetime', value: e.value })
                                    }>
                                </Calendar>
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className="p-col-12 p-md-6">
                                <label>Freq. Semanal</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                                { weeklyOptions.map(opt => (
                                    <React.Fragment>
                                        <Checkbox 
                                            inputId={"w" + opt.value} 
                                            value={opt.value}
                                            onChange={e => {
                                                let selected = plan.freq_week.split(',');
                                                if (e.checked) selected.push(e.value);
                                                else selected.splice(selected.indexOf(e.value), 1);
                                                dispatch({ type: 'update', field: 'freq_week', value: selected.join(',') });        
                                            }} 
                                            checked={plan.freq_week.split(',').includes(opt.value)}
                                        />
                                        <label htmlFor={"w" + opt.value} className="p-checkbox-label weekopt">
                                            { opt.label }
                                        </label>
                                        <br />
                                    </React.Fragment>
                                ))}
                            </div>

                            <div className="p-col-12 p-md-6">
                                <label>Freq. Diária</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <Dropdown 
                                    placeholder="Escolha uma opção"
                                    value={plan.freq_day}
                                    options={dailyOptions} 
                                    onChange={(e) => 
                                        dispatch({ type: 'update', field: 'freq_day', value: e.value })
                                    }
                                />
                            </div>

                            <div className="p-col-12 p-md-6">
                                <label className="field-required">Hora de Inicio</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <Calendar 
                                    inline={true}
                                    locale={date_pt}
                                    placeholder="Hora"
                                    value={day_hourmin} 
                                    timeOnly={true} 
                                    showTime={true} 
                                    hourFormat="24"
                                    onChange={(e) => {
                                        dispatch({ type: 'update', field: 'day_hourmin', value: e.value })
                                    }}>
                                </Calendar>
                            </div>
                        </React.Fragment>
                    ) }

                    <div className="p-col-12 p-md-6">
                        <label className="field-required">Duração</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Dropdown 
                            placeholder="Escolha uma opção"
                            value={plan.duration}
                            options={durationOptions} 
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'duration', value: e.value })
                            }
                        />
                        { displayErrors(errors, 'duration') }
                    </div>

                    <div className="p-col-12 p-md-6">
                        <label className="field-required">Inicio</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Calendar 
                            placeholder="Inicio"
                            value={start_date}
                            locale={date_pt}
                            monthNavigator={true} 
                            yearNavigator={true} 
                            yearRange="2020:2030"
                            dateFormat="yy-mm-dd"
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'start_date', value: e.value })
                            }>
                        </Calendar>
                    </div>

                    <div className="p-col-12 p-md-6">
                        <label>Fim</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Calendar 
                            placeholder="Fim"
                            value={end_date}
                            locale={date_pt}
                            monthNavigator={true} 
                            yearNavigator={true} 
                            yearRange="2020:2030"
                            dateFormat="yy-mm-dd"
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'end_date', value: e.value })
                            }>
                        </Calendar>
                    </div>

                </div>
            </div>
            <div className="p-col-12 p-lg-6">
                <Editor 
                    style={{height:'320px'}} 
                    value={plan.description}
                    onTextChange={(e) => 
                        dispatch({ type: 'update', field: 'description', value: e.htmlValue })
                    }
                />
            </div>

        </div>

        { hasMod(['T4CCS1']) && (
            <React.Fragment>
                <h2 className="field-required">Colaboradores Preferidos</h2>
                <p className="subtitle"><small><em>Colaboradores usados na atribuição automática de tarefas</em></small></p>

                <PickList 
                    source={availableUsers} 
                    target={plan.users} 
                    sourceHeader="Colaboradores disponíveis"
                    targetHeader="Colaboradores selecionados"
                    itemTemplate={item => item.username}
                    responsive={false}
                    onChange={(e) => 
                        dispatch({ type: 'update', field: 'users', value: e.target })
                    }
                />
            </React.Fragment>
        ) } 

    </React.Fragment>
  );

}

export default Form;
