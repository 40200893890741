import React, { useState, useEffect } from 'react';
import loader from '../loader';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import moment from 'moment';
import { Link } from 'react-router-dom';

let dt = null;

function ClientsHistory({ client }) {

  let [ items, setItems ] = useState([]);
  
  let [ loading, setLoading ] = useState(false);

  // Load history
  useEffect(() => {
    if (!parseInt(client.id, 10)) return;
    loader.get('/diary/client/history/' + client.id, res => {
        setItems(res.items);
      }, setLoading);
  }, [client.id]);
  
  if (loading) return <ProgressSpinner />;
  const isMobile = window.innerWidth <= 768;
  return (
    <React.Fragment>

        <div className="card card-w-title">
            <h1>
                Histórico
                <Button
                    type="button" 
                    icon="pi pi-external-link" 
                    iconPos="left" 
                    label="CSV" 
                    onClick={e => dt.exportCSV()}
                    style={{ float: 'right' }}
                />
            </h1>

            <DataTable value={items} ref={(el) => { dt = el; }}>
                <Column
                    header="Atividade"
                    field="title"
                />
                <Column
                    header="Inicio"
                    field="start_date"
                    style={{ textAlign: isMobile ? 'left' : 'center' }}
                />
                <Column
                    header="Fim"
                    field="end_date"
                    style={{ textAlign: isMobile ? 'left' : 'center' }}
                />
                { !isMobile && (
                    <Column
                        header="Última"
                        field="datahora"
                        style={{ textAlign: isMobile ? 'left' : 'center' }}
                        body={item => item.datahora ? (
                            <Link to={"/backoffice/diary/view/" + item.diary_id}>
                                { moment(item.datahora).format('YYYY-MM-DD') }
                            </Link>) : ''
                        }
                    />
                ) }
                { !isMobile && (
                    <Column
                        header="Total"
                        field="total"
                        style={{ textAlign: isMobile ? 'left' : 'center' }}
                    />
                ) }
            </DataTable>
        </div>

    </React.Fragment>
  );

}

export default ClientsHistory;
