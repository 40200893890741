import React from 'react';
import { Button } from 'primereact/button';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import items from '../users/roles';
import { useHistory } from "react-router-dom";

function RolesList() {

    let history = useHistory();

    let recordOptions = (item, column) => {
        return <div>
            <Button
                onClick={e => history.push({ pathname: '/backoffice/roles/form/' + item.id })} 
                type="button" 
                icon="pi pi-pencil" 
                className="p-button-info"
            />
        </div>;
    }

  let orderedItems = items.sort((a, b) => a.level < b.level ? 1 : -1);
  return (
    <React.Fragment>
        <div className="card card-w-title">
            <h1>Funções</h1>

            <div className="p-grid">
                <div className="p-col-12">

                <DataTable value={orderedItems} responsive={false}>
                    <Column 
                        header="Nome"
                        field="label"
                        style={{ textAlign: 'center' }}
                    />
                    <Column 
                        header="Acessos"
                        field="description"
                    />
                    <Column 
                        header="Opções"
                        body={recordOptions}
                        style={{ textAlign: 'right', width: '70px' }}
                    />
                </DataTable>
                    
                </div>
            </div>
            
        </div>
    </React.Fragment>
  );

}

export default RolesList;
