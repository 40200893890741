import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import moment from 'moment';
import loader from '../loader';
import { InputTextarea } from 'primereact/inputtextarea';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import timeSetup from '../timeSetup';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import {Calendar} from 'primereact/calendar';
import date_pt from '../date_pt.json';
import './client.css';
import { DiaryItemList } from '../diary/DiaryItemList';
import { hasMod } from '../users/Permissions';
import ClientsGoalsResume from './ClientsGoalsResume';

const serverUrl = process.env.REACT_APP_SERVER_URL || '';

let today = moment();
let start = timeSetup.to(today).subtract(1, 'days').toDate();
let end = timeSetup.to(today).add(1, 'days').toDate();

function ClientsResume({ client, logged }) {

  let [ diary, setDiary ] = useState([]);
  let [ business, setBusiness ] = useState(null);
  let [ entities, setEntities ] = useState([]);
  let [ plans, setPlans ] = useState([]);
  let [ healthParams, setHealthParams ] = useState([]);
  let [ incidents, setIncidents ] = useState([]);
  let [ loading, setLoading ] = useState(false);
  let [ topicsModal, setTopicsModal ] = useState(false);
  let [ interval, setDiaryInterval ] = useState([start, end]);
  let history = useHistory();

  // Permission
  let isSuperAdmin = ['ADMINISTRATOR'].includes(logged.role1);
  let isAdmin = ['ORG_ADMIN', 'ADMIN'].includes(logged.role1);
  let isAnalyst = [logged.role1, logged.role2].includes('ANALYST');
  let isClient = ['CLIENT', 'CLIENT_ADMIN'].includes(logged.role1);

  // Load client diary
  useEffect(() => {
    let [istart, iend] = interval;
    if (!istart || !iend) return;
    let url = '/diary/client/list/' + client.id + '/' + istart + '/' + iend;
    loader.get(url, res => {
        if (!res.success) return console.log(res);
        let titems = res.items;
        titems = titems.filter(i => {
            if (!isAdmin) return logged.topics.map(t => String(t.id)).includes(i.parent_id);
            else return true;
        })
        .map(i => ({
            ...i,
            datahora: timeSetup.from(i.datahora)
        }));
        setDiary(titems);
    }, setLoading);
  }, [client.id, interval]);

  // Load entities
  useEffect(() => {
    if (!parseInt(client.id, 10)) return;
    let url = '/entity/list?client_id=' + client.id;
    loader.get(url, res => {
        setEntities(res.items);
    }, setLoading);
  }, [client.id]);

  // Load plans
  useEffect(() => {
    if (!parseInt(client.id, 10)) return;
    let url = '/plan/client/' + client.id;
    loader.get(url, res => {
        setPlans(res.items);
    }, setLoading);
  }, [client.id]);

  // Load last health params
  useEffect(() => {
    loader.get('/diary/health/last/' + client.id, res => {
        let items = res.items;
        items = items.map(i => {
            i.datahora = timeSetup.from(i.datahora).format('YYYY-MM-DD HH:mm');
            return i;
        });
        setHealthParams(items);
    }, setLoading);
  }, [client.id]);

  // Load last business
  useEffect(() => {
    loader.get('/business/item/' + client.business_id, res => {
        setBusiness(res.item);
    }, setLoading);
  }, [client.id]);

  // Load incidents
  useEffect(() => {
    let tstart = moment().subtract(2, 'year').format('YYYY-MM-DD');
    let tend = moment().format('YYYY-MM-DD');
    loader.get('/diary/incidents/list/' + tstart + '/' + tend + '/' + client.id, res => {
        setIncidents(res.items);
    }, setLoading);
    }, [client.id]);

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/photo_placeholder.png';
  }

  let baseTaskLink = "/technical/diary/view/";
  if (isClient) baseTaskLink = "/client/diary/form/";
  if (isAdmin || isSuperAdmin) baseTaskLink = "/backoffice/diary/view/";

  if (!client || loading) return <ProgressSpinner />;
  const isMobile = window.innerWidth <= 768;
  return (
    <React.Fragment>

        <div className="p-grid" id="client">

            <div className="p-col-12 p-lg-3">
                <div className="card card-w-title" style={{ textAlign: 'center' }}>
                    <h1>{ client.firstname } { client.lastname }</h1>

                    <img 
                        src={getFileUrl(client.photo, 'clients')} 
                        alt="Fotografia"
                        title="Fotografia"
                        className="img-avatar"
                        style={{ maxWidth: '64%', margin: '0 auto' }}
                    />
                    
                </div>
            </div>

            <div className="p-col-12 p-lg-3">
                <div className="card card-w-title">
                    <h1>Metas</h1>
                    <ClientsGoalsResume client={client} />
                </div>
            </div>

            <div className="p-col-12 p-lg-6">
                <div className="card card-w-title">
                    <h1>
                        Diário
                        <Button 
                            style={{ float: 'right' }}
                            label="Registar Tarefa" 
                            icon="pi pi-briefcase" 
                            onClick={e => setTopicsModal(true)}
                        />
                        <Calendar 
                            placeholder="Data / Hora"
                            value={interval} 
                            locale={date_pt}
                            dateFormat="yy-mm-dd"
                            selectionMode="range" 
                            style={{ maxWidth: '278px', float: 'right', marginRight: '1.4em' }}
                            showIcon
                            readOnlyInput
                            onChange={e => {
                                setDiaryInterval(e.value) 
                            }}
                        />
                    </h1>
                    <div style={{ width: '100%', clear: 'both' }} />

                    { diary.length === 0 ? (
                        <p>Não existem tarefas</p>
                    ) : (
                        <DataTable value={diary} id="client-diary">
                            <Column 
                                header="Tarefa" 
                                field="title" 
                                filter={true}
                                filterMatchMode="contains"
                                body={item => <DiaryItemList item={item} link={baseTaskLink + item.id} />}
                            />

                            { !isMobile &&
                                <Column 
                                    header="Colaborador"
                                    field="username"
                                    filter={true}
                                    filterMatchMode="contains"
                                    style={{ textAlign: 'center', width: '110px' }}
                                    body={item => (
                                        <Link to={"/backoffice/users/form/" + item.user_id}>{item.username}</Link>
                                    ) }
                                />
                            }

                            <Column
                                style={{ textAlign: 'center', width: '76px' }}
                                header="Terminado"
                                body={item => (
                                    <React.Fragment>
                                    <InputSwitch
                                        checked={item.terminated}
                                        onClick={e => history.push({ pathname: '/backoffice/diary/form/' + item.id })}
                                    />
                                    </React.Fragment>
                                )}
                            />
                        </DataTable>
                    )}

                </div>
            </div>



            { isAdmin && (
                <div className="p-col-12 p-lg-3">
                    <div className="card card-w-title">
                        <h1>Dados Pessoais</h1>

                        <div className="p-grid">

                            <div className="p-col-12">
                                <InputText value={client.fullname || ''} readOnly placeholder="Nome completo..." />
                            </div>

                            <div className="p-col-12 p-lg-6"><label>Data de Nasc.</label></div>
                            <div className="p-col-12 p-lg-6">
                                <InputText value={moment(client.birthdate).format('YYYY-MM-DD')} readOnly placeholder="Nome completo..." />
                            </div>

                            <div className="p-col-12 p-lg-6"><label>Idade</label></div>
                            <div className="p-col-12 p-lg-6">
                                <InputText value={moment().diff(client.birthdate, 'year', false)} readOnly placeholder="Nome completo..." />
                            </div>

                            <div className="p-col-12 p-lg-6"><label>BI / CC</label></div>
                            <div className="p-col-12 p-lg-6">
                                <InputText value={client.bi_n || ''} readOnly placeholder="BI..." />
                            </div>

                            <div className="p-col-12 p-lg-6"><label>N.º Contribuinte</label></div>
                            <div className="p-col-12 p-lg-6">
                                <InputText value={client.nif_n || ''} readOnly placeholder="BI..." />
                            </div>

                            <div className="p-col-12 p-lg-6"><label>N.º SNS</label></div>
                            <div className="p-col-12 p-lg-6">
                                <InputText value={client.sns_n || ''} readOnly placeholder="BI..." />
                            </div>

                            <div className="p-col-12 p-lg-6"><label>N.º Seg. Social</label></div>
                            <div className="p-col-12 p-lg-6">
                                <InputText value={client.ss_n || ''} readOnly placeholder="BI..." />
                            </div>

                        </div>
                    </div>
                </div>
            )}

            { isAdmin && (
                <div className="p-col-12 p-lg-3">
                    <div className="card card-w-title">
                        <h1>Contactos</h1>

                        <div className="p-grid">

                            <div className="p-col-12 p-lg-6"><label>Tel. Casa</label></div>
                            <div className="p-col-12 p-lg-6">
                                <InputText value={client.phone_n || ''} readOnly placeholder="Telefone..." />
                            </div>

                            <div className="p-col-12 p-lg-6"><label>Telemóvel</label></div>
                            <div className="p-col-12 p-lg-6">
                                <InputText value={client.mobile_n || ''} readOnly placeholder="Telemóvel..." />
                            </div>

                            <div className="p-col-12 p-lg-6"><label>E-mail</label></div>
                            <div className="p-col-12 p-lg-6">
                                <InputText value={client.email_n || ''} readOnly placeholder="E-mail..." />
                            </div>

                            <div className="p-col-12"><label>Morada Completa</label></div>
                            <div className="p-col-12">
                                <InputTextarea value={client.address_n || ''} rows="3" readOnly placeholder="Morada..." />
                            </div>
                            
                        </div>
                    </div>
                </div>
            )}

            { isAdmin && hasMod(['T4CCP1']) && (
                <div className="p-col-12 p-lg-3">
                    <div className="card card-w-title">
                        <h1>Pessoas Próximas</h1>

                        <div className="p-grid">
                            { entities.map(i => (
                                <React.Fragment key={i.id}>
                                    <div className={i.media === 'Endereço' ? "p-col-12 p-lg-12" : "p-col-12 p-lg-6" }>
                                        <label>{ i.name }</label>
                                    </div>
                                    <div className={i.media === 'Endereço' ? "p-col-12 p-lg-12" : "p-col-12 p-lg-6" }>
                                        <InputText value={i.value} readOnly />
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>

                    </div>
                </div>
            )}

            { isAdmin && hasMod(['T4CPN1']) && (
                <div className="p-col-12 p-lg-3">
                    <div className="card card-w-title">
                        <h1>Plano de Serviço Atual</h1>

                        { business ? (
                            <React.Fragment>
                                <div className="p-col-12"><label><strong>{ business.title }</strong></label></div>

                                <div className="p-datatable p-component">
                                    <div className="p-datatable-wrapper">
                                        <table role="grid">
                                            <tbody className="p-datatable-tbody">

                                                { client.business_price && 
                                                <tr className="p-datatable-row">
                                                    <td className="">Preço</td>
                                                    <td className="">{ client.business_price }</td>
                                                </tr> }

                                                { client.business_discount && 
                                                <tr className="p-datatable-row">
                                                    <td className="">Desconto</td>
                                                    <td className="">{ client.business_discount }</td>
                                                </tr> }
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                
                                <DataTable value={business.features.filter(i => i.active)}>
                                    <Column 
                                        header="Items do Plano" field="title" 
                                    />
                                </DataTable>
                            </React.Fragment>
                        ) : (
                            <p>Sem plano atribuido</p>
                        )}

                    </div>
                </div>
            ) }

            { hasMod(['T4CPI1']) && (
                <div className="p-col-12 p-lg-3">
                    <div className="card card-w-title">
                        <h1>Plano Individual</h1>

                        { plans.filter(i => i.active).map(i => (
                            <React.Fragment key={i.id}>
                                <div className="p-col-12"><label>{ i.title }</label></div>
                            </React.Fragment>
                        ))}

                    </div>
                </div>
            ) }

            { isAdmin && hasMod(['T4CNU1']) && (
                <div className="p-col-12 p-lg-3">
                    <div className="card card-w-title">
                        <h1>Saúde</h1>

                        { healthParams.length === 0 ? (
                            <p>Não existem resultados</p>
                        ) : (
                            <DataTable value={healthParams}>
                                <Column header="Parâmetro" field="label" />
                                <Column 
                                    header="Valor" 
                                    field="value"
                                    style={{ textAlign: 'center' }}
                                    body={item => {
                                        return item.type === 'decimal' ? 
                                            parseFloat(item.value) 
                                            : item.value;
                                    }}
                                />
                                <Column 
                                    header="Data / Hora" 
                                    field="datahora"
                                    style={{ minWidth: '90px' }}
                                    body={item => moment(item.datahora).format('YYYY-MM-DD HH:mm')} 
                                />
                            </DataTable>
                        )}
                    </div>
                </div>
            ) }

            { hasMod(['T4CIN1']) && (
                <div className="p-col-12 p-lg-3">
                    <div className="card card-w-title">
                        <h1>Ocorrências</h1>

                        <DataTable value={incidents}>
                            <Column header="Etiqueta" field="title" />
                            <Column 
                                header="Data / Hora" 
                                field="datahora"
                                body={item => moment(item.datahora).format('YYYY-MM-DD HH:mm')} 
                            />
                        </DataTable>

                    </div>
                </div>
            ) }

        </div>

        <Dialog
            header="Escolha a Categoria" 
            visible={topicsModal}
            style={{width: isMobile ? '90%' : '50vw'}} 
            modal={true} onHide={() => setTopicsModal(false)}>
                <div id="choose-topic-buttons">
                    { logged.topics.map(topic => (
                        <Link 
                            key={topic.id}
                            to={"/backoffice/diary/form/0?client_id=" + client.id + "&parent_id=" + topic.id }>
                            <Button label={topic.title} icon="pi pi-briefcase" className="p-button-lg" />&nbsp;
                        </Link>
                    ))}
                </div>
        </Dialog>


    </React.Fragment>
  );

}

export default ClientsResume;
