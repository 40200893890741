import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import { Message } from 'primereact/message';
import {FileUpload} from 'primereact/fileupload';
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import loader from '../loader';
import { ProgressSpinner } from 'primereact/progressspinner';
import date_pt from '../plans/date_pt.json';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import Session from '../auth/Session';
import { Dialog } from 'primereact/dialog';
import ProductForm from './ProductForm';
import { InputText } from 'primereact/inputtext';
import timeSetup from '../timeSetup';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const serverUrl = process.env.REACT_APP_SERVER_URL;

function StockDisposalForm() {

  const logged = Session.getCookie();

  const initialState = {
    datahora: moment().toDate(),
    from_id: null,
    to_id: null,
    owner_id: logged.id,
    product_id: '',
    received_by: null,
    received_datahora: null,
    amount: 0
  }

  let [ stock, setStock ] = useState(initialState);
  let [ entities, setEntities ] = useState([]);
  let [ users, setUsers ] = useState([]);
  let [ products, setProducts ] = useState([]);
  
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  let [ productModal, setProductModal ] = useState(false);

  // Load entities
  useEffect(() => {
    loader.get('/entity/list?media=Endereço', res => {
        setEntities(res.items);
    }, setLoading);
  }, []);

  // Load users
  useEffect(() => {
    loader.get('/user/list', res => {
        setUsers(res.items);
    }, setLoading);
  }, []);

  // Load products
  const reloadProducts = (from_id) => {
    setProductModal(false);
    if (!from_id) return;
    loader.get('/product/list/1/' + from_id, res => {
        let items = res.items;
        items = items.map(i => {
            i.move = 0;
            return i;
        });
        setProducts(items);
    }, setLoading);
  }
  useEffect(() => {
      reloadProducts(stock.from_id);
  }, [stock.from_id]);

  // Register stock
  let history = useHistory();
  let register = () => {
    let data = {}, count = 0;
    let moves = products.filter(i => !!i.move);
    for (let i = 0; i < moves.length; i++) {
        data = {
            ...stock,
            product_id: moves[i].id,
            amount: moves[i].move,
            datahora: timeSetup.to(stock.datahora).toISOString(),
            received_datahora: stock.received_datahora ? timeSetup.to(stock.received_datahora).toISOString() : null
        };
        let url = '/stock/create';
        setSuccess(false);
        loader.post(url, data, res => {
            if (res.errors) return;
            setSuccess(true);
            count++;
            if (count === moves.length) history.goBack();
        });
    }
  };

  let isDataInvalid = () => {
      return loading
        || !stock.from_id
        || !stock.to_id
        || !products.filter(i => !!parseInt(i.move)).length;
  }

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/photo_placeholder.png';
  }

  // Upload
  const onUpload = (e) => {
    if (e.files.length === 0) return;
    
    const formData  = new FormData();
    formData.append('folder', 'stocks');
    formData.append('upload', e.files[0]);
    const url = '/upload';
    loader.upload(url, formData, res => {
      if (!res.filename) return console.log(res);
      setStock({ ...stock, transport_doc: res.filename });  
    }, setLoading);
  }

  const onRemoveFile = (e, file) => {
    setStock({ ...stock, transport_doc: null });
  }

  const entityOptions = entities.map(opt => ({
    label: opt.name,
    value: opt.id,
    key: opt.id
  }));

  const userOptions = users.map(opt => ({
    label: opt.firstname + ' ' + opt.lastname,
    value: opt.id,
    key: opt.id
  }));

  const setProductMove = (product, amount, replace) => {
    let newProduct = Object.assign({}, product);
    let newProducts = Object.assign([], products);
    if (replace) newProduct.move = amount;
    else newProduct.move = parseInt(amount, 10) ? (parseInt(newProduct.move, 10) || 0) + parseInt(amount, 10) : amount;
    let index = products.indexOf(product);
    newProducts.splice(index, 1, newProduct);
    setProducts(newProducts);
  }

  const isMobile = window.innerWidth <= 768;
  if (loading) return <ProgressSpinner />;
  const title = "Registar Movimento";
  return (
    <React.Fragment>
        <div className="card card-w-title">
            <h1>{ title }</h1>

            { success && (
                <div className="p-grid">
                    <div className="p-col-12">

                        <Message 
                            severity="success" 
                            text="O movimento foi registado com sucesso!">
                        </Message>
                        
                    </div>
                </div>
            ) }

              <Button 
                onClick={register} 
                label="Guardar"
                disabled={isDataInvalid()}
                style={{ float: 'right' }}
              />

              <p className="form-info">
                  <strong style={{ color: 'red' }}>*</strong> 
                  {' '}Campos obrigatórios
              </p>

              <div className="p-grid">
                <div className="p-col-12 p-lg-6">
                    <div className="p-grid">

                    <div className="p-col-12 p-md-6">
                            <label className="field-required">Data / Hora do Movimento</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <Calendar 
                                placeholder="Data / Hora"
                                value={stock.datahora || ''}
                                locale={date_pt}
                                monthNavigator={true} 
                                yearNavigator={true} 
                                yearRange="2020:2030"
                                dateFormat="yy-mm-dd"
                                showTime={true}
                                hourFormat="24"
                                onChange={(e) => 
                                    setStock({ ...stock, datahora: e.value })
                                }>
                            </Calendar>
                        </div>

                        <div className="p-col-12 p-md-6">
                            <label className="field-required">Origem</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <Dropdown 
                                placeholder="Escolha uma localização"
                                value={parseInt(stock.from_id, 10)}
                                options={entityOptions} 
                                onChange={(e) => 
                                    setStock({ ...stock, from_id: e.value })
                                }
                            />
                        </div>

                        <div className="p-col-12 p-md-6">
                            <label className="field-required">Destino</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <Dropdown 
                                placeholder="Escolha uma localização"
                                value={parseInt(stock.to_id, 10)}
                                options={entityOptions} 
                                onChange={(e) => 
                                    setStock({ ...stock, to_id: e.value })
                                }
                            />
                        </div>

                        <div className="p-col-12 p-md-6">
                            <label>Recebido Por</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <Dropdown 
                                placeholder="Escolha um utilizador"
                                value={parseInt(stock.received_by, 10)}
                                options={userOptions} 
                                onChange={(e) =>
                                    setStock({ ...stock, received_by: e.value }) 
                                }
                            />
                        </div>

                        <div className="p-col-12 p-md-6">
                            <label>Recebido em</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <Calendar 
                                placeholder="Data / Hora"
                                value={stock.received_datahora || ''}
                                locale={date_pt}
                                monthNavigator={true} 
                                yearNavigator={true} 
                                yearRange="2020:2030"
                                dateFormat="yy-mm-dd"
                                showTime={true}
                                hourFormat="24"
                                onChange={(e) => 
                                    setStock({ ...stock, received_datahora: e.value }) 
                                }>
                            </Calendar>
                        </div>

                        <div className="p-col-12 p-md-6">
                            <label>Guia de Transporte</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            { !!stock.transport_doc && (
                                <Link to={getFileUrl(stock.image, 'stocks')}>
                                    <Button label="Descarregar" icon="pi pi-download" />
                                </Link>
                            ) }{' '}
                            <FileUpload 
                                name="image"
                                mode="basic"
                                url={'/upload'}
                                customUpload={true}
                                uploadHandler={onUpload}
                                auto={true}
                                chooseLabel={stock.transport_doc ? "Alterar" : "Escolher" }
                                onRemove={onRemoveFile}
                                style={{ overflow: 'visible' }}
                            />
                        </div>

                        <div className="p-col-12 p-md-12" style={{ position: 'relative' }}>
                            <label className="field-required">Produtos</label>
                            <Button icon="pi pi-plus" 
                                style={{ float: 'right' }}
                                onClick={e => setProductModal(true)} 
                            />

                            <DataTable value={products}>
                                <Column 
                                    header="Produto"
                                    field="name"
                                    body={item => (
                                        <Button icon="pi pi-pencil" 
                                            label={item.name}
                                            onClick={e => setProductModal(item.id)} 
                                        />
                                    )}
                                />
                                <Column 
                                    header="Stock Calculado"
                                    body={item => item.stock - item.move}
                                />
                                <Column 
                                    header="Movimento"
                                    body={item => (
                                        <div>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <Button
                                                                icon="pi pi-minus"
                                                                onClick={e => setProductMove(item, -1)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <InputText
                                                                value={item.move}
                                                                onChange={e => setProductMove(item, e.target.value, true)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Button
                                                                icon="pi pi-plus"
                                                                onClick={e => setProductMove(item, 1)}
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                />

                            </DataTable>

                        </div>

                   </div>
              </div>
            </div>
        </div>

        <Dialog
            header="Adicionar Produto" 
            visible={productModal} 
            style={{width: isMobile ? '100%' : '50vw' }} 
            modal={true} 
            onHide={e => setProductModal(false)}>
                <ProductForm
                    id={productModal} 
                    onSave={product => {
                    reloadProducts(stock.from_id); 
                }} />
        </Dialog>

    </React.Fragment>
  );

}

export default StockDisposalForm;
