import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import moment from 'moment';
import 'moment/locale/pt';
import loader from '../loader';
import Session from '../auth/Session';
import MessagesForm from './MessagesForm';
import { animateScroll } from "react-scroll";
import useInterval from './useInterval';
import timeSetup from '../timeSetup';
moment.locale('pt');

const serverUrl = process.env.REACT_APP_SERVER_URL;

function isScrolledIntoView(el, container) {
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top;

    // Only completely visible elements return true:
    var isVisible = (elemTop >= 0) && (elemTop <= container.scrollHeight);
    return isVisible;
}

const loadItems = (sender, logged, setItems, setLoading, forceScroll, containerRef, lastRef) => {
    let url = '/message/sender/' + sender.sender_id;
    loader.get(url, res => {
        let titems = res.items;
        titems = titems.map(i => ({ 
            ...i, 
            datahora: timeSetup.from(i.datahora).format('YYYY-MM-DD HH:mm:ss') 
        }));
        setItems(res.items);

        // Scroll to bottom
        let lastVisible = isScrolledIntoView(lastRef.current, containerRef.current);
        if (forceScroll || lastVisible) {
            setTimeout(() => {
                animateScroll.scrollToBottom({ containerId: "msgList" });
            }, 10);
        }

    }, setLoading);
}

function MessagesSenderList(props) {

  const { sender, onExit } = props;
  const logged = Session.getCookie();
  
  let [ items, setItems ] = useState([]);
  let [ loading, setLoading ] = useState(false);

  const containerRef = useRef(null);
  const lastRef = useRef(null);

  useEffect(() => {
    loadItems(sender, logged, setItems, setLoading, true, containerRef, lastRef);
  }, []);

  // Update messages
  useInterval(() => {
    const logged = Session.getCookie();
    if (!logged) return;
    loadItems(sender, logged, setItems, setLoading, false, containerRef, lastRef);
  }, 8 * 1000);


  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/photo_placeholder.png';
  }

  return (
    <div className="card card-w-title">
        <h1>
            { sender.sendername }
            <Button label="Contactos" onClick={e => onExit() } style={{ float: 'right' }} />
        </h1>
            
        <div className="p-grid">
            <div className="p-col-12">

            <div className="msg-list" id="msgList" ref={containerRef}>
                { items.map((item, i) => String(item.sender_id) === String(logged.id) ?
                (
                    <div className="msg-row me" key={item.id}
                        ref={i === items.length - 1 ? lastRef : null} 
                        id={i === items.length - 1 ? 'msgLast' : 'msg-i-' + i}>
                        <div className="msg-msg-me">
                            <span dangerouslySetInnerHTML={{ __html: item.message.replace("\n", '<br />') }}></span>
                            <div className="msg-time-me subtitle">{ moment(item.datahora).fromNow() }</div>
                        </div>
                        <div className="msg-photo">
                            <img 
                                src={getFileUrl(item.senderphoto, 'users')} 
                                alt="Fotografia"
                                title="Fotografia"
                                className="img-avatar-mini"
                            />
                        </div>
                    </div>
                ) : (
                    <div className="msg-row" key={item.id}
                        ref={i === items.length - 1 ? lastRef : null} 
                        id={i === items.length - 1 ? 'msgLast' : 'msg-i-' + i}>
                        <div className="msg-photo">
                            <img 
                                src={getFileUrl(item.senderphoto, 'users')} 
                                alt="Fotografia"
                                title="Fotografia"
                                className="img-avatar-mini"
                            />
                        </div>
                        <div className="msg-msg">
                            <span dangerouslySetInnerHTML={{ __html: item.message.replace("\n", '<br />') }}></span>
                            <div className="msg-time subtitle">{ moment(item.datahora).add(12, 'hour').fromNow() }</div>
                        </div>
                    </div>
                )
                )}
            </div>

            <MessagesForm user_id={sender.sender_id} />

            </div>
        </div>
            
    </div>
  );

}

export default MessagesSenderList;
