import React from 'react';
import { Button } from 'primereact/button';
import {Card} from 'primereact/card';
import { useHistory } from "react-router-dom";
import { Message } from 'primereact/message';

function UnAuthorized(props) {

  let { reasons, goBack } = props;
  goBack = typeof goBack === 'undefined' ? true : false;

  let history = useHistory();
  
  return (
    <Card
      title="Não Autorizado">
        <div>
          <Message severity="error" text="Não tem permissão para esta ação."></Message>
          <p><strong>Razões:</strong></p>
          <ul style={{ textAlign: 'left' }}>
            { reasons.map( r => <li>{ r }</li>)}
          </ul>
          { goBack && 
            <p>
              <Button 
                label="Voltar"
                onClick={e => history.goBack()} 
              />
            </p>
          }
        </div>
    </Card>
  );

}

export default UnAuthorized;
