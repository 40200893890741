import React, { useState, useEffect, useReducer } from 'react';
import { Button } from 'primereact/button';
import {Calendar} from 'primereact/calendar';
import {Dropdown} from 'primereact/dropdown';
import { Message } from 'primereact/message';
import loader from '../loader';
import DiaryFieldInput from '../diary/DiaryFieldInput';
import { useHistory } from "react-router-dom";
import Session from '../auth/Session';
import { ProgressSpinner } from 'primereact/progressspinner';
import timeSetup from '../timeSetup';
import moment from 'moment';
import { InputText } from 'primereact/inputtext';
import date_pt from '../date_pt.json';

let original_topic = '';
let original_fields = [];

const serverUrl = process.env.REACT_APP_SERVER_URL || '';

function DiaryForm(props) {

  const { id, user, topic, client, terminated, therapy, incident } = props;
  let logged = Session.getCookie();

  const initialState = {
    datahora: moment().startOf('hour').toDate(),
    topic_id: topic ? topic.id : null,
    user_id: incident ? logged.id : (user ? user.id : null),
    client_id: client ? client.id : null,
    duration: topic ? topic.duration : null,
    terminated: !!terminated,
    therapy: therapy ? true : false,
    incident: incident ? true : false,
    request: '',
    owner_id: logged.id,
    fields: [],
    title: null
  }

  let [ topics, setTopics ] = useState([]);
  let [ users, setUsers ] = useState([]);
  let [ clients, setClients ] = useState([]);

  let history = useHistory();

  function reducer(state, action) {

    switch (action.type) {
        case 'update_fields':
            let value = state.fields;
            
            // Add missing
            let existing = state.fields.map(f => f.id);
            action.value.forEach(f => {
                if (!existing.includes(f.id)) value.push(f);
            });

            // Remove extra
            let extra = action.value.map(f => f.id);
            value = value.filter(f => extra.includes(f.id));
        
            if (String(state.topic_id) === original_topic) value = original_fields;
            return { ...state, fields: value };
        case 'update':
            let tvalue = action.value;
            if (action.field === 'fields' && String(state.topic_id) === original_topic) tvalue = original_fields;
            if (action.field === 'topic_id') {
                const topic = topics.find(t => String(t.id) === String(action.value));
                let title = topic.title;
                return { ...state, [action.field]: tvalue, duration: topic.duration, title };
            }
            return { ...state, [action.field]: tvalue };
      case 'update_all':
        original_topic = action.value.topic_id;
        original_fields = action.value.fields;
        return { ...action.value }
      default:
        throw new Error();
    }
  }

  let [ diary, dispatch ] = useReducer(reducer, initialState);
  
  let [ errors, setErrors ] = useState({});
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  const filterByOrganization = (items, logged) => {
      return items.filter(opt => opt.active);
  }

  // Update all fields of the same database
  const updateAll = (field, record) => {
    if (!record) return;
    diary.fields.forEach((tfield, i) => {
        if (
            tfield.id !== field.id
            && tfield.display === 'db_table'
            && tfield.db_table === field.db_table 
            && typeof record[tfield.db_table_value] !== 'undefined'
        ) {
            tfield['value'] = record[tfield.db_table_value];
        }
    });
    dispatch({ type: 'update', field: 'fields', value: diary.fields });
  }

  // Load topics
  useEffect(() => {
    let url = '/topic/as/activities' + (diary.therapy ? '/1' : '/0');
    if (diary.user_id) url += '/' + diary.user_id;
    loader.get(url, res => {
        let items = res.items.filter(opt => opt.active);
        if (diary.incident) items = items.filter(i => i.incident);
        setTopics(items);

        // Set unique
        if (items.length === 1) {
            setTimeout(() => {
                dispatch({ type: 'update', field: 'topic_id', value: items[0].id });
            }, 10)
        }
    });
  }, [diary.therapy]);

  // Load users
  useEffect(() => {
    loader.get('/user/list', res => {
        let items = filterByOrganization(res.items, logged);
        if (!(['ADMINISTRATOR', 'ORG_ADMIN', 'ADMIN'].includes(logged.role1))) {
            items = items.filter(i => i.id === logged.id);
        }
        setUsers(items);
        if (items.length === 1) {
            setTimeout(() => {
                dispatch({ type: 'update', field: 'user_id', value: items[0].id })
            }, 50);
        }
    });
  }, []);

  // Load clients
  useEffect(() => {
    loader.get('/client/list', res => {
        setClients(filterByOrganization(res.items, logged));
    });
  }, []);

  // Load fields
  useEffect(() => {
    if (!diary.topic_id) return;
    loader.get('/topic/fields/' + diary.topic_id, res => {
        dispatch({ type: 'update', field: 'fields', value: res.items });
    });
  }, [diary.topic_id]);

  // Load diary
  useEffect(() => {
    if (!parseInt(id, 10)) {
        return dispatch({ type: 'update_all', value: initialState });
    }
    loader.get('/diary/item/' + id, res => {
        res.item.datahora = timeSetup.from(res.item.datahora).format('YYYY-MM-DD HH:mm:ss');
        res.item.created_at = res.item.created_at ? timeSetup.from(res.item.created_at).format('YYYY-MM-DD HH:mm:ss')
            : moment().format('YYYY-MM-DD HH:mm:ss');
        dispatch({ type: 'update_all', value: res.item });
    }, setLoading);
  }, [id]);

  // Register diary
  let register = (terminated) => {
    let data = {
        ...diary,
        user_id: terminated ? logged.id : diary.user_id,
        terminated: typeof terminated === 'undefined' ? diary.terminated : terminated,
        datahora: timeSetup.to(diary.datahora).format('YYYY-MM-DD HH:mm:ss'),
        created_at: timeSetup.to(diary.created_at).format('YYYY-MM-DD HH:mm:ss')
    };
    let url = '/diary/create';
    if (diary.id) url = '/diary/update';
    setLoading(true);
    setSuccess(false);
    loader.post(url, data, res => {
        if (res.errors) {
            setLoading(false);
            return setErrors(res.errors);
        }
        history.goBack();
    });
  };

  let isDataInvalid = () => {
    return loading
      || !diary.datahora
      || !diary.topic_id
      || !diary.user_id
      || (client ? !diary.client_id : false);
  }

  let displayErrors = (errors, key) => !!errors[key] && errors[key].map(msg =>
    <Message severity="error" text={msg}></Message>
  )

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/photo_placeholder.png';
  }

  // Build topic options
  let topicOptions = [];
  const allowedTopics = logged.topics.map(t => String(t.id));
  topics.map(opt => {
    if (!allowedTopics.includes(opt.parent_id)) return;
    topicOptions.push({
        label: opt.title,
        value: opt.id,
        key: opt.id,
        category: opt.category
    });
  });

  const userOptions = users.map(opt => ({
    label: opt.username,
    value: opt.id,
    key: opt.id
  }));

  const clientOptions = clients.map(opt => ({
    label: opt.firstname + ' ' + opt.lastname,
    value: opt.id,
    key: opt.id
  }));
  
  if (loading) return (
    <div style={{ maxWidth: '580px', margin: '0 auto', textAlign: 'center'}}>
      <div style={{ textAlign: 'unset' }}>
        <ProgressSpinner style={{ margin: '0 auto' }} />
      </div>
    </div>
  );
  let datahora = diary.datahora ? moment(diary.datahora).toDate() : moment().toDate();
  let created_at = diary.created_at ? moment(diary.created_at) : moment();
  return (
    <React.Fragment>
        { success && (
            <div className="p-grid">
                <div className="p-col-12">

                    <Message 
                        severity="success" 
                        text="O tarefa foi guardada com sucesso!">
                    </Message>
                    
                </div>
            </div>
        ) }

        <p className="form-info">
            <strong style={{ color: 'red' }}>*</strong> 
            {' '}Campos obrigatórios
        </p>

        <div className="p-grid">

            <div className="p-col-6">
                <label style={{ fontSize: '0.8em'}}>Data do Registo</label>
            </div>
            <div className="p-col-6">
                <em style={{ fontSize: '0.8em'}}>{ created_at.format('ddd, D MMM [de] YYYY [ás] HH:mm') }</em>
            </div>

            { !diary.topic_id && (
                <React.Fragment>
                    <div className="p-col-12 p-md-6">
                        <label className="field-required">
                            <i class="pi pi-fw pi-briefcase"></i>
                            Tipo
                        </label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Dropdown 
                            placeholder="Escolha um tópico"
                            value={parseInt(diary.topic_id, 10)}
                            options={topicOptions} 
                            itemTemplate={opt => (
                                <div key={opt.id}>
                                    <div className="topic-opt-cat">{ opt.category }</div>
                                    <div className="topic-opt-label">{ opt.label }</div>
                                </div>
                            )}
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'topic_id', value: e.value })
                            }
                        />
                    </div>
                </React.Fragment>
            ) }

            { !!diary.incident && (
                <React.Fragment>
                    <div className="p-col-12 p-md-6">
                        <label className="field-required">
                            <i class="pi pi-fw pi-tag"></i>
                            Etiqueta
                        </label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <InputText 
                            placeholder="Etiqueta"
                            value={diary.title}
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'title', value: e.target.value })
                            }
                        />
                        { displayErrors(errors, 'title') }
                    </div>
                </React.Fragment>
            ) }

            { (incident || !diary.id) ? (
                <React.Fragment>
                    <div className="p-col-12 p-md-6">
                        <label className="field-required">
                            <i class="pi pi-fw pi-calendar"></i>
                            Data / Hora
                        </label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Calendar 
                            inline={true}
                            placeholder="Data / Hora"
                            value={datahora} 
                            locale={date_pt}
                            dateFormat="yy-mm-dd"
                            showTime={true}
                            hourFormat="24"
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'datahora', value: e.value })
                            }>
                        </Calendar>
                    </div>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <div className="p-col-6">
                        <label>
                            <i class="pi pi-fw pi-calendar"></i>
                            Data de Realização
                        </label>
                    </div>
                    <div className="p-col-6">
                        <em>{ moment(diary.datahora).format('ddd, D MMM [de] YYYY [ás] HH:mm') }</em>
                    </div>
                </React.Fragment>
            )}

            { !diary.user_id && (
                <React.Fragment>
                    <div className="p-col-12 p-md-6">
                        <label className="field-required">
                            <i class="pi pi-fw pi-user"></i>
                            Atribuido a
                        </label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Dropdown 
                            placeholder="Escolha um utilizador"
                            value={parseInt(diary.user_id, 10)}
                            options={userOptions} 
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'user_id', value: e.value })
                            }
                        />
                    </div>
                </React.Fragment>
            ) }

            { !diary.client_id && (
                <React.Fragment>
                    <div className="p-col-12 p-md-6">
                        <label className={client ? "field-required" : ""}>
                            <i class="pi pi-fw pi-heart"></i>
                            Cliente Associado
                            {' '}{ client ? '' : <small className="subtitle">(opcional)</small>}
                        </label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Dropdown 
                            placeholder="Escolha um cliente"
                            value={parseInt(diary.client_id, 10)}
                            options={clientOptions} 
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'client_id', value: e.value })
                            }
                        />
                    </div>
                </React.Fragment>
            ) }

            { !!diary.request && (
                <React.Fragment>
                    <div className="p-col-12 p-md-6">
                        <label>
                            <i class="pi pi-fw pi-info-circle"></i>
                            Detalhes do Pedido
                        </label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        {diary.request}
                    </div>
                </React.Fragment>
            ) }

            { !!diary.request_video && (
                <React.Fragment>
                    <div className="p-col-12 p-md-6">
                        <label>
                            <i class="pi pi-fw pi-video"></i>
                            Video Instrucional
                        </label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        { !!diary.request_video && (
                            <video controls
                                alt="Video"
                                title="Video"
                                className="img-avatar"
                                style={{minHeight: '140px'}}>
                                <source
                                    src={getFileUrl(diary.request_video, 'diary')}
                                    type="video/mp4"
                                />
                                O seu browser não suporta videos HTML5
                            </video>
                        ) }
                    </div>
                </React.Fragment>
            ) }

            { !!diary.fields.length &&
                <div className="p-col-12 p-shadow-3" style={{ display: 'block '}}>
                    <div className="p-grid">
                        { diary.fields.map((field, i) => 
                            <DiaryFieldInput
                                key={field.id}
                                field={field}
                                disabled={String(diary.user_id) !== String(logged.id)}
                                count={diary.fields.length}
                                onChange={(value, record) => {
                                    field['value'] = value;
                                    dispatch({ type: 'update', field: 'fields', value: diary.fields })
                                    if (field.db_table_sync) updateAll(field, record);
                                }}
                            />
                        )}
                    </div>
                </div>
            }

            <div className="p-col-6 p-md-6" style={{ textAlign: 'right', borderTop: '1px solid #f0f0f0' }}>
                <Button 
                    onClick={e => history.goBack()} 
                    label="Voltar"
                    className="p-button-danger"
                />
            </div>
            <div className="p-col-6 p-md-6" style={{ borderTop: '1px solid #f0f0f0' }}>
                <Button
                    icon="pi pi-check" 
                    onClick={e => register(true)} 
                    label={ diary.id ? "Concluir" : "Registar" }
                    disabled={isDataInvalid()}
                />
                {' '}
                <Button 
                    className="p-button-secondary"
                    onClick={e => register()} 
                    icon="pi pi-save"
                    disabled={isDataInvalid()}
                />
            </div>
        </div>

    </React.Fragment>
  );

}

export default DiaryForm;
