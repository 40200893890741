import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Button } from 'primereact/button';
import {ProgressSpinner} from 'primereact/progressspinner';
import TopicsListPublic from './TopicsList';
import loader from '../loader';

const serverUrl = process.env.REACT_APP_SERVER_URL;

export default function TopicsView(props) {

  let [ topic, setTopic ] = useState(null);
  let [ loading, setLoading ] = useState(true);

  let history = useHistory();

  // Load item
  let urlParams = useParams();
  useEffect(() => {
    if (parseInt(urlParams.id, 10) < 1) return;
    loader.get('/topic/item/' + urlParams.id, res => {
      setTopic(res.item);
    }, setLoading);
  }, [urlParams.id]);

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/topic_placeholder.png';
  }

  if (loading) return <ProgressSpinner />;
  if (!topic) return <div>A informação não foi encontrada</div>;
  return (
    <React.Fragment>
      <div className="card card-w-title">
          <h1>
            { topic.title }

            <Button
                style={{ float: 'right' }} 
                onClick={e => history.goBack()} 
                label="Voltar"
                className="p-button-danger"
            />
          </h1>
          
          { !!topic.image && (
              <img 
                alt="Imagem"
                className="image-pad-right" 
                src={getFileUrl(topic.image, 'topics')} 
              />
          ) }
          
          <div dangerouslySetInnerHTML={{ __html: topic.description }}></div>
          <div style={{ clear: 'right' }}></div>

          <br />
          { !!topic.info_video &&
              <div className="p-grid">
                  <div className="p-col-12">
                      <label>
                          <i class="pi pi-fw pi-video"></i>
                          Video Informativo
                      </label>
                  </div>
                  <div className="p-col-12">
                      { !!topic.info_video && (
                          <video controls
                              alt="Video"
                              title="Video"
                              className="img-avatar"
                              style={{minHeight: '140px'}}>
                              <source
                                  src={getFileUrl(topic.info_video, 'topic')}
                                  type="video/mp4"
                              />
                              O seu browser não suporta videos HTML5
                          </video>
                      ) }
                  </div>
              </div>
          }

      </div>
      <TopicsListPublic parent={topic} />
    </React.Fragment>
  )
}