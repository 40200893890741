import React from 'react';
import { Button } from 'primereact/button';
import {Toolbar} from 'primereact/toolbar';
import { Link } from "react-router-dom";
import moment from 'moment';
import 'moment/locale/pt';
moment.locale('pt');

const serverUrl = process.env.REACT_APP_SERVER_URL;

function MessagesIndexList(props) {

  const { groups, senders, setSelectedGroup, setSelectedSender, setCompose } = props;

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/photo_placeholder.png';
  }

  return (

    <div className="card card-w-title">
        <h1>Mensagens</h1>

        <Toolbar>
            <div className="p-toolbar-group-left">
                <Link to="/backoffice/messages">
                    <Button label="Contactos" />
                </Link>
            </div>
            <div className="p-toolbar-group-right">
                <Button type="button" label="Novo Chat" onClick={(e) => setCompose(true)} />
            </div>
        </Toolbar>

        <div className="p-grid">
            <div className="p-col-12">

            <div className="msg-list">
                { groups.map(item => (
                    <div key={item.group_id} className="msg-row" onClick={e => setSelectedGroup(item)}>
                        <div className="msg-photo">
                            <img 
                                src={getFileUrl(item.groupimage, 'groups')} 
                                alt="Fotografia"
                                title="Fotografia"
                                className="img-avatar-mini"
                            />
                        </div>
                        <div className="msg-msg">
                            { item.groupname }
                            { !!parseInt(item.count, 10) && (
                                <div style={{ textAlign: 'right' }}>
                                    <span className="msg-badge">{ item.count }</span>
                                </div>
                            ) }
                            <div className="msg-time subtitle">
                                { item.lasttime ? moment(item.lasttime).fromNow() : '' }
                            </div>
                        </div>
                    </div>
                ))}
                { senders.map(item => (
                    <div key={item.sender_id} className="msg-row" onClick={e => setSelectedSender(item)}>
                        <div className="msg-photo">
                            <img 
                                src={getFileUrl(item.senderphoto, 'users')} 
                                alt="Fotografia"
                                title="Fotografia"
                                className="img-avatar-mini"
                            />
                        </div>
                        <div className="msg-msg">
                            { item.sendername }
                            { !!parseInt(item.count, 10) && (
                                <div style={{ textAlign: 'right' }}>
                                    <span className="msg-badge">{ item.count }</span>
                                </div>
                            )}
                            <div className="msg-time subtitle">
                                { item.lasttime ? moment(item.lasttime).fromNow() : '' }
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            </div>
        </div>

    </div>
    
  );

}

export default MessagesIndexList;
