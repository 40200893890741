import React, { useState } from 'react';
import loader from '../loader';
import Mapa from './Mapa';
import { ProgressSpinner } from 'primereact/progressspinner';
import Session from '../auth/Session';

function ClientsLocation({ client, setClient }) {

  let [ loading, setLoading ] = useState(false);

  // Save client
  let save = (latlon) => {
    let data = {
        id: client.id,
        firstname: client.firstname,
        lastname: client.lastname,
        latitude: latlon.lat,
        longitude: latlon.lon,
        mapzoom: latlon.zoom
    };
    let url = '/client/update';
    loader.post(url, data, res => {
        if (!res.success) console.log(res);
    }, setLoading);
  };

  if (!client || loading) return <ProgressSpinner />;
  const logged = Session.getCookie();
  const isSuperAdmin = ['ADMINISTRATOR', 'ORG_ADMIN'].includes(logged.role1);
  const isAdmin = ['ADMIN'].includes(logged.role1);
  return (
    <React.Fragment>

        <div className="card card-w-title">
            <h1>Localização</h1>
            <p className="subtitle"><small><em>A localização é utilizada para comparar a distancia da localização do cliente com a localização do colaborador</em></small></p>

            <Mapa 
                data={client}
                onMove={latlon => {
                    if (!isSuperAdmin && !isAdmin) return;
                    setClient({ 
                        ...client,
                        latitude: latlon.lat,
                        longitude: latlon.lon,
                        mapzoom: latlon.zoom
                    });
                    setTimeout(() => { save(latlon) }, 10);
                } } 
            />
        </div>

    </React.Fragment>
  );

}

export default ClientsLocation;
