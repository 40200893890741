import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import {InputSwitch} from 'primereact/inputswitch';
import { Message } from 'primereact/message';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toolbar} from 'primereact/toolbar';
import { useHistory, Link } from "react-router-dom";
import moment from 'moment';
import loader from '../loader';
import typeOptions from './types.json';
import { ProgressSpinner } from 'primereact/progressspinner';
import Session from '../auth/Session';
import timeSetup from '../timeSetup';

const getItems = (res, setItems) => {
    let titems = res.items;
    titems = titems.map(i => ({
        ...i,
        start: timeSetup.from(i.start).format('YYYY-MM-DD HH:mm:ss'),
        end: timeSetup.from(i.end).format('YYYY-MM-DD HH:mm:ss')    
    }))
    setItems(titems);
}

function OutOfServiceList(props) {
  const { user_id } = props;

  let [ items, setItems ] = useState([]);
  
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  let history = useHistory();

  useEffect(() => {
    if (user_id) {
        loader.get('/outofservice/user/' + user_id, res => {
            if (!res.success) return;
            getItems(res, setItems);
        }, setLoading);
    } else {
        loader.get('/outofservice/list', res => {
            if (!res.success) return;
            getItems(res, setItems);
        }, setLoading);    
    }
  }, []);

  let recordOptions = (item, column) => {
    return <div style={{ textAlign: 'right' }}>
        <Button
            onClick={e => history.push({ pathname: '/technical/outofservices/form/' + item.id })} 
            type="button" 
            icon="pi pi-pencil" 
            className="p-button-info"
        />
    </div>;
  }

  let recordAuthorized = (item, column) => {
    return <div style={{ textAlign: 'center' }}>
        <InputSwitch checked={item.authorized} />
    </div>;
  }

  const getOptionLabel = (options, value) => {
    const item = options.find(opt => opt.value === value);
    return item ? item.label : value;
  }

  if (loading) return <ProgressSpinner />;
  let createUrl = "/technical/outofservices/form/0" + (user_id ? "/" + user_id : "");
  return (
    <React.Fragment>

        <Toolbar>
            <div className="p-toolbar-group-left">
                <Link to={createUrl}>
                    <Button label="Inserir Nova" />
                </Link>
            </div>
            <div className="p-toolbar-group-right">
                
            </div>
        </Toolbar>

        { success && (
            <div className="p-grid">
                <div className="p-col-12">

                    <Message 
                        severity="success" 
                        text="A ausência foi alterada com sucesso!">
                    </Message>
                    
                </div>
            </div>
        ) }

        
        <div className="p-grid">
            <div className="p-col-12">

            <DataTable value={items} responsive={true}>
                { !user_id && (
                    <Column 
                        header="Utilizador"
                        field="username"  
                        sortable={true}
                        filter={true}
                        filterMatchMode="contains"
                    />
                ) }
                
                <Column 
                    header="Tipo" 
                    field="type"
                    sortable={true}
                    filter={true}
                    filterMatchMode="contains"
                    body={i => getOptionLabel(typeOptions, i.type)} 
                />
                
                <Column 
                    header="Inicio" 
                    filter={true}
                    filterMatchMode="contains"
                    body={item => item.start ? moment(item.start).format('YYYY-MM-DD HH:mm:ss') : ''}
                />
                <Column 
                    header="Fim" 
                    filter={true}
                    filterMatchMode="contains"
                    body={item => item.end ? moment(item.end).format('YYYY-MM-DD HH:mm:ss') : ''}
                />

                <Column body={recordAuthorized} header="Autorizado" />
                <Column body={recordOptions} header="Opções" />
            </DataTable>
                
            </div>
        </div>
            
    </React.Fragment>
  );

}

export default OutOfServiceList;
