import React, { useEffect, useState } from 'react';
import { Password as InputPassword } from 'primereact/password';
import {Dialog} from 'primereact/dialog';
import Session from './Session';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './login.css';
import { useLocation, useParams } from 'react-router-dom';
import { getParams } from '../helpers';
import loader from '../loader';

const baseUrl = process.env.PUBLIC_URL;
const serverUrl = process.env.REACT_APP_SERVER_URL;

function BuyNowPage() {

  let [ organization, setOrganization ] = useState('');
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  let urlParams = useParams();
  let params = getParams(useLocation().search);

  useEffect(() => {

    // Load organization
    loader.get('/organization/fallback/' + (params.org || ''), res => {
      setOrganization(res.item);
    });
  }, []);

  if (!organization) return null;

  const isMobile = window.innerWidth <= 768;
  const url = window.localStorage ? window.localStorage.getItem('team4care_buynow') || 'https://reborn4life.pt' : 'https://reborn4life.pt';

  const org_logo = organization.logo ? serverUrl + "/download/organizations/" + organization.logo
    : baseUrl + 'assets/images/logo_512x512.png';
  const org_title = organization.name || "Team 4 Care";
  const org_bg = organization.background_img ? `url("${serverUrl}/download/organizations/${organization.background_img}")`
    : `url("${baseUrl}/assets/images/bg3.jpg")`;

  return (
    <div className="App" style={{ backgroundImage: org_bg }}>
      <div className="App-header">
        <div className="login-container">
          <div className="p-grid p-fluid">
            <div className="p-col-12">
              <div className="card card-w-title">

                <img src={ org_logo } 
                  className="logo" 
                  alt={ org_title }
                  title={ org_title }
                />

                <div className="p-grid">
                  <div className="p-col-12">
                    <p>Não foram encontrados produtos ativos.</p>

                    <p>Por favor contacte o seu fornecedor. Obrigado!</p>

                    <a tabIndex="1" 
                      href={url}
                      className="p-button p-component p-button-text-only">
                        <span className="p-button-text p-c">Comprar Agora</span>
                    </a>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );

}

export default BuyNowPage;
