import React from 'react';
import EntitiesList from '../entities/EntitiesList';

function ClientsEntities({ client }) {
  return (
    <React.Fragment>

        <div className="card card-w-title">
            <h1>Pessoas Próximas</h1>
                <EntitiesList 
                  filter={{ client_id: client.id }}
                  addLabel="Adicionar" 
                />
        </div>

    </React.Fragment>
  );

}

export default ClientsEntities;
