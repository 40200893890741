import React, { useState, useEffect } from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toolbar} from 'primereact/toolbar';
import {Calendar} from 'primereact/calendar';
import loader from '../loader';
import moment from 'moment';
import { Button } from 'primereact/button';
import timeSetup from '../timeSetup';
import { ProgressSpinner } from 'primereact/progressspinner';
import date_pt from '../date_pt.json';

let dt = null;

function CheckpointList(props) {
  const { user_id } = props;

  let [ items, setItems ] = useState([]);
  let [ start, setStart ] = useState(moment().startOf('month').toDate());
  let [ end, setEnd ] = useState(moment().endOf('month').toDate());
  
  let [ loading, setLoading ] = useState(false);

  useEffect(() => {
    let tstart = moment(start).format('YYYY-MM-DD');
    let tend = moment(end).format('YYYY-MM-DD');
    loader.get('/user/checkpoints/' + user_id + '/' + tstart + '/' + tend, res => {
        if (!res.success) return;
        let titems = res.items;
        titems = titems.map(i => ({
            ...i, 
            entrada: i.entrada ? timeSetup.from(i.entrada).format('HH:mm') : '',
            saida: i.saida ? timeSetup.from(i.saida).format('HH:mm') : '',
        }));
        setItems(titems);
    }, setLoading);
  }, [start, end]);

  const isMobile = window.innerWidth <= 768;
  if (loading) return <ProgressSpinner />;
  return (

    <div className="p-grid">
        <div className="p-col-12">

            <Toolbar>
                <div className="p-toolbar-group-left">
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-6">
                            <Calendar 
                                placeholder="Data de Inicio"
                                value={start} 
                                locale={date_pt}
                                dateFormat="yy-mm-dd"
                                yearNavigator={true}
                                yearRange="2020:2030"
                                onChange={(e) => setStart(e.value) }>
                            </Calendar>
                        </div>
                        <div className="p-col-12 p-lg-6">
                            <Calendar 
                                placeholder="Data de Fim"
                                value={end} 
                                locale={date_pt}
                                dateFormat="yy-mm-dd"
                                yearNavigator={true}
                                yearRange="2020:2030"
                                onChange={(e) => setEnd(e.value) }>
                            </Calendar>
                        </div>
                    </div>
                </div>
                <div className="p-toolbar-group-right">
                    <Button
                        type="button" 
                        icon="pi pi-external-link" 
                        iconPos="left" 
                        label="CSV" 
                        onClick={e => dt.exportCSV()}
                    />
                </div>
            </Toolbar>

        <DataTable value={items} ref={(el) => { dt = el; }} style={{ textAlign: 'center'}}>
            <Column 
                header={<span><i className="pi pi-calendar hidden-big"></i><span className="hidden-small">Dia</span></span>} 
                field="monthday" 
                body={item => moment(item.monthday).format('DD')}
            />
            <Column 
                header={<span><i className="pi pi-sign-in hidden-big"></i><span className="hidden-small">Entrada</span></span>} 
                field="entrada" 
            />
            <Column 
                header={<span><i className="pi pi-sign-out hidden-big"></i><span className="hidden-small">Saída</span></span>} 
                field="saida" 
            />
            { !isMobile && (
                <Column 
                    header={<span><i className="pi pi-thumbs-up hidden-big"></i><span className="hidden-small">Realizado</span></span>} 
                    field="realizado" 
                />
            ) }
            { !isMobile && (
                <Column 
                    header={<span><i className="pi pi-clock hidden-big"></i><span className="hidden-small">Horário</span></span>} 
                    field="previsto" 
                />
            ) }
            { !isMobile && (
                <Column 
                    header={<span><i className="pi pi-check hidden-big"></i><span className="hidden-small">Diferença</span></span>} 
                    field="diferenca" 
                />
            ) }
            
            <Column 
                header={<span><i className="pi pi-check hidden-big"></i></span>} 
                field="diferenca"
                style={{ width: '35px' }}
                body={item => <div>
                    { item.diferenca > 0 ? 
                        <i className="pi pi-check" style={{ color: 'green'}}></i> 
                        : <i className="pi pi-times"  style={{ color: 'red'}}></i>
                    }
                </div>} 
            />
        
            { !isMobile && (
                <Column 
                    header={<span><i className="pi pi-angle-double-left hidden-big"></i><span className="hidden-small">Distância Entrada</span></span>} 
                    field="entrada_dist" 
                />
            ) }
            { !isMobile && (
                <Column 
                    header={<span><i className="pi pi-angle-double-left hidden-big"></i></span>} 
                    field="entrada_dist"
                    style={{ width: '35px' }}
                    body={item => <div>
                        { item.entrada_dist > 0 && item.entrada_dist <= 1000 ? 
                            <i className="pi pi-check" style={{ color: 'green'}}></i> 
                            : <i className="pi pi-times"  style={{ color: 'red'}}></i>
                        }
                    </div>} 
                />
            ) }
            { !isMobile && (
                <Column 
                    header={<span><i className="pi pi-angle-double-right hidden-big"></i><span className="hidden-small">Distância Saída</span></span>} 
                    field="saida_dist" 
                />
            ) }
            { !isMobile && (
                <Column 
                    header={<span><i className="pi pi-angle-double-right hidden-big"></i></span>} 
                    field="saida_dist"
                    style={{ width: '35px' }}
                    body={item => <div>
                        { item.saida_dist > 0 && item.saida_dist <= 1000 ? 
                            <i className="pi pi-check" style={{ color: 'green'}}></i> 
                            : <i className="pi pi-times"  style={{ color: 'red'}}></i>
                        }
                    </div>} 
                />
            ) }
            { !isMobile && (
                <Column 
                    header={<span><i className="pi pi-plus hidden-big"></i><span className="hidden-small">1/2h Extra</span></span>} 
                    field="extra" 
                />
            ) }
        </DataTable>
            
        </div>
    </div>
    
  );

}

export default CheckpointList;
