import React, { useState, useEffect } from 'react';
import {Panel} from 'primereact/panel';
import {ProgressSpinner} from 'primereact/progressspinner';
import loader from '../loader';
import DiaryChart from './DiaryChart';
import DynamicChart from './DynamicChart';
import DiaryIncidents from './DiaryIncidents';
import Session from '../auth/Session';
import ClientsGoalsResume from '../clients/ClientsGoalsResume';

const serverUrl = process.env.REACT_APP_SERVER_URL;

function Dashboard() {

  let logged = Session.getCookie();
  let client_id = logged.client_id;

  let [ stickies, setStickies ] = useState([]);
  let [ data, setData ] = useState({});
  let [ client, setClient ] = useState(null);

  let [ loading, setLoading ] = useState(false);

  useEffect(() => {
    loader.get('/client/item/' + client_id, res => {
        setClient(res.item);
    }, setLoading);
  }, []);

  useEffect(() => {
    loader.get('/topic/stickies', res => {
        setStickies(res.items);
    }, setLoading);
  }, []);

  useEffect(() => {
    loader.get('/stats/dashboard', res => {
        setData(res.data);
    }, setLoading);
  }, []);

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/topic_placeholder.png';
  }

  if (loading || !client) return <ProgressSpinner />;
  return (
      <React.Fragment>
          <h1>{ client.firstname} {client.lastname}</h1>
          
          { loading ? <ProgressSpinner/> : (
            stickies.map(topic => (
              <Panel header={topic.title} className="alert" key={topic.id}>
                { !!topic.image && (
                  <img 
                    alt="Imagem"
                    className="image-pad-right" 
                    src={getFileUrl(topic.image, 'topics')} 
                  />
                ) }
                <div dangerouslySetInnerHTML={{ __html: topic.description }}></div>
                <div style={{ clear: 'right' }}></div>
              </Panel>
            ))
          ) }

          <div className="p-grid p-fluid dashboard">
            <div className="p-col-12 p-lg-4">
              <div className="card card-w-title" style={{ minHeight: '365px' }}>
                <h1>Metas</h1>
                <ClientsGoalsResume client={client} />
              </div>
            </div>
            <div className="p-col-12 p-lg-8">
              <DiaryChart />
            </div>
            <div className="p-col-12 p-lg-8">
              <DynamicChart />
            </div>
            <div className="p-col-12 p-lg-4">
              <DiaryIncidents />
            </div>
          </div>
          
      </React.Fragment>  
  );
}

export default Dashboard;
