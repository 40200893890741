import React, { useState, useEffect } from 'react';
import loader from '../loader';
import Session from '../auth/Session';
import timeSetup from '../timeSetup';

const getItem = (res, setLasttime) => {
    let titem = res.item;
    titem.start = timeSetup.from(titem.start).format('YYYY-MM-DD HH:mm:ss');
    titem.end = titem.end ? timeSetup.from(titem.end).format('YYYY-MM-DD HH:mm:ss') : null;
    setLasttime(titem);
}

function OutOfOffice() {

    let [ lasttime, setLasttime ] = useState(null);
    let [ loading, setLoading ] = useState(false);

    // Init from server
    useEffect(() => {
        let me = Session.getCookie();
        if (!me) return;
        loader.get('/user/outofservice/last/' + me.id, res => {
            if (!res.item) return;
            getItem(res, setLasttime);
        }, setLoading);
    }, []);

    // Toggle
    const toggle = () => {
        let me = Session.getCookie();
        if (!me) return;
        loader.get('/user/outofservice/toggle/' + me.id, res => {
            if (!res.success) return;
            getItem(res, setLasttime);
        }, setLoading);
    }

    if (loading) return <i className="pi pi-spin pi-spinner" style={{ fontSize: '1.7rem' }} />;
    return (
        <button 
            className="p-link" 
            title="Ausência"
            onClick={e => toggle()} 
            style={{ color: lasttime && lasttime.end === null ? 'red' : 'orange' }}>
            <span className="layout-topbar-item-text">Ausência</span>
            <span className="layout-topbar-icon pi pi-clock"/>
        </button>
    )
}

export default OutOfOffice;