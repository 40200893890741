import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import {InputSwitch} from 'primereact/inputswitch';
import { Message } from 'primereact/message';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toolbar} from 'primereact/toolbar';
import {Card} from 'primereact/card';
import { useHistory, Link } from "react-router-dom";
import loader from '../loader';
import Session from '../auth/Session';
import { ProgressSpinner } from 'primereact/progressspinner';

const serverUrl = process.env.REACT_APP_SERVER_URL;

function GoalsList(props) {

  const { client_id } = props;

  let [ items, setItems ] = useState([]);
  let [ display, setDisplay ] = useState('grid');
  
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  let history = useHistory();

  useEffect(() => {
    const url = '/goal/list' + (client_id ? '/' + client_id : '');
    loader.get(url, res => {
        setItems(res.items);
    }, setLoading);
  }, [client_id]);

  let updateItem = (data) => {
    const url = '/goal/update';
    setLoading(true);
    setSuccess(false);
    loader.post(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then(res => res.json())
    .then(res => {
        setLoading(false);
        setSuccess(true);
    })
    .catch(err => {
        setLoading(false);
        console.log(err);
    });
  };

  let deleteItem = (id) => {
    const url = '/goal/delete/' + id;
    setSuccess(false);
    loader.get(url, res => {
        if (!res.success) return;
        setSuccess(true);
        items = items.filter(i => i.id !== id);
        setItems(items);
    }, setLoading);
  };

  let toggleActive = (goals, id) => {
    let items = Object.assign([], goals);
    items = items.map(item => {
        if (item.id === id) {
            item.active = !item.active;
            updateItem({ id: item.id, active: item.active });
        }
        return item;
    });
    setItems(items);
  }

  let recordOptions = (item, column) => {
    const logged = Session.getCookie();
    return <div>
        <Button
            onClick={e => history.push({ pathname: '/backoffice/goals/form/' + item.id })} 
            type="button" 
            icon="pi pi-pencil" 
            className="p-button-info"
        />
        <Button
            onClick={e => deleteItem(item.id)} 
            type="button" 
            icon="pi pi-times" 
            className="p-button-danger"
            disabled={![logged.role1].includes('ADMINISTRATOR')}
        />
    </div>;
  }

  let recordActive = (item, column) => {
    return <div>
        <InputSwitch 
            checked={item.active} 
            onChange={(e) => toggleActive(items, item.id)} 
        />
    </div>;
  }

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/photo_placeholder.png';
  }

  let recordPhoto = (item, column) => {
    return (
      <Link to={"/backoffice/clients/view/" + item.client_id}>
        <img 
            src={getFileUrl(item.clientphoto, 'clients')} 
            alt="Fotografia"
            title="Fotografia"
            className="img-avatar-mini"
        />
      </Link>
    )
  }

  if (loading) return <ProgressSpinner />;
  return (
    <React.Fragment>
        <div className="card card-w-title">
            <h1>Metas</h1>

            <Toolbar>
                <div className="p-toolbar-group-left">
                    <Link to="/backoffice/goals/form/0">
                        <Button label="Inserir Nova" />
                    </Link>
                </div>
                <div className="p-toolbar-group-right">
                    <InputSwitch 
                        label="Grelha"
                        checked={display === 'grid'} 
                        onChange={(e) => setDisplay(display === 'grid' ? 'list' : 'grid')} 
                    />{' '}Grelha
                </div>
            </Toolbar>

            { success && (
                <div className="p-grid">
                    <div className="p-col-12">

                        <Message 
                            severity="success" 
                            text="A meta foi alterada com sucesso!">
                        </Message>
                        
                    </div>
                </div>
            ) }

            { display === 'grid' ? (
                <div className="p-grid">

                    { items.map(item => (
                        <div className="p-col-6 p-lg-2" key={item.id}>
                            <Card
                                header={(
                                    <Link to={'/backoffice/goals/form/' + item.id }>
                                        <img 
                                            src={getFileUrl(item.logo, 'goals')} 
                                            alt="Logotipo"
                                            title="Logotipo"
                                            className="img-avatar"
                                        />
                                    </Link>
                                )}>
                                { item.title }
                            </Card>
                        </div>
                    )) }
                    
                </div>
            ) : (
                <div className="p-grid">
                    <div className="p-col-12">

                    <DataTable value={items} responsive={true}>
                        <Column body={recordPhoto} header="Logo" />
                        <Column field="title" header="Título" />
                        <Column body={recordActive} header="Activo" style={{ textAlign: 'center'}} />
                        <Column body={recordOptions} header="Opções" style={{ textAlign: 'right'}} />
                    </DataTable>
                        
                    </div>
                </div>
            ) }
            
        </div>
    </React.Fragment>
  );

}

export default GoalsList;
