import React from 'react';
import GoalsList from '../goals/GoalsList';

function ClientsGoals({ client, setClient }) {

  return (
    <GoalsList
      client_id={client.id}
    />

  );

}

export default ClientsGoals;
