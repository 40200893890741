import React, { useState, useEffect } from 'react';
import loader from '../loader';
import EntitiesList from '../entities/EntitiesList';
import { ProgressSpinner } from 'primereact/progressspinner';

function UsersContacts({ id }) {

  let [ user, setUser ] = useState();
  let [ loading, setLoading ] = useState(false);

  // Load user
  useEffect(() => {
    if (!parseInt(id, 10)) return;
    loader.get('/user/item/' + id, res => {
        setUser(res.item);
    }, setLoading);
  }, [id]);

  if (loading || !user) return <ProgressSpinner />;
  return (
    <React.Fragment>
 
        <div className="card card-w-title">
            <h1>Contactos</h1>
            <EntitiesList filter={{ user_id: user.id, name: user.username }} />
        </div>
        
    </React.Fragment>
  );

}

export default UsersContacts;
