import { Cookies } from 'react-cookie';
import moment from 'moment';
import 'moment/locale/pt';
moment.locale('pt');

const cookies = new Cookies();

const Session = {
    getCookie() {
        return cookies.get('team4care');
    },
    isAuthenticated() {
        return !!Session.getCookie();
    },
    authenticate(data, cb) {
        const options = {
            maxAge: moment(moment().endOf('month')).diff(moment(), 'seconds')
        }
        cookies.set('team4care', data, options);
        setTimeout(cb, 0);
    },
    signout(cb) {
        cookies.remove('team4care');
        setTimeout(cb, 0);
    },

    // Check permissions
    canAccessDiary(logged, diary) {
        if (!diary) return { result: true };
        if (!diary.id) return { result: true };

        const isAnalyst = [logged.role1, logged.role2].includes('ANALYST');
        const isOwner = logged.id === Number(diary.owner_id);
        const isClient = logged.id === Number(diary.client_id);
        const isAssigned = logged.id === Number(diary.user_id);
        const isLastClient = logged.last_client ? Number(logged.last_client) === Number(diary.client_id) : false;

        const reasons = [];
        if (!isAnalyst) reasons.push('Não é técnico de saúde');
        if (!isOwner) reasons.push('Não é o criador da tarefa');
        if (!isClient) reasons.push('Não é o cliente');
        if (!isAssigned) reasons.push('Não lhe está atribuida a tarefa');
        if (!isLastClient) reasons.push('Não está na localização do cliente (QR Code)');
        return { result: isAnalyst || isOwner || isClient || isAssigned || isLastClient, reasons };
    },

    canAccessClinicResume(logged, client) {
        if (!client) return true;
        if (!client.id) return true;

        const isAnalyst = [logged.role1, logged.role2].includes('ANALYST');
        const isClient = logged.client_id === Number(client.id);
        const reasons = [];
        if (!isAnalyst) reasons.push('Não é técnico de saúde');
        if (!isClient) reasons.push('Não é o cliente');
        return { result: isAnalyst || isClient, reasons };
    }
};

export default Session;