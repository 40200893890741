import React from 'react';

class Mapa extends React.Component {

    constructor(props) {
        super(props);
        this.mymap = null;
        this.popup = null;
        this.marker = null;
        this.defaultLat = 37.0202561;
        this.defaultLon = -7.9484004;
        this.defaultZoom = 9;
    }

    componentDidMount() {
        let latitude = this.props.data.latitude;
        let longitude = this.props.data.longitude;
        let zoom = this.props.data.mapzoom;
        if (!latitude) latitude = this.defaultLat;
        if (!longitude) longitude = this.defaultLon;
        if (!zoom) zoom = this.defaultZoom;
        
        // Create map
        this.mymap = window.L.map('mapid')
            .setView([latitude, longitude], zoom);
        window.L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.mymap);
        let myIcon = window.L.icon({
            iconUrl: './assets/images/marker-icon.png',
            iconSize: [25, 41]
        });
        
        // Create markers
        this.marker = window.L.marker([latitude, longitude], { draggable: true, icon: myIcon }).addTo(this.mymap);
        let props = this.props;
        let mymap = this.mymap;
        let move = { lat: latitude, lon: longitude, zoom: this.mymap.getZoom() };
        this.mymap.on('moveend', e => {
            move.zoom = mymap.getZoom();
        });
        this.marker.on('move', e => {
            if (!e.latlng) return;
            move.lat = e.latlng.lat;
            move.lon = e.latlng.lng;
        });
        this.marker.on('moveend', e => {
            if (!move) return;
            if (props.onMove) props.onMove(move);
        });
    }

    componentDidUpdate() {
        const { data } = this.props;
        let latitude = data.latitude;
        let longitude = data.longitude;
        if (!latitude) latitude = this.defaultLat;
        if (!longitude) longitude = this.defaultLon;
    }


    render() {
        return (
            <div id="mapid"></div>
        )
    }
}

export default Mapa;