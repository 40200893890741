import React, { useState, useEffect, useReducer } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import {InputSwitch} from 'primereact/inputswitch';
import { Message } from 'primereact/message';
import {FileUpload} from 'primereact/fileupload';
import {Editor} from 'primereact/editor';
import {PickList} from 'primereact/picklist';
import loader from '../loader';
import { ColorPicker } from 'primereact/colorpicker';
import { ProgressSpinner } from 'primereact/progressspinner';
import slugify from './slugify';

const serverUrl = process.env.REACT_APP_SERVER_URL;

function reducer(state, action) {
    switch (action.type) {
      case 'update':
        return {
            ...state,
            [action.field]: action.value
        };
      case 'update_all':
        return {
            ...action.value
        }
      default:
        throw new Error();
    }
}

function Form(props) {
  const { business_id } = props;

  const initialState = {
    title: '',
    description: '',
    price_text: '',
    discount_text: '',
    active: true,
    features: [],
    icon: 'pi-briefcase'
  };

  let [ business, dispatch ] = useReducer(reducer, initialState);
  let [ features, setFeatures ] = useState([]);
  
  let [ errors, setErrors ] = useState({});
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  // Load features
  useEffect(() => {
    loader.get('/feature/list', res => {
        setFeatures(res.items.filter(i => i.active));
    }, setLoading);
  }, []);

  // Load item
  useEffect(() => {
    if (!parseInt(business_id, 10)) {
        return dispatch({ type: 'update_all', value: initialState });
    }
    loader.get('/business/item/' + business_id, res => {
        const value = {
            ...res.item
        };
        dispatch({ type: 'update_all', value });
    }, setLoading);
  }, [business_id]);
  
  // Register
  let register = () => {
    let data = {
        ...business,
        features: business.features.map(f => f.id)
    };
    let url = '/business/create';
    if (business.id) url = '/business/update';
    setSuccess(false);
    loader.post(url, data, res => {
        if (res.errors) return setErrors(res.errors);
        dispatch({ type: 'update', field: 'id', value: res.id });
        setSuccess(true);
    }, setLoading);
  };

  let isDataInvalid = () => {
      return loading
        || business.title.length < 3;
  }

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/topic_placeholder.jpg';
  }

  // Upload
  const onUpload = (e) => {
    if (e.files.length === 0) return;
    const formData  = new FormData();
    formData.append('folder', 'business');
    formData.append('upload', e.files[0]);
    const url = '/upload';
    loader.upload(url, formData, res => {
      if (!res.filename) return;
      dispatch({ type: 'update', field: 'image', value: res.filename });  
    }, setLoading);
  }

  const onRemoveImage = (e, file) => {
    dispatch({ type: 'update', field: 'image', value: '' });
  }

  let displayErrors = (errors, key) => !!errors[key] && errors[key].map(msg =>
    <Message severity="error" text={msg}></Message>
  )

  const availableFeatures = features.filter(item1 => 
    !business.features.find(item2 => item1.id === item2.id)
  );

  if (loading) return <ProgressSpinner />;
  const msg = "O plano foi guardado com sucesso!";
  return (
    <React.Fragment>

        { success && (
            <div className="p-grid">
                <div className="p-col-12">

                    <Message severity="success" text={msg}></Message>
                    
                </div>
            </div>
        ) }

        <Button 
            onClick={register} 
            label="Guardar"
            disabled={isDataInvalid()}
            style={{ float: 'right' }}
        />

        <p className="form-info">
            <strong style={{ color: 'red' }}>*</strong> 
            {' '}Campos obrigatórios
            
        </p>

        <div className="p-grid">
            <div className="p-col-12 p-lg-6">
                <div className="p-grid">
                    
                    <div className="p-col-12 p-md-6">
                        <label className="feature-required">Título</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <InputText 
                            placeholder="Título"
                            value={business.title}
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'title', value: e.target.value })
                            }
                        />
                        { displayErrors(errors, 'title') }
                    </div>

                    <div className="p-col-12 p-md-6">
                        <label>Preço (opcional)</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <InputText 
                            placeholder="Preço"
                            value={business.price_text}
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'price_text', value: e.target.value })
                            }
                        />
                    </div>

                    <div className="p-col-12 p-md-6">
                        <label>Desconto (opcional)</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <InputText 
                            placeholder="Desconto"
                            value={business.discount_text}
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'discount_text', value: e.target.value })
                            }
                        />
                    </div>

                    <div className="p-col-10 p-md-6">
                        <label>Activo</label>
                    </div>
                    <div className="p-col-2 p-md-6">
                        <InputSwitch 
                            checked={business.active} 
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'active', value: !business.active })
                            } 
                        />
                    </div>

                    <React.Fragment>
                        <div className="p-col-12 p-md-6">
                            <label>Côr de Etiqueta</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <ColorPicker
                                value={business.color} 
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'color', value: e.value })
                                }
                            />
                        </div>
                    </React.Fragment>
                    
                    <React.Fragment>
                        <div className="p-col-12 p-md-6">
                            <label>Icone</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <InputText 
                                placeholder="Icone"
                                value={business.icon}
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'icon', value: e.target.value })
                                }
                            />
                        </div>
                    </React.Fragment>
                    
                    <React.Fragment>
                        <div className="p-col-12 p-md-6">
                            <label className="feature-required">Imagem</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            { !!business.image && (
                                <img 
                                    src={getFileUrl(business.image, 'business')} 
                                    alt="Imagem"
                                    title="Imagem"
                                    className="img-avatar"
                                />
                            ) }
                            <FileUpload 
                                name="logo"
                                mode="basic"
                                url={'/upload'}
                                customUpload={true}
                                uploadHandler={onUpload}
                                auto={true}
                                chooseLabel={business.image ? "Alterar" : "Escolher" }
                                onRemove={onRemoveImage}
                            />
                        </div>
                    </React.Fragment>
                    
                </div>
            </div>
            <div className="p-col-12 p-lg-6">
                <Editor 
                    style={{height: '320px'}} 
                    value={business.content}
                    onTextChange={(e) => 
                        dispatch({ type: 'update', field: 'content', value: e.htmlValue })
                    }
                />
            </div>

            <React.Fragment>
                <h5>Itens do Plano</h5>

                <PickList 
                    source={availableFeatures} 
                    target={business.features} 
                    sourceHeader="Items disponíveis"
                    targetHeader="Items selecionados"
                    itemTemplate={item => item.title}
                    responsive={false}
                    onChange={(e) => 
                        dispatch({ type: 'update', field: 'features', value: e.target })
                    }
                />
                
            </React.Fragment>

        </div>
    </React.Fragment>
  );

}

export default Form;
