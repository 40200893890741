import React from 'react';
import { withRouter } from 'react-router-dom';

class Mapa extends React.Component {

    constructor(props) {
        super(props);
        this.mymap = null;
        this.items = [];
    }

    getIconStyle(item) {
        return window.L.icon({
            iconUrl: item.client_id ? 'assets/images/marker-icon-client.png' :
                item.user_id ? 'assets/images/marker-icon-user.png' : 
                'assets/images/marker-icon.png',
            iconSize: [25, 41],
            iconAnchor: [12, 20],
            popupAnchor: [0, 0],
            shadowUrl: 'assets/images/marker-shadow.png',
            shadowSize: [41, 41],
            shadowAnchor: [10, 20]
        });
    }

    update() {
        const { items } = this.props;
        if (this.items.length) {
            this.items.forEach(item => item.remove());
        }
        items.forEach(item => {
            let content = document.createElement('span');
            content.textContent = item.label;
            content.onclick = () => this.props.history.push(item.link);
            let marker = window.L.marker([item.lat, item.lon], { icon: this.getIconStyle(item) })
                .bindPopup(content, { autoClose: false })
                .addTo(this.mymap)
            this.items.push(marker);
        });
    }

    componentDidMount() {
        this.mymap = window.L.map('mapid')
            .setView([37.0202561, -7.9484004], 9);
        window.L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.mymap);
        this.update();
    }

    componentDidUpdate() {
        this.update();
    }

    componentWillUnmount() {
        if (this.items.length) {
            this.items.forEach(item => item.remove());
        }
        this.mymap.remove();
        this.mymap = null;
    }


    render() {
        return (
            <div id="mapid"></div>
        )
    }
}

export default withRouter(Mapa);