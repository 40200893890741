import React from 'react';
import OutOfServiceForm from './OutOfServiceForm';
import { useParams } from "react-router-dom";

function OutOfServiceFormRoute() {

  let urlParams = useParams();

  const title = parseInt(urlParams.id) ? "Alterar Ausência" : "Registar Ausência / Férias";
  return (
    <React.Fragment>
        <div className="card card-w-title">
          <h1>{ title }</h1>
          <OutOfServiceForm user_id={urlParams.user_id} id={urlParams.id} />
        </div>
    </React.Fragment>
  );

}

export default OutOfServiceFormRoute;
