import React, { useState, useEffect } from 'react';
import loader from '../loader';
import useInterval from '../messages/useInterval';

let notification = null;

function createNotification(item) {
    if (notification) notification.close();
    let options = {
        tag: 'renotify',
        renotify: true
    };
    notification = new Notification("Colaborador ausente: " + item.username, options);
}

function OutOfOfficeCheck() {

    let [ last, setLast ] = useState(null);
    let [ loading, setLoading ] = useState(false);

    const reload = () => {
        loader.get('/user/outofservice/lastusers', res => {
            if (res.items.length) {
                if (res.items[0].end === null && ((last && res.items[0].id !== last.id) || !last)) {
                    createNotification(res.items[0]);
                }
                setLast(res.items[0]);
            }
        }, setLoading);
    }

    useInterval(() => {
        reload();
    }, 120 * 1000);

    useEffect(() => {
        reload();
    }, []);

    if (loading) return <i className="pi pi-spin pi-spinner" style={{ fontSize: '1.7rem' }} />;
    return null;
}

export default OutOfOfficeCheck;