import React from 'react';
import loader from '../loader';
import ClientOutList from './ClientOutList';
import Session from '../auth/Session';
import { useEffect } from 'react';
import { useState } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

function ClientOutListRoute() {

  let [ client, setClient ] = useState(null);
  let [ loading, setLoading ] = useState(false);

  let logged = Session.getCookie();
  let id = logged.client_id;

  // Load client
  useEffect(() => {
      setLoading(true);
    if (!parseInt(id, 10)) return;
    loader.get('/client/item/' + id, res => {
        setClient(res.item);
        setLoading(false);
    });
  }, [id]);

  if (loading || !client) return <ProgressSpinner />;

  return (
    <div className="card card-w-title">
      <h1>Ausências</h1>

      <ClientOutList id={id} client={client} />
    </div>
  );

}

export default ClientOutListRoute;
