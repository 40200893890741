import React from 'react';
import {TabMenu} from 'primereact/tabmenu';
import { useHistory } from "react-router-dom";
import Session from '../auth/Session';
import { hasMod } from '../users/Permissions';

function ClientsMenu({ client, activeMenu }) {
    
    let history = useHistory();
    const { id } = client;
    let logged = Session.getCookie();
    
    const items = [
        {id: "view", label: 'Geral', icon: 'pi pi-fw pi-home', url: '#/backoffice/clients/view/' + id}
    ];

    if (['ADMINISTRATOR', 'ORG_ADMIN', 'ADMIN'].includes(logged.role1)) {
        items.push({id: "form", label: 'Ficha', icon: 'pi pi-fw pi-pencil', url: '#/backoffice/clients/form/' + id});
    }

    if (['ADMINISTRATOR', 'ORG_ADMIN', 'ANALYST'].includes(logged.role1)) {
        items.push({id: "goals", label: 'Metas', icon: 'pi pi-fw pi-star', url: '#/backoffice/clients/goals/' + id});
        if (hasMod(['T4CPI1'])) items.push({id: "plans", label: 'Plano Individual', icon: 'pi pi-fw pi-calendar', url: '#/backoffice/clients/plans/' + id});
        if (hasMod(['T4CTP1'])) items.push({id: "therapy", label: 'Terapêutica', icon: 'pi pi-fw pi-heart', url: '#/backoffice/clients/therapy/' + id});
        items.push({id: "history", label: 'Histórico', icon: 'pi pi-fw pi-list', url: '#/backoffice/clients/history/' + id});
    }
    
    if (['ADMINISTRATOR', 'ORG_ADMIN', 'ADMIN'].includes(logged.role1)) {
        if (hasMod(['T4CCP1'])) items.push({id: "entities", label: 'Contactos', icon: 'pi pi-fw pi-user', url: '#/backoffice/clients/entities/' + id});
        if (hasMod(['T4CLC1'])) items.push({id: "location", label: 'Localização', icon: 'pi pi-fw pi-map-marker', url: '#/backoffice/clients/location/' + id});
    }

    if (['ADMINISTRATOR', 'ORG_ADMIN', 'ADMIN', 'ANALYST'].includes(logged.role1)) {
        items.push({id: "out", label: 'Ausências', icon: 'pi pi-fw pi-sign-out', url: '#/backoffice/clients/out/' + id});
        items.push({id: "docs", label: 'Documentos', icon: 'pi pi-fw pi-paperclip', url: '#/backoffice/clients/docs/' + id});
    }
    if (hasMod(['T4CGD1'])) items.push({id: "graphs", label: 'Gráficos', icon: 'pi pi-fw pi-chart-bar', url: '#/backoffice/clients/graphs/' + id});
    if (hasMod(['T4CXP2'])) items.push({id: "report", label: 'Relatório', icon: 'pi pi-fw pi-file-pdf', url: '#/backoffice/clients/report/' + id});
    if (hasMod(['T4CFR1'])) items.push({id: "discuss", label: 'Discussões', icon: 'pi pi-fw pi-comment', url: '#/backoffice/clients/discuss/' + id});

    let activeItem = items.find(i => i.id === activeMenu);
    return (
        <React.Fragment>
            <TabMenu id="clientmenu" 
                model={items} 
                activeItem={activeItem}
                onTabChange={(e) => {
                    history.push({ pathname: e.value.url })
                }}
            />
        </React.Fragment>
    );
}

export default ClientsMenu;