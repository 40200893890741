import React from 'react';
import Session from '../auth/Session';
import { Button } from 'primereact/button';
import { useHistory } from "react-router-dom";
import CheckpointList from '../users/CheckpointList';

function CheckpointListRoute() {

  //let urlParams = useParams();
  let logged = Session.getCookie();
  let history = useHistory();

  return (
    <React.Fragment>
        <div className="card card-w-title">
          <h1>As Minhas Presenças</h1>

          <Button
              style={{ float: 'right' }} 
              onClick={e => history.goBack()} 
              label="Voltar"
              className="p-button-danger"
          />

          <CheckpointList user_id={logged.id} />
        </div>
    </React.Fragment>
  );

}

export default CheckpointListRoute;
