import React from 'react';
import { useParams } from "react-router-dom";
import GoalsForm from './GoalsForm';

function GoalsFormRoute() {

  let urlParams = useParams();

  let title = parseInt(urlParams.id, 10) ? "Alterar Meta" : "Nova Meta";
  return (
    <React.Fragment>
        <div className="card card-w-title">
          <h1>{ title }</h1>
          <GoalsForm
            id={urlParams.id}
            client_id={urlParams.client_id}
          />
        </div>
    </React.Fragment>
  );

}

export default GoalsFormRoute;
