import React from 'react';
import { useLocation } from "react-router-dom";
import EntitiesList from './EntitiesList';

function EntitiesListRoute() {

  let filter = {};
  let search = useLocation().search;
  search.replace('?', '').split('&').map(i => {
    let kv = i.split('=');
    filter[kv[0]] = kv[1];
    return kv;
  });

  return (
    <React.Fragment>
        <div className="card card-w-title">
          <h1>Contactos</h1>
            <EntitiesList filter={filter} />
        </div>
    </React.Fragment>
  );

}

export default EntitiesListRoute;
