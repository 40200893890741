import React from 'react';
import TopicsForm from './TopicsForm';
import { useParams } from "react-router-dom";

function TopicsFormRoute() {

  let urlParams = useParams();

  const defaultActivity = !!urlParams.activity;
  const label = defaultActivity ? 'Tipo de Tarefa' : 'Conteúdo';
  const title = parseInt(urlParams.id, 10) ? "Alterar " + label : "Criar " + label;
  return (
    <React.Fragment>
        <div className="card card-w-title">
          <h1>{ title }</h1>
          
          <TopicsForm 
            topic_id={urlParams.id} 
            defaultActivity={defaultActivity} 
          />
        </div>
    </React.Fragment>
  );

}

export default TopicsFormRoute;
