import React, { useState, useEffect, useReducer } from 'react';
import { Button } from 'primereact/button';
import {InputSwitch} from 'primereact/inputswitch';
import {Dropdown} from 'primereact/dropdown';
import {Checkbox} from 'primereact/checkbox';
import { Message } from 'primereact/message';
import { Calendar } from 'primereact/calendar';
import {Editor} from 'primereact/editor';
import moment from 'moment';
import 'moment/locale/pt';
import loader from '../loader';
import Session from '../auth/Session';
import dailyOptions from './daily.json';
import weeklyOptions from './weekly_days.json';
import monthlyOptions from './monthly.json';
import durationOptions from './duration.json';
import date_pt from './date_pt.json';
import { ColorPicker } from 'primereact/colorpicker';
import timeSetup from '../timeSetup';
import { ProgressSpinner } from 'primereact/progressspinner';
moment.locale('pt');

function Form(props) {
  let { id, owner_id } = props;

  let logged = Session.getCookie();
  const initialState = {
    description: '',
    active: true,
    owner_id: parseInt(owner_id, 10) ? owner_id : logged.id,
    freq_month: '*',
    freq_week: '1,2,3,4,5,6,7',
    freq_day: '*',
    day_hourmin: moment().startOf('day').add(8, 'hour').format('YYYY-MM-DD HH:mm:ss'),
    start_date: moment().startOf('day').add(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
    end_date: null,
    client_id: null,
    duration: 60
  };

  let [ users, setUsers ] = useState([]);
  let [ clients, setClients ] = useState([]);

  function reducer(state, action) {
    switch (action.type) {
      case 'update':
        return {
            ...state,
            [action.field]: action.value
        };
      case 'update_all':
        return {
            ...action.value
        }
      default:
        throw new Error();
    }
  }
  let [ workplan, dispatch ] = useReducer(reducer, initialState);
  
  let [ errors, setErrors ] = useState({});
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  // Load users
  useEffect(() => {
    loader.get('/user/list', res => {
        setUsers(res.items);
    }, setLoading);
  }, []);

  // Load clients
  useEffect(() => {
    loader.get('/client/list', res => {
        setClients(res.items);
    }, setLoading);
  }, []);

  // Load item
  useEffect(() => {
    if (!parseInt(id, 10)) {
        return dispatch({ type: 'update_all', value: initialState });
    }
    loader.get('/workplan/item/' + id, res => {
        const value = {
            ...res.item,
            start_date: timeSetup.from(res.item.start_date).format('YYYY-MM-DD HH:mm:ss'),
            end_date: res.item.end_date ? timeSetup.from(res.item.end_date).format('YYYY-MM-DD HH:mm:ss') : null,
            day_hourmin: timeSetup.from(res.item.day_hourmin).format('YYYY-MM-DD HH:mm:ss')
        };
        dispatch({ type: 'update_all', value });
    }, setLoading);
  }, [id]);
  
  // Register
  let register = () => {
    let data = {
        ...workplan,
        start_date: timeSetup.to(workplan.start_date).format('YYYY-MM-DD HH:mm:ss'),
        end_date: workplan.end_date ? timeSetup.to(workplan.end_date).format('YYYY-MM-DD HH:mm:ss') : null,
        day_hourmin: timeSetup.to(workplan.day_hourmin).format('YYYY-MM-DD HH:mm:ss')
    };
    let url = '/workplan/create';
    if (workplan.id) url = '/workplan/update';
    setSuccess(false);
    loader.post(url, data, res => {
        if (res.errors) return setErrors(res.errors);
        dispatch({ type: 'update', field: 'id', value: res.id });
        setSuccess(true);
    }, setLoading);
  };

  let isDataInvalid = () => {
      return loading
        || !workplan.owner_id;
  }
  
  let displayErrors = (errors, key) => !!errors[key] && errors[key].map(msg =>
    <Message severity="error" text={msg}></Message>
  )

  // Get owner options
  const ownerOptions = users.map(opt => ({
    label: opt.username,
    value: opt.id,
    key: opt.id
  }));

  // Get client options
  const clientOptions = clients.map(opt => ({
    label: opt.firstname + ' ' + opt.lastname,
    value: opt.id,
    key: opt.id
  }));

  if (loading) return <ProgressSpinner />;

  let day_hourmin = workplan.day_hourmin ?
    moment(workplan.day_hourmin).toDate() 
    : moment().toDate()
  let start_date = workplan.start_date ? 
    moment(workplan.start_date).toDate() : moment().toDate();
  let end_date = workplan.end_date ? 
    moment(workplan.end_date).toDate() : null;
  return (
    <React.Fragment>

        { success && (
            <div className="p-grid">
                <div className="p-col-12">

                    <Message 
                        severity="success" 
                        text="O horário foi guardado com sucesso!">
                    </Message>
                    
                </div>
            </div>
        ) }

            <Button 
            onClick={register} 
            label="Guardar"
            disabled={isDataInvalid()}
            style={{ float: 'right' }}
            />
            <p className="form-info">
                <strong style={{ color: 'red' }}>*</strong> 
                {' '}Campos obrigatórios
            </p>

            <div className="p-grid">
            <div className="p-col-12 p-lg-6">
                <div className="p-grid">

                    <div className="p-col-6 p-md-6">
                        <label className="field-required">Colaborador</label>
                    </div>
                    <div className="p-col-6 p-md-6">
                        <Dropdown 
                            placeholder="Escolha um utilizador"
                            value={parseInt(workplan.owner_id, 10)}
                            options={ownerOptions} 
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'owner_id', value: e.value })
                            }
                        />
                    </div>

                    <div className="p-col-12 p-md-6">
                        <label>Cliente Associado</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Dropdown 
                            placeholder="Escolha um cliente"
                            value={parseInt(workplan.client_id, 10)}
                            options={clientOptions} 
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'client_id', value: e.value })
                            }
                        />
                    </div>

                    <div className="p-col-10 p-md-6">
                        <label className="field-required">Activo</label>
                    </div>
                    <div className="p-col-2 p-md-6">
                        <InputSwitch 
                            checked={workplan.active} 
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'active', value: !workplan.active })
                            } 
                        />
                    </div>

                    { !!workplan.color && (
                        <React.Fragment>
                            <div className="p-col-12 p-md-6">
                                <label>Côr de Etiqueta</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <ColorPicker
                                    value={workplan.color} 
                                    onChange={(e) => 
                                        dispatch({ type: 'update', field: 'color', value: e.value })
                                    }
                                />
                            </div>
                        </React.Fragment>
                    )}

                    <div className="p-col-12 p-md-6">
                        <label className="field-required">Frequência</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Dropdown 
                            placeholder="Escolha uma opção"
                            value={workplan.freq_month}
                            options={monthlyOptions} 
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'freq_month', value: e.value })
                            }
                        />
                    </div>

                    { workplan.freq_month === 'unique_datetime' ? (
                        <React.Fragment>
                            <div className="p-col-12 p-md-6">
                                <label className="field-required">Data / Hora</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <Calendar 
                                    inline={true}
                                    placeholder="Data / Hora"
                                    value={workplan.unique_datetime || ''}
                                    locale={date_pt}
                                    monthNavigator={true} 
                                    yearNavigator={true} 
                                    yearRange="2020:2030"
                                    dateFormat="yy-mm-dd"
                                    showTime={true}
                                    hourFormat="24"
                                    onChange={(e) => 
                                        dispatch({ type: 'update', field: 'unique_datetime', value: e.value })
                                    }>
                                </Calendar>
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className="p-col-12 p-md-6">
                                <label>Freq. Semanal</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                                { weeklyOptions.map(opt => (
                                    <React.Fragment>
                                        <Checkbox 
                                            inputId={"w" + opt.value} 
                                            value={opt.value}
                                            onChange={e => {
                                                let selected = workplan.freq_week.split(',');
                                                if (e.checked) selected.push(e.value);
                                                else selected.splice(selected.indexOf(e.value), 1);
                                                dispatch({ type: 'update', field: 'freq_week', value: selected.join(',') });        
                                            }} 
                                            checked={workplan.freq_week.split(',').includes(opt.value)}
                                        />
                                        <label htmlFor={"w" + opt.value} className="p-checkbox-label weekopt">
                                            { opt.label }
                                        </label>
                                        <br />
                                    </React.Fragment>
                                ))}
                            </div>

                            <div className="p-col-12 p-md-6">
                                <label>Freq. Diária</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <Dropdown 
                                    placeholder="Escolha uma opção"
                                    value={workplan.freq_day}
                                    options={dailyOptions} 
                                    onChange={(e) => 
                                        dispatch({ type: 'update', field: 'freq_day', value: e.value })
                                    }
                                />
                            </div>

                            <div className="p-col-12 p-md-6">
                                <label className="field-required">Hora de Inicio</label>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <Calendar 
                                    inline={true}
                                    placeholder="Hora"
                                    value={day_hourmin} 
                                    timeOnly={true} 
                                    showTime={true} 
                                    hourFormat="24"
                                    onChange={(e) => {
                                        dispatch({ type: 'update', field: 'day_hourmin', value: e.value })
                                    }}>
                                </Calendar>
                            </div>
                        </React.Fragment>
                    ) }

                    <div className="p-col-12 p-md-6">
                        <label className="field-required">Duração</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Dropdown 
                            placeholder="Escolha uma opção"
                            value={workplan.duration}
                            options={durationOptions} 
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'duration', value: e.value })
                            }
                        />
                        { displayErrors(errors, 'duration') }
                    </div>

                    <div className="p-col-12 p-md-6">
                        <label className="field-required">Inicio</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Calendar 
                            placeholder="Inicio"
                            value={start_date}
                            locale={date_pt}
                            monthNavigator={true} 
                            yearNavigator={true} 
                            yearRange="2020:2030"
                            dateFormat="yy-mm-dd "
                            showTime={true} 
                            hourFormat="24"
                            onChange={(e) => {
                                dispatch({ type: 'update', field: 'start_date', value: e.value })
                            }}>
                        </Calendar>
                    </div>

                    <div className="p-col-12 p-md-6">
                        <label>Fim</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Calendar 
                            placeholder="Fim"
                            value={end_date}
                            locale={date_pt}
                            monthNavigator={true} 
                            yearNavigator={true} 
                            yearRange="2020:2030"
                            dateFormat="yy-mm-dd"
                            showTime={true} 
                            hourFormat="24"
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'end_date', value: e.value })
                            }>
                        </Calendar>
                    </div>

                </div>
            </div>
            <div className="p-col-12 p-lg-6">
                <Editor 
                    style={{height:'320px'}} 
                    value={workplan.description}
                    onTextChange={(e) => 
                        dispatch({ type: 'update', field: 'description', value: e.htmlValue })
                    }
                />
            </div>

        </div>

    </React.Fragment>
  );

}

export default Form;
