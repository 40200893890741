import React, { useState, useEffect } from 'react';
import {Panel} from 'primereact/panel';
import {ProgressSpinner} from 'primereact/progressspinner';
import loader from '../loader';
import DiaryChart from './DiaryChart';
import UserChart from './UserChart';
import DynamicChart from './DynamicChart';
import OutOfOfficeUsers from '../backoffice/OutOfOfficeUsers';
import DiaryIncidents from './DiaryIncidents';
import Session from '../auth/Session';

const serverUrl = process.env.REACT_APP_SERVER_URL;

function Stats() {

  let [ stickies, setStickies ] = useState([]);
  let [ data, setData ] = useState({});

  let [ loading, setLoading ] = useState(false);

  useEffect(() => {
    loader.get('/topic/stickies', res => {
        setStickies(res.items);
    }, setLoading);
  }, []);

  useEffect(() => {
    loader.get('/stats/dashboard', res => {
        setData(res.data);
    }, setLoading);
  }, []);

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/topic_placeholder.png';
  }

  if (loading) return <ProgressSpinner />;
  return (
      <React.Fragment>
          
          { loading ? <ProgressSpinner/> : (
            stickies.map(topic => (
              <Panel header={topic.title} className="alert" key={topic.id}>
                { !!topic.image && (
                  <img 
                    alt="Imagem"
                    className="image-pad-right" 
                    src={getFileUrl(topic.image, 'topics')} 
                  />
                ) }
                <div dangerouslySetInnerHTML={{ __html: topic.description }}></div>
                <div style={{ clear: 'right' }}></div>
              </Panel>
            ))
          ) }

          <div className="p-grid p-fluid dashboard">
            <div className="p-col-12 p-lg-4">
                <div className="card summary">
                    <span className="title">Clientes</span>
                    <span className="detail">Ativos</span>
                    <span className="count visitors">{ data.clients }</span>
                </div>
            </div>
            <div className="p-col-12 p-lg-4">
                <div className="card summary">
                    <span className="title">Atividades</span>
                    <span className="detail">Planeadas</span>
                    <span className="count purchases">{ data.plans }</span>
                </div>
            </div>
            <div className="p-col-12 p-lg-4">
                <div className="card summary">
                    <span className="title">Colaboradores</span>
                    <span className="detail">Ativos</span>
                    <span className="count revenue">{ data.users }</span>
                </div>
            </div>
          </div>

          <div className="p-grid p-fluid dashboard">
            <div className="p-col-12 p-lg-8">
              <DiaryChart />
              <DynamicChart />
              <UserChart />
              <OutOfOfficeUsers />
            </div>
            <div className="p-col-12 p-lg-4">
              <DiaryIncidents />
            </div>
          </div>
      </React.Fragment>  
  );
}

export default Stats;
