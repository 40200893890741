import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import {InputSwitch} from 'primereact/inputswitch';
import { Message } from 'primereact/message';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toolbar} from 'primereact/toolbar';
import { useHistory, Link } from "react-router-dom";
import moment from 'moment';
import loader from '../loader';
import monthlyOptions from './monthly.json';
import durationOptions from './duration.json';
import { ProgressSpinner } from 'primereact/progressspinner';
import Session from '../auth/Session';
import timeSetup from '../timeSetup';
import 'moment/locale/pt';
moment.locale('pt');

let dt = null;

const getItems = (res, setItems, therapy) => {
    let titems = res.items.filter(i => i.therapy === !!therapy);
    titems = titems.map(i => ({
        ...i,
        start_date: timeSetup.from(i.start_date).format('YYYY-MM-DD HH:mm:ss'),
        end_date: i.end_date ? timeSetup.from(i.end_date).format('YYYY-MM-DD HH:mm:ss') : null,
        day_hourmin: timeSetup.from(i.day_hourmin).format('YYYY-MM-DD HH:mm:ss')    
    }))
    setItems(titems);
}

function PlansList(props) {
  const { client_id, therapy } = props;

  let [ items, setItems ] = useState([]);
  
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  let history = useHistory();

  useEffect(() => {
    if (client_id) {
        loader.get('/plan/client/' + client_id, res => {
            if (!res.success) return;
            getItems(res, setItems, therapy);
        }, setLoading);
    } else {
        loader.get('/plan/list', res => {
            if (!res.success) return;
            getItems(res, setItems, therapy);
        }, setLoading);    
    }
  }, [therapy]);

  let updateItem = (data) => {
    const url = '/plan/update';
    setSuccess(false);
    loader.post(url, data, res => {
        setSuccess(true);
    }, setLoading);
  };

  let deleteItem = (id) => {
    const url = '/plan/delete/' + id;
    setSuccess(false);
    loader.get(url, res => {
        setSuccess(true);
        items = items.filter(i => i.id !== id);
        setItems(items);
    }, setLoading);
  };

  let toggleActive = (plans, id) => {
    let items = Object.assign([], plans);
    items = items.map(item => {
        if (item.id === id) {
            item.active = !item.active;
            item.end_date = !item.active ? moment().format('YYYY-MM-DD') : null
            updateItem({ id: item.id, active: item.active, end_date: item.end_date });
        }
        return item;
    });
    setItems(items);
  }

  const logged = Session.getCookie();
  const isSuperAdmin = ['ADMINISTRATOR', 'ORG_ADMIN'].includes(logged.role1);
  const isAnalyst = [logged.role1, logged.role2].includes('ANALYST');

  let recordOptions = (item, column) => {
    return <div>
        <Button
            onClick={e => history.push({ pathname: '/backoffice/plans/form/' + item.id })} 
            type="button" 
            icon="pi pi-pencil" 
            className="p-button-info"
            disabled={!isSuperAdmin && !isAnalyst}
        />
        {/*
        <Button
            onClick={e => deleteItem(item.id)} 
            type="button" 
            icon="pi pi-times" 
            className="p-button-danger"
            disabled={![logged.role1].includes('ADMINISTRATOR')}
        />
        */}
    </div>;
  }

  let recordActive = (item, column) => {
    return <div>
        <InputSwitch 
            disabled={!(isSuperAdmin || isAnalyst)}
            checked={item.active} 
            onChange={(e) => toggleActive(items, item.id)} 
        />
    </div>;
  }

  const getOptionLabel = (options, value) => {
    const item = options.find(opt => opt.value === value);
    return item ? item.label : value;
  }

  const isMobile = window.innerWidth <= 768;
  if (loading) return <ProgressSpinner />;
  let createUrl = "/backoffice/plans/form/0";
  createUrl += '/' + (parseInt(client_id, 10) ? client_id : '0');
  if (therapy) createUrl += '/1';
  return (
    <React.Fragment>

        <Toolbar>
            <div className="p-toolbar-group-left">
                { (isSuperAdmin || isAnalyst) && (
                    <Link to={createUrl}>
                        <Button label={therapy ? "Adicionar Terapêutica" : "Adicionar Atividade" } />
                    </Link>
                )}
            </div>
            <div className="p-toolbar-group-right">
                <Button
                    type="button" 
                    icon="pi pi-external-link" 
                    iconPos="left" 
                    label="CSV" 
                    onClick={e => dt.exportCSV()}
                    style={{ float: 'right' }}
                />
            </div>
        </Toolbar>

        { success && (
            <div className="p-grid">
                <div className="p-col-12">

                    <Message 
                        severity="success" 
                        text="O registo foi alterada com sucesso!">
                    </Message>
                    
                </div>
            </div>
        ) }

        <div className="p-grid plans">
            <div className="p-col-12">

            <DataTable value={items} ref={(el) => { dt = el; }}>
                { (!client_id || !isMobile) && (
                    <Column 
                        header="Cliente"
                        field="clientname"  
                        sortable={true}
                        filter={true}
                        filterMatchMode="contains"
                    />
                ) }
                
                <Column 
                    header="Título"
                    field="title"
                    sortable={true}
                    filter={true}
                    filterMatchMode="contains"
                />
                
                { !isMobile && (
                    <Column 
                        header="Tipo" 
                        field="freq_month"
                        body={i => getOptionLabel(monthlyOptions, i.freq_month)} 
                    />
                ) }
                <Column 
                    header="Hora" 
                    field="day_hourmin"
                    body={item => moment(item.day_hourmin).format('HH:mm')}
                />
                { !isMobile && (
                    <Column 
                        header="Duração" 
                        field="duration"
                        body={i => getOptionLabel(durationOptions, i.duration)}
                    />
                ) }
                { !isMobile && (
                    <Column 
                        header="Inicio" 
                        field="start_date"
                        body={item => item.start_date ? moment(item.start_date).format('YYYY-MM-DD') : ''}
                    />
                ) }
                { !isMobile && (
                    <Column 
                        header="Fim" 
                        field="end_date"
                        body={item => {
                            return item.end_date ? moment(item.end_date).format('YYYY-MM-DD') : ''
                        }}
                    />
                ) }
                { !isMobile && (
                    <Column 
                        body={recordActive} 
                        header="Activo"
                        field="active"
                    />
                ) }

                <Column body={recordOptions} header="Opções" />
            </DataTable>
                
            </div>
        </div>
            
    </React.Fragment>
  );

}

export default PlansList;
