import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import DiaryForm from './DiaryForm';
import Session from '../auth/Session';
import loader from '../loader';
import { ProgressSpinner } from 'primereact/progressspinner';
import UnAuthorized from '../auth/UnAuthorized';

function DiaryFormRoute() {

  // Load diary
  let [ diary, setDiary ] = useState(null);
  let [ loading, setLoading ] = useState(false);

  let urlParams = useParams();
  let id = urlParams.id;
  let logged = Session.getCookie();

  // Load diary
  useEffect(() => {
    if (!parseInt(id, 10)) return;
    loader.get('/diary/item/' + id, res => {
        setDiary(res.item);
    }, setLoading);
  }, [id]);

  if (loading) return <ProgressSpinner />;
  
  let title = diary ? diary.title : "Novo Registo";
  const therapy = !!parseInt(urlParams.therapy, 10);
  const incident = !!parseInt(urlParams.incident, 10);
  if (incident) title = diary ? diary.title : "Nova Ocorrência";
  const canAssign = ['ADMINISTRATOR', 'ORG_ADMIN'].includes(logged.role1);
  const user = canAssign ? null : logged;

  // Check permissions
  const allowed = Session.canAccessDiary(logged, diary); 
  if (!allowed.result) {
    return <div className="main-panel-small"><UnAuthorized reasons={allowed.reasons || []} /></div>;
  }

  return (
    <div className="card card-w-title" style={{ maxWidth: '560px', margin: '0 auto' }}>
        <h1>{ title }</h1>

        <DiaryForm 
          id={urlParams.id} 
          user={user} 
          therapy={therapy} 
          incident={incident}
        />

    </div>
  );

}

export default DiaryFormRoute;
