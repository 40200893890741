import React from 'react';
import io from "socket.io-client";
import Peer from "simple-peer";
import { Howl } from 'howler'
import ringtone from './Sounds/ringtone.mp3'

const ringtoneSound = new Howl({
  src: [ringtone],
  loop: true,
  preload: true
});

const socketURL = process.env.REACT_APP_SERVER_URL || "http://localhost:4040/";

const socket = React.createRef();
const myPeer = React.createRef();
const stream = React.createRef();

export const VCContext = React.createContext({
  yourID: "",
  users: {},
  receivingCall: false,
  caller: "",
  callingFriend: false,
  callerSignal: null,
  callAccepted: false,
  callRejected: false,
  modalMessage: '',
  audioMuted: false,
  videoMuted: false,

  userVideo: React.createRef(),
  partnerVideo: React.createRef()
});

export default class VideoCallContext extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      yourID: "",
      users: {},
      receivingCall: false,
      caller: "",
      callingFriend: false,
      callerSignal: null,
      callAccepted: false,
      callRejected: false,
      modalMessage: '',
      audioMuted: false,
      videoMuted: false,
    
      userVideo: React.createRef(),
      partnerVideo: React.createRef(),

      setYourId: this.setYourId.bind(this),
      clearAll: this.clearAll.bind(this),
      acceptCall: this.acceptCall.bind(this),
      setReceivingCall: this.setReceivingCall.bind(this),
      callPeer: this.callPeer.bind(this),
      endCall: this.endCall.bind(this),
      rejectCall: this.rejectCall.bind(this),
      shareScreen: this.shareScreen.bind(this),
      toggleMuteAudio: this.toggleMuteAudio.bind(this),
      toggleMuteVideo: this.toggleMuteVideo.bind(this),
      toggleFullScreen: this.toggleFullScreen.bind(this),
      setModalMessage: this.setModalMessage.bind(this)
    }
  }

  componentWillUnmount() {
    console.log('unmount');
  }

  setYourId(yourID) {
    this.setState({ ...this.state, yourID }, () => {

      socket.current = /localhost/ig.test(socketURL) ? 
        io.connect(socketURL, { transports: ['polling'], query: { yourID } }) :
        io.connect({
          path: socketURL + '/socket.io',
          transports: ['polling'],
          query: { yourID }
        });

      socket.current.on("allUsers", users => {
        this.setState({ ...this.state, users });
      });

      socket.current.on("hey", (data) => {
        ringtoneSound.play();
        this.setState({
          ...this.state,
          receivingCall: true,
          caller: data.from,
          callerSignal: data.signal
        });
      });

      socket.current.on("disconnect", (reason) => {
        window.location.reload();
      });

    });
  }

  clearAll() {
    if (socket.current) socket.current.disconnect();
  }

  setReceivingCall(value) {
    this.setState({ ...this.state, receivingCall: value });
  }

  toggleFullScreen() {
    this.setState({ ...this.state, isfullscreen: !this.state.isfullscreen });
  }

  setModalMessage(modalMessage) {
    this.setState({ ...this.state, modalMessage });
  }

  callPeer(id) {
    const { yourID, users } = this.state;
    if (users[id] && id !== yourID) {
      navigator.mediaDevices.getUserMedia({ video: { facingMode: "user" }, audio: true }).then(vstream => {

        stream.current = vstream;

        const peer = new Peer({
          initiator: true,
          trickle: false,
          
          config: {
    
            iceServers: [
              {
                urls: "stun:numb.viagenie.ca",
                username: "sultan1640@gmail.com",
                credential: "98376683"
              },
              {
                urls: "turn:numb.viagenie.ca",
                username: "sultan1640@gmail.com",
                credential: "98376683"
              }
            ]
          },
          
          stream: stream.current,
        });
        peer._debug = console.log;
    
        peer.on("signal", data => {
          socket.current.emit("callUser", {
            userToCall: id,
            signalData: data,
            from: yourID
          });
        });
    
        peer.on("stream", partnerStream => {
          let { partnerVideo } = this.state;
          if (partnerVideo.current) {
            partnerVideo.current.srcObject = partnerStream;
            partnerVideo.current.onloadedmetadata = function(e) {
              partnerVideo.current.play();
            };
          }
        });
  
        peer.on('error', err => {
          console.log(err);
          this.endCall();
        });
    
        socket.current.on("callAccepted", signal => {
          this.setState({ ...this.state, callAccepted: true }, () => {
            peer.signal(signal);
          });
        });
  
        socket.current.on('close', disconnected => {
          if (disconnected) if (this.state.caller === disconnected) this.endCall();
          else this.endCall();
        });
  
        socket.current.on('rejected', () => {
          this.endCall();
        });

        myPeer.current = peer;

        this.setState({
          ...this.state,
          callingFriend: true,
          caller: id,
          modalMessage: ''
        }, () => {
          let { userVideo } = this.state;
          if (userVideo.current) userVideo.current.srcObject = stream.current;
        });

      })
      .catch((err)=>{
        console.log(err);
        let modalMessage = 'Não é possivel fazer a chamada sem autorizar o acesso a webcam e microfone. ERRO: ' + err;
        this.setState({...this.state, modalMessage }, () => {
          this.endCall();
        });
      })
    } else {
      let modalMessage = 'O nome da sala parece não existir. Por favor tente mais tarde.';
      this.setState({...this.state, modalMessage }, () => {
        this.endCall();
      });
    }
  }
  
  acceptCall() {
    ringtoneSound.unload();
    navigator.mediaDevices.getUserMedia({ video: { facingMode: "user" }, audio: true }).then(vstream => {

      stream.current = vstream;
      let { callerSignal } = this.state;

      const peer = new Peer({
        initiator: false,
        trickle: false,
        stream: stream.current,
      });
      peer._debug = console.log;
  
      peer.on("signal", data => {
        const { caller } = this.state;
        socket.current.emit("acceptCall", { signal: data, to: caller })
      })
  
      peer.on("stream", partnerStream => {
        let { partnerVideo } = this.state;
        if (partnerVideo.current) {
          partnerVideo.current.srcObject = partnerStream;
          partnerVideo.current.onloadedmetadata = function(e) {
            partnerVideo.current.play();
          };
        }
      });
  
      peer.on('error', (err) => {
        console.log('peer error', err);
        this.endCall();
      });
  
      peer.signal(callerSignal);
  
      socket.current.on('close', disconnected => {
        if (disconnected) if (this.state.caller === disconnected) this.endCall();
        else this.endCall();
      });

      myPeer.current = peer;

      this.setState({
        ...this.state,
        callAccepted: true,
        modalMessage: ''
      }, () => {
        let { userVideo } = this.state;
        if (userVideo.current) userVideo.current.srcObject = stream.current;
      });

    })
    .catch(err => {
      console.log(err);
      let modalMessage = 'Não é possivel estabelecer a chamada sem autorizar o acesso a camara e ao microfone. Verifique as suas definições do navegador. Erro: ' + err;
      this.setState({ ...this.state, modalMessage, receivingCall: false }, () => {
        this.endCall();
      });
    })
  }

  cleanEvents() {
    if (stream.current) {
      stream.current.getTracks().forEach(track => {
        track.stop();
      });
    }
    socket.current.removeAllListeners("callAccepted");
    socket.current.removeAllListeners("rejected");
    socket.current.removeAllListeners("close");
    if (myPeer.current) {
      myPeer.current.removeAllListeners("stream");
      myPeer.current.removeAllListeners("signal");
      myPeer.current.removeAllListeners("error");
      myPeer.current.destroy();
      myPeer.current = null;
    }
  }
  
  rejectCall() {
    console.log('reject Call');
    ringtoneSound.unload();
    const { caller } = this.state;
    socket.current.emit('rejected', { to: caller });
    this.cleanEvents();
    this.setState({
      ...this.state,
      callRejected: true,
      receivingCall: false,
      callerSignal: null
    });
  }
  
  endCall() {
    console.log('end call');
    const { caller } = this.state;
    socket.current.emit('close', { to: caller });
    this.cleanEvents();
    this.setState({
      ...this.state,
      receivingCall: false,
      caller: "",
      callerSignal: null,
      callingFriend: false,
      callAccepted: false,
      callRejected: false
    });
  }
  
  shareScreen() {
    navigator.mediaDevices.getDisplayMedia({cursor:true})
    .then(screenStream => {
      let { stream, userVideo } = this.state;
      myPeer.current.replaceTrack(stream.getVideoTracks()[0], screenStream.getVideoTracks()[0], stream);
      userVideo.current.srcObject = screenStream;
      screenStream.getTracks()[0].onended = () => {
        myPeer.current.replaceTrack(screenStream.getVideoTracks()[0], stream.getVideoTracks()[0], stream);
        userVideo.current.srcObject = stream;
      }
    })
  }
  
  toggleMuteAudio() {
    let { stream } = this.state;
    if (stream) {
      let audioMuted = !audioMuted;
      stream.getAudioTracks()[0].enabled = audioMuted;
      this.setState({ ...this.state, audioMuted });
    }
  }
  
  toggleMuteVideo() {
    let { stream } = this.state;
    if (stream) {
      let videoMuted = !videoMuted;
      stream.getVideoTracks()[0].enabled = videoMuted;
      this.setState({ ...this.state, videoMuted });
    }
  }

  render() {
    return (
      <VCContext.Provider value={this.state}>
        { this.props.children }
      </VCContext.Provider>
    )
  }
}