const serverUrl = process.env.REACT_APP_SERVER_URL || '';

function loader(url, cb, setLoading) {
    if (setLoading) setLoading(true);
    return fetch(serverUrl + url, {
        credentials: 'include',
        headers: {
            'content-type': 'application/json'
        }
    })
    .then(res => res.json())
    .then(res => {
        cb(res);
        if (setLoading) setLoading(false);
        
    })
    .catch(err => {
        if (setLoading) setLoading(false);
        console.log(err);
    });
}

function loaderPost(url, data, cb, setLoading, credentials) {
    if (setLoading) setLoading(true);
    const fetchOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify(data)
    };
    if (!credentials) fetchOptions['credentials'] = 'include';
    return fetch(serverUrl + url, fetchOptions)
    .then(res => res.json())
    .then(res => {
        cb(res);
        if (setLoading) setLoading(false);
    })
    .catch(err => {
        if (setLoading) setLoading(false);
        console.log(err);
    });
}

function loaderUpload(url, body, cb, setLoading) {
    if (setLoading) setLoading(true);
    return fetch(serverUrl + url, {
        credentials: 'include',
        method: 'POST',
        body
    })
    .then(res => res.json())
    .then(res => {
        cb(res);
        if (setLoading) setLoading(false);
    })
    .catch(err => {
        if (setLoading) setLoading(false);
        console.log(err);
    });
}

function get(url, cb, setLoading) {
    return loader(url, cb, setLoading);
}

function post(url, data, cb, setLoading) {
    return loaderPost(url, data, cb, setLoading);
}

function upload(url, body, cb, setLoading) {
    return loaderUpload(url, body, cb, setLoading);
}

export default { get, post, upload };