import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import {Card} from 'primereact/card';
import {InputSwitch} from 'primereact/inputswitch';
import loader from '../loader';
import DiaryFieldInput from './DiaryFieldInput';
import { ProgressSpinner } from 'primereact/progressspinner';
import moment from 'moment';
import { useHistory, Link } from "react-router-dom";
import timeSetup from '../timeSetup';
import { hasMod } from '../users/Permissions';

const serverUrl = process.env.REACT_APP_SERVER_URL;

function DiaryView(props) {

  const { id } = props;

  let [ diary, setDiary ] = useState(null);
  let [ loading, setLoading ] = useState(false);
  let history = useHistory();

  // Load diary
  useEffect(() => {
    if (!parseInt(id, 10)) return;
    loader.get('/diary/item/' + id, res => {
        if (!res.success) history.goBack();
        res.item.datahora = timeSetup.from(res.item.datahora).format('YYYY-MM-DD HH:mm:ss');
        setDiary(res.item);
    }, setLoading);
  }, [id]);

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/photo_placeholder.png';
  }

  let userPhoto = (item, column) => {
    let url = "/backoffice/users/form/" + item.user_id;
    let src = getFileUrl(item.userphoto, 'users');
    return (
      <Link to={url}>
        <img 
            src={src} 
            alt="Fotografia"
            title="Fotografia"
            className="img-avatar-mini"
        />
      </Link>
    )
  }

  let clientPhoto = (item, column) => {
    let url = "/backoffice/clients/view/" + item.client_id;
    let src = getFileUrl(item.clientphoto, 'clients');
    return (
      <Link to={url}>
        <img 
            style={{ marginTop: '4em' }}
            src={src} 
            alt="Fotografia"
            title="Fotografia"
            className="img-avatar-mini"
        />
      </Link>
    )
  }
  
  if (loading || !diary) return <ProgressSpinner />;
  let datahora = moment(diary.datahora).format('YYYY-MM-DD HH:mm');
  return (
    <React.Fragment>
        <Card
          title={diary.title}
          subTitle={datahora}
          header={ clientPhoto(diary) }
          footer={
            <div className="p-grid diary-footer">
                <div className="p-col-3 p-lg-2">
                    { userPhoto(diary) }
                </div>
                <div className="p-col-6 p-lg-8 padtop15">
                    <em className="subtitle">{ !diary.username ? 'Não atribuida' : diary.username }</em>
                </div>
                <div className="p-col-3 p-lg-2 padtop15">
                    <InputSwitch 
                        checked={diary.terminated || false} 
                    />
                </div>
            </div>
          }>
          <div style={{ position: 'relative' }}>
              <Link to={"/backoffice/diary/form/" + diary.id}>
                  <Button
                    title="Atualizar tarefa" 
                    icon="pi pi-pencil"
                    style={{ position: 'absolute', top: '-320px', right: '0' }}
                  />
              </Link>
          </div>

          { hasMod(['T4CFR1']) &&
            <div style={{ position: 'relative' }}>
                <Link to={
                  diary.discuss_id ?
                  "/backoffice/clients/discuss/" + diary.client_id + "/view/" + diary.discuss_id :
                  "/backoffice/clients/discuss/" + diary.client_id + "/form/0?diary_id=" + diary.id}>
                    <Button 
                      title="Discussão"
                      icon="pi pi-comments"
                      className="p-button-info"
                      style={{ position: 'absolute', top: '-320px', right: '34px' }}
                    />
                </Link>
            </div>
          }

          <div style={{ position: 'relative' }}>
              <Button
                title="Voltar atrás" 
                icon="pi pi-arrow-left"
                className="p-button-secondary"
                style={{ position: 'absolute', top: '-320px', left: '0' }}
                onClick={e => window.history.back()}
              />
          </div>

          { !!diary.request && 
            <div className="diary-request">
              { diary.request }
            </div>
          }

          { !!diary.request_video && 
            <div>
              <video controls
                  alt="Video"
                  title="Video"
                  className="img-avatar"
                  style={{minHeight: '140px'}}>
                  <source
                      src={getFileUrl(diary.request_video, 'diary')}
                      type="video/mp4"
                  />
                  O seu browser não suporta videos HTML5
              </video>
            </div>
          }

          <div className="diary-fields" id="taskform">
            <div className="p-grid">
              { diary.fields.map((field, i) => 
                  <DiaryFieldInput
                      key={field.id}
                      field={field}
                      disabled={true}
                      readOnly={true}
                  />
              ) }
            </div>
          </div>
        </Card>

    </React.Fragment>
  );

}

export default DiaryView;
