import React from 'react';
import ClientOutForm from './ClientOutForm';
import { useParams } from "react-router-dom";

function ClientOutFormRoute() {

  let urlParams = useParams();

  const title = parseInt(urlParams.id) ? "Alterar Ausência" : "Registar Ausência";
  return (
    <div className="card card-w-title">
      <h1>{ title }</h1>
      <ClientOutForm client_id={urlParams.client_id} id={urlParams.id} />
    </div>
  );

}

export default ClientOutFormRoute;
