import React, { useState, useEffect, useRef } from 'react';
import { Password as InputPassword } from 'primereact/password';
import {Dialog} from 'primereact/dialog';
import Geo from '../Geo';
import loader from '../loader';
import Session from './Session';
import moment from "moment";
import 'moment/locale/pt';
import { useHistory, useLocation } from "react-router-dom";
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './login.css';
import { getParams } from '../helpers';

moment.locale('pt');

const baseUrl = process.env.PUBLIC_URL;
const serverUrl = process.env.REACT_APP_SERVER_URL;
const technicalRoles = ['SECURITY', 'ASSISTENT'];
const backofficeRoles = ['ADMINISTRATOR', 'ORG_ADMIN', 'ANALYST'];
const clientRoles = ['CLIENT', 'CLIENT_ADMIN'];

function LoginPage() {

  let [ organization, setOrganization ] = useState('');
  let submitRef = useRef(null);
  let [ username, setUsername ] = useState('');
  let [ password, setPassword ] = useState('');
  let [ code, setCode ] = useState('');
  let [ geolocation, setGeoLocation ] = useState({ latitude: null, longitude: null });

  let [ success, setSuccess ] = useState(null);
  let [ loading, setLoading ] = useState(false);
  let [ reason, setReason ] = useState('');

  let history = useHistory();
  let location = useLocation();
  let params = getParams(location.search);

  useEffect(() => {

    // Load organization
    loader.get('/organization/fallback/' + (params.org || ''), res => {
      setOrganization(res.item);
    });

    Geo.getLocation(
      (latitude, longitude) => setGeoLocation({ latitude, longitude }),
      (error) => console.log(error)
    );

    // Redirect if logged
    const user = Session.getCookie();
    if (user) {
      const clientRoles = ['CLIENT', 'CLIENT_ADMIN'];
      const technicalRoles = ['SECURITY', 'ASSISTENT'];
      const adminRoles = ['ADMINISTRATOR', 'ORG_ADMIN', 'ADMIN', 'ACCOUNTANT', 'ANALYST'];
      if (adminRoles.includes(user.role1)) {
          history.replace({ pathname: '/backoffice' });
      }
      if (clientRoles.includes(user.role1)) {
          history.replace({ pathname: '/client' });
      }
      if (technicalRoles.includes(user.role1)) {
          history.replace({ pathname: '/technical' });
      }
    }
  }, []);

  const add = (n) => setCode(String(code) + String(n));
  const remove = (n) => setCode(String(code).substr(0, code.length-1));

  const checkSubmit = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      submitRef.current.focus();
      return false;
    }
    return true;
  }

  let { from } = location.state || { from: { pathname: "/" } };
  let login = (e) => {
    if (e) e.preventDefault();
  
    let data = {
        username,
        password,
        code,
        geolocation
    };
    
    setSuccess(null);
    setReason('');
    const url = '/user/login';
    loader.post(url, data, (res) => {
      if (!res.success) {
        setPassword('');
        setCode('');
        if (res.reason) setReason(res.reason);
        return setSuccess(false);
      }
      
      // Validate pack
      if (res.pack) {
        if (window.localStorage) {
          window.localStorage.setItem('team4care_buynow', res.buynow);
          window.localStorage.setItem('team4care_pack', JSON.stringify(res.pack));
        }
        const basepack = res.pack.find(p => /T4CBASE/ig.test(p.code));
        if (!basepack) return history.replace({ pathname: "/buynow" });
        const packExpire = moment(basepack.date).add(12, 'month').diff() / 1000;
        let giveTime = -1 * 60 * 60 * 48; // Give 48 hours to pay before block
        if (packExpire < giveTime) return history.replace({ pathname: "/buynow" });
      }

      // Create session
      Session.authenticate(res.user, () => {
        
        // Redirect user
        const isClient = clientRoles.includes(res.user.role1);
        const isAssistent = technicalRoles.includes(res.user.role1);
        const isAdmin = backofficeRoles.includes(res.user.role1);
        const goTechnical = /technical/ig.test(from.pathname);
        const goClient = /client/ig.test(from.pathname);
        //return console.log(res.user);

        if (isClient && !goClient) return history.replace({ pathname: "/client" });
        else if (isAssistent && !goTechnical) return history.replace({ pathname: "/technical" });
        else if (isAdmin && from.pathname === '/') return history.replace({ pathname: "/backoffice" });
        else history.replace(from);

      });
    }, setLoading, true);
  };

  // Auto login
  useEffect(() => {
    if (code.length !== 4) return;
    login();
  }, [code]);

  const isMobile = window.innerWidth <= 768;

  if (!organization) return null;

  const org_logo = organization.logo ? serverUrl + "/download/organizations/" + organization.logo
    : baseUrl + 'assets/images/logo_512x512.png';
  const org_title = organization.name || "Team 4 Care";
  const org_bg = organization.background_img ? `url("${serverUrl}/download/organizations/${organization.background_img}")`
    : `url("${baseUrl}/assets/images/bg3.jpg")`;

  return (
    <div className="App" style={{ backgroundImage: org_bg }}>
      <div className="App-header">
        <div className="login-container">
          <div className="p-grid p-fluid">
            <div className="p-col-12">
              <div className="card card-w-title">

                <img src={ org_logo } 
                  className="logo" 
                  alt={ org_title }
                  title={ org_title }
                />
                
                <form onSubmit={login}>
                  <div className="p-grid">
                    <div className="p-col-12">
                      <label>Nome de Utilizador</label>
                      
                      <input 
                        name="username" 
                        placeholder="Nome de Utilizador" 
                        tabIndex="0" 
                        className="p-inputtext p-component p-filled" 
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        onKeyPress={checkSubmit}
                      />
                      
                    </div>

                    {/*
                    <div className="p-col-12">
                      <label>Palavra-passe</label>
                      <InputPassword 
                        name="password"
                        placeholder="Password" 
                        feedback={false}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    */}

                    <div className="p-col-12">
                      <label>PIN</label>
                      <InputPassword 
                        name="code"
                        placeholder="Pin"
                        value={code}
                        feedback={false}
                        onChange={(e) => setCode(e.target.value)}
                        disabled={loading}
                      />
                    </div>
                    
                    <div className="p-col-12">
                      <table className="pinpad">
                        <tbody>
                          <tr>
                            <td className="al"><button className="p-button" type="button" onClick={e => add('1')}>1</button></td>
                            <td><button className="p-button" type="button" onClick={e => add('2')}>2</button></td>
                            <td className="ar"><button className="p-button" type="button" onClick={e => add('3')}>3</button></td>
                          </tr>
                          <tr>
                            <td className="al"><button className="p-button" type="button" onClick={e => add('4')}>4</button></td>
                            <td><button className="p-button" type="button" onClick={e => add('5')}>5</button></td>
                            <td className="ar"><button className="p-button" type="button" onClick={e => add('6')}>6</button></td>
                          </tr>
                          <tr>
                            <td className="al"><button className="p-button" type="button" onClick={e => add('7')}>7</button></td>
                            <td><button className="p-button" type="button" onClick={e => add('8')}>8</button></td>
                            <td className="ar"><button className="p-button" type="button" onClick={e => add('9')}>9</button></td>
                          </tr>
                          <tr>
                            <td className="al">
                              <button className="p-button" type="button" title="Corrigir" onClick={e => remove()}>
                                <i className="pi pi-step-backward"></i>
                              </button>
                            </td>
                            <td><button className="p-button" type="button" onClick={e => add('0')}>0</button></td>
                            <td className="ar">
                              <button className="p-button" type="button" title="Reiniciar" onClick={e => setCode('')}>
                                <i className="pi pi-refresh"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    {/*
                    <div className="p-col-12">
                      
                      <button 
                        type="submit" 
                        tabIndex="1" 
                        ref={submitRef} 
                        disabled={loading}
                        className="p-button p-component p-button-text-only">
                          { loading ? <i className="pi pi-fw pi-spin pi-spinner"></i> 
                            : <span className="p-button-text p-c">Entrar</span> }
                      </button>

                    </div>
                    */}

                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog 
        header="Erro de Autenticação" 
        visible={success === false}
        style={{width: isMobile ? '90%' : '50vw'}} 
        modal={true} onHide={() => setSuccess(null)}>
        { reason || 'A autenticação falhou. Tente Novamente.' }
      </Dialog>

    </div>
  );

}

export default LoginPage;
