import React, { useState, useEffect, useReducer } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import {InputSwitch} from 'primereact/inputswitch';
import { Message } from 'primereact/message';
import {FileUpload} from 'primereact/fileupload';
import loader from '../loader';
import { ProgressSpinner } from 'primereact/progressspinner';

const serverUrl = process.env.REACT_APP_SERVER_URL;

const initialState = {
    name: '',
    image: '',
    unit_cost: 0,
    active: true
}

function reducer(state, action) {
    switch (action.type) {
      case 'update':
        return {
            ...state,
            [action.field]: action.value
        };
      case 'update_all':
        return {
            ...action.value
        }
      default:
        throw new Error();
    }
}

function ProductForm(props) {
  const { id, onSave } = props;

  let [ product, dispatch ] = useReducer(reducer, initialState)
  
  let [ errors, setErrors ] = useState({});
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  useEffect(() => {
    if (!parseInt(id, 10)) {
        return dispatch({ type: 'update_all', value: initialState });
    }
    loader.get('/product/item/' + id, res => {
        const value = { 
            ...res.item
        };
        dispatch({ type: 'update_all', value });
    }, setLoading);
  }, [id]);

  let register = () => {
    let data = {
        ...product
    };
    let url = '/product/create';
    if (product.id) url = '/product/update';
    setSuccess(false);
    loader.post(url, data, res => {
        if (res.errors) return setErrors(res.errors);
        data.id = res.id;
        dispatch({ type: 'update', field: 'id', value: res.id });
        setSuccess(true);
        onSave(data);
    }, setLoading);
  };

  let isDataInvalid = () => {
      return loading
        || product.name.length < 3;
  }

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/logo_placeholder.png';
  }

  // Upload
  const onUpload = (e) => {
    if (e.files.length === 0) return;
    
    const formData  = new FormData();
    formData.append('folder', 'products');
    formData.append('upload', e.files[0]);
    const url = '/upload';
    loader.post(url, formData, res => {
      if (!res.filename) return;
      dispatch({ type: 'update', field: 'image', value: res.filename });  
    });
  }

  const onRemoveAvatar = (e, file) => {
    dispatch({ type: 'update', field: 'image', value: '' });
  }

  let displayErrors = (errors, key) => !!errors[key] && errors[key].map(msg =>
    <Message severity="error" text={msg}></Message>
  )

  if (loading) return <ProgressSpinner />;
  let title = product.id ? 'Novo Produto' : 'Atualizar Produto';
  return (
    <React.Fragment>
        <div className="card card-w-title main-panel-small">
            <h1>{ title }</h1>

            { success && (
                <div className="p-grid">
                    <div className="p-col-12">

                        <Message 
                            severity="success" 
                            text="O produto foi guardado com sucesso!">
                        </Message>
                        
                    </div>
                </div>
            ) }

            <Button 
                onClick={register} 
                label="Registar"
                disabled={isDataInvalid()}
                style={{ float: 'right' }}
            />
            
            <p className="form-info">
                <strong style={{ color: 'red' }}>*</strong> 
                {' '}Campos obrigatórios
            </p>            

            <div className="p-grid">

                <div className="p-col-12 p-md-6">
                    <label className="field-required">Nome</label>
                </div>
                <div className="p-col-12 p-md-6">
                    <InputText 
                        placeholder="Nome"
                        value={product.name}
                        onChange={(e) => 
                            dispatch({ type: 'update', field: 'name', value: e.target.value })
                        }
                    />
                    { displayErrors(errors, 'name') }
                </div>

                <div className="p-col-10 p-md-6">
                    <label>Activo</label>
                </div>
                <div className="p-col-2 p-md-6">
                    <InputSwitch 
                        checked={product.active} 
                        onChange={(e) => 
                            dispatch({ type: 'update', field: 'active', value: !product.active })
                        } 
                    />
                </div>

                <div className="p-col-12 p-md-6">
                    <label className="field-required">Imagem</label>
                </div>
                <div className="p-col-12 p-md-6">
                  { !!product.logo && (
                    <img 
                        src={getFileUrl(product.logo, 'products')} 
                        alt="Logotipo"
                        title="Logotipo"
                        className="img-avatar"
                    />
                  ) }
                  <FileUpload 
                    name="logo"
                    mode="basic"
                    url={'/upload'}
                    customUpload={true}
                    uploadHandler={onUpload}
                    auto={true}
                    chooseLabel={product.imagem ? "Alterar" : "Escolher" }
                    onRemove={onRemoveAvatar}
                  />
                </div>

            </div>

        </div>
    </React.Fragment>
  );

}

export default ProductForm;
