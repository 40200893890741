import React, { useState, useEffect } from 'react';
import {Dialog} from 'primereact/dialog';
import moment from 'moment';
import 'moment/locale/pt';
import loader from '../loader';
import MessagesForm from './MessagesForm';
import MessagesGroupList from './MessagesGroupList';
import MessagesIndexList from './MessagesIndexList';
import MessagesSenderList from './MessagesSenderList';
import { ProgressSpinner } from 'primereact/progressspinner';
import Session from '../auth/Session';
import timeSetup from '../timeSetup';
moment.locale('pt');

function MessagesList() {

  let [ userID, setUserID ] = useState();
  let [ groups, setGroups ] = useState([]);
  let [ senders, setSenders ] = useState([]);
  let [ compose, setCompose ] = useState(false);
  let [ selectedGroup, setSelectedGroup ] = useState();
  let [ selectedSender, setSelectedSender ] = useState();
  
  let [ loading, setLoading ] = useState(false);

  useEffect(() => {
    let url = '/message/index';
    loader.get(url, res => {
        let tgroups = res.groups;
        let tsenders = res.senders;
        tgroups = tgroups.map(i => ({ 
            ...i, 
            lasttime: timeSetup.from(i.last).format('YYYY-MM-DD HH:mm:ss') 
        }));
        tsenders = tsenders.map(i => ({ 
            ...i, 
            lasttime: timeSetup.from(i.last).format('YYYY-MM-DD HH:mm:ss') 
        }));
        setGroups(tgroups);
        setSenders(tsenders);
        setUserID(res.user_id);
    }, setLoading);
  }, []);

  const isMobile = window.innerWidth <= 768;
  if (loading) return <ProgressSpinner />;

  const cookie = Session.getCookie();
  
  return (
    <React.Fragment>

        <Dialog 
            header="Compor Mensagem" 
            visible={compose} 
            style={{width: isMobile ? '100%' : '50vw' }} 
            modal={true} onHide={e => setCompose(false)}>
            <MessagesForm />
        </Dialog>

        { selectedGroup ? (
            <MessagesGroupList 
                group={selectedGroup}
                onExit={e => setSelectedGroup(null)}
                setCompose={setCompose}
            />
        ) : selectedSender ? (
            <MessagesSenderList 
                sender={selectedSender}
                onExit={e => setSelectedSender(null)}
                setCompose={setCompose}
            />
        ) : 
        (
            <MessagesIndexList 
                groups={groups} 
                senders={senders} 
                setCompose={setCompose}
                setSelectedGroup={setSelectedGroup}
                setSelectedSender={setSelectedSender}
            /> 
        ) }
            
    </React.Fragment>
  );

}

export default MessagesList;
