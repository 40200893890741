import React, { useState, useEffect, useReducer } from 'react';
import { Button } from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import { Message } from 'primereact/message';
import moment from 'moment';
import loader from '../loader';
import Session from '../auth/Session';
import { InputTextarea } from 'primereact/inputtextarea';
import timeSetup from '../timeSetup';

function MessagesForm(props) {

    const logged = Session.getCookie();
  
    const initialState = {
        datahora: moment().format('YYYY-MM-DD HH:mm:ss'),
        sender_id: logged.id,
        user_id: props.user_id || null,
        group_id: props.group_id || null,
        message: ''
    }

    function reducer(state, action) {
        switch (action.type) {
        case 'update':
            return {
                ...state,
                [action.field]: action.value
            };
        case 'update_all':
            return {
                ...action.value
            }
        default:
            throw new Error();
        }
    }

  let [ message, dispatch ] = useReducer(reducer, initialState)
  let [ users, setUsers ] = useState([]);
  let [ groups, setGroups ] = useState([]);
  
  let [ errors, setErrors ] = useState({});
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  // Load users
  useEffect(() => {
    loader.get('/user/list', res => {
        setUsers(res.items);
    }, setLoading);
  }, []);

  // Load groups
  useEffect(() => {
    loader.get('/group/list', res => {
        setGroups(res.items);
    }, setLoading);
  }, []);

  // Register
  let register = () => {
    let data = {
        ...message,
        datahora: timeSetup.to(moment()).format('YYYY-MM-DD HH:mm:ss')
    };
    let url = '/message/create';
    if (message.id) url = '/message/update';
    setSuccess(false);
    loader.post(url, data, res => {
        if (res.errors) return setErrors(res.errors);
        setSuccess(true);
        dispatch({ type: 'update', field: 'message', value: '' });
    });
  };

  let isDataInvalid = () => {
      return loading
        || message.message.length === 0;
  }

  const userOptions = users.filter(i => String(i.id) !== String(logged.id)).map(opt => ({
    label: opt.username,
    value: opt.id,
    key: opt.id
  }));

  const groupOptions = groups.map(opt => ({
    label: opt.name,
    value: opt.id,
    key: opt.id
  }));

  let displayErrors = (errors, key) => !!errors[key] && errors[key].map(msg =>
    <Message severity="error" text={msg}></Message>
  )

  const isMobile = window.innerWidth <= 768;

  return (
    <React.Fragment>

        { success && (
            <div className="p-grid">
                <div className="p-col-12">

                    <Message 
                        severity="success" 
                        text="A mensagem foi enviada com sucesso!">
                    </Message>
                    
                </div>
            </div>
        ) }

        { !message.user_id && !message.group_id && (
            <div className="p-grid">
                <div className="p-col-12 p-lg-3">
                    <label className="field-required">Para o utilizador</label>
                </div>
                <div className="p-col-12 p-lg-3">
                    <Dropdown 
                        value={message.user_id}
                        options={userOptions} 
                        placeholder="Escolha um colaborador"
                        onChange={(e) => 
                            dispatch({ type: 'update', field: 'user_id', value: e.value })
                        }
                    />
                </div>

                <div className="p-col-12 p-lg-3">
                    <label className="field-required">Para o grupo</label>
                </div>
                <div className="p-col-12 p-lg-3">
                    <Dropdown 
                        placeholder="Escolha um grupo"
                        value={parseInt(message.group_id, 10)}
                        options={groupOptions} 
                        onChange={(e) => 
                            dispatch({ type: 'update', field: 'group_id', value: e.value })
                        }
                    />
                </div>
            </div>
        )}

        <div className="p-grid msg-form">
            <div className="p-col-10">
                <InputTextarea
                    placeholder="Escrever..."
                    value={message.message}
                    onChange={(e) => 
                        dispatch({ type: 'update', field: 'message', value: e.target.value })
                    }
                    onKeyUp={e => {
                        if (e.keyCode === 13 && !e.ctrlKey) register();
                        if (e.ctrlKey && e.keyCode === 13) dispatch({ type: 'update', field: 'message', value: e.target.value + "\n" });
                    }}
                />
                { displayErrors(errors, 'message') }
            </div>
            <div className="p-col-2" style={{ textAlign: 'center' }}>
                <Button 
                    style={{ marginTop: '15px' }}
                    onClick={register} 
                    label={ isMobile ? "" : "Enviar" }
                    icon="pi pi-play" iconPos="left"
                    disabled={isDataInvalid()}
                />
            </div>

        </div>

    </React.Fragment>
  );

}

export default MessagesForm;
