import React, { useEffect, useState } from 'react';
import './App.css';
import { Link, useLocation } from 'react-router-dom';
import { Button } from 'primereact/button';
import { getParams } from '../helpers';
import loader from '../loader';

const baseUrl = process.env.PUBLIC_URL;
const serverUrl = process.env.REACT_APP_SERVER_URL;

function SplashScreen() {

  let [ organization, setOrganization ] = useState('');
  let params = getParams(useLocation().search);

  useEffect(() => {

    // Load organization
    loader.get('/organization/fallback/' + (params.org || ''), res => {
      setOrganization(res.item);
    });
  }, []);

  if (!organization) return null;

  const org_logo = organization.logo ? serverUrl + "/download/organizations/" + organization.logo
    : baseUrl + 'assets/images/logo_512x512.png';
  const org_title = organization.name || "Team 4 Care";
  const org_bg = organization.background_img ? `url("${serverUrl}/download/organizations/${organization.background_img}")`
    : `url("${baseUrl}/assets/images/bg3.jpg")`;

  return (
    <div className="App" style={{ backgroundImage: org_bg }}>
      <header className="App-header">

        <div className="splash-main">

          <img src={ org_logo } 
            className="App-logo" 
            alt={ org_title }
            title={ org_title }
          />

          <p>{ org_title }</p>
          <p className="subtitle" style={{ fontSize: '0.8em'}}>Acompanhamento Pessoal</p>

          <div className="p-grid">
            <div className="p-col-12">
              <Link to="/login">
                <Button label="Entrar" />
              </Link>
            </div>
          </div>
        </div>
        
      </header>
    </div>
  );
}

export default SplashScreen;
