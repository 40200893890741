import React from 'react';
import jsQR from 'jsqr';

class QRCodeReader extends React.Component {

    constructor(props) {
      super(props);
      this.video = null;
      this.canvasElement = null;
      this.canvas = null;
      this.loadingMessage = null;
      this.outputContainer = null;
      this.outputMessage = null;
      this.stream = null
    }

    componentDidMount() {
      const { onSuccess } = this.props;
      this.initQRCodeReader(onSuccess);
    }

    componentWillUnmount() {
      if (this.stream) {
        this.stream.getTracks().forEach(function(track) {
          track.stop();
        });
        //this.stream = null;
        this.video.srcObject = null;
      }
    }

    initQRCodeReader(onSuccess) {
      this.video = document.createElement("video");
      this.canvasElement = document.getElementById("canvas");
      this.canvas = this.canvasElement.getContext("2d");
      this.loadingMessage = document.getElementById("loadingMessage");
      this.outputContainer = document.getElementById("output");
      this.outputMessage = document.getElementById("outputMessage");
      let loadingMessage = this.loadingMessage;
      let video = this.video;
      let canvasElement = this.canvasElement;
      let outputContainer = this.outputContainer;
      let canvas = this.canvas;
      let outputMessage = this.outputMessage;
      let currentStream = this.stream;
  
      function drawLine(begin, end, color) {
        canvas.beginPath();
        canvas.moveTo(begin.x, begin.y);
        canvas.lineTo(end.x, end.y);
        canvas.lineWidth = 4;
        canvas.strokeStyle = color;
        canvas.stroke();
      }
  
      // Use facingMode: environment to attemt to get the front camera on phones
      navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then(function(stream) {
        currentStream = stream;
        video.srcObject = stream;
        video.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
        video.play();
        requestAnimationFrame(tick);
      });
  
      function tick() {

        loadingMessage.innerText = "⌛ Loading video..."
        if (video.readyState === video.HAVE_ENOUGH_DATA) {
          loadingMessage.hidden = true;
          canvasElement.hidden = false;
          outputContainer.hidden = false;
  
          canvasElement.height = video.videoHeight;
          canvasElement.width = video.videoWidth;
          canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
          var imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
          var code = jsQR(imageData.data, imageData.width, imageData.height, {
            inversionAttempts: "dontInvert",
          });
          if (code) {
            drawLine(code.location.topLeftCorner, code.location.topRightCorner, "#FF3B58");
            drawLine(code.location.topRightCorner, code.location.bottomRightCorner, "#FF3B58");
            drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, "#FF3B58");
            drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, "#FF3B58");
            outputMessage.hidden = true;
            onSuccess(code.data);
          } else {
            outputMessage.hidden = false;
          }
        }
        requestAnimationFrame(tick);
      }
  }

    render() {
      return (
        <div>
          <div id="loadingMessage">🎥 Não foi possivel utilizar a sua câmera</div>
          <canvas id="canvas" hidden style={{ width: '100%' }}></canvas>
          <div id="output" hidden>
              <div id="outputMessage">A detectar o código QR</div>
          </div>
        </div>
      )
    }
}

export default QRCodeReader;