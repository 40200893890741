import React, { useState } from 'react';
import DynamicChart from '../dashboard/DynamicChart';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

function ClientsGraphs({ client }) {

  let [ items, setItems ] = useState(['Gráfico 1...']);

  return (
    <React.Fragment>

        <div className="card card-w-title">
            <h1>Gráficos</h1>

            { items.map((name, i) => (
              <React.Fragment key={i}>
                <InputText
                  style={{ textAlign: 'left' }}
                  onChange={e => {
                    let input = e.target.value;
                    items[i] = input;
                    setItems(Object.assign([], items));
                  }}
                  value={name}
                />
                <DynamicChart client={client} noTitle={true} />
              </React.Fragment>
            )) }

            <hr />
            <Button onClick={e => {
                items.push('Gráfico ' + (items.length + 1) + '...');
                setItems(Object.assign([], items));
              }}
              label="Adicionar Gráfico"
            />
            
        </div>

    </React.Fragment>
  );

}

export default ClientsGraphs;
