import React, { useState, useEffect } from 'react';
import {Panel} from 'primereact/panel';
import {ProgressSpinner} from 'primereact/progressspinner';
import loader from '../loader';
import Session from '../auth/Session';
import {InputSwitch} from 'primereact/inputswitch';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import { Button } from 'primereact/button';
import DiaryCalendar from '../diary/DiaryCalendar';
import timeSetup from '../timeSetup';
import { useHistory, Link } from "react-router-dom";
import moment from 'moment';
import 'moment/locale/pt';
import { DiaryItemList } from '../diary/DiaryItemList';
moment.locale('pt');

let today = moment();
let start = today.format('YYYY-MM-DD');
let end = today.add(1, 'days').format('YYYY-MM-DD');

const serverUrl = process.env.REACT_APP_SERVER_URL;

function Home() {

  let me = Session.getCookie();

  let [ stickies, setStickies ] = useState([]);
  let [ diary, setDiary ] = useState([]);
  let [ others, setOthers ] = useState([]);
  let [ interval, setDiaryInterval ] = useState([start, end, 'timeGridDay'].join(','));

  let [ loading, setLoading ] = useState(true);

  let history = useHistory();
  let [istart, iend, iDefaultView] = interval.split(',');

  useEffect(() => {
    loader.get('/topic/stickies', res => {
        setStickies(res.items);
    });
  }, []);

  // Load diary
  useEffect(() => {
    if (parseInt(me.id, 10) < 1) return;
    let [istart, iend] = interval.split(',');
    let url = '/diary/user/list/' + me.id + '/' + istart + '/' + iend;
    loader.get(url, res => {
        setDiary(res.items);
    }, setLoading);

    url = '/diary/others/list/' + istart + '/' + iend;
    if (me.last_client) url = '/diary/client/list/' + me.last_client + '/' + istart + '/' + iend;
    loader.get(url, res => {
      setOthers(res.items);
    });
  }, [me.id, interval]);

  // Load unassigned tasks
  /*
  useEffect(() => {
    if (parseInt(me.id, 10) < 1) return;
    loader.get('/diary/unassigned/' + me.id, res => {
        setOthers(res.items);
    }, setLoading);
  }, [me.id]);
  */

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/photo_placeholder.png';
  }

  const getImageUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/topic_placeholder.jpg';
  }

  let recordPhoto = (item, column) => {
    if (!item.clientphoto) return (
      <Link to={"/technical/diary/form/" + item.id}>
        <img 
            src={getImageUrl(item.topicimage, 'topics')} 
            alt={item.title}
            title={item.title}
            className="img-avatar-mini"
        />
      </Link>
    );
    return (
      <Link to={"/technical/diary/form/" + item.id}>
        <img 
            src={getFileUrl(item.clientphoto, 'clients')} 
            alt={item.clientname}
            title={item.clientname}
            className="img-avatar-mini"
        />
      </Link>
    )
  }

  const calendar = diary.map(i => ({
    id: i.id,
    title: i.title,
    start: moment(i.datahora).format('YYYY-MM-DD HH:mm:ss'),
    end: moment(i.datahora).add(i.duration, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
    color: '#' + i.color
  }));

  if (loading) return (
    <div style={{ maxWidth: '580px', margin: '0 auto', textAlign: 'center'}}>
      <div style={{ textAlign: 'unset' }}>
        <ProgressSpinner style={{ margin: '0 auto' }} />
      </div>
    </div>
  );

  return (
    <div style={{ maxWidth: '580px', margin: '0 auto', textAlign: 'center'}} className="fadein">
      <div style={{ textAlign: 'unset' }}>
        { stickies.map(topic => (
          <Panel header={topic.title} className="alert" key={topic.id}>
            { !!topic.image && (
              <img 
                alt="Imagem"
                className="image-pad-right" 
                src={getFileUrl(topic.image, 'topics')} 
              />
            ) }
            <div dangerouslySetInnerHTML={{ __html: topic.description }}></div>
            <div style={{ clear: 'right' }}></div>
          </Panel>
        )) }
        { !!stickies.length && <hr /> }

        <div className="card card-w-title technical-tasks">
          <h1>
            As Minhas Tarefas
            <Link to="/technical/diary/form/0">
                <Button style={{ float: 'right' }} label="Nova" />
            </Link>
          </h1>
          <DataTable value={diary}>
              <Column 
                  header="Cliente"
                  body={recordPhoto}
                  style={{ textAlign: 'center', width: '48px' }}
              />
              <Column 
                  header="Atividade"
                  field="title"
                  style={{ textAlign: 'center' }}
                  body={item => (
                    <DiaryItemList
                      item={item} 
                      link={"/technical/diary/form/" + item.id}
                    />
                  )}
              />
              <Column
                  style={{ textAlign: 'right', width: '48px' }}
                  header="Terminado"
                  body={item => 
                    <Link to={"/technical/diary/form/" + item.id}>
                      { item.terminated ?
                        <img src="/images/icon-done-small.png" alt="Terminada" />
                        :
                        <img src="/images/icon-undone-small.png" alt="Por fazer" />
                      }
                    </Link>
                  }
              />
          </DataTable>
        </div>

        <div className="card card-w-title">
          <h1>O Meu Calendário</h1>
          <DiaryCalendar 
            items={calendar}
            defaultView={iDefaultView}
            start={istart}
            onChange={(nstart, nend, nCalView) => {
              setDiaryInterval([nstart, nend, nCalView].join(',')) 
            }}
          />
        </div>

        <div className="card card-w-title technical-tasks">
          { me.last_client ? <h1>Tarefas do Cliente</h1> : <h1>Outras Tarefas</h1> }
          <DataTable value={others}>
              <Column 
                  header="Cliente"
                  body={recordPhoto}
                  style={{ textAlign: 'center', width: '48px' }}
              />
              <Column 
                  header="Atividade"
                  field="title"
                  style={{ textAlign: 'center' }}
                  body={item => (
                    <DiaryItemList
                      item={item} 
                      link={"/technical/diary/form/" + item.id}
                    />
                  )}
              />
              <Column
                  style={{ textAlign: 'right', width: '48px' }}
                  header="Terminado"
                  body={item => 
                    <Link to={"/technical/diary/form/" + item.id}>
                      { item.terminated ?
                        <img src="/images/icon-done-small.png" alt="Terminada" />
                        :
                        <img src="/images/icon-undone-small.png" alt="Por fazer" />
                      }
                    </Link>
                  }
              />
          </DataTable>
        </div>

      </div>
    </div> 
  );
}

export default Home;
