import React from 'react';
import UserActivityForm from './UserActivityForm';
import { useParams } from "react-router-dom";
import Session from '../auth/Session';

function UserActivityFormRoute() {

  let urlParams = useParams();

  let logged = Session.getCookie();
  let owner_id = parseInt(urlParams.owner_id, 10) ? urlParams.owner_id : logged.id;
  let title = parseInt(urlParams.id, 10) ? "Alterar Serviço" : "Novo Serviço";
  return (
    <React.Fragment>
        <div className="card card-w-title">
          <h1>{ title }</h1>
            <UserActivityForm 
                id={urlParams.id}
                owner_id={owner_id} 
            />
        </div>
    </React.Fragment>
  );

}

export default UserActivityFormRoute;
