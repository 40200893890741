import React, { useState, useEffect, useReducer } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import {InputSwitch} from 'primereact/inputswitch';
import {Dropdown} from 'primereact/dropdown';
import { Message } from 'primereact/message';
import {FileUpload} from 'primereact/fileupload';
import {PickList} from 'primereact/picklist';
import {ColorPicker} from 'primereact/colorpicker';
import { useParams } from "react-router-dom";
import loader from '../loader';
import { ProgressSpinner } from 'primereact/progressspinner';

const serverUrl = process.env.REACT_APP_SERVER_URL;

const initialState = {
    name: '',
    active: true,
    organization_id: null,
    image: '',
    users: []
}

function reducer(state, action) {
    switch (action.type) {
      case 'update':
        return {
            ...state,
            [action.field]: action.value
        };
      case 'update_all':
        return {
            ...action.value
        }
      default:
        throw new Error();
    }
}

function GroupsForm() {

  let [ group, dispatch ] = useReducer(reducer, initialState);
  let [ organizations, setOrganizations ] = useState([]);
  let [ users, setUsers ] = useState([]);
  
  let [ errors, setErrors ] = useState({});
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  // Load organizations
  useEffect(() => {
    loader.get('/organization/list', res => {
        setOrganizations(res.items);
    }, setLoading);
  }, []);

  // Load users
  useEffect(() => {
    loader.get('/user/list', res => {
        setUsers(res.items);
    }, setLoading);
  }, []);

  // Load group
  let urlParams = useParams();
  useEffect(() => {
    if (parseInt(urlParams.id, 10) < 1) {
        const tinitialState = {
            ...initialState,
            color: 'ff0000'
        };
        return dispatch({ type: 'update_all', value: tinitialState });
    }
    loader.get('/group/item/' + urlParams.id, res => {
        dispatch({ type: 'update_all', value: res.item });
    }, setLoading);
  }, [urlParams.id]);

  // Register group
  let register = () => {
    let data = {
        ...group,
        users: group.users.map(f => f.id)
    };
    let url = '/group/create';
    if (group.id) url = '/group/update';
    setSuccess(false);
    loader.post(url, data, res => {
        if (res.errors) return setErrors(res.errors);
        dispatch({ type: 'update', field: 'id', value: res.id });
        setSuccess(true);
    }, setLoading);
  };

  let isDataInvalid = () => {
      return loading
        || group.name.length < 3
        || !group.organization_id;
  }

  const organizationOptions = organizations.map(opt => ({
    label: opt.name,
    value: opt.id,
    key: opt.id
  }));

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/photo_placeholder.png';
  }

  // Upload
  const onUpload = (e) => {
    if (e.files.length === 0) return;
    const formData  = new FormData();
    formData.append('folder', 'groups');
    formData.append('upload', e.files[0]);
    const url = '/upload';
    loader.upload(url, formData, res => {
      if (!res.filename) return console.log(res);
      dispatch({ type: 'update', field: 'image', value: res.filename });  
    }, setLoading);
  }

  const onRemoveAvatar = (e, file) => {
    dispatch({ type: 'update', field: 'image', value: '' });
  }

  let displayErrors = (errors, key) => !!errors[key] && errors[key].map(msg =>
    <Message severity="error" text={msg}></Message>
  )

  const availableUsers = users.filter(item1 => 
    !group.users.find(item2 => item1.id === item2.id)
  );

  if (loading) return <ProgressSpinner />;
  const title = group.id ? "Alterar Grupo" : "Criar Novo Grupo";
  return (
    <React.Fragment>
        <div className="card card-w-title">
            <h1>{ title }</h1>

            { success && (
                <div className="p-grid">
                    <div className="p-col-12">

                        <Message 
                            severity="success" 
                            text="O grupo foi guardado com sucesso!">
                        </Message>
                        
                    </div>
                </div>
            ) }

              <Button 
                onClick={register} 
                label="Guardar"
                disabled={isDataInvalid()}
                style={{ float: 'right' }}
              />

              <p className="form-info">
                  <strong style={{ color: 'red' }}>*</strong> 
                  {' '}Campos obrigatórios
              </p>

              <div className="p-grid">
                <div className="p-col-12 p-lg-6">
                    <div className="p-grid">

                        <div className="p-col-12 p-md-6">
                            <label className="field-required">Nome</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <InputText 
                                placeholder="Nome"
                                value={group.name}
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'name', value: e.target.value })
                                }
                            />
                            { displayErrors(errors, 'name') }
                        </div>

                        <div className="p-col-12 p-md-6">
                            <label className="field-required">Organização</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <Dropdown 
                                placeholder="Escolha uma organização"
                                value={parseInt(group.organization_id, 10)}
                                options={organizationOptions} 
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'organization_id', value: e.value })
                                }
                            />
                        </div>

                        <div className="p-col-10 p-md-6">
                            <label>Activo</label>
                        </div>
                        <div className="p-col-2 p-md-6">
                            <InputSwitch 
                                checked={group.active} 
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'active', value: !group.active })
                                } 
                            />
                        </div>

                        { !!group.color && (
                            <React.Fragment>
                                <div className="p-col-12 p-md-6">
                                    <label>Côr de Etiqueta</label>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <ColorPicker 
                                        value={group.color} 
                                        onChange={(e) => 
                                            dispatch({ type: 'update', field: 'color', value: e.value })
                                        }
                                    />
                                </div>
                            </React.Fragment>
                        )}

                        <div className="p-col-12 p-md-6">
                            <label>Fotografia</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                        { !!group.image && (
                            <img 
                                src={getFileUrl(group.image, 'groups')} 
                                alt="Fotografia"
                                title="Fotografia"
                                className="img-avatar"
                            />
                        ) }
                        <FileUpload 
                            name="image"
                            mode="basic"
                            url={'/upload'}
                            customUpload={true}
                            uploadHandler={onUpload}
                            auto={true}
                            chooseLabel={group.image ? "Alterar" : "Escolher" }
                            onRemove={onRemoveAvatar}
                        />
                        </div>
                   </div>
              </div>
            </div>
        </div>

        { !!group.id && (
            <div className="card card-w-title">
                <h2>Utilizadores</h2>
                <PickList 
                    source={availableUsers} 
                    target={group.users} 
                    sourceHeader="Utilizadores disponíveis"
                    targetHeader="Utilizadores associados"
                    itemTemplate={item => item.username}
                    responsive={false}
                    onChange={(e) => 
                        dispatch({ type: 'update', field: 'users', value: e.target })
                    }
                />
            </div>
        )}

    </React.Fragment>
  );

}

export default GroupsForm;
