import React, { useState, useEffect } from 'react';
import { useParams, Route } from "react-router-dom";
import loader from '../loader';
import { ProgressSpinner } from 'primereact/progressspinner';
import UsersForm from './UsersForm';
import UsersContacts from './UsersContacts';
import UsersWorkplans from './UsersWorkplans';
import UsersCheckpoints from './UsersCheckpoints';
import UsersMenu from './UsersMenu';
import UsersFailedLogins from './UsersFailedLogins';

function UsersRoute() {

  // Load diary
  let [ user, setUser ] = useState(null);
  let [ loading, setLoading ] = useState(false);

  let urlParams = useParams();
  let id = urlParams.id;
  let action = urlParams.action;

  // Load user
  useEffect(() => {
    if (!parseInt(id, 10)) return;
    loader.get('/user/item/' + id, res => {
        setUser(res.item);
    }, setLoading);
  }, [id]);

  if (loading) return <ProgressSpinner />;
  
  let title = user ? user.firstname + ' ' + user.lastname : "Novo Utilizador";
  return (
    <div>
        <h1 className="subtitle">{ title }</h1>

        { !!user && <UsersMenu user={user} activeMenu={action} /> }

        <Route path="/backoffice/users/form/:id">
            <UsersForm id={id} />
        </Route>
        
        <Route path="/backoffice/users/contacts/:id">
            <UsersContacts id={id} />
        </Route>
        
        <Route path="/backoffice/users/workplans/:id">
            <UsersWorkplans id={id} />
        </Route>
        
        <Route path="/backoffice/users/checkpoints/:id">
            <UsersCheckpoints id={id} />
        </Route>

        <Route path="/backoffice/users/failed/:id">
            <UsersFailedLogins id={id} />
        </Route>
    </div>
  );

}

export default UsersRoute;
