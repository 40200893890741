import React, { useState, useEffect } from 'react';
import DiaryCalendar from './DiaryCalendar';
import moment from 'moment';
import loader from '../loader';
import { ProgressSpinner } from 'primereact/progressspinner';
import timeSetup from '../timeSetup';

function DiaryNoTasksAssigned() {

  let [ date, setDate ] = useState(moment().startOf('week').format('YYYY-MM-DD'));
  let [ items, setItems ] = useState([]);
  
  let [ loading, setLoading ] = useState(false);

  // Load day items
  useEffect(() => {
    let start = timeSetup.to(date).format('YYYY-MM-DD');
    let end = timeSetup.to(date).add(7, 'days').format('YYYY-MM-DD');
    loader.get('/diary/between/'+start+'/'+end, res => {
        setItems(res.items);
    }, setLoading);
  }, [date]);
  
  // Calendar items
  let calendar = [];
  calendar = calendar.concat(items.filter(i => !i.user_id).map(i => ({
    id: i.id,
    title: i.title,
    start: timeSetup.from(i.datahora).format('YYYY-MM-DD HH:mm:ss'),
    end: timeSetup.from(i.datahora).add(i.duration, 'minute').format('YYYY-MM-DD HH:mm:ss'),
    backgroundColor: '#' + i.color
  })));

  if (loading) return <ProgressSpinner />;
  return (
    <div className="card card-w-title">
        <h1>Tarefas por Atribuir</h1>

        <DiaryCalendar 
            start={moment(date).add(1, 'day').format('YYYY-MM-DD')}
            items={calendar} 
            onChange={(start) => setDate(start)} 
        />
    </div>
  );

}

export default DiaryNoTasksAssigned;
