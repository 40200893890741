import React, { useState, useEffect, useReducer } from 'react';
import { Button } from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import { Message } from 'primereact/message';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import loader from '../loader';
import typeOptions from './types.json';
import date_pt from './date_pt.json';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputTextarea } from 'primereact/inputtextarea';
import timeSetup from '../timeSetup';

function ClientOutForm(props) {
  const { id, client_id } = props;

  const initialState = {
    type: 'Temporária',
    motivation: '',
    authorized: false,
    client_id: client_id ? parseInt(client_id, 10) : null,
    start: moment().startOf('day').add(1, 'day').toDate(),
    end: moment().startOf('day').add(2, 'day').toDate()
  };

  let [ clients, setClients ] = useState([]);

  function reducer(state, action) {
    switch (action.type) {
      case 'update':
        return {
            ...state,
            [action.field]: action.value
        };
      case 'update_all':
        return {
            ...action.value
        }
      default:
        throw new Error();
    }
  }
  let [ clientout, dispatch ] = useReducer(reducer, initialState);
  
  let [ errors, setErrors ] = useState({});
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  // Load clients
  useEffect(() => {
    loader.get('/client/list', res => {
        setClients(res.items);
    }, setLoading);
  }, []);

  // Load item
  useEffect(() => {
    if (!parseInt(id, 10)) {
        return dispatch({ type: 'update_all', value: { ...initialState } });
    }
    loader.get('/clientout/item/' + id, res => {
        if (!res.success) return;
        const value = {
            ...res.item,
            start: timeSetup.from(res.item.start).format('YYYY-MM-DD HH:mm:ss'),
            end: timeSetup.from(res.item.end).format('YYYY-MM-DD HH:mm:ss')
        };
        dispatch({ type: 'update_all', value });
    }, setLoading);
  }, [id]);
  
  // Register
  let register = () => {
    let data = {
        ...clientout,
        start: timeSetup.to(clientout.start).format('YYYY-MM-DD HH:mm:ss'),
        end: timeSetup.to(clientout.end).format('YYYY-MM-DD HH:mm:ss')
    };
    let url = '/clientout/create';
    if (clientout.id) url = '/clientout/update';
    
    setSuccess(false);
    loader.post(url, data, res => {
        if (res.errors) setErrors(res.errors);
        else {
            dispatch({ type: 'update', field: 'id', value: res.id });
            setSuccess(true);
        }
    }, setLoading);
  };

  let isDataInvalid = () => {
      return loading
        || !clientout.start
        || !clientout.end
        || !clientout.client_id;
  }
  
  let displayErrors = (errors, key) => !!errors[key] && errors[key].map(msg =>
    <Message severity="error" text={msg}></Message>
  )

  // Get client options
  const clientOptions = clients.map(opt => ({
    label: opt.firstname + ' ' + opt.lastname,
    value: opt.id,
    key: opt.id
  }));

  if (loading) return <ProgressSpinner />;
  let start_date = clientout.start ? 
    moment(clientout.start).toDate() : null;
  let end_date = clientout.end ? 
    moment(clientout.end).toDate() : null;
  return (
    <React.Fragment>

        { success && (
            <div className="p-grid">
                <div className="p-col-12">

                    <Message 
                        severity="success" 
                        text="A ausência foi registada com sucesso!">
                    </Message>
                    
                </div>
            </div>
        ) }

        <Button 
            onClick={register} 
            label="Guardar"
            disabled={isDataInvalid()}
            style={{ float: 'right' }}
        />
        
        <p className="form-info">
            <strong style={{ color: 'red' }}>*</strong> 
            {' '}Campos obrigatórios
            
        </p>

        <div className="p-grid">
            <div className="p-col-12 p-lg-6">
                <div className="p-grid">

                    <div className="p-col-6 p-md-6">
                        <label className="field-required">Tipo</label>
                    </div>
                    <div className="p-col-6 p-md-6">
                        <Dropdown 
                            placeholder="Escolha um tipo"
                            value={clientout.type}
                            options={typeOptions} 
                            onChange={(e) => {
                                dispatch({ type: 'update', field: 'type', value: e.value })
                            }}
                        />
                    </div>

                    <div className="p-col-6 p-md-6">
                        <label className="field-required">Cliente</label>
                    </div>
                    <div className="p-col-6 p-md-6">
                        <Dropdown 
                            placeholder="Escolha um cliente"
                            value={parseInt(clientout.client_id, 10)}
                            options={clientOptions} 
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'client_id', value: e.value })
                            }
                        />
                    </div>

                    <div className="p-col-12 p-md-6">
                        <label className="field-required">Inicio</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Calendar 
                            placeholder="Data / Hora"
                            value={start_date}
                            locale={date_pt}
                            monthNavigator={true} 
                            yearNavigator={true} 
                            yearRange="2020:2030"
                            dateFormat="yy-mm-dd"
                            showTime={true}
                            hourFormat="24"
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'start', value: e.value })
                            }>
                        </Calendar>
                    </div>

                    <div className="p-col-12 p-md-6">
                        <label className="field-required">Fim</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <Calendar 
                            placeholder="Data / Hora"
                            value={end_date}
                            locale={date_pt}
                            monthNavigator={true} 
                            yearNavigator={true} 
                            yearRange="2020:2030"
                            dateFormat="yy-mm-dd"
                            showTime={true}
                            hourFormat="24"
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'end', value: e.value })
                            }>
                        </Calendar>
                    </div>

                </div>
            </div>
            <div className="p-col-12 p-lg-6">
                <h2>Motivação / Propósito</h2>
                <InputTextarea
                    rows="10" 
                    value={clientout.motivation}
                    onChange={(e) => 
                        dispatch({ type: 'update', field: 'motivation', value: e.target.value })
                    }
                />
                { displayErrors(errors, 'motivation') }
            </div>
        </div>
    </React.Fragment>
  );

}

export default ClientOutForm;
