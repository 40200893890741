import React, { useState, useEffect, useReducer } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import {InputMask} from 'primereact/inputmask';
import {InputSwitch} from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import {Dropdown} from 'primereact/dropdown';
import { Message } from 'primereact/message';
import moment from 'moment';
import relationOptions from './relations.json';
import mediaOptions from './media.json';
import loader from '../loader';
import { ProgressSpinner } from 'primereact/progressspinner';
import timeSetup from '../timeSetup.js';
import EntitiesLocation from './EntitiesLocation.js';

function EntitiesForm(props) {

    const { filter, id } = props;
  
    const initialState = {
        client_id: filter.client_id || null,
        user_id: filter.user_id ? parseInt(filter.user_id, 10) : null,
        name: filter.name || '',
        relation: 'Próprio',
        media: 'Telemóvel',
        value: '',
        active: true
    }

    function reducer(state, action) {
        switch (action.type) {
        case 'update':
            return {
                ...state,
                [action.field]: action.value
            };
        case 'update_all':
            return {
                ...action.value
            };
        case 'update_map':
            return {
                ...state,
                latitude: action.latitude,
                longitude: action.longitude,
                mapzoom: action.mapzoom
            };
        default:
            throw new Error();
        }
    }

  let [ entity, dispatch ] = useReducer(reducer, initialState)
  let [ users, setUsers ] = useState([]);
  let [ clients, setClients ] = useState([]);
  
  let [ errors, setErrors ] = useState({});
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  // Load users
  useEffect(() => {
    loader.get('/user/list', res => {
        setUsers(res.items);
    }, setLoading);
  }, []);

  // Load clients
  useEffect(() => {
    loader.get('/client/list', res => {
        setClients(res.items);
    }, setLoading);
  }, []);

  // Load entity
  useEffect(() => {
    if (!parseInt(id, 10)) {
        const initialData = { ...initialState };
        return dispatch({ type: 'update_all', value: initialData }); 
    }
    loader.get('/entity/item/' + id, res => {
        if (!res.success) return;
        let titem = res.item;
        titem.updated_at = timeSetup.from(titem.updated_at).format('YYYY-MM-DD HH:mm:ss');
        dispatch({ type: 'update_all', value: titem });
    }, setLoading);
  }, [id]);

  // Register
  let register = () => {
    let data = {
        ...entity,
        updated_at: timeSetup.to(moment()).format('YYYY-MM-DD HH:mm:ss')
    };
    let url = '/entity/create';
    if (entity.id) url = '/entity/update';
    setSuccess(false);
    loader.post(url, data, res => {        
        if (res.errors) return setErrors(res.errors);
        dispatch({ type: 'update', field: 'id', value: res.id });
        setSuccess(true);
    }, setLoading);
  };

  let isDataInvalid = () => {
      return loading
        || entity.name.length < 3
        || entity.value.length < 3;
  }

  const userOptions = users.map(opt => ({
    label: opt.username,
    value: String(opt.id),
    key: opt.id
  }));

  const clientOptions = clients.map(opt => ({
    label: opt.fullname,
    value: String(opt.id),
    key: opt.id
  }));

  let displayErrors = (errors, key) => !!errors[key] && errors[key].map(msg =>
    <Message severity="error" text={msg}></Message>
  )

  let valueLabel = 'Contacto';
  switch(entity.media) {
      case 'Fax':
      case 'Telefone':
      case 'Telemóvel': valueLabel = 'Número'; break;
      case 'Endereço': valueLabel = 'Endereço'; break;
      case 'E-mail': valueLabel = 'Endereço'; break;
  }

  let valueMask = '';
  switch(entity.media) {
      case 'Fax':
      case 'Telefone':
      case 'Telemóvel': valueMask = '(+99?9) 999 999 999'; break;
      case 'Endereço':
      case 'E-mail': valueMask = ''; break;
  }

  if (loading) return <ProgressSpinner />;
  return (
    <React.Fragment>

        { success && (
            <div className="p-grid">
                <div className="p-col-12">

                    <Message 
                        severity="success" 
                        text="O contacto foi guardado com sucesso!">
                    </Message>
                    
                </div>
            </div>
        ) }

        <Button 
            onClick={register} 
            label="Guardar"
            disabled={isDataInvalid()}
            style={{ float: 'right' }}
        />
        
        <p className="form-info">
            <strong style={{ color: 'red' }}>*</strong> 
            {' '}Campos obrigatórios
        </p>

        <div className="p-grid">
            

            <div className="p-col-6">
                <label className="field-required">Tipo de Contacto</label>
            </div>
            <div className="p-col-6">
                <Dropdown 
                    value={entity.media}
                    options={mediaOptions} 
                    placeholder="Escolha um tipo de contacto"
                    onChange={(e) => 
                        dispatch({ type: 'update', field: 'media', value: e.value })
                    }
                />
            </div>

            <div className="p-col-6">
                <label className="field-required">{ valueLabel }</label>
            </div>
            <div className="p-col-6">
                { valueMask ? (
                    <InputMask
                        mask={valueMask}
                        placeholder={ valueLabel }
                        value={entity.value}
                        onChange={(e) => 
                            dispatch({ type: 'update', field: 'value', value: e.target.value })
                        }
                    />
                ) : (
                    entity.media === 'Endereço' ? (
                        <InputTextarea
                            rows={5} 
                            style={{ width: '100%' }}
                            placeholder={ valueLabel }
                            value={entity.value}
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'value', value: e.target.value })
                            }
                        />
                    ) : (
                        <InputText
                            placeholder={ valueLabel }
                            value={entity.value}
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'value', value: e.target.value })
                            }
                        />
                    )
                ) }
                { displayErrors(errors, 'value') }
            </div>

            <div className="p-col-12 p-md-6">
                <label className="field-required">Nome do Contacto</label>
            </div>
            <div className="p-col-12 p-md-6">
                <InputText 
                    placeholder="Nome"
                    value={entity.name}
                    onChange={(e) => 
                        dispatch({ type: 'update', field: 'name', value: e.target.value })
                    }
                />
                { displayErrors(errors, 'name') }
            </div>

            <div className="p-col-12 p-md-6">
                <label>Utilizador Associado</label>
            </div>
            <div className="p-col-12 p-md-6">
                <Dropdown 
                    placeholder="Escolha um utilizador"
                    value={parseInt(entity.user_id, 10)}
                    options={userOptions} 
                    onChange={(e) => 
                        dispatch({ type: 'update', field: 'user_id', value: e.value })
                    }
                />
            </div>

            <div className="p-col-12 p-md-6">
                <label>Cliente Associado</label>
            </div>
            <div className="p-col-12 p-md-6">
                <Dropdown 
                    value={entity.client_id}
                    options={clientOptions.filter(opt => opt.value !== entity.role2)} 
                    placeholder="Escolha um cliente"
                    onChange={(e) => 
                        dispatch({ type: 'update', field: 'client_id', value: e.value })
                    }
                />
            </div>

            <div className="p-col-12 p-md-6">
                <label className="field-required">Relação</label>
            </div>
            <div className="p-col-12 p-md-6">
                <Dropdown 
                    value={entity.relation}
                    options={relationOptions} 
                    placeholder="Escolha uma relação"
                    onChange={(e) => 
                        dispatch({ type: 'update', field: 'relation', value: e.value })
                    }
                />
            </div>

            <div className="p-col-10 p-md-6">
                <label>Activo</label>
            </div>
            <div className="p-col-2 p-md-6">
                <InputSwitch 
                    checked={entity.active} 
                    onChange={(e) => 
                        dispatch({ type: 'update', field: 'active', value: !entity.active })
                    } 
                />
            </div>
            
        </div>

        { entity.media === 'Endereço' && (
            <EntitiesLocation entity={entity} dispatch={dispatch} />
         ) }

    </React.Fragment>
  );

}

export default EntitiesForm;
