import React, { useState, useEffect, useReducer } from 'react';
import { useParams } from "react-router-dom";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import {Editor} from 'primereact/editor';
import {PickList} from 'primereact/picklist';
import loader from '../loader';
import Session from '../auth/Session';
import { ColorPicker } from 'primereact/colorpicker';
import { ProgressSpinner } from 'primereact/progressspinner';
import moment from 'moment';

function reducer(state, action) {
    switch (action.type) {
      case 'update':
          if (action.field === 'subject') {
              return {
                  ...state,
                  subject: action.value
              }
          }
        return {
            ...state,
            [action.field]: action.value
        };
      case 'update_all':
        return {
            ...action.value
        }
      default:
        throw new Error();
    }
}

function DiscussForm(props) {
  const { client, params } = props;

  let logged = Session.getCookie();
  const initialState = {
    client_id: client ? client.id : null,
    subject: '',
    body: '',
    user_id: logged.id,
    users: [],
    diary_id: params.diary_id || null
  };

  let [ discuss, dispatch ] = useReducer(reducer, initialState);
  let [ users, setUsers ] = useState([]);
  
  let [ errors, setErrors ] = useState({});
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  let urlParams = useParams();
  let id = urlParams.discuss_id;

  // Load users
  useEffect(() => {
    loader.get('/user/list', res => {
        setUsers(res.items.filter(i =>i.active));
    }, setLoading);
  }, []);

  // Load item
  useEffect(() => {
    if (!parseInt(id, 10)) {
        return dispatch({ type: 'update_all', value: { ...initialState, color: 'ff0000' }});
    }
    loader.get('/discuss/item/' + id, res => {
        const value = {
            ...res.item
        };
        dispatch({ type: 'update_all', value });
    }, setLoading);
  }, [id]);
  
  // Register
  let register = () => {
    let data = {
        ...discuss,
        created_at: discuss.created_at || moment().format('YYYY-MM-DD HH:mm:ss'),
        users: discuss.users.map(f => f.id)
    };
    let url = '/discuss/create';
    if (discuss.id) url = '/discuss/update';
    setSuccess(false);
    loader.post(url, data, res => {
        if (res.errors) return setErrors(res.errors);
        dispatch({ type: 'update', field: 'id', value: res.id });
        setSuccess(true);
    }, setLoading);
  };

  let isDataInvalid = () => {
      return loading
        || discuss.subject.length < 3;
  }

  const availableUsers = users.filter(item1 => 
    !discuss.users.find(item2 => item1.id === item2.id)
  );

  let displayErrors = (errors, key) => !!errors[key] && errors[key].map(msg =>
    <Message severity="error" text={msg}></Message>
  )

  if (loading) return <ProgressSpinner />;
  const msg = "A discussão foi guardada com sucesso!";
  const title = Number(discuss.id) ? "Alterar Discussão" : "Nova Discussão";  
  return (
    <div className="card card-w-title main-panel-small">
        <h1>{ title }</h1>

        { success && (
            <div className="p-grid">
                <div className="p-col-12">

                    <Message severity="success" text={msg}></Message>
                    
                </div>
            </div>
        ) }

        <Button 
            onClick={register} 
            label="Guardar"
            disabled={isDataInvalid()}
            style={{ float: 'right' }}
        />

        <p className="form-info">
            <strong style={{ color: 'red' }}>*</strong> 
            {' '}Campos obrigatórios
            
        </p>

        <div className="p-grid">
            <div className="p-col-12">
                <div className="p-grid">
                    
                    <div className="p-col-12 p-md-6">
                        <label className="field-required">Assunto</label>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <InputText 
                            placeholder="Assunto"
                            value={discuss.subject}
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'subject', value: e.target.value })
                            }
                        />
                        { displayErrors(errors, 'subject') }
                    </div>

                    <React.Fragment>
                        <div className="p-col-12 p-md-6">
                            <label>Côr de Etiqueta</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <ColorPicker
                                value={discuss.color} 
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'color', value: e.value })
                                }
                            />
                        </div>
                    </React.Fragment>

                    <div className="p-col-12">
                        <Editor 
                            style={{height: '210px'}} 
                            value={discuss.body}
                            onTextChange={(e) => 
                                dispatch({ type: 'update', field: 'body', value: e.htmlValue })
                            }
                        />
                    </div>

                    <div className="p-col-12">
                        <h5>Intervenientes</h5>

                        <PickList 
                            source={availableUsers} 
                            target={discuss.users} 
                            sourceHeader="Utilizadores disponíveis"
                            targetHeader="Utilizadores selecionados"
                            itemTemplate={item => item.username}
                            responsive={false}
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'users', value: e.target })
                            }
                        />
                    </div>
                    
                </div>
            </div>

        </div>
    </div>
  );

}

export default DiscussForm;
