import React, { useState, useEffect } from 'react';
import {InputSwitch} from 'primereact/inputswitch';
import { Message } from 'primereact/message';
import moment from 'moment';
import 'moment/locale/pt';
import loader from '../loader';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
moment.locale('pt');

const serverUrl = process.env.REACT_APP_SERVER_URL;

function ClientActivityList(props) {
  let { id } = props;

  let [ clients, setClients ] = useState([]);
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  const reload = () => {
    if (!parseInt(id, 10)) return;
    loader.get('/user_activity/client/list/' + id, res => {
        setClients(res.items);
    }, setLoading);
  }

  // Load enroll clients
  useEffect(() => {
    reload();
  }, [id]);

  let updateItem = (data) => {
    const url = '/client/activity/toggle/' + data.client_id + '/' + data.activity_id;
    loader.get(url, data, res => {
        reload();
    });
  };

  let toggleActive = (user_activitys, aid) => {
    let items = Object.assign([], user_activitys);
    items = items.map(item => {
        if (item.id === aid) {
            item.active = !item.active;
            updateItem({ id: item.id, active: item.active });
        }
        return item;
    });
    setClients(items);
  }
  
  let recordActive = (item, column) => {
    return <div style={{ textAlign: 'right' }}>
        <InputSwitch 
            checked={item.active} 
            onChange={(e) => toggleActive(clients, item.id)} 
        />
    </div>;
  }

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/photo_placeholder.png';
  }

  let recordPhoto = (item, column) => {
    return (
        <img 
            src={getFileUrl(item.photo, 'clients')} 
            alt="Fotografia"
            title="Fotografia"
            className="img-avatar-mini"
        />
    )
  }

  if (loading) return <ProgressSpinner />;
  return (
    <React.Fragment>

        { success && (
            <div className="p-grid">
                <div className="p-col-12">

                    <Message 
                        severity="success" 
                        text="O horário foi guardado com sucesso!">
                    </Message>
                    
                </div>
            </div>
        ) }

        <div>
            <h2>Clientes Inscritos</h2>

            <DataTable value={clients} responsive={true}>
                <Column 
                    header="Foto"
                    body={recordPhoto}
                />
                <Column 
                    header="Nome"
                    field="fullname"
                    sortable={true}
                    filter={true}
                    filterMatchMode="contains"
                />
                <Column 
                    header="Activo"
                    body={recordActive}
                />
            </DataTable>
        </div>

    </React.Fragment>
  );

}

export default ClientActivityList;
