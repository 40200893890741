import React, { useContext } from 'react';
import { VCContext } from './VideoCallContext';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import './index.css';

function ListOnline() {
  const context = useContext(VCContext);
  const { yourID, users } = context;
  
  return (
    <div className="videocall">
      <div className="card card-w-title">
        <h1>Utilizadores Online</h1>

        <DataTable
          style={{ maxWidth: '480px' }}
          value={Object.keys(users).filter(k => k != yourID).map(k => ({ key: k, id: k, label: k }))} 
          dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
          currentPageReportTemplate="De {first} até {last} do total de {totalRecords}">
          <Column field="label" header="Contacto" sortable></Column>
          <Column body={(rowData) => {
            return (
              <React.Fragment>
                <Button
                  icon="pi pi-video"
                  className="p-button-rounded p-button-success"
                  onClick={() => context.callPeer(rowData.id.toLowerCase().trim())}
                />
              </React.Fragment>
            );
          }}></Column>
        </DataTable>
      </div>
    </div>
  )
}

export default ListOnline;