import React, { useState, useReducer } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import {Checkbox} from 'primereact/checkbox';
import reportOptions from './reportOptions.json';
import FileSaver from 'file-saver';

const serverUrl = process.env.REACT_APP_SERVER_URL || '';

const initialState = {
    title: 'Relatório de Cliente',
    obs: '',
    selectedOptions: 'business,resume,plans,therapy,lastHealth,incidents,history'
}

function ClientsReport({ client }) {

    function reducer(state, action) {
        switch (action.type) {
        case 'update':
            return {
                ...state,
                [action.field]: action.value
            };
        case 'update_all':
            return {
                ...action.value
            }
        default:
            throw new Error();
        }
    }

  let [ report, dispatch ] = useReducer(reducer, initialState);
  let [ loading, setLoading ] = useState(false);

  // Get report
  let create = () => {
    let data = {
        ...report
    };
    let url = '/client/report/' + client.id;
    setLoading(true);
    const fetchOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(data)
    };
    fetch(serverUrl + url, fetchOptions)
    .then(response => response.blob())
    .then(blob => {
        setLoading(false);

        // download
        FileSaver.saveAs(blob, "team4care-client-report.pdf");
    })
    .catch(err => {
        setLoading(false);
        console.log(err);
    });
  };
  
  return (
    <div style={{ textAlign: 'center' }}>
        
        <div className="card card-w-title" style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}>
            <h1>Relatório</h1>
            
              <Button
                disabled={loading}
                onClick={create} 
                label="Download"
                style={{ float: 'right' }}
              />

              <div className="p-grid">
                <div className="p-col-12 p-lg-6">
                    <label className="field-required">Título do Relatório</label>
                </div>
                <div className="p-col-12 p-lg-6">
                    <InputText 
                        placeholder="Título do Relatório"
                        value={report.title}
                        onChange={(e) => 
                            dispatch({ type: 'update', field: 'title', value: e.target.value })
                        }
                    />
                </div>

                <div className="p-col-12 p-lg-6">
                    <label>Informações / Finalidade do Relatório</label>
                </div>
                <div className="p-col-12 p-lg-6">
                    <InputTextarea
                        rows={10} 
                        style={{ width: '100%' }}
                        placeholder="Detalhes"
                        value={report.obs}
                        onChange={(e) => 
                            dispatch({ type: 'update', field: 'obs', value: e.target.value })
                        }
                    />
                </div>

                <div className="p-col-12 p-lg-6">
                    <label>Secções Incluídas</label>
                </div>
                <div className="p-col-12 p-lg-6">
                    { reportOptions.map(opt => (
                        <React.Fragment key={opt.value}>
                            <Checkbox 
                                inputId={"w" + opt.value} 
                                value={opt.value}
                                onChange={e => {
                                    let selected = report.selectedOptions.split(',');
                                    if (e.checked) selected.push(e.value);
                                    else selected.splice(selected.indexOf(e.value), 1);
                                    dispatch({ type: 'update', field: 'selectedOptions', value: selected.join(',') });        
                                }} 
                                checked={report.selectedOptions.split(',').includes(opt.value)}
                            />
                            <label htmlFor={"w" + opt.value} className="p-checkbox-label weekopt">
                                { opt.label }
                            </label>
                            <br />
                        </React.Fragment>
                    ))}
                </div>
                
            </div>

        </div>
    </div>
  );

}

export default ClientsReport;
