import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Session from '../auth/Session';
import { Redirect, withRouter, Link } from "react-router-dom";

const baseUrl = process.env.PUBLIC_URL;

class AppTopbar extends Component {

    static defaultProps = {
        onToggleMenu: null
    }

    static propTypes = {
        onToggleMenu: PropTypes.func.isRequired
    }
    
    login() {
        const { history } = this.props;
        history.push({ pathname: "/login" });
    };

    render() {
        return (
            <div className="layout-topbar clearfix">
                
                <button className="p-link layout-menu-button" onClick={this.props.onToggleMenu}>
                    <span className="pi pi-bars"/>
                </button>

                <Link to="/topics">
                    <img
                        className="topbar-logo" 
                        src={ baseUrl + '/images/icons_simple/apple-icon-72x72.png'}
                        alt="Logo"
                    />
                    <span className="topbar-title">Team4Care</span>
                </Link>
                
                <div className="layout-topbar-icons">
                    
                    <button title="Entrar" className="p-link" onClick={this.login.bind(this)}>
                        <span className="layout-topbar-icon pi pi-sign-in"/>
                    </button>
                    
                </div>
            </div>
        );
    }
}

export default withRouter(AppTopbar);