import React from 'react';
import BusinessForm from './BusinessForm';
import { useParams } from "react-router-dom";

function BusinessFormRoute() {

  let urlParams = useParams();

  const label = 'Plano';
  const title = parseInt(urlParams.id, 10) ? "Alterar " + label : "Criar " + label;
  return (
    <React.Fragment>
        <div className="card card-w-title">
          <h1>{ title }</h1>
          
          <BusinessForm 
            business_id={urlParams.id} 
          />
        </div>
    </React.Fragment>
  );

}

export default BusinessFormRoute;
