import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { getParams } from '../helpers';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './login.css';
import { Message } from 'primereact/message';
import loader from '../loader';

const baseUrl = process.env.PUBLIC_URL;
const serverUrl = process.env.REACT_APP_SERVER_URL;

function ActivationPage() {

  let [ organization, setOrganization ] = useState('');
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  let urlParams = useParams();
  let params = getParams(useLocation().search);

  useEffect(() => {

    // Load organization
    loader.get('/organization/fallback/' + (params.org || ''), res => {
      setOrganization(res.item);
    });
  }, []);

  useEffect(() => {

    // Load organization
    loader.get('/user/activate/' + urlParams.activation_code, res => {
      setSuccess(res.success);
    }, setLoading);
  }, [urlParams.activation_code]);

  if (!organization) return null;

  const org_logo = organization.logo ? serverUrl + "/download/organizations/" + organization.logo
    : baseUrl + 'assets/images/logo_512x512.png';
  const org_title = organization.name || "Team 4 Care";
  const org_bg = organization.background_img ? `url("${serverUrl}/download/organizations/${organization.background_img}")`
    : `url("${baseUrl}/assets/images/bg3.jpg")`;

  return (
    <div className="App" style={{ backgroundImage: org_bg }}>
      <div className="App-header">
        <div className="login-container register">
          <div className="p-grid p-fluid">
            <div className="p-col-12">
              <div className="card card-w-title">

                <img src={ org_logo } 
                  className="logo" 
                  alt={ org_title }
                  title={ org_title }
                />
            
                <h1>Ativar Conta de Utilizador</h1>

                { loading && <Message severity="info" text="A ativar a conta..."></Message> }

                { success ? (
                    <div className="p-grid">
                        <div className="p-col-12">
                            <Message severity="success" text="A conta foi ativada com sucesso!"></Message>
                            <Link to="/login">Entrar</Link>
                        </div>
                    </div>
                ) : (
                  <div className="p-grid">
                      <div className="p-col-12">
                          <Message severity="error" text="Não foi possivel ativar a conta de utilizador."></Message>
                          <Link to="/">Voltar ao inicio</Link>
                      </div>
                    </div>
                ) }

              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  );

}

export default ActivationPage;
