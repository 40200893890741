const geoOptions = {
    enableHighAccuracy: true, 
    maximumAge: 30000,
    timeout: 5000
};

function askLocation(successCb, errorCb) {
    navigator.geolocation.getCurrentPosition((position) => {
        if (position && position.coords) {
            successCb(position.coords.latitude, position.coords.longitude);
        } else errorCb()
    }, errorCb, geoOptions);
}
  
function getLocation(successCb, errorCb) {    
    if ("geolocation" in navigator) {
        askLocation(successCb, errorCb);
    } else {
        errorCb();
    }
}

export default { getLocation };