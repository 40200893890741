import React from 'react';
import MessagesIndex from './MessagesIndex';

function MessagesListRoute() {

  return (
    <MessagesIndex />
  );

}

export default MessagesListRoute;
