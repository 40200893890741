import React, { useState, useEffect, useReducer } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import {InputSwitch} from 'primereact/inputswitch';
import { Message } from 'primereact/message';
import {Editor} from 'primereact/editor';
import { useParams } from "react-router-dom";
import loader from '../loader';
import { ProgressSpinner } from 'primereact/progressspinner';

const initialState = {
    title: '',
    description: '',
    active: true
}

function reducer(state, action) {
    switch (action.type) {
      case 'update':
        return {
            ...state,
            [action.field]: action.value
        };
      case 'update_all':
        return {
            ...action.value
        }
      default:
        throw new Error();
    }
}

function FieldsForm() {

  let [ feature, dispatch ] = useReducer(reducer, initialState)
  
  let [ errors, setErrors ] = useState({});
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  let urlParams = useParams();
  useEffect(() => {
    if (parseInt(urlParams.id, 10) < 1) {
        return dispatch({ type: 'update_all', value: initialState });
    }
    loader.get('/features/item/' + urlParams.id, res => {
        const value = { 
            ...res.item 
        };
        dispatch({ type: 'update_all', value });
    }, setLoading);
  }, [urlParams.id]);

  let register = () => {
    let data = {
        ...feature
    };
    let url = '/feature/create';
    if (feature.id) url = '/feature/update';
    setSuccess(false);
    loader.post(url, data, res => {        
        if (res.errors) return setErrors(res.errors);
        dispatch({ type: 'update', field: 'id', value: res.id });
        setSuccess(true);
    }, setLoading);
  };

  let isDataInvalid = () => {
      return loading
        || feature.title.length < 3;
  }

  let displayErrors = (errors, key) => !!errors[key] && errors[key].map(msg =>
    <Message severity="error" text={msg}></Message>
  )

  if (loading) return <ProgressSpinner />;
  const title = parseInt(feature.id, 10) ? "Alterar Item" : "Criar Novo Item";
  return (
    <React.Fragment>
        <div className="card card-w-title main-panel-small">
            <h1>{ title }</h1>

            { success && (
                <div className="p-grid">
                    <div className="p-col-12">

                        <Message 
                            severity="success" 
                            text="O item foi guardado com sucesso!">
                        </Message>
                        
                    </div>
                </div>
            ) }

            <Button 
                onClick={register} 
                label="Guardar"
                disabled={isDataInvalid()}
                style={{ float: 'right'}}
            />

            <p className="form-info">
                <strong style={{ color: 'red' }}>*</strong> 
                {' '}Campos obrigatórios
            </p>

            <div className="p-grid">

                <div className="p-col-12 p-md-6">
                    <label className="field-required">Etiqueta</label>
                </div>
                <div className="p-col-12 p-md-6">
                    <InputText 
                        placeholder="Etiqueta"
                        value={feature.title}
                        onChange={(e) => 
                            dispatch({ type: 'update', field: 'title', value: e.target.value })
                        }
                    />
                    { displayErrors(errors, 'label') }
                </div>

                <div className="p-col-12 p-md-6">
                    <label>Descrição</label>
                </div>
                <div className="p-col-12 p-md-6">
                    <Editor 
                        style={{height:'100px'}} 
                        value={feature.description}
                        onTextChange={(e) => 
                            dispatch({ type: 'update', feature: 'description', value: e.htmlValue })
                        }
                    />
                </div>

                <div className="p-col-10 p-md-6">
                    <label>Activo</label>
                </div>
                <div className="p-col-2 p-md-6">
                    <InputSwitch 
                        checked={feature.active} 
                        onChange={(e) => 
                            dispatch({ type: 'update', field: 'active', value: !feature.active })
                        } 
                    />
                </div>
                
            </div>

        </div>
    </React.Fragment>
  );

}

export default FieldsForm;
