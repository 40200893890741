import React, { useState, useEffect } from 'react';
import {Toolbar} from 'primereact/toolbar';
import {Calendar} from 'primereact/calendar';
import loader from '../loader';
import moment from 'moment';
import {Chart} from 'primereact/chart';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputSwitch } from 'primereact/inputswitch';
import date_pt from '../date_pt.json';

const serverUrl = process.env.REACT_APP_SERVER_URL;
let dt1 = null;
let dt2 = null;

function UserChart() {

  let [ items1, setItems1 ] = useState([]);
  let [ items2, setItems2 ] = useState([]);
  let [ start, setStart ] = useState(moment().startOf('month').toDate());
  let [ end, setEnd ] = useState(moment().endOf('month').toDate());
  
  let [ display, setDisplay ] = useState('chart');
  let [ loading, setLoading ] = useState(false);

  useEffect(() => {
    let tstart = moment(start).format('YYYY-MM-DD');
    let tend = moment(end).format('YYYY-MM-DD');
    loader.get('/user/report/checkpoint/' + tstart + '/' + tend, res => {
        setItems1(res.items);
    }, setLoading);
    loader.get('/user/report/diary/' + tstart + '/' + tend, res => {
        setItems2(res.items);
    }, setLoading);
  }, [start, end]);

  const data1 = {
    labels: items1.map(i => i.username),
    datasets: [
        {
            label: 'Total de Horas Realizadas',
            data: items1.map(i => i.total),
            fill: false,
            backgroundColor: items1.map(i => '#' + i.color),
            borderColor: items1.map(i => '#' + i.color)
        }
    ]
  };

  const data2 = {
    labels: items2.map(i => i.username),
    datasets: [
        {
            label: 'Total de Tarefas Terminadas',
            data: items2.map(i => i.total),
            fill: false,
            backgroundColor: items2.map(i => '#' + i.color),
            borderColor: items2.map(i => '#' + i.color)
        }
    ]
  };

  if (loading) return <ProgressSpinner />;
  return (

    <div className="card card-w-title">
        <h1>Colaboradores</h1>

        <Toolbar>
            <div className="p-toolbar-group-left">
                <div className="p-grid">
                    <div className="p-col-12 p-lg-6">
                        <Calendar 
                            placeholder="Data de Inicio"
                            value={start} 
                            locale={date_pt}
                            dateFormat="yy-mm-dd"
                            yearNavigator={true}
                            yearRange="2020:2030"
                            onChange={(e) => setStart(e.value) }>
                        </Calendar>
                    </div>
                    <div className="p-col-12 p-lg-6">
                        <Calendar 
                            placeholder="Data de Fim"
                            value={end} 
                            locale={date_pt}
                            dateFormat="yy-mm-dd"
                            yearNavigator={true}
                            yearRange="2020:2030"
                            onChange={(e) => setEnd(e.value) }>
                        </Calendar>
                    </div>
                </div>
            </div>

            { (!!items1.length || !!items2.length) && (
                <div className="p-toolbar-group-right">
                    <InputSwitch
                        id="displayToggle"
                        style={{ marginBottom: '-7px' }}
                        label="Grelha"
                        checked={display === 'table'} 
                        onChange={(e) => setDisplay(display === 'chart' ? 'table' : 'chart')} 
                    />{' '}<label htmlFor="displayToggle">Tabela</label>{' '}
                    { display === 'table' && (
                        <React.Fragment>
                            <Button
                                type="button" 
                                icon="pi pi-external-link" 
                                iconPos="left" 
                                onClick={e => dt1.exportCSV()}
                            />{' '}
                            <Button
                                type="button" 
                                icon="pi pi-external-link" 
                                iconPos="left" 
                                onClick={e => dt2.exportCSV()}
                            />
                        </React.Fragment>
                    )}
                </div>
            )}
        </Toolbar>

        <h2>Total de Horas Realizadas</h2>

        { items1.length === 0 ? <p>Sem dados a apresentar</p> : (
            display === 'chart' ? 
                <Chart type="bar" data={data1} options={{ maintainAspectRatio: false, scales: {
                    yAxes: [{
                        ticks: {
                            min: 0
                        }
                    }]
                } }} />
                : (
                <DataTable
                    value={items1} 
                    ref={(el) => { dt1 = el; }}
                    style={{ textAlign: 'center' }}>
                    <Column header="Utilizador" field="username" />
                    <Column header="Total de Horas Realizadas" field="total" />
                </DataTable>
                )
        ) }

        <h2>Total de Tarefas Terminadas</h2>

        { items2.length === 0 ? <p>Sem dados a apresentar</p> : (
            display === 'chart' ? 
                <Chart type="bar" data={data2} options={{ maintainAspectRatio: false, scales: {
                    yAxes: [{
                        ticks: {
                            min: 0
                        }
                    }]
                } }} />
                : (
                <DataTable 
                    value={items2} 
                    ref={(el) => { dt2 = el; }}
                    style={{ textAlign: 'center' }}>
                    <Column header="Utilizador" field="username" />
                    <Column header="Total de Tarefas Terminadas" field="total" />
                </DataTable>
                )
        ) }
        
    </div>
    
  );

}

export default UserChart;
