import React from 'react';
import ClientActivityList from './ClientActivityList';
import Session from '../auth/Session';

function ClientActivityListRoute() {

  let logged = Session.getCookie();
  let client_id = logged.client_id;
  return (
    <React.Fragment>
      <div className="card card-w-title">
        <h1>Atividades Inscritas</h1>
        
        <ClientActivityList client_id={client_id} />
      </div>
    </React.Fragment>
  );

}

export default ClientActivityListRoute;
