import React, { useState, useEffect } from 'react';
import loader from '../loader';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Column } from 'primereact/column';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import timeSetup from '../timeSetup';

function OutOfOfficeUsers() {

    let [ items, setItems ] = useState([]);
    let [ loading, setLoading ] = useState(false);

    useEffect(() => {
        loader.get('/user/outofservice/lastusers', res => {
            let titems = res.items;
            titems = titems.map(i => ({
                ...i,
                start: timeSetup.from(i.start).format('YYYY-MM-DD HH:mm'),
                end: i.end ? timeSetup.from(i.end).format('YYYY-MM-DD HH:mm') : null
            }))
            setItems(titems);
        }, setLoading);
    }, []);

    if (loading) return <ProgressSpinner />;
    return (
        <div className="card card-w-title">
            <h1>Ausências Temporárias</h1>

            <DataTable value={items} style={{ textAlign: 'center'}}>
                <Column header="Inicio" field="start" body={item => 
                    item.start
                } />
                <Column header="Fim" field="end" body={item => 
                    item.end ? item.end : ''
                } />
                <Column header="Duração (minutos)" body={item => 
                    item.end ? moment(item.end).diff(item.start, 'minutes') : ''
                } />
                <Column header="Utilizador" field="username" />
            </DataTable>
        </div>
    )
}

export default OutOfOfficeUsers;