import React from 'react';
import {SelectButton} from 'primereact/selectbutton';
import moment from 'moment';
import 'moment/locale/pt';
moment.locale('pt');

function PicarPontoItems({ done, picarPonto, items }) {
  return (
    <React.Fragment>

      <h1>Clique na picagem correspondente</h1>

      <SelectButton 
        multiple
        value={done}
        options={items} 
        onChange={(e) => picarPonto(e)} 
        itemTemplate={opt => (
          <div className="checkpoint-opt">

            <div className="checkpoint-title">
              { opt.type === 'start' ? 'Entrada' : 'Saída' }
            </div>
            <div className="checkpoint-expected">
                { moment(opt.expected).format('HH:mm') }
            </div>

            { !!opt.done ? (
              <React.Fragment>
                <div className="checkpoint-done">
                  <i className="pi pi-fw pi-check"></i>{' '}
                  { moment(opt.done).format('HH:mm') }
                </div>
                <div className="checkpoint-diff">
                  { opt.diff }
                </div>
                <div className="checkpoint-extra">
                  { opt.extra }
                </div>
              </React.Fragment>
            ) : (
              <div className="checkpoint-todo">
                <i className="pi pi-fw pi-question"></i>
              </div>
            )}

          </div>
        )} 
        optionLabel="expected" 
        optionValue="expected" 
      />
    </React.Fragment>
  )
}

export default PicarPontoItems;