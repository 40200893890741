import React, { useState, useEffect } from 'react';
import loader from '../loader';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';

function FailedLoginsList({ user }) {

  let [ items, setItems ] = useState([]);
  let [ loading, setLoading ] = useState(false);

  // Load items
  useEffect(() => {
    let url = '/failed/list' + (user ? '/' + user.username : '');
    loader.get(url, res => {
        setItems(res.items);
    }, setLoading);
  }, [user]);

  const deleteFailedUser = (item) => {
    loader.get('/failed/delete/' + item.id, res => {
        let index = items.indexOf(item);
        items.splice(index, 1);
        let newItems = Object.assign([], items);
        setItems(newItems);
    }, setLoading);
  }

  if (loading) return <ProgressSpinner />;
  const isMobile = window.innerWidth <= 768;
  return (
    <React.Fragment>
 
        <div className="card card-w-title">
            <h1>Tentativas de Login</h1>
            
            <DataTable value={items} style={{ textAlign: 'center'}}>
                <Column 
                    header={<span><i className="pi pi-clock hidden-big"></i><span className="hidden-small">Data</span></span>} 
                    field="data" 
                    body={item => moment(item.data).format('DD/MM HH:mm')}
                />
                
                { !user && (
                    <Column 
                        header={<span><i className="pi pi-info-circle hidden-big"></i><span className="hidden-small">Login</span></span>} 
                        field="username" 
                    />
                ) }

                { !isMobile && (
                    <Column 
                        header={<span><i className="pi pi-cloud hidden-big"></i><span className="hidden-small">IP</span></span>} 
                        field="ip" 
                    />
                ) }

                { !isMobile && (
                    <Column 
                        header={<span><i className="pi pi-mobile hidden-big"></i><span className="hidden-small">Dispositivo</span></span>} 
                        field="user_agent" 
                        body={(item) => 
                            /android/ig.test(item.user_agent) ? <i className="pi pi-android"></i> :
                            /apple/ig.test(item.user_agent) ? <i className="pi pi-apple"></i> : 
                            /chrome/ig.test(item.user_agent) ? <i className="pi pi-google"></i> :
                            <i className="pi pi-question-circle"></i> 
                        }
                    />
                ) }

                <Column 
                    header={<span><i className="pi pi-info-circle hidden-big"></i><span className="hidden-small">Razão</span></span>} 
                    field="reason" 
                />
                <Column 
                    header={<span><i className="pi pi-cog hidden-big"></i><span className="hidden-small">Opções</span></span>} 
                    body={(item) =>
                        <div>
                            <Button
                              className="p-button-danger"
                              onClick={e => deleteFailedUser(item)}
                              icon="pi pi-times"
                            />
                        </div>
                    }
                />
            </DataTable>
        </div>

    </React.Fragment>
  );

}

export default FailedLoginsList;
