import React, { useState, useEffect } from 'react';
import moment from 'moment';
import loader from '../loader';
import { useHistory, Link } from "react-router-dom";
import { ProgressSpinner } from 'primereact/progressspinner';
import timeSetup from '../timeSetup';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Session from '../auth/Session';
import { InputSwitch } from 'primereact/inputswitch';

function ClientsDiscuss({ client }) {

  let [ items, setItems ] = useState([]);
  let [ loading, setLoading ] = useState(false);
  let [ success, setSuccess ] = useState(false);
  let history = useHistory();

  const getItems = (res, setItems) => {
    let titems = res.items;
    titems = titems.map(i => ({
        ...i,
        created_at: timeSetup.from(i.created_at).format('YYYY-MM-DD HH:mm:ss'),
        closed_at: i.closed_at ? timeSetup.from(i.closed_at).format('YYYY-MM-DD HH:mm:ss') : null
    }))
    setItems(titems);
  }

  useEffect(() => {
    if (!client) return;
    loader.get('/discuss/client/' + client.id, res => {
        if (!res.success) return;
        getItems(res, setItems);
    }, setLoading);
  }, [client]);

  let updateItem = (data) => {
    const url = '/discuss/update';
    setSuccess(false);
    loader.post(url, data, res => {
        setSuccess(true);
    }, setLoading);
  };

  let deleteItem = (id) => {
    const url = '/discuss/delete/' + id;
    setSuccess(false);
    loader.get(url, res => {
        setSuccess(true);
        items = items.filter(i => i.id !== id);
        setItems(items);
    }, setLoading);
  };

  let toggleActive = (discusss, id) => {
    let items = Object.assign([], discusss);
    items = items.map(item => {
        if (item.id === id) {
            item.closed_at = !item.closed_at ? moment().format('YYYY-MM-DD') : null
            updateItem({ id: item.id, closed_at: item.closed_at });
        }
        return item;
    });
    setItems(items);
  }

  const logged = Session.getCookie();
  const isSuperAdmin = ['ADMINISTRATOR', 'ORG_ADMIN'].includes(logged.role1);
  const isAnalyst = [logged.role1, logged.role2].includes('ANALYST');

  let recordOptions = (item, column) => {
    if (Number(item.user_id) !== Number(logged.id)) return '';
    return <div style={{textAlign: 'center'}}>
        <Button
            onClick={e => history.push({ pathname: '/backoffice/clients/discuss/' + client.id + '/form/' + item.id })} 
            type="button" 
            icon="pi pi-pencil" 
            className="p-button-info"
            disabled={!isSuperAdmin && !isAnalyst}
        />
        <Button
            onClick={e => deleteItem(item.id)} 
            type="button" 
            icon="pi pi-times" 
            className="p-button-danger"
            disabled={![logged.role1].includes('ADMINISTRATOR')}
        />
    </div>;
  }

  let recordActive = (item, column) => {
    return <div>
            <span className="p-float-label">
                <InputSwitch
                    id="toggleActive"
                    disabled={!isSuperAdmin && !isAnalyst}
                    checked={!!item.closed_at} 
                    onChange={(e) => toggleActive(items, item.id)} 
                />
                <label style={{ marginLeft: '43px' }} htmlFor="toggleActive">{ item.closed_at ? moment(item.closed_at).format('YYYY-MM-DD') : '' }</label>
            </span>
    </div>;
  }

  if (!client || loading) return <ProgressSpinner />;

  const isMobile = window.innerWidth <= 768;
  if (loading) return <ProgressSpinner />;
  let createUrl = "/backoffice/clients/discuss/" + client.id + "/form/0";

  return (
    <React.Fragment>

        <div className="card card-w-title main-panel-small">
            <h1>Discussões</h1>

            <Toolbar>
                <div className="p-toolbar-group-left">
                    { (isSuperAdmin || isAnalyst) && (
                        <Link to={createUrl}>
                            <Button label={"Nova Discussão" } />
                        </Link>
                    )}
                </div>
                <div className="p-toolbar-group-right">
                    
                </div>
            </Toolbar>

            { success && (
                <div className="p-grid">
                    <div className="p-col-12">

                        <Message 
                            severity="success" 
                            text="O registo foi alterada com sucesso!">
                        </Message>
                        
                    </div>
                </div>
            ) }

            
            <div className="p-grid">
                <div className="p-col-12">

                <DataTable value={items}>
                    
                    <Column 
                        header="Assunto"
                        field="subject"
                        sortable={true}
                        filter={true}
                        filterMatchMode="contains"
                        body={item => (
                            <Link to={"/backoffice/clients/discuss/" + client.id + "/view/" + item.id }>
                                { item.subject }
                            </Link>
                        )}
                    />
                    
                    { !isMobile && (
                        <Column 
                            header="Data" 
                            body={item => moment(item.created_at).format('YYYY-MM-DD')}
                        />
                    ) }
                    { !isMobile && (
                        <Column 
                            header="Fechado" 
                            body={recordActive}
                        />
                    ) }

                    <Column body={recordOptions} header="Opções" style={{ width: '90px' }} />
                </DataTable>
                    
                </div>
            </div>

        </div>

    </React.Fragment>
  );

}

export default ClientsDiscuss;
