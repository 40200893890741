import React, { useState, useEffect, useReducer } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import {Calendar} from 'primereact/calendar';
import {InputSwitch} from 'primereact/inputswitch';
import {RadioButton} from 'primereact/radiobutton';
import {Dropdown} from 'primereact/dropdown';
import { Message } from 'primereact/message';
import {FileUpload} from 'primereact/fileupload';
import {ColorPicker} from 'primereact/colorpicker';
import moment from 'moment';
import loader from '../loader';
import support_types from './support_type.json';
import support_levels from './support_level.json';
import date_pt from '../date_pt.json';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputMask } from 'primereact/inputmask';
import Session from '../auth/Session';
import { PickList } from 'primereact/picklist';
import { hasMod } from '../users/Permissions';
import UnAuthorized from '../auth/UnAuthorized';
import QRCode from 'qrcode';

const serverUrl = process.env.REACT_APP_SERVER_URL || '';

const initialState = {
    firstname: '',
    lastname: '',
    birthdate: '',
    active: true,
    organization_id: null,
    business_id: null,
    plans: [],
    users: []
}

function ClientsForm({ id }) {

    let [ organizations, setOrganizations ] = useState([]);
    let [ business, setBusiness ] = useState([]);

    function reducer(state, action) {
        switch (action.type) {
        case 'update':
            let business_price = state.business_price;
            let business_discount = state.business_discount;
            if (action.field === 'business_id') {
                let business_item = business.find(i => String(i.id) === String(action.value));
                if (business_item) {
                    business_price = business_item.price_text;
                    business_discount = business_item.discount_text;
                }
            }
            return {
                ...state,
                business_price: business_price,
                business_discount: business_discount,
                [action.field]: action.value
            };
        case 'update_all':
            return {
                ...action.value
            }
        case 'update_latlon':
            return {
                ...state,
                latitude: action.value.lat,
                longitude: action.value.lon,
                mapzoom: action.value.zoom
            }
        default:
            throw new Error();
        }
    }

  let [ client, dispatch ] = useReducer(reducer, initialState);
  let [ users, setUsers ] = useState([]);
  let [ qrcodeUrl, setQRCodeUrl ] = useState('');
  
  let [ errors, setErrors ] = useState({});
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  // Load organizations
  useEffect(() => {
    loader.get('/organization/list', res => {
        setOrganizations(res.items);
    }, setLoading);
  }, []);

  // Load users
  useEffect(() => {
    loader.get('/user/list', res => {
        setUsers(res.items.filter(i =>i.active));
    }, setLoading);
  }, []);

  // Load business
  useEffect(() => {
    loader.get('/business/list', res => {
        setBusiness(res.items);
    }, setLoading);
  }, []);

  // Load client
  useEffect(() => {
    if (parseInt(id, 10) < 1) {
        const tinitialState = {
            ...initialState,
            color: 'ff0000'
        };
        return dispatch({ type: 'update_all', value: tinitialState });
    }
    loader.get('/client/item/' + id, res => {
        dispatch({ type: 'update_all', value: res.item });
        if (res.item.qrcode) generateQRCode(res.item.qrcode);
    }, setLoading);
  }, [id]);

  // Register client
  let register = () => {
    let data = {
        ...client,
        birthdate: client.birthdate ? moment(client.birthdate).format('YYYY-MM-DD') : null,
        plans: client.plans.map(f => f.id),
        users: client.users.map(f => f.id)
    };
    let url = '/client/create';
    if (client.id) url = '/client/update';
    
    setSuccess(false);
    loader.post(url, data, res => {
        if (res.errors) setErrors(res.errors);
        else {
            dispatch({ type: 'update', field: 'id', value: res.id });
            setSuccess(true);
        }
    }, setLoading);
  };

  let isDataInvalid = () => {
      return loading
        || client.firstname.length < 3
        || client.lastname.length < 3
        || !client.birthdate
        || !client.organization_id
        || (client.notifications && (!client.mobile_n || client.mobile_n.length < 15));
  }

  const organizationOptions = organizations.map(opt => ({
    label: opt.name,
    value: opt.id,
    key: opt.id
  }));

  const businessOptions = business.map(opt => ({
    label: opt.title,
    value: opt.id,
    key: opt.id
  }));
  businessOptions.unshift({ label: 'Sem plano', value: null, key: '' });

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/photo_placeholder.png';
  }

  // Upload
  const onUpload = (e) => {
    if (e.files.length === 0) return;
    
    const formData  = new FormData();
    formData.append('folder', 'clients');
    formData.append('upload', e.files[0]);
    const url = '/upload';
    loader.upload(url, formData, res => {
        if (!res.filename) return;
        dispatch({ type: 'update', field: 'photo', value: res.filename });  
      }, setLoading);
  }

  const onRemoveAvatar = (e, file) => {
    dispatch({ type: 'update', field: 'photo', value: '' });
  }

  let displayErrors = (errors, key) => !!errors[key] && errors[key].map(msg =>
    <Message severity="error" text={msg}></Message>
  )

  const availableUsers = users.filter(item1 => 
    !client.users.find(item2 => item1.id === item2.id)
  );

  const generateQRCode = (value) => {
    var opts = {
        errorCorrectionLevel: 'H',
        type: 'image/jpeg',
        quality: 0.3,
        margin: 1,
    }
    QRCode.toDataURL(value, opts)
      .then(url => {
        setQRCodeUrl(url);
      })
      .catch(err => {
        console.error(err)
      });
  }

  const logged = Session.getCookie();
  const isSuperAdmin = ['ADMINISTRATOR'].includes(logged.role1);
  const isAdmin = ['ADMIN', 'ORG_ADMIN'].includes(logged.role1) || ['ADMIN', 'ORG_ADMIN'].includes(logged.role2);
  const isAnalyst = [logged.role1, logged.role2].includes('ANALYST');
  const isClient = [String(logged.id), String(logged.client_id)].includes(String(client.id));
  //console.log('super', isSuperAdmin, 'admin', isAdmin, 'analyst', isAnalyst, 'client', isClient);
  //console.log(logged.role1, logged.role2);

  // Check permissions
  const allowedClinicResume = Session.canAccessClinicResume(logged, client); 
  return (
    <React.Fragment>
        
        <div className="card card-w-title">

            { success && (
                <div className="p-grid">
                    <div className="p-col-12">

                        <Message 
                            severity="success" 
                            text="O cliente foi guardado com sucesso!">
                        </Message>
                        
                    </div>
                </div>
            ) }

              <Button 
                onClick={register} 
                label="Guardar"
                disabled={isDataInvalid()}
                style={{ float: 'right' }}
              />

              <p className="form-info">
                  <strong style={{ color: 'red' }}>*</strong> 
                  {' '}Campos obrigatórios
              </p>

              <div className="p-grid">
                <div className="p-col-12 p-lg-6">
                    <div className="p-grid">

                        <div className="p-col-12 p-md-6">
                            <label className="field-required">Primeiro Nome</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <InputText 
                                placeholder="Primeiro Nome"
                                value={client.firstname}
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'firstname', value: e.target.value })
                                }
                            />
                            <p className="field-help">(min 3 caracteres)</p>
                            { displayErrors(errors, 'firstname') }
                        </div>

                        <div className="p-col-12 p-md-6">
                            <label className="field-required">Último Nome</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <InputText 
                                placeholder="Último Nome"
                                value={client.lastname}
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'lastname', value: e.target.value })
                                }
                            />
                            <p className="field-help">(min 3 caracteres)</p>
                            { displayErrors(errors, 'lastname') }
                        </div>

                        { (isAdmin || isAnalyst || isClient) &&
                            <React.Fragment>
                                <div className="p-col-12 p-md-6">
                                    <label className="field-required">Data de Nascimento</label>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <Calendar 
                                        placeholder="Data de Nascimento"
                                        value={client.birthdate} 
                                        locale={date_pt}
                                        dateFormat="yy-mm-dd"
                                        yearNavigator={true}
                                        yearRange="1920:2010"
                                        onChange={(e) => 
                                            dispatch({ type: 'update', field: 'birthdate', value: e.value })
                                        }>
                                    </Calendar>
                                </div>
                            </React.Fragment>
                        }

                        <div className="p-col-12 p-md-6">
                            <label className="field-required">Organização</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <Dropdown 
                                disabled={!isSuperAdmin}
                                placeholder="Escolha uma organização"
                                value={parseInt(client.organization_id, 10)}
                                options={organizationOptions} 
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'organization_id', value: e.value })
                                }
                            />
                        </div>

                        <div className="p-col-6 p-md-6">
                            <label>Activo</label>
                        </div>
                        <div className="p-col-6 p-md-6">
                            <InputSwitch 
                                disabled={!isSuperAdmin}
                                checked={client.active} 
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'active', value: !client.active })
                                } 
                            />
                        </div>

                        <div className="p-col-12 p-md-6">
                            <label>Código QR</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <InputText 
                                style={{ width: '100%' }}
                                placeholder="Introduza o texto para gerar a imagem"
                                value={client.qrcode}
                                onChange={(e) => {
                                    dispatch({ type: 'update', field: 'qrcode', value: e.target.value });
                                    generateQRCode(e.target.value);
                                }}
                            />
                            <div style={{ textAlign: 'center', padding: '15px' }}>
                                <a href={qrcodeUrl} target="_blank" download={"QRCode_cliente.jpg"}>
                                    <img src={qrcodeUrl} alt="" />
                                </a>
                            </div>
                        </div>

                        { !!client.color && (
                            <React.Fragment>
                                <div className="p-col-12 p-md-6">
                                    <label>Côr de Etiqueta</label>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <ColorPicker 
                                        value={client.color} 
                                        onChange={(e) => 
                                            dispatch({ type: 'update', field: 'color', value: e.value })
                                        }
                                    />
                                </div>
                            </React.Fragment>
                        )}

                        <div className="p-col-12 p-md-6">
                            <label>Data de Admissão</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <Calendar 
                                disabled={!isSuperAdmin && !isAdmin}
                                placeholder="Data de Admissão"
                                value={client.date_admission} 
                                dateFormat="yy-mm-dd"
                                yearNavigator={true}
                                yearRange={"1990:" + moment().format('YYYY')}
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'date_admission', value: e.value })
                                }>
                            </Calendar>
                        </div>

                        <div className="p-col-12 p-md-6">
                            <label>N.º Cliente</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <InputText 
                                disabled={!isSuperAdmin && !isAdmin}
                                placeholder="N.º do Cliente"
                                value={client.num}
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'num', value: e.target.value })
                                }
                            />
                        </div>

                        <div className="p-col-12 p-md-6">
                            <label>Plano Atual</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <Dropdown 
                                disabled={!isSuperAdmin}
                                placeholder="Escolha um plano"
                                value={parseInt(client.business_id, 10)}
                                options={businessOptions} 
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'business_id', value: e.value })
                                }
                            />
                        </div>

                        { (isAdmin || isClient) && client.business_id && (
                            <React.Fragment>
                                <div className="p-col-12 p-md-6">
                                    <label>Preço (opcional)</label>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <InputText 
                                        placeholder="Preço"
                                        value={client.business_price}
                                        onChange={(e) => 
                                            dispatch({ type: 'update', field: 'business_price', value: e.target.value })
                                        }
                                    />
                                </div>

                                <div className="p-col-12 p-md-6">
                                    <label>Desconto (opcional)</label>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <InputText 
                                        placeholder="Desconto"
                                        value={client.business_discount}
                                        onChange={(e) => 
                                            dispatch({ type: 'update', field: 'business_discount', value: e.target.value })
                                        }
                                    />
                                </div>
                            </React.Fragment>
                        )}

                    </div>
                </div>
                <div className="p-col-12 p-lg-6">
                    <div className="p-grid">

                        <div className="p-col-12 p-md-6">
                            <label>Fotografia</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            { !!client.photo && (
                                <img 
                                    src={getFileUrl(client.photo, 'clients')} 
                                    alt="Fotografia"
                                    title="Fotografia"
                                    className="img-avatar"
                                />
                            ) }
                            <FileUpload 
                                name="photo"
                                mode="basic"
                                url={'/upload'}
                                customUpload={true}
                                uploadHandler={onUpload}
                                auto={true}
                                chooseLabel={client.photo ? "Alterar" : "Escolher" }
                                onRemove={onRemoveAvatar}
                            />
                        </div>
                        
                        <div className="p-col-12 p-md-6">
                            <label>Nome Completo</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <InputText 
                                placeholder="Nome completo"
                                value={client.fullname}
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'fullname', value: e.target.value })
                                }
                            />
                        </div>

                        { (isAdmin || isClient) && (
                            <React.Fragment>
                                
                                <div className="p-col-12">
                                    <label>Morada Completa</label>
                                </div>
                                <div className="p-col-12">
                                    <InputTextarea
                                        rows={3} 
                                        style={{ width: '100%' }}
                                        placeholder="Morada completa"
                                        value={client.address_n}
                                        onChange={(e) => 
                                            dispatch({ type: 'update', field: 'address_n', value: e.target.value })
                                        }
                                    />
                                </div>

                                <div className="p-col-6 p-md-6">
                                    <label>Telef. Casa</label>
                                </div>
                                <div className="p-col-6 p-md-6">
                                    <InputMask
                                        mask="+999 999 999 999"
                                        placeholder="Telef. Casa"
                                        value={client.phone_n}
                                        onChange={(e) => 
                                            dispatch({ type: 'update', field: 'phone_n', value: e.target.value })
                                        }
                                    />
                                </div>
                                
                                <div className="p-col-6 p-md-6">
                                    <label>Notificações SMS</label>
                                </div>
                                <div className="p-col-6 p-md-6">
                                    <InputSwitch 
                                        disabled={!(isClient || isAdmin)}
                                        checked={client.notifications} 
                                        onChange={(e) => 
                                            dispatch({ type: 'update', field: 'notifications', value: !client.notifications })
                                        } 
                                    />
                                </div>
                                <div className="p-col-6 p-md-6">
                                    <label className={client.notifications ? "field-required" : ""}>
                                        Telemóvel
                                    </label>
                                </div>
                                <div className="p-col-6 p-md-6">
                                    <InputMask
                                        mask="+999 999 999 999?99"
                                        placeholder="Telemóvel"
                                        value={client.mobile_n}
                                        onChange={(e) => 
                                            dispatch({ type: 'update', field: 'mobile_n', value: e.target.value })
                                        }
                                    />
                                </div>

                                <div className="p-col-6 p-md-6">
                                    <label>N.º BI / Cartão do Cidadão</label>
                                </div>
                                <div className="p-col-6 p-md-6">
                                    <InputMask
                                        mask="999 999 99?9"
                                        placeholder="N.º BI / CC"
                                        value={client.bi_n}
                                        onChange={(e) => 
                                            dispatch({ type: 'update', field: 'bi_n', value: e.target.value })
                                        }
                                    />
                                </div>

                                <div className="p-col-6 p-md-6">
                                    <label>N.º Contribuinte</label>
                                </div>
                                <div className="p-col-6 p-md-6">
                                    <InputMask
                                        mask="999 999 99?9"
                                        placeholder="N.º Contribuinte"
                                        value={client.nif_n}
                                        onChange={(e) => 
                                            dispatch({ type: 'update', field: 'nif_n', value: e.target.value })
                                        }
                                    />
                                </div>

                                <div className="p-col-6 p-md-6">
                                    <label>N.º Utente SNS</label>
                                </div>
                                <div className="p-col-6 p-md-6">
                                    <InputMask
                                        mask="999 999 999"
                                        placeholder="N.º Utente SNS"
                                        value={client.sns_n}
                                        onChange={(e) => 
                                            dispatch({ type: 'update', field: 'sns_n', value: e.target.value })
                                        }
                                    />
                                </div>

                                <div className="p-col-6 p-md-6">
                                    <label>N.º Seg. Social</label>
                                </div>
                                <div className="p-col-6 p-md-6">
                                    <InputMask
                                        mask="99 999 999 999"
                                        placeholder="N.º Seg. Social"
                                        value={client.ss_n}
                                        onChange={(e) => 
                                            dispatch({ type: 'update', field: 'ss_n', value: e.target.value })
                                        }
                                    />
                                </div>

                                <div className="p-col-6 p-md-6">
                                    <label>E-mail</label>
                                </div>
                                <div className="p-col-6 p-md-6">
                                    <InputText 
                                        placeholder="E-mail"
                                        value={client.email_n}
                                        onChange={(e) => 
                                            dispatch({ type: 'update', field: 'email_n', value: e.target.value })
                                        }
                                    />
                                </div>
                            </React.Fragment>
                        )}
                        
                    </div>
                </div>
            </div>
        </div>

        <div className="card card-w-title">

            { (isAdmin || isClient || isAnalyst) && (
                <div className="p-grid">

                    <div className="p-col-12 p-lg-6">
                        <label>O Cliente necessita de suporte para satisfazer e/ou desevolver atividades quotidianas?</label>
                    </div>
                    <div className="p-col-12 p-lg-6">
                        <RadioButton 
                            id="daily_support_yes"
                            disabled={!isSuperAdmin && !isAdmin && !isAnalyst}
                            value={true} 
                            name="daily_support" 
                            checked={client.daily_support}
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'daily_support', value: true })
                            }
                        />
                        <label htmlFor="daily_support_yes" className="p-radiobutton-label">Sim</label>
                        {'          '}
                        <RadioButton 
                            id="daily_support_no"
                            disabled={!isSuperAdmin && !isAdmin && !isAnalyst}
                            value={false} 
                            name="daily_support" 
                            checked={!client.daily_support}
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'daily_support', value: false })
                            }
                        />
                        <label htmlFor="daily_support_no" className="p-radiobutton-label">Não</label>
                    </div>

                    <div className="p-col-12 p-lg-6">
                        <label>Identifique o suporte existente do cliente?</label>
                    </div>
                    <div className="p-col-12 p-lg-6">
                        <Dropdown 
                            disabled={!isSuperAdmin && !isAdmin && !isAnalyst}
                            placeholder="Escolha uma opção"
                            value={client.daily_support_type}
                            options={support_types} 
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'daily_support_type', value: e.value })
                            }
                        />
                    </div>

                    <div className="p-col-12 p-lg-6">
                        <label>Grau de dependência global do cliente</label>
                    </div>
                    <div className="p-col-12 p-lg-6">
                        <Dropdown 
                            disabled={!isSuperAdmin && !isAdmin && !isAnalyst}
                            placeholder="Escolha uma opção"
                            value={client.support_level}
                            options={support_levels} 
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'support_level', value: e.value })
                            }
                        />
                    </div>                

                    <div className="p-col-12">
                        <h2>Motivo do Pedido</h2>
                    </div>
                    <div className="p-col-12">
                        <InputTextarea
                            disabled={!isSuperAdmin && !isAdmin}
                            rows={3} 
                            style={{ width: '100%' }}
                            placeholder="Motivo do Pedido"
                            value={client.register_motivation}
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'register_motivation', value: e.target.value })
                            }
                        />
                    </div>

                    <div className="p-col-12">
                        <h2>Informações Relevantes</h2>
                    </div>
                    <div className="p-col-12">
                        <InputTextarea
                            rows={3} 
                            disabled={!isSuperAdmin && !isAdmin}
                            style={{ width: '100%' }}
                            placeholder="Informações Relevantes"
                            value={client.relevant_info}
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'relevant_info', value: e.target.value })
                            }
                        />
                    </div>

                    <div className="p-col-12">
                        <h2>Resumo Clínico</h2>
                        <small>Acesso Condicionado</small>
                    </div>
                    <div className="p-col-12">
                        { !allowedClinicResume.result ?
                            <UnAuthorized reasons={allowedClinicResume.reasons || []} goBack={false} />
                        :
                            <InputTextarea
                                rows={3} 
                                disabled={!isSuperAdmin && !isAdmin}
                                style={{ width: '100%' }}
                                placeholder="Resumo Clínico"
                                value={client.resumo_clinico}
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'resumo_clinico', value: e.target.value })
                                }
                            />
                        }
                    </div>

                    <div className="p-col-12">
                        <h2>Alergias</h2>
                    </div>
                    <div className="p-col-12">
                        <InputTextarea
                            disabled={!isSuperAdmin && !isAdmin}
                            rows={3} 
                            style={{ width: '100%' }}
                            placeholder="Alergias"
                            value={client.alergias}
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'alergias', value: e.target.value })
                            }
                        />
                    </div>

                    <div className="p-col-12">
                        <h2>Restrinções Alimentares</h2>
                    </div>
                    <div className="p-col-12">
                        <InputTextarea
                            disabled={!isSuperAdmin && !isAdmin}
                            rows={3} 
                            style={{ width: '100%' }}
                            placeholder="Restrinções Alimentares"
                            value={client.restrincoes_alimentares}
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'restrincoes_alimentares', value: e.target.value })
                            }
                        />
                    </div>

                    <div className="p-col-12">
                        <h2>Outras Observações decorrentes da entrevista pré-diagnóstica</h2>
                    </div>
                    <div className="p-col-12">
                        <InputTextarea
                            rows={3} 
                            style={{ width: '100%' }}
                            placeholder="Outras Observações"
                            value={client.other_info}
                            onChange={(e) => 
                                dispatch({ type: 'update', field: 'other_info', value: e.target.value })
                            }
                        />
                    </div>
                </div>
            )}

            { hasMod(['T4CCS1']) && (
                <React.Fragment>
                    <h5>Colaboradores Preferidos</h5>

                    <PickList
                        source={availableUsers} 
                        target={client.users} 
                        sourceHeader="Items disponíveis"
                        targetHeader="Items selecionados"
                        itemTemplate={item => item.username}
                        responsive={false}
                        onChange={(e) => 
                            dispatch({ type: 'update', field: 'users', value: e.target })
                        }
                    />
                    
                </React.Fragment>
            ) }

        </div>
    </React.Fragment>
  );

}

export default ClientsForm;
