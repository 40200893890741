import React, { useState, useEffect } from 'react';
import {InputSwitch} from 'primereact/inputswitch';
import { Message } from 'primereact/message';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toolbar} from 'primereact/toolbar';
import DiaryCalendar from '../diary/DiaryCalendar';
import moment from 'moment';
import loader from '../loader';
import durationOptions from '../workplans/duration.json';
import { ProgressSpinner } from 'primereact/progressspinner';
import timeSetup from '../timeSetup';
import { Card } from 'primereact/card';

const serverUrl = process.env.REACT_APP_SERVER_URL;

const getItems = (res, setItems) => {
    let titems = res.items;
    titems = titems.map(i => ({
        ...i,
        start_date: timeSetup.from(i.start_date).format('YYYY-MM-DD HH:mm'),
        end_date: i.end_date ? timeSetup.from(i.end_date).format('YYYY-MM-DD HH:mm') : null,
        day_hourmin: timeSetup.from(i.day_hourmin).format('YYYY-MM-DD HH:mm')    
    }))
    setItems(titems);
}

function ClientActivityList(props) {
  const { client_id } = props;

  let [ items, setItems ] = useState([]);
  let [ date, setDate ] = useState(moment().startOf('week').format('YYYY-MM-DD'));
  let [ calendar, setCalendar ] = useState([]);
  let [ display, setDisplay ] = useState('grid');
  
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  const reload = () => {
    loader.get('/client/activity/list/' + client_id, res => {
        if (!res.success) return;
        getItems(res, setItems);
    }, setLoading);

    let startdate = moment().startOf('week').format('YYYY-MM-DD');
    let enddate = moment().startOf('week').add(2, 'weeks').format('YYYY-MM-DD');
    loader.get('/client/activity/calendar/' + client_id + '/' + startdate + '/' + enddate, res => {
        let calitems = res.items.map(i => ({
            id: i.id,
            title: i.title,
            start: timeSetup.from(i.start).format('YYYY-MM-DD HH:mm:ss'),
            end: timeSetup.from(i.end).format('YYYY-MM-DD HH:mm:ss'),
            backgroundColor: i.color
        }));
        setCalendar(calitems);
    });
  }

  useEffect(() => {
    reload();
  }, []);

  let updateItem = (data) => {
    const url = '/client/activity/toggle/' + client_id + '/' + data.id;
    loader.get(url, data, res => {
        reload();
    });
  };

  let toggleActive = (user_activitys, aid) => {
    let items = Object.assign([], user_activitys);
    items = items.map(item => {
        if (item.id === aid) {
            item.active = !item.active;
            updateItem({ id: item.id, active: item.active });
        }
        return item;
    });
    setItems(items);
  }

  let recordActive = (item, column) => {
    return (
        <InputSwitch 
            checked={item.active} 
            onChange={(e) => toggleActive(items, item.id)} 
        />
    )
  }

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/topic_placeholder.jpg';
  }

  const getOptionLabel = (options, value) => {
    const item = options.find(opt => opt.value === value);
    return item ? item.label : value;
  }

  if (loading) return <ProgressSpinner />;
  return (
    <React.Fragment>
        
        <Toolbar>
            <div className="p-toolbar-group-left">
                
            </div>
            <div className="p-toolbar-group-right">
                <InputSwitch 
                    label="Grelha"
                    checked={display === 'grid'} 
                    onChange={(e) => setDisplay(display === 'grid' ? 'list' : 'grid')} 
                />{' '}Grelha
            </div>
        </Toolbar>

        { success && (
            <div className="p-grid">
                <div className="p-col-12">

                    <Message 
                        severity="success" 
                        text="A inscrição foi alterada com sucesso!">
                    </Message>
                    
                </div>
            </div>
        ) }

        { items.length === 0 && (
            <p>Não existem atividades disponiveis.</p>
        )}

        { display === 'grid' ? (
            <div className="p-grid">

                { items.map(item => (
                    <div className="p-col-6 p-lg-3" key={item.id} id="user-activities-grid">
                        <Card
                            title={item.title}
                            subTitle={item.duration + ' minutos'}
                            header={(
                                <img 
                                    src={getFileUrl(item.image, 'topics')} 
                                    alt="Imagem"
                                    title="Imagem"
                                    className="img-avatar"
                                />
                            )}
                            footer={
                                <div className="p-grid diary-footer">
                                    <div className="p-col-6">
                                        <big>{ moment(item.day_hourmin).format('HH:mm') }</big>
                                    </div>
                                    <div className="p-col-6">
                                        { recordActive(item) }
                                    </div>
                                </div>
                            }>
                            <em className="subtitle">{ item.category }</em>
                            <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
                            <hr />
                            <div className="p-grid">
                                <div className="p-col-6">
                                    <div><small>Início</small></div>
                                    <div className="subtitle">
                                        <small>{ moment(item.start_date).format('YYYY-MM-DD')}</small>
                                    </div>
                                </div>
                                <div className="p-col-6">
                                    <div><small>Fim</small></div>
                                    <div className="subtitle">
                                        <small>{ item.end_date ? moment(item.end_date).format('YYYY-MM-DD') : 'Indeterminado'}</small>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                )) }
                
            </div>
        ) : (

            <div className="p-grid">
                <div className="p-col-12">

                <DataTable value={items} responsive={true}>
                    <Column 
                        header="Título"
                        field="title"
                        body={item => (item.category ? item.category + ' - ' : '') + item.title}  
                        sortable={true}
                        filter={true}
                        filterMatchMode="contains"
                    />

                    <Column 
                        header="Hora" 
                        body={i => moment(i.day_hourmin).format('HH:mm')} 
                    />

                    <Column 
                        header="Duração" 
                        body={i => getOptionLabel(durationOptions, i.duration)}
                    />
                    <Column 
                        header="Inicio" 
                        body={item => moment(item.start_date).format('YYYY-MM-DD')}
                    />
                    <Column 
                        header="Fim" 
                        body={item => item.end_date ? moment(item.end_date).format('YYYY-MM-DD') : ''}
                    />
                    <Column 
                        header="Activo"
                        body={item => (
                            <div style={{ textAlign: 'right' }}>{ recordActive(item) }</div>
                        )} 
                    />
                </DataTable>
                    
                </div>
            </div>
        ) }

        <div>
            <hr/>
            <h2>Calendário</h2>
            <small className="subtitle">Apenas calculado para as próximas 2 semanas</small>

            <DiaryCalendar 
                start={moment(date).add(1, 'day').format('YYYY-MM-DD')}
                items={calendar} 
            />
        </div>
            
    </React.Fragment>
  );

}

export default ClientActivityList;
