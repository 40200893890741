import React, { useState, useEffect, useReducer } from 'react';
import { useParams } from "react-router-dom";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import {FileUpload} from 'primereact/fileupload';
import {Editor} from 'primereact/editor';
import { Accordion, AccordionTab } from 'primereact/accordion';
import loader from '../loader';
import Session from '../auth/Session';
import { ProgressSpinner } from 'primereact/progressspinner';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const serverUrl = process.env.REACT_APP_SERVER_URL;

function reducer(state, action) {
    switch (action.type) {
      case 'update':
          if (action.field === 'subject') {
              return {
                  ...state,
                  subject: action.value
              }
          }
        return {
            ...state,
            [action.field]: action.value
        };
      case 'update_all':
        return {
            ...action.value
        }
      default:
        throw new Error();
    }
}

function DiscussView(props) {

  let logged = Session.getCookie();
  const initialState = {
    discuss_id: null,
    body: '',
    user_id: logged.id
  };

  const initialDocState = { 
    discuss_id: null, 
    user_id: logged.id,
    label: '', 
    filename: '' 
  };

  let [ discuss, setDiscuss ] = useState({ replies: [] });
  let [ reply, dispatch ] = useReducer(reducer, initialState);
  let [ document, setDocument ] = useState(initialDocState);
  let [ activeIndex, setActiveIndex ] = useState([]);
  
  let [ errors, setErrors ] = useState({});
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  let urlParams = useParams();
  let id = urlParams.discuss_id;

  function reload(id) {
    loader.get('/discuss/item/' + id, res => {
        setDiscuss(res.item);
        dispatch({ type: 'update', field: 'discuss_id', value: res.item.id });
        setDocument({ ...document, discuss_id: res.item.id });
    }, setLoading);
  }

  // Load discuss item
  useEffect(() => {
    reload(id);
  }, [id]);
  
  // Register reply
  let register = () => {
    let data = {
        ...reply,
        created_at: moment().format('YYYY-MM-DD HH:mm:ss')
    };
    let url = '/discuss/reply/create';
    setSuccess(false);
    loader.post(url, data, res => {
        if (res.errors) return setErrors(res.errors);
        dispatch({ type: 'update', field: 'id', value: res.id });
        setSuccess(true);
        reload(id);
    }, setLoading);
  };

  // Register reply
  let registerDocument = () => {
    let data = {
        ...document,
        created_at: moment().format('YYYY-MM-DD HH:mm:ss')
    };
    let url = '/discuss/attach/create';
    loader.post(url, data, res => {
        if (res.errors) return setErrors(res.errors);
        setDocument({ ...document, label: '', filename: '' });
        reload(id);
    }, setLoading);
  };

  let isDataInvalid = () => {
      return loading
        || reply.body.length < 3;
  }

  // Upload
  const onUpload = (e) => {
    if (e.files.length === 0) return;
    const formData  = new FormData();
    formData.append('folder', 'docs');
    formData.append('upload', e.files[0]);
    const url = '/upload';
    loader.upload(url, formData, res => {
      if (!res.filename) return;
      let filename = res.filename;
      setDocument({ ...document, filename });
    }, setLoading);
  }

  const onRemoveFile = (e, file) => {
    setDocument({ ...document, filename: '' });
  }

  let deleteDocument = (doc) => {
    const url = '/discuss/attach/delete/' + doc.id;
    loader.get(url, res => {
        if (!res.success) return;
        let attach = discuss.attach.filter(i => i.id !== id);
        dispatch({ type: 'update', field: 'attach', value: attach });
        reload(id);
    }, setLoading);
  };

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/photo_placeholder.png';
  }

  // Download
  const getDocFileUrl = (filename) => {
    return serverUrl + '/download/docs/' + filename;
  }

  let recordPhoto = (item, column) => {
    return (
        <img 
            src={getFileUrl(item.photo, 'users')} 
            alt="Fotografia"
            title="Fotografia"
            className="img-avatar-mini"
        />
    )
  }

  if (loading) return <ProgressSpinner />;
  const msg = "A resposta foi guardada com sucesso!";
  return (
    <div className="p-grid">
        <div className="p-col-12 p-lg-8">
            <div className="card card-w-title">
                <h1>{ discuss.subject }</h1>
                <p><em>De: { discuss.username } a { moment(reply.created_at).format('YYYY-MM-DD HH:mm') }</em></p> 

                <div dangerouslySetInnerHTML={{ __html: discuss.body }}></div>
            </div>
        </div>
        <div className="p-col-12 p-lg-4">
            <div className="card card-w-title">
                <h1>Anexos</h1>

                <DataTable value={discuss.attach}>
                    <Column
                        field="created_at"
                        body={at => (
                            moment(at.created_at).format('YYYY-MM-DD')
                        )}
                    />
                    <Column
                        field="created_at"
                        body={at => (
                            at.username
                        )}
                    />
                    <Column
                        field="label"
                        body={at => (
                            <a href={getDocFileUrl(at.filename)} target="_blank">{ at.label }</a>
                        )}
                    />
                    <Column
                        style={{ width: '50px'}}
                        body={at => (
                            <Button
                                onClick={e => deleteDocument(at)}
                                type="button" 
                                icon="pi pi-times" 
                                className="p-button-danger"
                                disabled={![logged.role1].includes('ADMINISTRATOR')}
                            />
                        )}
                    />
                </DataTable>
                <hr />

                <div className="discuss-upload">
                    <div className="p-grid">
                        <div className="p-col-12">
                            <InputText
                                name="label"
                                placeholder="Etiqueta..."
                                value={document.label}
                                onChange={e => setDocument({ ...document, label: e.target.value })}
                            />
                        </div>
                        <div className="p-col-6">
                            <FileUpload
                                name="filename"
                                mode="basic"
                                url={'/upload'}
                                customUpload={true}
                                uploadHandler={onUpload}
                                auto={true}
                                chooseLabel={document.filename ? "Alterar" : "Ficheiro" }
                                onRemove={onRemoveFile}
                                icon="pi pi-check"
                            />
                        </div>
                        <div className="p-col-6" style={{ textAlign: 'right' }}>
                            <Button 
                                onClick={e => registerDocument()} 
                                label="Enviar"
                                disabled={loading || (!document.label || !document.filename)} 
                            />
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>

        <div className="p-col-12">
            <div className="card card-w-title discuss-replies">
                <h1>Respostas</h1>

                <Accordion 
                    activeIndex={activeIndex} 
                    multiple={true}
                    onTabChange={(e) => setActiveIndex(e.index)}>
                    { discuss.replies.map((reply, i) => (
                        <AccordionTab key={reply.id} header={(
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            { recordPhoto(reply) }        
                                        </td>
                                        <td>
                                            { reply.username }        
                                        </td>
                                        <td>
                                            { moment(reply.created_at).format('YYYY-MM-DD HH:mm') }    
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        )}>
                            <div dangerouslySetInnerHTML={{ __html: reply.body }}></div>
                        </AccordionTab>
                    ))}
                </Accordion>
            </div>
        </div>

        <div className="p-col-12">
            <div className="card card-w-title">
                <h1>Responder</h1>

                { success && (
                    <div className="p-grid">
                        <div className="p-col-12">
        
                            <Message severity="success" text={msg}></Message>
                            
                        </div>
                    </div>
                ) }

                <Button 
                    onClick={register} 
                    label="Enviar"
                    disabled={isDataInvalid()}
                    style={{ float: 'right' }}
                />

                <Editor 
                    style={{height: '210px'}} 
                    value={reply.body}
                    onTextChange={(e) => 
                        dispatch({ type: 'update', field: 'body', value: e.htmlValue })
                    }
                />
        
                     
                
            </div>
        </div>
    </div>
  );

}

export default DiscussView;
