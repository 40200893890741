import React from 'react';
import PlansForm from './PlansForm';
import { useParams } from "react-router-dom";

function PlansFormRoute() {

  let urlParams = useParams();

  let title = parseInt(urlParams.id, 10) ? "Alterar Atividade" : "Nova Atividade";
  const therapy = !!urlParams.therapy;
  if (therapy) title = parseInt(urlParams.id, 10) ? "Alterar Terapêutica" : "Nova Terapêutica";
  return (
    <React.Fragment>
        <div className="card card-w-title">
          <h1>{ title }</h1>
          <PlansForm 
            client_id={urlParams.client_id} 
            id={urlParams.id} 
            therapy={therapy}
          />
        </div>
    </React.Fragment>
  );

}

export default PlansFormRoute;
