import React, { useState, useEffect } from 'react';
import {Dialog} from 'primereact/dialog';
import moment from 'moment';
import 'moment/locale/pt';
import loader from '../loader';
import { useHistory } from "react-router-dom";
import Session from '../auth/Session';
import Geo from '../Geo';
import timeSetup from '../timeSetup';
import QRCodeReader from '../users/QRCodeReader';
import { ProgressSpinner } from 'primereact/progressspinner';
import PicarPontoItems from './PicarPontoItems';
moment.locale('pt');

const serverUrl = process.env.REACT_APP_SERVER_URL;
const tolerance = process.env.REACT_APP_CHECKPOINT_TOLERANCE || 15;
let notification = null, notifyTimeout = null;

function createNotification() {
    if (notification) notification.close();
    let options = {
        tag: 'renotify',
        renotify: true
    };
    notification = new Notification("Picagem de Ponto!", options);
}

function notify() {

    // Create notification
    if (!("Notification" in window)) return console.log('No notifications support');

    if (Notification.permission === "granted") {
        createNotification();
    } else if (Notification.permission !== 'denied') {
        Notification.requestPermission(function (permission) {
            if (permission === "granted") createNotification();
        });
    }
}

function PicarPonto() {

  const logged = Session.getCookie();

  let [ items, setItems ] = useState([]);
  let [ done, setDone ] = useState([]);
  let [ requestUnlock, setRequestUnlock ] = useState(false);
  let [ loading, setLoading ] = useState(false);
  let [ loading2, setLoading2 ] = useState(false);
  let [ checkpointInput, setCheckpointInput ] = useState({});
  let [ userClick, setUserClick ] = useState(false);

  let history = useHistory();

  const serverCheckPoint = (checkpoint, cb) => {
    const user = Session.getCookie();
    if (!user) return history.push({ pathname: '/login' });
    if (!requestUnlock) return;
      
    // Save checkpoint
    const data = { 
        user_id: user.id,
        checkpoint: checkpoint.type,
        workplan_id: checkpoint.workplan_id,
        qrcode: checkpoint.qrcode,
        latitude: checkpoint.latitude,
        longitude: checkpoint.longitude
    };
    loader.post('/user/checkpoint/' + user.id, data, res => {
        cb(res);
    }, setLoading2);
  }

  // find next notification
  const findNextNotification = (items) => {
    if (loading) return;
    let found = false;
    if (notification) clearTimeout(notifyTimeout);
    items.map(v => {
        if (found) return;
        let check = moment.parseZone(v.expected).isAfter();
        if (check) {
            found = true;
            let next = moment.parseZone(v.expected).diff(moment(), 'seconds');
            notifyTimeout = setTimeout(() => {
                notify();
            }, next * 1000);
        }
    });
  }

  // Load next workplans
  const loadNextWorkplan = () => {
    if (loading) return;
    setCheckpointInput({ ...checkpointInput, qrcode: null });

    loader.get('/workplan/next/' + logged.id, res => {
        if (!res.success) return;
        let items = res.items;
        items = items.map(i => {
            i.expected = timeSetup.from(i.expected).format('YYYY-MM-DD HH:mm:ss');
            i.done = i.done ? timeSetup.from(i.done).format('YYYY-MM-DD HH:mm:ss') : null;
            return i;
        })
        setItems(items);
        setDone(items.filter(i => i.done).map(i => i.expected));
        setRequestUnlock(true);

        // Find next notification
        findNextNotification(items);

        // Save checkpoint
        Geo.getLocation((latitude, longitude) => {
            setCheckpointInput({ ...checkpointInput, latitude, longitude });
        }, (error) => console.log(error));
        
    }, setLoading2);
  }

  // Get next notification
  useEffect(() => {
    loader.get('/workplan/next/' + logged.id, res => {
      if (!res.success) return;
      findNextNotification(res.items);
    }, setLoading);
  }, []);

  // Get user click
  useEffect(() => {
    if (!userClick) return;
    serverCheckPoint(checkpointInput, (res) => {
        if (!res.success) loadNextWorkplan();

        // Set last client on user session
        if (res.success && res.client_id) {
            Session.authenticate({
                ...logged,
                last_client: res.client_id
            }, () => window.location.reload());
        }
    });
  }, [userClick]);

  // Get last done
  let last = null;
  items.map((opt, i) => {
    if (opt.done) last = i;
  });

  // Save checkpoint
  const picarPonto = (e) => {

    // Only available options
    let value = e.value.filter(v => !done.includes(v));
    if (value.length === 0) return;

    // Only options after last selected
    let index = items.map(i => i.expected).indexOf(value[0]);
    if ((last !== null) && index <= last) return;

    let selected = items.filter(v => v.expected === value[0]).pop();
    
    // Only start option after tolerance
    /*
    if (selected.type === 'start') {
        let maxTime = moment.parseZone(selected.expected).add(tolerance, 'minutes');
        let now = moment();
        let allowed = now.isAfter(maxTime);
        if (!allowed) return;
    }
    */

    // OK!
    setCheckpointInput({ ...checkpointInput, ...selected });
    setUserClick(true);
  }
  
  const isMobile = window.innerWidth <= 768;
  if (loading) return <i className="pi pi-spin pi-spinner" style={{ fontSize: '1.7rem' }} />;
  return (
    <React.Fragment>

        <button 
            className="p-link" 
            title="Picar Ponto"
            onClick={e => loadNextWorkplan()} 
            style={{ color: 'green' }}>
            <span className="layout-topbar-item-text">Picar Ponto</span>
            <span className="layout-topbar-icon pi pi-check"/>
        </button>

        { !!requestUnlock &&
            <Dialog
                header="Picar Ponto" 
                visible={!!requestUnlock} 
                style={{width: isMobile ? '100%' : '50vw' }} 
                modal={true} 
                onHide={e => setRequestUnlock(false)}>
                <div className="card card-w-title lock-screen" style={{ textAlign: 'center' }}>

                    { loading2 ? <ProgressSpinner /> :
                    <div>

                        { !items.length && 
                            <React.Fragment>
                                <h2>Descanse, hoje não trabalha!</h2>
                            </React.Fragment>
                        }

                        { !!logged.checkqr ? (
                            !!items.length && !checkpointInput.qrcode ? 
                            
                                <React.Fragment>
                                    <h2>Por favor aponte a camera para o código QR</h2>
                                    <QRCodeReader 
                                        onSuccess={qrcode => setCheckpointInput({ ...checkpointInput, qrcode }) }
                                    />
                                </React.Fragment>

                            :

                                <PicarPontoItems
                                    done={done}
                                    items={items}
                                    picarPonto={picarPonto}
                                />
                        ) :
                            !!items.length && (
                            <PicarPontoItems
                                done={done}
                                items={items}
                                picarPonto={picarPonto}
                            />
                            )
                        }
                        
                    </div>
                    }
                </div>
            </Dialog>
        }

    </React.Fragment>
  );

}

export default PicarPonto;
