import React, { useRef } from 'react';
import {FullCalendar} from 'primereact/fullcalendar';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import ptLocale from '@fullcalendar/core/locales/pt';
import moment from 'moment';
import '@fullcalendar/core/main.min.css';
import '@fullcalendar/daygrid/main.min.css';
import '@fullcalendar/timegrid/main.min.css';

function DiaryCalendar(props) {
  const { start, title, items, onChange, dayView, defaultView } = props;
  const fc = useRef();

  const isMobile = window.innerWidth <= 768;

  let calendarOptions = {
    locale: ptLocale,
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    defaultView: defaultView ? defaultView : (dayView ? 'timeGridDay' : 'timeGridWeek'),
    defaultDate: start,
    allDaySlot: false,
    allDayText: false,
    slotDuration: '01:00:00',
    //minTime: '06:00:00',
    scrollTime: '08:00:00',
    height: isMobile ? 250 : 370,
    contentHeight: isMobile ? 250 : 370,
    //contentHeight:"auto",
    timeGridEventMinHeight: 22,
    handleWindowResize:true,
    header: {
        left: 'prev,next',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
    },
    views: {
      dayGridMonth: {
        titleFormat: isMobile ? { year: 'numeric', month: '2-digit' } : { year: 'numeric', month: 'long', day: '2-digit' }
      },
      timeGridWeek: {
        titleFormat: isMobile ? { day: '2-digit' } : { month: 'long', day: '2-digit' }
      },
      timeGridDay: {
        titleFormat: isMobile ? { month: '2-digit', day: '2-digit' } : { year: 'numeric', month: 'long', day: '2-digit' }
      }
    },
    dateClick: function(info) {
      //setSelected(info.dateStr);
    },
    eventClick: function(einfo) {
      console.log(einfo);
    },
    datesRender: function(info) {
      //console.log('datesRender', info);
      //console.log(info.view.activeStart, info.view.currentStart);
      //let start = moment(info.view.activeStart).subtract(1, 'day').format('YYYY-MM-DD');
      //let end = moment(info.view.activeEnd).subtract(1, 'day').format('YYYY-MM-DD');
      let start = moment(info.view.currentStart).format('YYYY-MM-DD');
      let end = moment(info.view.currentEnd).format('YYYY-MM-DD');
      if (onChange) onChange(start, end, info.view.type);
    }
  }

  React.useEffect(() => {
    return () => {
      fc.current.calendar.destroy();
    }
  },[]);

  return (
    <React.Fragment>
        
        <div className="p-grid">
            <div className="p-col-12">

                { !!title && <h5>{ title }</h5> }

                <FullCalendar
                    ref={fc}
                    events={items} 
                    options={calendarOptions} 
                />

            </div>
        </div>
            
    </React.Fragment>
  );

}

export default DiaryCalendar;
