import React, { useState, useEffect, useReducer } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password as InputPassword } from 'primereact/password';
import {Calendar} from 'primereact/calendar';
import {InputSwitch} from 'primereact/inputswitch';
import {Dropdown} from 'primereact/dropdown';
import { Message } from 'primereact/message';
import {FileUpload} from 'primereact/fileupload';
import moment from 'moment';
import roleOptions from './roles';
import durationOptions from '../plans/duration.json';
import loader from '../loader';
import { ColorPicker } from 'primereact/colorpicker';
import { ProgressSpinner } from 'primereact/progressspinner';
import Session from '../auth/Session';
import { InputMask } from 'primereact/inputmask';
import { PickList } from 'primereact/picklist';
import date_pt from '../date_pt.json';

durationOptions.unshift({ value: 0, label: '0 minutos' });

const serverUrl = process.env.REACT_APP_SERVER_URL;

const initialState = {
    email: '',
    username: '',
    password: '',
    confirm: '',
    code: '',
    firstname: '',
    lastname: '',
    birthdate: null,
    active: true,
    organization_id: null,
    role1: 'REGISTERED',
    role2: '',
    extra_time: 5,
    mobile_n: '',
    topics: [],
    checkqr: false
}

function reducer(state, action) {
    switch (action.type) {
      case 'update':
        return {
            ...state,
            [action.field]: action.value
        };
      case 'update_all':
        return {
            ...action.value
        }
      default:
        throw new Error();
    }
}

function UsersForm({ id }) {

  let [ user, dispatch ] = useReducer(reducer, initialState)
  let [ organizations, setOrganizations ] = useState([]);
  let [ clients, setClients ] = useState([]);
  let [ topics, setTopics ] = useState([]);

  let [ errors, setErrors ] = useState({});
  let [ success, setSuccess ] = useState('');
  let [ loading, setLoading ] = useState(false);

  let [ activation, setActivation ] = useState('');

  const logged = Session.getCookie();
  
  const filterByOrganization = (items, logged) => {
    return items.filter(opt => opt.active && (opt.organization_id === logged.organization_id))
  }

  // Load organizations
  useEffect(() => {
    loader.get('/organization/list', res => {
        setOrganizations(res.items);
    }, setLoading);
  }, []);

  // Load clients
  useEffect(() => {
    loader.get('/client/list', res => {
        setClients(filterByOrganization(res.items, logged));
    }, setLoading);
  }, []);

  // Load topics
  useEffect(() => {
    loader.get('/topic/only/parents', res => {
        setTopics(res.items);
    }, setLoading);
  }, []);

  // Load user
  useEffect(() => {
    if (parseInt(id, 10) < 1) {
        return dispatch({ type: 'update_all', value: { ...initialState, color: 'ff0000' } }); 
    }
    loader.get('/user/item/' + id, res => {
        dispatch({ type: 'update_all', value: res.item });
    }, setLoading);
  }, [id]);

  let sendActivationEmail = () => {
    setActivation('loading');
    loader.get('/user/send_activation/' + id, res => {
        setActivation(res.result);
    }, setLoading);
  }

  // Register
  let register = () => {
    let data = {
        ...user,
        birthdate: user.birthdate ? moment(user.birthdate).format('YYYY-MM-DD') : null,
        topics: user.topics.map(f => f.id)
    };
    let url = '/user/create';
    if (user.id) url = '/user/update';
    setSuccess(false);
    loader.post(url, data, res => {
        if (res.errors) return setErrors(res.errors);
        dispatch({ type: 'update', field: 'id', value: res.id });
        setSuccess(true);
    }, setLoading);
  };

  let isDataInvalid = () => {
      return loading
        || user.username.length < 3
        || user.password === '' 
        || user.code.length !== 4 
        || user.password !== user.confirm
        || !user.organization_id
        || (user.notifications && (!user.mobile_n || user.mobile_n.length < 15))
        || (['CLIENT', 'CLIENT_ADMIN'].includes(user.role1) && !user.client_id);
  }

  const organizationOptions = organizations.map(opt => ({
    label: opt.name,
    value: opt.id,
    key: opt.id
  }));

  const clientOptions = clients.map(opt => ({
    label: opt.firstname + ' ' + opt.lastname,
    value: opt.id,
    key: opt.id
  }));

  const availableTopics = topics.filter(item1 => 
    !user.topics.find(item2 => item1.id === item2.id)
  );

  const filteredRoleOptions = roleOptions.filter(item1 => {
    if (item1.value === 'ADMINISTRATOR') {
        return [user.role1, user.role2].includes('ADMINISTRATOR');
    }
    return true;
  });

  // Download
  const getFileUrl = (filename, folder) => {
    return filename ? serverUrl + '/download/' + folder + '/' + filename
        : '/assets/images/photo_placeholder.png';
  }

  // Upload
  const onUpload = (e) => {
    if (e.files.length === 0) return;

    const formData  = new FormData();
    formData.append('folder', 'users');
    formData.append('upload', e.files[0]);
    const url = '/upload';
    loader.upload(url, formData, res => {
        if (!res.filename) return;
        dispatch({ type: 'update', field: 'photo', value: res.filename });  
      }, setLoading);
  }

  const onRemoveAvatar = (e, file) => {
    dispatch({ type: 'update', field: 'photo', value: '' });
  }

  let displayErrors = (errors, key) => !!errors[key] && errors[key].map(msg =>
    <Message severity="error" text={msg}></Message>
  )

  if (loading) return <ProgressSpinner />;
  return (
    <React.Fragment>
        
        <div className="card card-w-title">
            <h1>Ficha do Utilizador</h1>

            { success && (
                <div className="p-grid">
                    <div className="p-col-12">

                        <Message 
                            severity="success" 
                            text="O registo foi concluido com sucesso!">
                        </Message>
                        
                    </div>
                </div>
            ) }

            <Button 
                onClick={register} 
                label="Guardar"
                disabled={isDataInvalid()}
                style={{ float: 'right' }}
            />
            <p className="form-info">
                <strong style={{ color: 'red' }}>*</strong> 
                {' '}Campos obrigatórios
            </p>

            <div className="p-grid">
                <div className="p-col-12 p-lg-6">
                    <div className="p-grid">
                        <div className="p-col-6 p-md-6">
                            <label className="field-required">Endereço de E-mail</label>
                        </div>
                        <div className="p-col-6 p-md-6">
                            <InputText 
                                placeholder="Endereço de E-mail"
                                value={user.email}
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'email', value: e.target.value })
                                }
                            />
                            { displayErrors(errors, 'email') }
                        </div>
                        <div className="p-col-6 p-md-6">
                            <label className="field-required">Nome de Utilizador</label>
                        </div>
                        <div className="p-col-6 p-md-6">
                            <InputText 
                                placeholder="Nome de Utilizador"
                                value={user.username}
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'username', value: e.target.value })
                                }
                            />
                            <p className="field-help">(min 3 caracteres)</p>
                            { displayErrors(errors, 'username') }
                        </div>
                        <div className="p-col-6 p-md-6">
                            <label className="field-required">Palavra-passe</label>
                        </div>
                        <div className="p-col-6 p-md-6">
                            <InputPassword 
                                placeholder="Password" 
                                value={user.password || ''}
                                feedback={false}
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'password', value: e.target.value })
                                }
                            />
                            { displayErrors(errors, 'password') }
                        </div>
                        <div className="p-col-6 p-md-6">
                            <label className="field-required">Confirmar</label>
                        </div>
                        <div className="p-col-6 p-md-6">
                            <InputPassword 
                                placeholder="Confirmar Password" 
                                feedback={false}
                                value={user.confirm || ''}
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'confirm', value: e.target.value })
                                }
                            />
                        </div>
                        <div className="p-col-6 p-md-6">
                            <label className="field-required">Código PIN</label>
                        </div>
                        <div className="p-col-6 p-md-6">
                            <InputPassword 
                                placeholder="Código PIN" 
                                feedback={false}
                                value={user.code || ''}
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'code', value: e.target.value })
                                }
                            />
                            <p className="field-help">(4 algarismos)</p>
                            { displayErrors(errors, 'code') }
                        </div>

                        <div className="p-col-12 p-md-6">
                            <label className="field-required">Organização</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <Dropdown 
                                placeholder="Escolha uma organização"
                                value={parseInt(user.organization_id, 10)}
                                options={organizationOptions} 
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'organization_id', value: e.value })
                                }
                            />
                        </div>

                        <div className="p-col-12 p-md-6">
                            <label className="field-required">Função/Cargo Primário</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <Dropdown 
                                value={user.role1}
                                options={filteredRoleOptions.filter(opt => opt.value !== user.role2)} 
                                placeholder="Escolha uma função"
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'role1', value: e.value })
                                }
                            />
                        </div>

                        <div className="p-col-12 p-md-6">
                            <label>Função/Cargo Secundário</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <Dropdown 
                                value={user.role2}
                                options={filteredRoleOptions.filter(opt => opt.value !== user.role1)} 
                                placeholder="Escolha uma função"
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'role2', value: e.value })
                                }
                            />
                        </div>

                        { ['CLIENT', 'CLIENT_ADMIN'].includes(user.role1) ? (
                            <React.Fragment>
                                <div className="p-col-12 p-md-6">
                                    <label className={"field-required"}>Cliente Associado</label>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <Dropdown 
                                        placeholder="Escolha um cliente"
                                        value={parseInt(user.client_id, 10)}
                                        options={clientOptions} 
                                        onChange={(e) => 
                                            dispatch({ type: 'update', field: 'client_id', value: e.value })
                                        }
                                    />
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <div className="p-col-12 p-md-6">
                                    <label>Máx. Horas Extraordinárias</label>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <Dropdown 
                                        value={user.extra_time}
                                        options={durationOptions} 
                                        placeholder="Escolha uma opção"
                                        onChange={(e) => 
                                            dispatch({ type: 'update', field: 'extra_time', value: e.value })
                                        }
                                    />
                                </div>
                            </React.Fragment>
                        )}

                        <div className="p-col-10 p-md-6">
                            <label>Activo</label>
                        </div>
                        <div className="p-col-2 p-md-6">
                            <InputSwitch 
                                checked={user.active} 
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'active', value: !user.active })
                                } 
                            />
                        </div>

                        { !!user.id && !!user.email && !user.active &&
                            <React.Fragment>
                                <div className="p-col-12 p-md-6">
                                    <label>Código de Ativação</label>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <Button
                                        type="button"
                                        style={{ width: '99%' }}
                                        label="Enviar"
                                        onClick={e => sendActivationEmail()} 
                                    />
                                    { activation ? <Message style={{ width: '99%' }} severity="info" text={activation}></Message> : ''}
                                </div>
                            </React.Fragment>
                        }
                        
                    </div>
                </div>
                <div className="p-col-12 p-lg-6">
                    <div className="p-grid">

                        <div className="p-col-10 p-md-6">
                            <label>Picagem de Ponto por Código QR</label>
                        </div>
                        <div className="p-col-2 p-md-6">
                            <InputSwitch 
                                checked={user.checkqr} 
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'checkqr', value: !user.checkqr })
                                } 
                            />
                        </div>

                        { !!user.color && (
                            <React.Fragment>
                                <div className="p-col-12 p-md-6">
                                    <label>Côr de Etiqueta</label>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <ColorPicker
                                        value={user.color} 
                                        onChange={(e) => 
                                            dispatch({ type: 'update', field: 'color', value: e.value })
                                        }
                                    />
                                </div>
                            </React.Fragment>
                        )}

                        { ['ADMINISTRATOR'].includes(user.role1) ? (
                            <React.Fragment>
                                <div className="p-col-6 p-md-6">
                                    <label>Customer E-mail</label>
                                </div>
                                <div className="p-col-6 p-md-6">
                                    <InputText 
                                        placeholder="Customer E-mail"
                                        value={user.customer_id}
                                        onChange={(e) => 
                                            dispatch({ type: 'update', field: 'customer_id', value: e.target.value })
                                        }
                                    />
                                </div>
                            </React.Fragment>
                        ) : null }

                        <div className="p-col-6 p-md-6">
                            <label>Primeiro Nome</label>
                        </div>
                        <div className="p-col-6 p-md-6">
                            <InputText 
                                placeholder="Primeiro Nome"
                                value={user.firstname}
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'firstname', value: e.target.value })
                                }
                            />
                            { displayErrors(errors, 'firstname') }
                        </div>

                        <div className="p-col-6 p-md-6">
                            <label>Último Nome</label>
                        </div>
                        <div className="p-col-6 p-md-6">
                            <InputText 
                                placeholder="Último Nome"
                                value={user.lastname}
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'lastname', value: e.target.value })
                                }
                            />
                            { displayErrors(errors, 'lastname') }
                        </div>

                        <div className="p-col-12 p-md-6">
                            <label>Data de Nascimento</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <Calendar 
                                placeholder="Data de Nascimento"
                                value={user.birthdate} 
                                locale={date_pt}
                                dateFormat="yy-mm-dd"
                                yearNavigator={true}
                                yearRange="1920:2010"
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'birthdate', value: e.value })
                                }>
                            </Calendar>
                        </div>

                        <div className="p-col-6 p-md-6">
                            <label>Notificações SMS</label>
                        </div>
                        <div className="p-col-6 p-md-6">
                            <InputSwitch 
                                checked={user.notifications} 
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'notifications', value: !user.notifications })
                                } 
                            />
                        </div>

                        <div className="p-col-6 p-md-6">
                            <label className={user.notifications ? "field-required" : ""}>
                                Telemóvel
                            </label>
                        </div>
                        <div className="p-col-6 p-md-6">
                            <InputMask
                                mask="+999 999 999 999?99"
                                placeholder="Telemóvel"
                                value={user.mobile_n}
                                onChange={(e) => 
                                    dispatch({ type: 'update', field: 'mobile_n', value: e.target.value })
                                }
                            />
                        </div>

                        <div className="p-col-12 p-md-6">
                            <label>Fotografia</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                        { !!user.photo && (
                            <img 
                                src={getFileUrl(user.photo, 'users')} 
                                alt="Fotografia"
                                title="Fotografia"
                                className="img-avatar"
                            />
                        ) }
                        <FileUpload 
                            name="photo"
                            mode="basic"
                            url={'/upload'}
                            customUpload={true}
                            uploadHandler={onUpload}
                            auto={true}
                            chooseLabel={user.photo ? "Alterar" : "Escolher" }
                            onRemove={onRemoveAvatar}
                        />
                        </div>
                    </div>
                </div>
            </div>

            <div className="card card-w-title">
                <h2>Categorias Permitidas</h2>
                <PickList
                    source={availableTopics} 
                    target={user.topics} 
                    sourceHeader="Categorias disponíveis"
                    targetHeader="Categorias permitidas"
                    itemTemplate={item => item.title}
                    responsive={false}
                    onChange={(e) => 
                        dispatch({ type: 'update', field: 'topics', value: e.target })
                    }
                />
            </div>

        </div>

    </React.Fragment>
  );

}

export default UsersForm;
