import React from 'react';
import {TabMenu} from 'primereact/tabmenu';
import { useHistory } from "react-router-dom";
import { hasMod } from './Permissions';

function UsersMenu({ user, activeMenu }) {
    
    let history = useHistory();
    const { id } = user;

    const items = [
        {id: "form", label: 'Ficha', icon: 'pi pi-fw pi-pencil', url: '#/backoffice/users/form/' + id},
        {id: "contacts", label: 'Contactos', icon: 'pi pi-fw pi-user', url: '#/backoffice/users/contacts/' + id},
        {id: "failed", label: 'Tentativas de Login', icon: 'pi pi-fw pi-times', url: '#/backoffice/users/failed/' + id}
    ];

    // Add worker menus
    const clientRoles = ['CLIENT', 'CLIENT_ADMIN'];
    if (!clientRoles.includes(user.role1)) {
        if (hasMod(['T4CSC1'])) items.push({id: "workplans", label: 'Horários', icon: 'pi pi-fw pi-clock', url: '#/backoffice/users/workplans/' + id});
        if (hasMod(['T4CFP1'])) items.push({id: "checkpoints", label: 'Folha de Ponto', icon: 'pi pi-fw pi-calendar', url: '#/backoffice/users/checkpoints/' + id});
    }

    let activeItem = items.find(i => i.id === activeMenu);
    return (
        <React.Fragment>
            <TabMenu id="clientmenu" 
                model={items} 
                activeItem={activeItem}
                onTabChange={(e) => history.push({ pathname: e.value.url })}
            />
        </React.Fragment>
    );
}

export default UsersMenu;